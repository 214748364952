
/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Snehal More
@Creation Date:8-04-2021
@Description:UI for Publisher MyReport under publisher view(under story 3357) to display tables
*/
//snehal-task-publisher side----agency lead tracking report---drop down and table header issue(width is removed)
import React from "react";
const Column = [
 //snehal-3543-publisher side----lead tracking---missing popup issue
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>PID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "pID",
        key: "pID",
        width: "10%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.pID < b.pID) {
                return asc ? -1 : 1;
            } else if (a.pID > b.pID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.pID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "campID",
        key: "campID",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.campID < b.campID) {
                return asc ? -1 : 1;
            } else if (a.campID > b.campID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.campID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Lead Interaction Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "leadInteractionDate",
        key: "leadInteractionDate",
        width: "22%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.leadInteractionDate < b.leadInteractionDate) {
                return asc ? -1 : 1;
            } else if (a.leadInteractionDate > b.leadInteractionDate) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.leadInteractionDate}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Email&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "email",
        key: "email",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.email.toUpperCase() < b.email.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.email.toUpperCase() > b.email.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.email}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>First Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "firstName",
        key: "firstName",
        width: "15%",
        align: "left",
        sorter: (a, b) => a.firstName - b.firstName,
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.firstName}
                </span>
                <br />
            </div>

        ),

    }
    ,
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Last Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "15%",
        align: "left",
        sorter: (a, b) => a.lastName - b.lastName,
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.lastName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "campaignName",
        key: "campaignName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.campaignName < b.campaignName) {
                return asc ? -1 : 1;
            } else if (a.campaignName > b.campaignName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.campaignName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>LinkedIn Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInCompanyName",
        key: "linkedInCompanyName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInCompanyName < b.linkedInCompanyName) {
                return asc ? -1 : 1;
            } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.linkedInCompanyName == "" || record.linkedInCompanyName == undefined || record.linkedInCompanyName == null || record.linkedInCompanyName == "undefined" || record.linkedInCompanyName == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.linkedInCompanyName}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobTitle < b.jobTitle) {
                return asc ? -1 : 1;
            } else if (a.jobTitle > b.jobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span id="to_truncate1" style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.jobTitle}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>LinkedIn Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInJobTitle",
        key: "linkedInJobTitle",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInJobTitle < b.linkedInJobTitle) {
                return asc ? -1 : 1;
            } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.linkedInJobTitle == "" || record.linkedInJobTitle == undefined || record.linkedInJobTitle == null || record.linkedInJobTitle == "undefined" || record.linkedInJobTitle == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.linkedInJobTitle}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Job Level&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobLevel",
        key: "jobLevel",
        width: "14%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobLevel < b.jobLevel) {
                return asc ? -1 : 1;
            } else if (a.jobLevel > b.jobLevel) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.jobLevel}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Job Function&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobFunction",
        key: "jobFunction",
        width: "14%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobFunction < b.jobFunction) {
                return asc ? -1 : 1;
            } else if (a.jobFunction > b.jobFunction) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.jobFunction}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Address&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "address",
        key: "address",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.address < b.address) {
                return asc ? -1 : 1;
            } else if (a.address > b.address) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.address}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>City&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "city",
        key: "city",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.city < b.city) {
                return asc ? -1 : 1;
            } else if (a.city > b.city) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.city}
                </span>
                <br />
            </div>
        ),
    },

    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Zip Code&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "zipCode",
        key: "zipCode",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.zipCode < b.zipCode) {
                return asc ? -1 : 1;
            } else if (a.zipCode > b.zipCode) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.zipCode}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Phone No.&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "workPhone",
        key: "workPhone",
        width: "15%",

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.workPhone < b.workPhone) {
                return asc ? -1 : 1;
            } else if (a.workPhone > b.workPhone) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.workPhone}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Company Revenue&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyRevenue",
        key: "companyRevenue",
        width: "16%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyRevenue < b.companyRevenue) {
                return asc ? -1 : 1;
            } else if (a.companyRevenue > b.companyRevenue) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.companyRevenue}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Company Employee Size&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyEmployeeSize",
        key: "companyEmployeeSize",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyEmployeeSize < b.companyEmployeeSize) {
                return asc ? -1 : 1;
            } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.companyEmployeeSize}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetName",
        key: "assetName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetName < b.assetName) {
                return asc ? -1 : 1;
            } else if (a.assetName > b.assetName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetName}
                </span>
                <br />
            </div>
        ),
    },

    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Voice Log Link&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "voiceLogLink",
        key: "voiceLogLink",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.voiceLogLink < b.voiceLogLink) {
                return asc ? -1 : 1;
            } else if (a.voiceLogLink > b.voiceLogLink) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.voiceLogLink}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Channel&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "channel",
        key: "channel",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.channel < b.channel) {
                return asc ? -1 : 1;
            } else if (a.channel > b.channel) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.channel}
                </span>
                <br />
            </div>
        ),
    },


    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Industry&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "industry",
        key: "industry",
        width: "15%",

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.industry.toUpperCase() > b.industry.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.industry}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>IP&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "ip",
        key: "ip",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.ip < b.ip) {
                return asc ? -1 : 1;
            } else if (a.ip > b.ip) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.ip}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "supportDocID",
        key: "supportDocID",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";
            if (a.supportDocID < b.supportDocID) {
                return asc ? -1 : 1;
            } else if (a.supportDocID > b.supportDocID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.supportDocID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Touch1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetNameTouch1",
        key: "assetNameTouch1",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetNameTouch1 < b.assetNameTouch1) {
                return asc ? -1 : 1;
            } else if (a.assetNameTouch1 > b.assetNameTouch1) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetNameTouch1}
                </span>
                <br />
            </div>
        ),
    },
    // {
    //     title: <span style={{ fontSize: '12px', color: 'white' }}>Multi Touch&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "assetNameTouch1",
    //     key: "assetNameTouch1",
    //     width: "25%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.assetNameTouch1 < b.assetNameTouch1) {
    //             return asc ? -1 : 1;
    //         } else if (a.assetNameTouch1 > b.assetNameTouch1) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div id="to_truncate1">
    //             <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
    //                 {record.assetNameTouch1}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Timestamp Touch1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetTimestampTouch1",
        key: "assetTimestampTouch1",
        width: "27%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetTimestampTouch1 < b.assetTimestampTouch1) {
                return asc ? -1 : 1;
            } else if (a.assetTimestampTouch1 > b.assetTimestampTouch1) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetTimestampTouch1}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Touch2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetNameTouch2",
        key: "assetNameTouch2",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetNameTouch2 < b.assetNameTouch2) {
                return asc ? -1 : 1;
            } else if (a.assetNameTouch2 > b.assetNameTouch2) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetNameTouch2}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Timestamp Touch2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetTimestampTouch2",
        key: "assetTimestampTouch2",
        width: "27%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetTimestampTouch2 < b.assetTimestampTouch2) {
                return asc ? -1 : 1;
            } else if (a.assetTimestampTouch2 > b.assetTimestampTouch2) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetTimestampTouch2}
                </span>
                <br />
            </div>
        ),
    },

    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Touch3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetNameTouch3",
        key: "assetNameTouch3",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetNameTouch3 < b.assetNameTouch3) {
                return asc ? -1 : 1;
            } else if (a.assetNameTouch3 > b.assetNameTouch3) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetNameTouch3}
                </span>
                <br />
            </div>
        ),
    },


    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Asset Name Timestamp Touch3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetTimestampTouch3",
        key: "assetTimestampTouch3",
        width: "27%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetTimestampTouch3 < b.assetTimestampTouch3) {
                return asc ? -1 : 1;
            } else if (a.assetTimestampTouch3 > b.assetTimestampTouch3) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.assetTimestampTouch3}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Custom Questions&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "customQuestionAliasName",
        key: "customQuestionAliasName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.customQuestionAliasName < b.customQuestionAliasName) {
                return asc ? -1 : 1;
            } else if (a.customQuestionAliasName > b.customQuestionAliasName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.customQuestionAliasName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra1",
        key: "extra1",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra1 < b.extra1) {
                return asc ? -1 : 1;
            } else if (a.extra1 > b.extra1) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra1 == "" || record.extra1 == undefined || record.extra1 == null || record.extra1 == "undefined" || record.extra1 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra1}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>State&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "state",
        key: "state",
        width: "14%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.state < b.state) {
                return asc ? -1 : 1;
            } else if (a.state > b.state) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.state}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Country&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "country",
        key: "country",
        width: "14%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.country < b.country) {
                return asc ? -1 : 1;
            } else if (a.country > b.country) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.country}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Street&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "street",
        key: "street",
        width: "12%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.street < b.street) {
                return asc ? -1 : 1;
            } else if (a.street > b.street) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.street == "" || record.street == undefined || record.street == null || record.street == "undefined" || record.street == "null" ?
                ""
                :
                <div>
                    <span id="to_truncate1" style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.street}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra2",
        key: "extra2",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra2 < b.extra2) {
                return asc ? -1 : 1;
            } else if (a.extra2 > b.extra2) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra2 == "" || record.extra2 == undefined || record.extra2 == null || record.extra2 == "undefined" || record.extra2 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra2}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra3",
        key: "extra3",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra3 < b.extra3) {
                return asc ? -1 : 1;
            } else if (a.extra3 > b.extra3) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra3 == "" || record.extra3 == undefined || record.extra3 == null || record.extra3 == "undefined" || record.extra3 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra3}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 4&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra4",
        key: "extra4",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra4 < b.extra4) {
                return asc ? -1 : 1;
            } else if (a.extra4 > b.extra4) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra4 == "" || record.extra4 == undefined || record.extra4 == null || record.extra4 == "undefined" || record.extra4 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra4}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 5&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra5",
        key: "extra5",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra5 < b.extra5) {
                return asc ? -1 : 1;
            } else if (a.extra5 > b.extra5) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra5 == "" || record.extra5 == undefined || record.extra5 == null || record.extra5 == "undefined" || record.extra5 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra5}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 6&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra6",
        key: "extra6",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra6 < b.extra6) {
                return asc ? -1 : 1;
            } else if (a.extra6 > b.extra6) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra6 == "" || record.extra6 == undefined || record.extra6 == null || record.extra6 == "undefined" || record.extra6 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra6}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 7&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra7",
        key: "extra7",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra7 < b.extra7) {
                return asc ? -1 : 1;
            } else if (a.extra7 > b.extra7) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra7 == "" || record.extra7 == undefined || record.extra7 == null || record.extra7 == "undefined" || record.extra7 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra7}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 8&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra8",
        key: "extra8",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra8 < b.extra8) {
                return asc ? -1 : 1;
            } else if (a.extra8 > b.extra8) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra8 == "" || record.extra8 == undefined || record.extra8 == null || record.extra8 == "undefined" || record.extra8 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra8}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 9&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra9",
        key: "extra9",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra9 < b.extra9) {
                return asc ? -1 : 1;
            } else if (a.extra9 > b.extra9) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra9 == "" || record.extra9 == undefined || record.extra9 == null || record.extra9 == "undefined" || record.extra9 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra9}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 10&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra10",
        key: "extra10",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra10 < b.extra10) {
                return asc ? -1 : 1;
            } else if (a.extra10 > b.extra10) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra10 == "" || record.extra10 == undefined || record.extra10 == null || record.extra10 == "undefined" || record.extra10 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra10}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 11&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra11",
        key: "extra11",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra11 < b.extra11) {
                return asc ? -1 : 1;
            } else if (a.extra11 > b.extra11) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra11 == "" || record.extra11 == undefined || record.extra11 == null || record.extra11 == "undefined" || record.extra11 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra11}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 12&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra12",
        key: "extra12",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra12 < b.extra12) {
                return asc ? -1 : 1;
            } else if (a.extra12 > b.extra12) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra12 == "" || record.extra12 == undefined || record.extra12 == null || record.extra12 == "undefined" || record.extra12 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra12}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 13&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra13",
        key: "extra13",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra13 < b.extra13) {
                return asc ? -1 : 1;
            } else if (a.extra13 > b.extra13) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra13 == "" || record.extra13 == undefined || record.extra13 == null || record.extra13 == "undefined" || record.extra13 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra13}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 14&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra14",
        key: "extra14",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra14 < b.extra14) {
                return asc ? -1 : 1;
            } else if (a.extra14 > b.extra14) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra14 == "" || record.extra14 == undefined || record.extra14 == null || record.extra14 == "undefined" || record.extra14 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra14}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 15&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra15",
        key: "extra15",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra15 < b.extra15) {
                return asc ? -1 : 1;
            } else if (a.extra15 > b.extra15) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra15 == "" || record.extra15 == undefined || record.extra15 == null || record.extra15 == "undefined" || record.extra15 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra15}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 16&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra16",
        key: "extra16",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra16 < b.extra16) {
                return asc ? -1 : 1;
            } else if (a.extra16 > b.extra16) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra16 == "" || record.extra16 == undefined || record.extra16 == null || record.extra16 == "undefined" || record.extra16 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra16}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 17&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra17",
        key: "extra17",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra17 < b.extra17) {
                return asc ? -1 : 1;
            } else if (a.extra17 > b.extra17) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra17 == "" || record.extra17 == undefined || record.extra17 == null || record.extra17 == "undefined" || record.extra17 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra17}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 18&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra18",
        key: "extra18",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra18 < b.extra18) {
                return asc ? -1 : 1;
            } else if (a.extra18 > b.extra18) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra18 == "" || record.extra18 == undefined || record.extra18 == null || record.extra18 == "undefined" || record.extra18 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra18}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 19&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra19",
        key: "extra19",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra19 < b.extra19) {
                return asc ? -1 : 1;
            } else if (a.extra19 > b.extra19) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra19 == "" || record.extra19 == undefined || record.extra19 == null || record.extra19 == "undefined" || record.extra19 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra19}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 20&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra20",
        key: "extra20",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra20 < b.extra20) {
                return asc ? -1 : 1;
            } else if (a.extra20 > b.extra20) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra20 == "" || record.extra20 == undefined || record.extra20 == null || record.extra20 == "undefined" || record.extra20 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra20}
                    </span>
                    <br />
                </div>
        ),
    },
    // Rutuja task 5304 added extra 21-30 fields
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 21&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra21",
        key: "extra21",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra21 < b.extra21) {
                return asc ? -1 : 1;
            } else if (a.extra21 > b.extra21) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra21 == "" || record.extra21 == undefined || record.extra21 == null || record.extra21 == "undefined" || record.extra21 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra21}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 22&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra22",
        key: "extra22",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra22 < b.extra22) {
                return asc ? -1 : 1;
            } else if (a.extra22 > b.extra22) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra22 == "" || record.extra22 == undefined || record.extra22 == null || record.extra22 == "undefined" || record.extra22 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra22}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 23&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra23",
        key: "extra23",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra23 < b.extra23) {
                return asc ? -1 : 1;
            } else if (a.extra23 > b.extra23) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra23 == "" || record.extra23 == undefined || record.extra23 == null || record.extra23 == "undefined" || record.extra23 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra23}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 24&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra24",
        key: "extra24",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra24 < b.extra24) {
                return asc ? -1 : 1;
            } else if (a.extra24 > b.extra24) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra24 == "" || record.extra24 == undefined || record.extra24 == null || record.extra24 == "undefined" || record.extra24 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra24}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 25&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra25",
        key: "extra25",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra25 < b.extra25) {
                return asc ? -1 : 1;
            } else if (a.extra25 > b.extra25) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra25 == "" || record.extra25 == undefined || record.extra25 == null || record.extra25 == "undefined" || record.extra25 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra25}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 26&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra26",
        key: "extra26",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra26 < b.extra26) {
                return asc ? -1 : 1;
            } else if (a.extra26 > b.extra26) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra26 == "" || record.extra26 == undefined || record.extra26 == null || record.extra26 == "undefined" || record.extra26 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra26}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 27&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra27",
        key: "extra27",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra27 < b.extra27) {
                return asc ? -1 : 1;
            } else if (a.extra27 > b.extra27) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra27 == "" || record.extra27 == undefined || record.extra27 == null || record.extra27 == "undefined" || record.extra27 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra27}
                    </span>
                    <br />
                </div>
        ),
    },{
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 28&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra28",
        key: "extra28",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra28 < b.extra28) {
                return asc ? -1 : 1;
            } else if (a.extra28 > b.extra28) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra28 == "" || record.extra28 == undefined || record.extra28 == null || record.extra28 == "undefined" || record.extra28 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra28}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 29&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra29",
        key: "extra29",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra29 < b.extra29) {
                return asc ? -1 : 1;
            } else if (a.extra29 > b.extra29) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra29 == "" || record.extra29 == undefined || record.extra29 == null || record.extra29 == "undefined" || record.extra29 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra29}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Extra 30&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra30",
        key: "extra30",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra30 < b.extra30) {
                return asc ? -1 : 1;
            } else if (a.extra30 > b.extra30) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.extra30 == "" || record.extra30 == undefined || record.extra30 == null || record.extra30 == "undefined" || record.extra30 == "null" ?
                ""
                :
                <div id="to_truncate1">
                    <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                        {record.extra30}
                    </span>
                    <br />
                </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Reallocation ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "reAllocationID",
        key: "reAllocationID",
        width: "16%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.reAllocationID < b.reAllocationID) {
                return asc ? -1 : 1;
            } else if (a.reAllocationID > b.reAllocationID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.reAllocationID}
                </span>
                <br />
            </div>
        ),
    },

    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Domain&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "domain",
        key: "domain",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.domain < b.domain) {
                return asc ? -1 : 1;
            } else if (a.domain > b.domain) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.domain}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Alternate Phone No&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "alternatePhoneNo",
        key: "alternatePhoneNo",
        width: "22%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.alternatePhoneNo < b.alternatePhoneNo) {
                return asc ? -1 : 1;
            } else if (a.alternatePhoneNo > b.alternatePhoneNo) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.alternatePhoneNo}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>LinkedIn&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedIn",
        key: "linkedIn",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedIn < b.linkedIn) {
                return asc ? -1 : 1;
            } else if (a.linkedIn > b.linkedIn) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.linkedIn}
                </span>
                <br />
            </div>
        ),
    },


    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Comments&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "comments",
        key: "comments",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.comments < b.comments) {
                return asc ? -1 : 1;
            } else if (a.comments > b.comments) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.comments}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Parent CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "parentCampID",
        key: "parentCampID",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.parentCampID < b.parentCampID) {
                return asc ? -1 : 1;
            } else if (a.parentCampID > b.parentCampID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.parentCampID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Lead Info ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: "13%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.leadInfoID < b.leadInfoID) {
                return asc ? -1 : 1;
            } else if (a.leadInfoID > b.leadInfoID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.leadInfoID}
                </span>
                <br />
            </div>
        ),
    },




    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Last Updated&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.lastUpdated < b.lastUpdated) {
                return asc ? -1 : 1;
            } else if (a.lastUpdated > b.lastUpdated) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.lastUpdated}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyName",
        key: "companyName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyName < b.companyName) {
                return asc ? -1 : 1;
            } else if (a.companyName > b.companyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.companyName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span style={{ fontSize: '12px', color: 'white' }}>Status&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "status",
        key: "status",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.status < b.status) {
                return asc ? -1 : 1;
            } else if (a.status > b.status) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.status}
                </span>
                <br />
            </div>
        ),
    },


]
export { Column };