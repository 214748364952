/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
 * @file navSwitchClient 
 * @author  Aman Shaikh - 4619
 * @version 1.0
 * @section DESCRIPTION Navigation bar for multiple role users login switch Page 
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-bug-4139

class Navigation extends React.Component {
  onLogout(e) {
    e.preventDefault();
    //Sonali-3809-logout from LiByAi page--placing removeCookies function befor userLogout
    fetch("/removeCookie", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => { })
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    const { isAuthenticated, user } = this.props.auth;
    let data = {
      //user: user, //Akash-bug-4139 removed user
    };
    fetch("users/userLogout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => { })
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    this.props.logoutUser(this.props.history);
    localStorage.removeItem("activeKey");
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div>
        <nav
          className="navbar navbar-expand-md navbar-dark navbar-fixed-top shadow"
          id="navbar_navpage_font_css"
        >
          {/* Nilesh-5131- Add MbleadHub logo image*/}
           {/* rutuja-5131- Add leads -nexus logo image*/}
          <img
            src="Leads-Nexus.png"
            alt="Leads-Nexus"
            height="50px"
            width="200px"
            id="navbar_navpage_logo_css"
            style={{ pointerEvents: "none"}}
          ></img>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Aman Task - 4619 -(Role & Name after login) PC/PNC-Login - Client Switch option */}
            {isAuthenticated ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp;{user.lastName}
                </b>
                &nbsp;-
                {user.role === "AC" ? <b> Agency Commercial</b> : ""}
                {user.role === "ANC" ? <b> Agency Non Commercial</b> : ""}
                {user.role === "AQA" ? <b> Agency Quality Assurance</b> : ""}
                {user.role === "AC-ACCOUNT" ? <b> Agency Accounting</b> : ""}
                {user.role === "PC" ? <b> Publisher Commercial</b> : ""}
                {user.role === "PNC" ? <b> Publisher Non Commercial</b> : ""}
                {user.role === "PQA" ? <b> Publisher Quality Assurance</b> : ""}
                {user.role === "PC-ACCOUNT" ? <b> Publisher Accounting</b> : ""}
                {user.role === "ADV" ? <b> Advertiser</b> : ""}
                {user.role === "DIQA" ? <b> DI Quality Analyst</b> : ""}
              </h5>
            )
            : (
              ""
            )}
            {/* Aman Task - 4619 -(LogOut Button) PC/PNC-Login - Client Switch option */}
            <div className="navbar-nav navbar-right">
                <li class="nav-item" style={{listStyle: "none", marginRight: "20px"}}>
                    <a
                        class="abc nav-link"
                        style={{ color: "aliceblue" }}
                        href="#"
                        onClick={this.onLogout.bind(this)}
                    >
                        {" "}
                        <span
                        class="glyphicon glyphicon-log-out"
                        style={{ color: "aliceblue" }}
                        ></span>
                    </a>
                </li>
            </div>
          </div>
        </nav>
     <Footer/> 
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
Navigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Navigation));
