/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Snehal More
@Creation Date:08-04-2021
@Description:UI for Publisher MyReport under publisher view(under story 3357)
*/
import React from "react";
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Checkbox } from "antd";//kiran-2836-removing console warning-remove unnecessary
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { css } from 'emotion';
// import styled from "styled-components"; //kiran-2836-removing console warning-not used
import Picky from "react-picky";
import { Column } from './columnsRenderArray';
import { properties } from '../status/leadStatusFE';
// import { useHistory } from "react-router-dom"; //kiran-2836-removing console warning-not used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Sandeep-task-3730-added default header
// const pickystyle_report = styled(Picky)`
// 	 background-color: #bd141480 !important;
//    border-radius: '4px !important'
//    #-list.picky__dropdown{
//      overflow:'hidden !important';
//    }
// 	 .picky {
// 		 width: 20% !important;
// 	 }
//  `;
//kiran-2836-removing console warning-not used
let z;
let a = 60;
if (a=== 60) {
  z = 100 * a
}
const Column1 = [
  {
     //snehal-3543-publisher side----lead tracking---missing popup issue
    title: <span>PID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "pID",
    key: "pID",
    width: "10%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.pID < b.pID) {
        return asc ? -1 : 1;
      } else if (a.pID > b.pID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.pID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "campID",
    key: "campID",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.campID < b.campID) {
        return asc ? -1 : 1;
      } else if (a.campID > b.campID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.campID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Lead Interaction Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "leadInteractionDate",
    key: "leadInteractionDate",
    width: "22%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.leadInteractionDate < b.leadInteractionDate) {
        return asc ? -1 : 1;
      } else if (a.leadInteractionDate > b.leadInteractionDate) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.leadInteractionDate}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Email&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "email",
    key: "email",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (
        a.email.toUpperCase() > b.email.toUpperCase()
      ) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.email}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>First Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "firstName",
    key: "firstName",
    width: "15%",
    align: "left",
    sorter: (a, b) => a.firstName - b.firstName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.firstName}
        </span>
        <br />
      </div>

    ),

  }
  ,
  {
    title: <span>Last Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "lastName",
    key: "lastName",
    width: "15%",
    align: "left",
    sorter: (a, b) => a.lastName - b.lastName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.lastName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "campaignName",
    key: "campaignName",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.campaignName < b.campaignName) {
        return asc ? -1 : 1;
      } else if (a.campaignName > b.campaignName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.campaignName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "jobTitle",
    key: "jobTitle",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobTitle < b.jobTitle) {
        return asc ? -1 : 1;
      } else if (a.jobTitle > b.jobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span id="to_truncate1" style={{ fontSize: "12px" }}>
          {record.jobTitle}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Job Level&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "jobLevel",
    key: "jobLevel",
    width: "14%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobLevel < b.jobLevel) {
        return asc ? -1 : 1;
      } else if (a.jobLevel > b.jobLevel) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.jobLevel}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Job Function&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "jobFunction",
    key: "jobFunction",
    width: "14%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobFunction < b.jobFunction) {
        return asc ? -1 : 1;
      } else if (a.jobFunction > b.jobFunction) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.jobFunction}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Address&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "address",
    key: "address",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.address < b.address) {
        return asc ? -1 : 1;
      } else if (a.address > b.address) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "12px" }}>
          {record.address}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: <span>Industry&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    dataIndex: "industry",
    key: "industry",
    width: "15%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (
        a.industry.toUpperCase() > b.industry.toUpperCase()
      ) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1">
        <span style={{ fontSize: "12px" }}>
          {record.industry}
        </span>
        <br />
      </div>
    ),
  },

];
const Swal = require("sweetalert2");
// const queryString = require("query-string");//kiran-2836-removing console warning-not used
const tableCSS = css({
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontFamily: "sans-serif",
    fontSize: '13px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    //border: '1px solid black',
    color: 'white',
  }
});
const campaignStatusDisplay = [
  { id: "Active", name: "Active" },
  { id: "Paused", name: "Paused" },
  { id: "Completed", name: "Completed" },
];
const leadStatusDisplay = [
  { id: "Accepted", name: "Accepted" },
  { id: "Rejected", name: "Rejected" },
  { id: "In QA", name: "In QA" },
];
const columnNameArray = [
  { id: 'Campaign ID', name: 'campID', value: 'No' },
  { id: 'Publisher ID', name: 'pID', value: 'No' },
  { id: 'Campaign Name', name: 'campaignName', value: 'No' },
  { id: 'Lead Interaction Day', name: 'leadInteractionDate', value: 'No' },
  { id: 'Email', name: 'email', value: 'No' },
  { id: 'Work Phone', name: 'workPhone', value: 'No' },
  { id: 'Company Name', name: 'companyName', value: 'No' },
  { id: 'Industry', name: 'industry', value: 'No' },
  { id: 'Company revenue', name: 'companyRevenue', value: 'No' },
  { id: 'LinkedIn Job Title', name: 'linkedInJobTitle', value: 'No' },
  { id: 'Job Level', name: 'jobLevel', value: 'No' },
  { id: 'Country', name: 'country', value: 'No' },
  { id: 'Address', name: 'address', value: 'No' },
  { id: 'City', name: 'city', value: 'No' },
  { id: 'IP', name: 'ip', value: 'No' },
  { id: 'Comments', name: 'comments', value: 'No' },
  { id: 'Allocation ID', name: 'reAllocationID', value: 'No' },
  { id: 'First Name', name: 'firstName', value: 'No' },
  { id: 'Last Name', name: 'lastName', value: 'No' },
  { id: 'Alternate Phone No', name: 'alternatePhoneNo', value: 'No' },
  { id: 'Job Title', name: 'jobTitle', value: 'No' },
  { id: 'LinkedIn', name: 'linkedIn', value: 'No' },//snehal-task-publisher side----agency lead tracking report---drop down and table header issue
  { id: 'LinkedIn Company Name', name: 'linkedInCompanyName', value: 'No' },
  { id: 'Company Employee Size', name: 'companyEmployeeSize', value: 'No' },
  { id: 'Job Function', name: 'jobFunction', value: 'No' },
  { id: 'State', name: 'state', value: 'No' },
  { id: 'Zip Code', name: 'zipCode', value: 'No' },
  { id: 'Asset ID', name: 'supportDocID', value: 'No' },
  { id: 'Asset Name', name: 'assetName', value: 'No' },
  { id: 'Street', name: 'street', value: 'No' },
  { id: 'Custom Questions', name: 'Custom Question', value: 'No' },
  { id: 'Multi Touch', name: 'multiTouch', value: 'No' },
  { id: 'Extra1', name: 'extra1', value: 'No' },
  { id: 'Extra2', name: 'extra2', value: 'No' },
  { id: 'Extra3', name: 'extra3', value: 'No' },
  { id: 'Extra4', name: 'extra4', value: 'No' },
  { id: 'Extra5', name: 'extra5', value: 'No' },
  { id: 'Extra6', name: 'extra6', value: 'No' },
  { id: 'Extra7', name: 'extra7', value: 'No' },
  { id: 'Extra8', name: 'extra8', value: 'No' },
  { id: 'Extra9', name: 'extra9', value: 'No' },
  { id: 'Extra10', name: 'extra10', value: 'No' },
  { id: 'Extra11', name: 'extra11', value: 'No' },
  { id: 'Extra12', name: 'extra12', value: 'No' },
  { id: 'Extra13', name: 'extra13', value: 'No' },
  { id: 'Extra14', name: 'extra14', value: 'No' },
  { id: 'Extra15', name: 'extra15', value: 'No' },
  { id: 'Extra16', name: 'extra16', value: 'No' },
  { id: 'Extra17', name: 'extra17', value: 'No' },
  { id: 'Extra18', name: 'extra18', value: 'No' },
  { id: 'Extra19', name: 'extra19', value: 'No' },
  { id: 'Extra20', name: 'extra20', value: 'No' },
    //Rutuja 5304 added extra fields 21-30 
    { id: 'Extra21', name: 'extra21', value: 'No' },
    { id: 'Extra22', name: 'extra22', value: 'No' },
    { id: 'Extra23', name: 'extra23', value: 'No' },
    { id: 'Extra24', name: 'extra24', value: 'No' },
    { id: 'Extra25', name: 'extra25', value: 'No' },
    { id: 'Extra26', name: 'extra26', value: 'No' },
    { id: 'Extra27', name: 'extra27', value: 'No' },
    { id: 'Extra28', name: 'extra28', value: 'No' },
    { id: 'Extra29', name: 'extra29', value: 'No' },
    { id: 'Extra30', name: 'extra30', value: 'No' },
];
// const deliveryName = columnNameArray.map(function (a) { return a.name });
// const columnHeader = ["Id", "firstName", "lastName", "email", "gender"]; //kiran-2836-removing console warning-not used
class AgencyLeadTrackingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      agencyName: '',//snehal-3417-agency name
      campaignStatus: [],//snehal-3417-acamp stats
      campaignStatusvalue: [],//snehal-3417-campaign status value
      leadStatusvalue: [],//snehal-3417-lead status 
      agencycampaignListDetails: [],//snehal-3417-campaign list array
      deliveryDetailsValue: [],//snehal-3417-value for picky
      deliveryDetailsValue1: [],//snehal-3417-value for picky
      tableHeading: Column,//snehal-3417-headings of table
      leadStatus: [],//snehal-3417-lead status
      campaign: '',//snehal-3417-campaign
      timeline: '',//snehal-3417-timeline
      agencyDetails: [],//snehal-3417-agency details
      drpdisabledStatus: '',//snehal-3417-picky disable
      agencyID: '',//snehal-3417-agencyid
      stackHolder: 'Publisher',//snehal-3417-stackholder
      agencyLeadTrackingDetails1: [],//snehal-3417-lead detail
      pickyStatuscss: 'pickyStatuscss',//snehal-3417-picky css
      agencyLeadTrackingDetails: [],//snehal-3417-lead detail
      agencyDeliveryDetails: [],//snehal-3417-delivery summery
      agencyDeliveryDetails1: [],//snehal-3417-delivery summery
      loader: "",//snehal-3417-loader data 
      loader1: "",//snehal-3417-loader generate
      pageSize2: 100,//snehal-3417-records per page
      tableState: { size: 'small' }//snehal-3417-table size
    }
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.trackingBackButton = this.trackingBackButton.bind(this);
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
    this.saveColumnButton = this.saveColumnButton.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.deliveryOnchange = this.deliveryOnchange.bind(this);
    this.tableHeadingFun = this.tableHeadingFun.bind(this);
    this.calltoBackend = this.calltoBackend.bind(this);
  }// end of constrcutor

  /**
   * @author Snehal More
   * @param  Description ComponentWillMount
   * @return Description render data when we go on page
   */
  //start of componentWillMount
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
        // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
      if (this.props.location.state !== undefined) {
        let	reportID = this.props.location.state.reportID;
        //  this.setState({reportID})
        this.state.reportID=reportID;
        let	reportName = this.props.location.state.reportName; 
        this.state.reportName=reportName
        }   //kiran-2836-not resolved this warning because giving issue
      // const { isAuthenticated, } = this.props.auth; //kiran-2836 resolved this warning-not used
        // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
      // const token = user.token;
      let data = {  }
      // authorization: token
      var reportName = this.state.reportName;
      fetch("/publisherReports/getAgencyListForATR", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
         
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(agencyDetails => {
          this.setState({ agencyDetails: agencyDetails });
        }).catch(function (err) { console.log(err) });
      //API call for agency
      // var parsed = queryString.parse(this.props.location.search); //kiran-2836-resolved this warning-not used
      // var reportID = parsed.reportID;
      // this.setState({ reportID: reportID })
      //authorization: token
      if (this.state.reportID) {
        //user:user
        let data = {  reportID: this.state.reportID }
        fetch("/publisherReports/getInfoAgencyTrackingReport", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
           
          },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencyLeadTrackingDetails => {
            this.setState({
              agencyLeadTrackingDetails: agencyLeadTrackingDetails[0].agencyDeliveryTrackingReportDetails, campaignStatus: agencyLeadTrackingDetails[0].campaignStatus, agencyID: agencyLeadTrackingDetails[0].agencyID, leadStatus: agencyLeadTrackingDetails[0].agencyDeliveryDetails[0].leadStatus,
              timeline: agencyLeadTrackingDetails[0].deadline, agencyName: agencyLeadTrackingDetails[0].agencyName, agencyDeliveryDetails: agencyLeadTrackingDetails[0].agencyDeliveryDetails,
              deliveryDetailsValue: agencyLeadTrackingDetails[0].manageColumns, customQuestionCount: agencyLeadTrackingDetails[0].agencyDeliveryDetails[0].customQuestionCount,
              campaign: agencyLeadTrackingDetails[0].agencyDeliveryDetails[0].campaignName
            },
              function () {
                let deliveryDetailsValue = agencyLeadTrackingDetails[0].manageColumns;
                this.tableHeadingFun(deliveryDetailsValue);
              })
          }).catch(function (err) { console.log(err) });
        //API call for old report
      }
    }
  }
  /**
* @author Snehal More
* @param  Description handle trackingBackButton
* @return Description back to publisherMyReports
*/
  trackingBackButton() {
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // const { user } = this.props.auth
    // var pID = user.id;
    //snehal-3543-publisher side----lead tracking---missing popup issue
    if (this.state.reportID) {
      window.location.href = "publisherMyReport";
    }
    else {
      if (this.state.agencyIDName !== ""  || this.state.timeline !== ""||this.state.campaign !== "" || this.state.campaignStatus !== ""||this.state.leadStatus !== "")  //kiran-2836-resolved this warning-changed != to !==
      {
      Swal.fire({
        //text: "Basic Details Saved",
        title: 'Are you sure you want to discard this report?',
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.value) {
          window.location.href = "publisherMyReport";
        } else if (result.dismiss === "close") {
          window.location.href = "#";
        } else {
          window.location.href = "#";
        }
      });
    }
    else{
      window.location.href = "publisherMyReport";
    }
    }
  }//handel change for pagination
  /**
* @author Snehal More
* @param  Description handle generateReport
* @return Description Generate report when data is present
*/
  generateReport() {
    // const { isAuthenticated,  } = this.props.auth;
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-resolved this warning
    // var pID = user.id;
    // const token = user.token;
    // var parsed = queryString.parse(this.props.location.search);
    var agencyIDName = this.state.agencyIDName;
    // var reportName = parsed.reportName;
    // var reportType = parsed.reportType;
    if (this.props.location.state !== undefined) {
			let	reportName = this.props.location.state.reportName;
      let	reportType = this.props.location.state.reportType;
      this.state.reportType=reportType; //kiran-2836-not used setState because giving issue for displaying report
      this.setState({reportName:reportName});
      }
    var campaignStatus = this.state.campaignStatus
    var campaign = this.state.campaign;
    var timeline = this.state.timeline;
    var leadStatus = this.state.leadStatus.toString();
    var stackHolder = this.state.stackHolder;
    var agencyLeadTrackingDetails = this.state.agencyLeadTrackingDetails;
    var agencyDeliveryDetails = this.state.agencyDeliveryDetails
    agencyDeliveryDetails[0] = {
      ...agencyDeliveryDetails[0],
      // ["leadStatus"]: leadStatus, ["campaignName"]: campaign
       "leadStatus": leadStatus, "campaignName": campaign //kiran-2836-removing console warning-not needed [""]when using variable names as property names
    }
    this.setState({ loader1: "gen_loader" })
    //user: user,
    let data = {
     
      agencyIDName: agencyIDName,
      reportName: this.state.reportName,
      type:this.state.reportType,
      campaignStatus: campaignStatus,
      //campaign:campaign,
      deadline: timeline,
      //leadStatus:leadStatus,
      stackHolder: stackHolder,
      manageColumns: this.state.deliveryDetailsValue,
      agencyTrackingDetails: agencyLeadTrackingDetails,
      agencyDeliveryDetails: agencyDeliveryDetails
    }
    // authorization: token
    fetch("/publisherReports/generateAgencyTrackingReport", {
      method: "POST",
      headers: { "Content-Type": "application/json",  },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(response => {
        this.setState({ loader1: "" })
        Swal.fire({
          html: "<h4><h4>Report generated successfully</h4><h4><a href='#'>Please </a><a href='publisherMyReport'><span style='color:#F28C0F'>click here</span></a><a href='#'> to check report</a></h4></h4>",
          icon: "Success",
          type: "success",
          confirmButtonColor: "#144999",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            window.location.href = "publisherMyReport";
          } else if (result.dismiss === "close") {
            window.location.href = "publisherMyReport";
          } else {
            // var parsed = queryString.parse(this.props.location.search);
            // var reportID = parsed.reportID;//kiran-2836-removing console warning-not used
          }
        });
      }).catch(function (err) { console.log(err) });

  }//handle change generate report
  /**
* @author Snehal More
* @param  Description handle common handle change
* @return Description to set filters 
*/
  commonhandleChange(e) {
    const { name} = e.target
    // const { isAuthenticated } = this.props.auth;//kiran-2836-removing console warning-not used
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // var pID = user.id;
    // const token = user.token;
    // var parsed = queryString.parse(this.props.location.search);
    // var reportType = this.state.reportType; //kiran-2836-removing console warning-not used
    if (name.toString() === "agencyIDName") {
      //snehal-task-3542publisher side---lead tracking report--camp issue  and snehal-task-3551-publisher side---lead tracking report----dropdown issue
      this.setState({agencyIDName: e.target.value, campaign:'', agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: []})//snehal-task-3578-publisher side---Agency lead tracking report issue
      if (e.target.value === "") {
        this.setState({ agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] })
      }
      if(this.state.campaignStatus!="" &&e.target.value !== "") //kiran-2836-not resolved giving issue
      { 
        var str_array = this.state.campaignStatus;
        var campaignStatusArray = [];
        for (var i = 0; i < str_array.length; i++) {
          if (str_array[i] !== "") {
            campaignStatusArray.push({ id: str_array[i], name: str_array[i] });
          }
        }
        //authorization: token
        //user: user
        let data = { agencyIDName: e.target.value, campaignStatus:campaignStatusArray, }
        this.setState({ campaignLoader: "campaignLoader" })
        fetch("/publisherReports/getCampaignListForLTR", {
          method: "POST",
          headers: { "Content-Type": "application/json",  },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencycampaignListDetails => {
            this.setState({ agencycampaignListDetails: agencycampaignListDetails, campaignLoader: "" })
          }).catch(function (err) { console.log(err) });
      }
      else {
        this.setState({ agencycampaignListDetails: [] })
      }
      var agency_id_name = e.target.value.split("-");
      let agencyID = agency_id_name[0]
      let agencyName = agency_id_name[1]
      //snhal-task-3553-lead tracking report---Agency ID and Agency Name issue
      this.setState({ agencyID: agencyID, agencyName: agencyName})
    }
    if (name.toString() === "campaign") { //kiran-2836-removing console warning-changed == to ===
      this.setState({ campaign: e.target.value, agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] });
      if ((this.state.agencyIDName !== "" && this.state.agencyIDName !== undefined) && this.state.timeline !== "" && e.target.value !== "" && this.state.campaignStatus !== []) {
        let campaign = e.target.value;
        let timeline = this.state.timeline
        this.calltoBackend(campaign, timeline)
      }
      else {
        this.setState({ agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] })
      }
    }
    if (name.toString()=== "timeline") {//kiran-2836-removing console warning-changed == to ===
      this.setState({ timeline: e.target.value, agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] });
      if ((this.state.agencyIDName !== "" && this.state.agencyIDName !== undefined) && this.state.campaign !== "" && e.target.value !== "" && this.state.campaignStatus !== []) { //kiran-2836-removing console warning-changed != to !==
        let timeline = e.target.value;
        let campaign = this.state.campaign;
        this.calltoBackend(campaign, timeline)
      }
      else {
        this.setState({ agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] })
      }
    }
  }//handle search box
  /**
  * @author Snehal More
  * @param  Description handle selectCampaignStatusMultipleOption
  * @return Description select multiple campaign status 
  */
  selectCampaignStatusMultipleOption(campaignStatusvalue) {
    this.setState(
      {
        campaignStatusvalue: campaignStatusvalue,
      },
      function () {
        let campaignStatus = [];
        for (var p = 0; p < this.state.campaignStatusvalue.length; p++) {
          campaignStatus.push(this.state.campaignStatusvalue[p].id);
        }
        this.setState({ campaignStatus, campaign: '', });
      }
    );
    //authorization: token
    if ((this.state.agencyIDName !== "" && this.state.agencyIDName !== undefined) && campaignStatusvalue !== "") {
      // const { isAuthenticated } = this.props.auth;
        // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-removing console warning-not used
      // const token = user.token;
      // user: user
      let data = { agencyIDName: this.state.agencyIDName, campaignStatus: campaignStatusvalue,  }
      this.setState({ campaignLoader: "campaignLoader" })
      fetch("/publisherReports/getCampaignListForLTR", {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(agencycampaignListDetails => {
          this.setState({ agencycampaignListDetails: agencycampaignListDetails, campaignLoader: "" })
        }).catch(function (err) { console.log(err) });
    }
    else {
      this.setState({ agencycampaignListDetails: [] })
    }
  }
  /**
 * @author Snehal More
 * @param  Description handle selectLeadStatusMultipleOption
 * @return Description select multiple Lead status 
 */
  selectLeadStatusMultipleOption(leadStatusvalue) {
    let leadStatus = [];
    this.setState(
      {
        leadStatusvalue: leadStatusvalue,
      },
      function () {
        for (var p = 0; p < this.state.leadStatusvalue.length; p++) {
          leadStatus.push(this.state.leadStatusvalue[p].id);
        }
        this.setState({ leadStatus });
      }
    );
    if ((this.state.agencyIDName !== "" && this.state.agencyIDName !== undefined) && this.state.campaign !== "" && this.state.timeline !== "" && leadStatusvalue !== "") { //kiran-2836-removing console warning-changed != to !==
      for (var p = 0; p < this.state.leadStatusvalue.length; p++) {
        let leadStatus = [];
        leadStatus.push(this.state.leadStatusvalue[p].id);
      }
      this.filterLeads(leadStatusvalue);
    }
    else {
      this.setState({ agencyLeadTrackingDetails: [], deliveryDetailsValue: [], agencyDeliveryDetails: [] })
    }
  }
  /**
* @author Snehal More
* @param  Description handle calltoBackend
* @return Description after selecting all filters call api for data 
*/
  calltoBackend(campaign, timeline) {
    // const { isAuthenticated,  } = this.props.auth;
      //Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // const token = user.token;

    // var parsed = queryString.parse(this.props.location.search);
    var reportType = this.state.reportType;
    //authorization: token 
   // user: user 
    let data = { agencyIDName: this.state.agencyIDName, campaignStatus: this.state.campaignStatus, deadline: timeline, campaign: campaign, leadStatus: this.state.leadStatus, type: reportType,}
    this.setState({ loader: "loader" })
    fetch("/publisherReports/getDataForLeadTrackingReport", {
      method: "POST",
      headers: { "Content-Type": "application/json",},
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(agencyDeliveryTrackingDetails => {
        let deliveryDetailsValue = [];
        for (const [key, value] of Object.entries(agencyDeliveryTrackingDetails.deliveryDetails[0])) {
          if (value === "Yes" || value === "yes") {
            deliveryDetailsValue.push(key)
          }
        }
        this.tableHeadingFun(deliveryDetailsValue);
        this.setState({
          loader: "", deliveryDetailsValue1: deliveryDetailsValue, agencyLeadTrackingDetails1: agencyDeliveryTrackingDetails.leadTrackingDetails, agencyDeliveryDetails1: agencyDeliveryTrackingDetails.agencyDeliveryDetails,
          customQuestionCount: agencyDeliveryTrackingDetails.agencyDeliveryDetails[0].customQuestionCount, campaign, timeline
        },
          function () {
          })
        if (this.state.leadStatus.length > 0 && this.state.agencyLeadTrackingDetails1.length > 0) {
          let leadStatus = this.state.leadStatus;
          this.filterLeads2(leadStatus)
        }

      }).catch(function (err) { console.log(err) });
  }
  /**
* @author Snehal More
* @param  Description handle filterLeads
* @return Description filter leads according to filter applied for status in JSON array
*/
  filterLeads(leadStatusvalue) {
    var accepted = properties.accepted;
    var clientAccepted = properties.clientAccepted;
    var rejected = properties.rejectedDB;
    var clientRejected = properties.clientRejected;
    var agencyInternalReview = properties.agencyInternalReview;
    var agencyQARejected = properties.agencyQARejected;
    var DI_QA_Accepted = properties.acceptedDI;
    var DI_QA_Rejected = properties.rejectedDI;
    var QA_Review = properties.QA_Review;
    var DI_QA_Review = properties.DI_Review;
    let agencyLeadTrackingDetails1 = this.state.agencyLeadTrackingDetails1;
    let leadStatus = this.state.leadStatus;
    leadStatus = [];
    for (var p = 0; p < leadStatusvalue.length; p++) {
      leadStatus.push(leadStatusvalue[p].id);
    }
    let agencyLeadTrackingDetails = [];
    agencyLeadTrackingDetails = agencyLeadTrackingDetails1.filter(function (leadData) {
      if (leadStatus.includes("Accepted") && leadStatus.includes("In QA") && leadStatus.includes("Rejected")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted") && leadStatus.includes("In QA")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted") && leadStatus.includes("Rejected")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase()
      }
      else if (leadStatus.includes("In QA") && leadStatus.includes("Rejected")) {
        return leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase()
      }
      else if (leadStatus.includes("Rejected")) {
        return leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase()
      }
      else if (leadStatus.includes("In QA")) {
        return leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
       return [];//kiran-2836-removing console warning-need return statement for function
    })

    this.setState({ agencyLeadTrackingDetails, deliveryDetailsValue: this.state.deliveryDetailsValue1, agencyDeliveryDetails: this.state.agencyDeliveryDetails1 })
  }
  /**
       * @author Snehal More
       * @param  Description handle filterLeads
       * @return Description filter leads according to filter applied for status string array
       */
  filterLeads2(leadStatus) {
    var accepted = properties.accepted;
    var clientAccepted = properties.clientAccepted;
    var rejected = properties.rejectedDB;
    var clientRejected = properties.clientRejected;
    var agencyInternalReview = properties.agencyInternalReview;
    var agencyQARejected = properties.agencyQARejected;
    var DI_QA_Accepted = properties.acceptedDI;
    var DI_QA_Rejected = properties.rejectedDI;
    var QA_Review = properties.QA_Review;
    var DI_QA_Review = properties.DI_Review;
    let agencyLeadTrackingDetails1 = this.state.agencyLeadTrackingDetails1;
    let agencyLeadTrackingDetails = [];
    agencyLeadTrackingDetails = agencyLeadTrackingDetails1.filter(function (leadData) {
      if (leadStatus.includes("Accepted") && leadStatus.includes("In QA") && leadStatus.includes("Rejected")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted") && leadStatus.includes("In QA")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted") && leadStatus.includes("Rejected")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase() || leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase()
      }
      else if (leadStatus.includes("In QA") && leadStatus.includes("Rejected")) {
        return leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase() || leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      else if (leadStatus.includes("Accepted")) {
        return leadData.status === accepted.toLowerCase() || leadData.status === clientAccepted.toLowerCase()
      }
      else if (leadStatus.includes("Rejected")) {
        return leadData.status === rejected.toLowerCase() || leadData.status === agencyQARejected.toLowerCase() || leadData.status === clientRejected.toLowerCase() || leadData.status === DI_QA_Rejected.toLowerCase()
      }
      else if (leadStatus.includes("In QA")) {
        return leadData.status === agencyInternalReview.toLowerCase() || leadData.status === DI_QA_Accepted.toLowerCase() || leadData.status === QA_Review.toLowerCase() || leadData.status === DI_QA_Review.toLowerCase()
      }
      return []; //kiran-2836-removing console warning-need return statement for function
    })

    this.setState({ agencyLeadTrackingDetails, deliveryDetailsValue: this.state.deliveryDetailsValue1, agencyDeliveryDetails: this.state.agencyDeliveryDetails1 })
  }

  /**
* @author Snehal More
* @param  Description handle handleChangeCheck
* @return Description handle too check and uncheck columns from manage cols 
*/
  handleChangeCheck(e) {
    let fields = this.state;
    // const { name, value } = e.target; //kiran-2836-removing console warning-not used
    fields[e.target.name] = e.target.value;
    if (e.target.checked === true) {
      fields[e.target.name] = "Yes";
    } else {
      fields[e.target.name] = "No";
    }
  }
  /**
 * @author Snehal More
 * @param  Description function deliveryOnChange
 * @return Description function used to check/uncheck checkboxes
 */
  deliveryOnchange = deliveryDetailsValue => {
    this.setState({
      deliveryDetailsValue,
    });
  };
  /**
 * @author Snehal More
 * @param  Description finction tableHeadingFun
 * @return Description used this function to display expected table headings 
 */
  tableHeadingFun(deliveryDetailsValue) {
    let tableHeading = [...Column]
    tableHeading = tableHeading.filter((obj) => {
      return deliveryDetailsValue.includes((obj.key).toString())
    })
    let customQuestionCount = this.state.customQuestionCount;
    let multiTouchNumber = this.state.agencyDeliveryDetails.multiTouchNumber
    if (this.state.reportID) {
      if (customQuestionCount > 0 && deliveryDetailsValue.includes("Custom Question")) {

        let cqArray = [];
        for (let i = 1; i <= customQuestionCount; i++) {
          let CQkey = `CQ-${i}`, ansKey = `CQ-${i}-ANS`;
          let Q_obj = {
            title: <span style={{ fontSize: '12px', color: 'white' }}>{CQkey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: CQkey,
            key: CQkey,
            width: "20%",
            align: "left",
            sorter: (a, b) => {
              const asc = "asc";

              if (a[CQkey] < b[CQkey]) {
                return asc ? -1 : 1;
              } else if (a[CQkey] > b[CQkey]) {
                return asc ? 1 : -1;
              } else {
                return 0;
              }
            }
          }
          let ans_obj = {
            title: <span style={{ fontSize: '12px', color: 'white' }}>{ansKey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: ansKey,
            key: ansKey,
            width: "20%",
            align: "left",
            sorter: (a, b) => {
              const asc = "asc";

              if (a[ansKey] < b[ansKey]) {
                return asc ? -1 : 1;
              } else if (a[ansKey] > b[ansKey]) {
                return asc ? 1 : -1;
              } else {
                return 0;
              }
            }
          }
          cqArray.push(Q_obj);
          cqArray.push(ans_obj);
          //  deliveryDetailsValue.push(CQkey);
          //  deliveryDetailsValue.push(ansKey);
        }//End of CQ for loop
        deliveryDetailsValue = [...new Set(deliveryDetailsValue)];
        tableHeading = tableHeading.concat(cqArray);
      }
    }
    else {
      if (customQuestionCount > 0 && (deliveryDetailsValue && deliveryDetailsValue.includes("Custom Question"))) {

        let cqArray = [];
        for (let i = 1; i <= customQuestionCount; i++) {
          let CQkey = `CQ-${i}`, ansKey = `CQ-${i}-ANS`;
          let Q_obj = {
            title: <span style={{ fontSize: '12px', color: 'white' }}>{CQkey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: CQkey,
            key: CQkey,
            width: "20%",
            align: "left",
            sorter: (a, b) => {
              const asc = "asc";

              if (a[CQkey] < b[CQkey]) {
                return asc ? -1 : 1;
              } else if (a[CQkey] > b[CQkey]) {
                return asc ? 1 : -1;
              } else {
                return 0;
              }
            }
          }
          let ans_obj = {
            title: <span style={{ fontSize: '12px', color: 'white' }}>{ansKey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: ansKey,
            key: ansKey,
            width: "20%",
            align: "left",
            sorter: (a, b) => {
              const asc = "asc";

              if (a[ansKey] < b[ansKey]) {
                return asc ? -1 : 1;
              } else if (a[ansKey] > b[ansKey]) {
                return asc ? 1 : -1;
              } else {
                return 0;
              }
            }
          }
          cqArray.push(Q_obj);
          cqArray.push(ans_obj);
          deliveryDetailsValue.push(CQkey);
          deliveryDetailsValue.push(ansKey);
        }//End of CQ for loop
        deliveryDetailsValue = [...new Set(deliveryDetailsValue)];
        tableHeading = tableHeading.concat(cqArray);
      } else {
        if (customQuestionCount === 0) {
          deliveryDetailsValue = deliveryDetailsValue.filter(a => a.id !== "Custom Question")
        }

        let keys = [];
        for (let i = 1; i <= customQuestionCount; i++) {
          let CQkey = `CQ-${i}`, ansKey = `CQ-${i}-ANS`;
          keys.push(CQkey);
          keys.push(ansKey);
        }
        tableHeading = tableHeading.filter((obj) => {
          return keys && !keys.includes(obj.key)
        })
      }
    }
    if (multiTouchNumber > 0 && (deliveryDetailsValue && deliveryDetailsValue.includes("Multi Touch"))) {
      let mlArray = [];
      for (let i = 1; i <= multiTouchNumber; i++) {
        let mlkey = `assetNameTouch${i}`, mlstmpKey = `assetTimestampTouch${i}`;
        let Q_obj = {
          title: <span style={{ fontSize: '12px', color: 'white' }}>{mlkey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
          dataIndex: mlkey,
          key: mlkey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[mlkey] < b[mlkey]) {
              return asc ? -1 : 1;
            } else if (a[mlkey] > b[mlkey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          }
        }
        let ans_obj = {
          title: <span style={{ fontSize: '12px', color: 'white' }}>{mlstmpKey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
          dataIndex: mlstmpKey,
          key: mlstmpKey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[mlstmpKey] < b[mlstmpKey]) {
              return asc ? -1 : 1;
            } else if (a[mlstmpKey] > b[mlstmpKey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          }
        }
        mlArray.push(Q_obj);
        mlArray.push(ans_obj);
        deliveryDetailsValue.push(mlkey);
        deliveryDetailsValue.push(mlstmpKey);
      }//End of CQ for loop
      deliveryDetailsValue = [...new Set(deliveryDetailsValue)];
      tableHeading = tableHeading.concat(mlArray);
    } else {
      if (multiTouchNumber === 0) {
        deliveryDetailsValue = deliveryDetailsValue.filter(a => a.id !== "Multi Touch")
      }

      let keys = [];
      for (let i = 1; i <= multiTouchNumber; i++) {
        let mlkey = `assetNameTouch${i}`, mlstmpKey = `assetTimestampTouch${i}`;
        keys.push(mlkey);
        keys.push(mlstmpKey);
      }
      tableHeading = tableHeading.filter((obj) => {
        return keys && !keys.includes(obj.key)
      })
    }
    this.setState({ tableHeading })

    //let size_width
    //size_width=300*(tableHeading.length)
    //this.setState(size_width)
  }
  /**
 * @author Snehal More
 * @param  Description function saveColumnButton
 * @return Description save the checked and unchecked checkboxes
 */
  saveColumnButton() {
    this.tableHeadingFun(this.state.deliveryDetailsValue);
  }
  /**
* @author Snehal More
* @param  Description handle handleChangePageSize2
* @return Description render records per page 
*/
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }
  render() {
    // const { isAuthenticated } = this.props.auth; //kiran-2836-removing console warning-not used

    function onChange(pagination, filters, sorter) {
    }
    //})}
    return (
      <div >
        <PublisherNavigation />
        <div style={{ margin: '20px' }}>
          <div class="container-fluid" style={{ paddingTop: '80px', paddingLeft: '0px', paddingRight: '0px' }}>
            <div class="row">
              <div class="col-lg-2 col-md-1 col-sm-1" >
                < a class="col" href='#/' style={{ color: '#056eb8', float: 'left' }} onClick={this.trackingBackButton.bind(this)} >
                  <FaArrowAltCircleLeft size={32} title="" className="backFrom_myReport" />
                </a>
              </div>
              <div class=" col-lg-8 col-md-8 col-sm-8" align="center">
                <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Agency Lead Tracking Report</label></center>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12" >
                {this.state.reportID ? "" :
                  this.state.agencyLeadTrackingDetails.length === 0 ?
                    <button class="btn add-button" disabled type="submit" value="submit1" style={{ margin: '5px', float: 'right' }} onClick={this.generateReport}>
                      Generate Report
                    </button>
                    :
                    <button class="btn add-button" type="submit" value="submit1" style={{ margin: '5px', float: 'right' }} onClick={this.generateReport}>
                      Generate Report
                      </button>
                }
              </div>
            </div><br />
            {this.state.reportID ?
              <div class="row" style={{ marginLeft: '10px' }}>
    {/* /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */ }
                <div className="col-lg-2 col-md-4 col-sm-12 camp_block_dis" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Agency : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="agencyIDName"
                      name="agencyIDName"
                      value={this.state.agencyIDName}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value={this.state.agencyName}>{this.state.agencyName}</option>

                    </select>
                  </div>
                </div>
                {/* //snehal-task-publisher side----agency lead tracking report---drop down and table header issue */}
                <div className=" col-lg-2 col-md-4 col-sm-6 camp_block_dis">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign Status : <span className="asterisk">*</span>&nbsp;</label>
                    <select
										type="text"
										id="campaignStatus"
										className="form-control inputStyle"
										name="campaignStatus"
										disabled
										onChange={this.commonhandleChange}
										value={this.state.campaignStatus}>
                       <option value={this.state.campaignStatus}>{this.state.campaignStatus}</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 camp_block_dis" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="campaign"
                      name="campaign"
                      value={this.state.campaign}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value={this.state.campaign}>{this.state.campaign}</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 camp_block_dis">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Timeline : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="timeline"
                      name="timeline"
                      value={this.state.timeline}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Timeline</option>
                      <option value="All" >All</option>
                      <option value="NoLead" >No Lead Delivered</option>
                      <option value="3Days">Ending 3 Days</option>
                      <option value="Weekly">Ending This Week</option>
                      <option value="Monthly">Ending This Month</option>
                      <option value="Quarterly">Ending This Quarter</option>
                      <option value="Yearly">Ending this Year</option>
                      <option value="PreMonth">Ending Previous Month</option>
                      <option value="PreQuarter">Ending Previous Quarter</option>
                      <option value="PreYear">Ending Previous Year</option>
                    </select>
                  </div>
                </div>
                {/* //snehal-task-publisher side----agency lead tracking report---drop down and table header issue */}
                <div className=" col-lg-2 col-md-4 col-sm-12 camp_block_dis_lead">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Lead Status : <span className="asterisk">*</span>&nbsp;</label>
                    <select
										type="text"
										id="leadStatus"
										className="form-control inputStyle"
										name="leadStatus"
										disabled
										onChange={this.commonhandleChange}
										value={this.state.leadStatus}>
                      <option value={this.state.leadStatus}>{this.state.leadStatus}</option>
                    </select>
                  </div>
                </div>
              </div>
              : <div class="row" style={{ marginLeft: '10px' }}>
                <div class="col-lg-2 col-md-4 col-sm-12" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Agency : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="agencyIDName"
                      name="agencyIDName"
                      value={this.state.agencyIDName}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="">Select Agency</option>
                      {this.state.agencyDetails.map((agencyDetails) => {
                        return <option value={agencyDetails.agencyIDName}>{agencyDetails.agencyName}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 camp_block1">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign Status : <span className="asterisk">*</span>&nbsp;</label>
                    <Picky
                      id=""
                      value={this.state.campaignStatusvalue}
                      options={campaignStatusDisplay}
                      style={{ borderRadius: '4px' }}
                      onChange={this.selectCampaignStatusMultipleOption.bind(this)}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      placeholder={'Select Campaign Status'}
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={false}
                      //dropdownHeight={400} 
                      numberDisplayed={2}
                      className={"pickystyle_report " + this.state.pickyStatuscss} //kiran-2836-removing console warning
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 camp_block2" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="campaign"
                      name="campaign"
                      value={this.state.campaign}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="">Select Campaign</option>
                      {this.state.agencycampaignListDetails.map((agencycampaignListDetails) => {
                        return <option value={agencycampaignListDetails.campIDName}>{agencycampaignListDetails.campIDName}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className=" col-lg-2 col-md-4 col-sm-12 camp_block3">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Timeline : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="timeline"
                      name="timeline"
                      value={this.state.timeline}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Timeline</option>
                      <option value="All">All</option>
                      <option value="NoLead" >No Lead Delivered</option>
                      <option value="3Days">Ending 3 Days</option>
                      <option value="Weekly">Ending This Week</option>
                      <option value="Monthly">Ending This Month</option>
                      <option value="Quarterly">Ending This Quarter</option>
                      <option value="Yearly">Ending this Year</option>
                      <option value="PreMonth">Ending Previous Month</option>
                      <option value="PreQuarter">Ending Previous Quarter</option>
                      <option value="PreYear">Ending Previous Year</option>
                    </select>
                  </div>
                </div>
                <div className=" col-lg-2 col-md-4 col-sm-12 camp_block4">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Lead Status : <span className="asterisk">*</span>&nbsp;</label>
                    <Picky
                      id=""
                      value={this.state.leadStatusvalue}
                      options={leadStatusDisplay}
                      style={{ borderRadius: '4px' }}
                      onChange={this.selectLeadStatusMultipleOption.bind(this)}
                      open={false}
                      valueKey="id"
                      labelKey="name"
                      overflow='none'
                      placeholder={'Select Lead Status'}
                      multiple={true}
                      includeSelectAll={true}
                      includeFilter={false}
                      numberDisplayed={2}
                      className={"pickystyle_report " + this.state.pickyStatuscss}//kiran-2836-removing console warning
                    />
                  </div>
                </div>
              </div>}
            <br />
            <hr />
          </div>
          <div class="row" align="center" id={this.state.loader1}></div>
          <div class="row" align="center" id={this.state.loader}></div>
          <div class="row" align="center" id={this.state.campaignLoader}></div>
          {this.state.agencyDeliveryDetails.length > 0 && this.state.agencyLeadTrackingDetails.length > 0 ?
            <div style={{ border: '1px solid #CCCCCC', margin: '5px', padding: '10px', backgroundColor: '#FFFFFF', borderRadius: '3px' }}>
              <div class="row" style={{ marginBottom: "-17px" }}>
                {/* kiran2836-changed != to 1== */}
                <p class="col-lg-12 col-md-12 col-sm-12" style={{ color: '#F28C0F', fontSize: '16px' }}><b>Delivery Summary : &nbsp;<label style={{ color: '#14254a', fontSize: '14px' }}>{this.state.agencyDeliveryDetails.length > 0 && this.state.agencyDeliveryDetails[0].deliveryTimeline !== "" ? <b>{this.state.agencyDeliveryDetails[0].deliveryTimeline}</b> : <b>{this.state.timeline}</b>}</label></b></p> 
              </div> <hr />
              <div class="row">
                <p class="col-lg-2 col-md-6 col-sm-12"><b><span style={{ color: '#14254a', fontSize: '14px' }}>Agency ID : &nbsp; </span>{this.state.agencyID}</b></p>
                <p class="col-lg-4 col-md-6 col-sm-12"><b><span style={{ color: '#14254a', fontSize: '14px' }}>Agency Name : &nbsp; </span>{this.state.agencyName}</b></p>
                {/* */}
                <p class="col-lg-2 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Report ID : &nbsp; </span>{this.state.reportID}</label></p>
                <p class="col-lg-4 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Report Name : &nbsp; </span>{this.state.reportName}</label></p>
                {/*Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link  */}
              </div><hr />
              <div class="row" style={{ marginBottom: "-8px" }}>
                <p class="col-lg-12 col-md-12 col-sm-12" ><label style={{ color: '#14254a', fontSize: '14px' }}>Total Count : &nbsp;</label></p>
              </div>
              <div class="row" style={{ marginBottom: "-10px" }}>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Allocated Lead : &nbsp; <span style={{ color: '#414141' }}>{this.state.agencyDeliveryDetails[0].agencyAllocatedLead != null ? this.state.agencyDeliveryDetails[0].agencyAllocatedLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Delivered : &nbsp; <span style={{ color: '#193D8F' }}>{this.state.agencyDeliveryDetails[0].agencyDeliveredLead != null ? this.state.agencyDeliveryDetails[0].agencyDeliveredLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Review Pending : &nbsp;<span style={{ color: '#F28C0F' }}>{this.state.leadStatus.includes("In QA") ? <b style={{ fontWeight: 600 }}>{this.state.agencyDeliveryDetails[0].agencyQAReviewLead}</b> : <span>{this.state.agencyDeliveryDetails[0].agencyQAReviewLead}</span>}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Accepted : &nbsp;<span style={{ color: '#008000' }}>{this.state.leadStatus.includes("Accepted") ? <b style={{ fontWeight: 600 }}>{this.state.agencyDeliveryDetails[0].agencyAcceptedLead}</b> : <span>{this.state.agencyDeliveryDetails[0].agencyAcceptedLead}</span>}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Rejected : &nbsp;<span style={{ color: '#FF0000' }}>{this.state.leadStatus.includes("Rejected") ? <b style={{ fontWeight: 600 }}>{this.state.agencyDeliveryDetails[0].agencyRejectedLead}</b> : <span>{this.state.agencyDeliveryDetails[0].agencyRejectedLead}</span>}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Balance : &nbsp; <span style={{ color: '#4F4F4F' }}>{this.state.agencyDeliveryDetails[0].agencyBalancedLead != null ? this.state.agencyDeliveryDetails[0].agencyBalancedLead : 0}</span></p>

              </div>

            </div>
            : ''}
          {this.state.agencyLeadTrackingDetails.length > 0 ?
          <div>
          <label>&nbsp; Lead Details : </label>
          {this.state.reportID ? ""
            : <label style={{ color: "#1890ff", float: 'right' }} data-toggle="modal" data-target="#manageColumnsModal" data-backdrop="static" data-keyboard="false" >
            {/*Aman-5313- change below <a href="#/"></a> to p tag to abvoid avoide url # issue because of that type and name showing undefined and not generated the report*/}
            <p style={{color:"#40a9ff", cursor:"pointer"}}>Manage Columns</p></label>}</div>:""}{/*snehal-task-3578-publisher side---Agency lead tracking report issue*/}
              {/* //snehal-task-publisher side----agency lead tracking report---drop down and table header issue */}
          <div className="modal fade" id="manageColumnsModal" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>  {/* snehal-task-3552-lead tracking report---responsive issue */}
            <div class="modal-dialog modal-md">
              <div class="modal-content" id="manageColumnsModal_css">
                <div class="modal-header" style={{ backgroundColor: "#FFFFFF", height: '50px' }}>
                  <p style={{ color: '#414141', fontSize: '20px', fontWeight: '500 ', fontFamily: 'Roboto' }}>Manage Columns
                    <button
                      type="button"
                      class="close"
                      style={{ color: '#414141', fontSize: '25px', marginTop: '-10px', fontWeight: '500' }}
                      data-dismiss="modal">
                      &times;
                              </button>
                  </p>
                </div>
                <div style={{ height: '280px', overflowY: "scroll",padding:'8px' }}>
                  <Checkbox.Group
                    style={{ display: 'grid', gridTemplateColumns: 'auto auto', width: '355px', fontSize: '12px', margin: '5px auto' }}
                    options={columnNameArray.map(columnNameArray => ({ label: columnNameArray.id, value: columnNameArray.name }))}
                    value={this.state.deliveryDetailsValue}
                    onChange={this.deliveryOnchange}
                  />
                </div>
                <br />
                <div className="row manage_col_footer" style={{ paddingBottom: '20px' }}>{/* snehal-task-3552-lead tracking report---responsive issue */}
                  <button class="btn offset-2 col-lg-3 col-md-4  col-md-3 col-sm-4  col-4" style={{ color: "#144999", float: 'left', borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999' }} type="button" value="cancel" data-dismiss="modal"> Cancel </button>
                  <button class="btn add-button offset-1 col-lg-4 col-md-4 col-sm-5  col-5 " type="cancel" data-dismiss="modal" style={{ float: 'right' }} onClick={this.saveColumnButton}> Save And Close </button>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive" >
            {/* kiran-2836-change classname */}
            {this.state.agencyLeadTrackingDetails.length > 0 ?
              <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.agencyLeadTrackingDetails}
                onChange={onChange} columns={this.state.tableHeading} className={`${tableCSS} "ant-table-thead"`}
                scroll={
                  this.state.multiTouch !== "No" ?
                    { x: z, y: 1333 }
                    :
                    this.state.customQuestionCount !== 0 ?
                      { x: 3500, y: 1333 }
                      :
                      { x: 2500, y: 1333 }
                }
                pagination={{ pageSize: this.state.pageSize2 }}></Table>

              : <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.agencyLeadTrackingDetails}
                onChange={onChange} columns={Column1}  className={`${tableCSS} "ant-table-thead"`}  scroll={{ y: 700, x: 1000 }}
                pagination={{ pageSize: this.state.pageSize2 }}></Table>}

            {this.state.agencyDeliveryDetails.length > 0 ?
              <p>
                <div class="row" style={{ paddingBottom: '120px' }}>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px', fontFamily: 'sans-serif', fontSize: '13px', color: '#4F4F4F' }}>
                    &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                  <select
                      defaultValue={this.state.pageSize2}
                      onChange={this.handleChangePageSize2}
                      id="pacing1"
                      class="input-small"
                      className="form-control"
                      name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                      <option value="100" selected>100</option>
                      <option value="150" >150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                  </div>
                </div>
              </p> : ""}

          </div>
        </div>
        <Footer />
      </div>
    );
  }// end of renders
}// end of class

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
AgencyLeadTrackingReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyLeadTrackingReport));