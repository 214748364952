/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :customMapping.js
 *Desc: Add Custom Mapping
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "./customMapping.css";
import "./labelDI.css";
import { MDBBtn, CardBody } from "mdbreact";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
import { Tooltip } from "antd"; //Rutuja - 5277 - Added to show message on Button 'Add More Extra Fields'

const Swal = require("sweetalert2");

class CustomMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientDetails: [],
      advertiserMapping: [],
      customMapping: [],
      agencyAdvertiserMap: [],
      mappingFormat: [],
      advertiserID: "",
      errors: {},
      length: 0,
      clientNameDisplay: "none",
      format: "Format 1",
      userID: "",
      validationDisplay: "none", //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number
      validationDisplay1: "none",
      validationDisplay2: "none", //rutuja task - 4779 added error message for all fields are mandetory
      validationDisplay3: "none", //Rutuja task - 4832 added error message for similar system fields
      workPhoneFormatError: false, //karan-task-3477-work phone format invalid input error
      duplicateWorkPhoneFormatError: false, //karan-task-3477-work phone format duplicate input error
      sameValue: false, //Sanjana-task-3897-Same Value found error
      difields: [], //Rutuja task -4832
      systemFields: [], //Rutuja task -4832
      duplicateAliasName: [], //Rutuja task -4832
      nonEnglishChecked: "No", //Chaitanya-4962
      isCheckednonEnglish: false, //Chaitanya-4962
      customMappingOtherField: [], //Rutuja - 5277 - added to store new  row value
      otherFieldCounter: 20, //Rutuja - 5277 - Counter for generating unique extra field names
      stectedClientID: "", //Nilesh- 5279 - added to set client Id
      oldcustomMapping: [],//Aman 5295 - To set old customMapping to track customMapping Chnages in mapping_log table
      customMappingStatus: "edit" //Aman 5295 - to store customMappingStatus value
    };
    this.handleClientChange = this.handleClientChange.bind(this);
    this.agencyMappinHandleChange = this.agencyMappinHandleChange.bind(this);
    this.getMappin = this.getMappin.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.validateForm = this.validateForm.bind(this); //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number
    //  this.displayMapping=this.displayMapping.bind(this);
    this.addCustomMappingField = this.addCustomMappingField.bind(this); //Rutuja - 5277 - to bind addCustomMappingField
    this.removeCustomMappingField = this.removeCustomMappingField.bind(this); //Rutuja - 5277 - to bind removeCustomMappingField
  } // end of constructor

  handleFormatChange(e) {
    e.preventDefault();
    //Rutuja 4843 - Added Below message to none
    this.setState({
      validationDisplay: "none",
      validationDisplay1: "none",
      validationDisplay2: "none",
      validationDisplay3: "none",
      systemFields: "",
      duplicateAliasName: "",
      customMappingOtherField: "", //Rutuja - 5277 - added to set customMappingOtherField value empty on formate change
      customMappingStatus: "edit" //Aman-5295-AC-Customize Mapping-set that satus to edit
    });
    
    this.state.advertiserMapping = [];
    this.state.format = "";
    const { user } = this.props.auth;
    var userID = user.id;
    var advertiserID = this.state.advertiserID;
    var format = e.target.value;
    document.getElementById("checkboxtick").disabled = false; //Chaitanya-4962-checkbox value update
    this.setState({ format: format, advertiserID: advertiserID }, function () {
      let data = {
        advertiserID: advertiserID,
        format: this.state.format,
      }; // karan-task-3723-vapt header and query params
      fetch("campaign/getAgencyAdvertiserCustomizedMap", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((advertiserMapping) => {
          //Aman-5295 added condition set the value of customMapping Status to pass in DB for mapping_log
           if (advertiserMapping.length === 0 ) {
            var customMappingStatus = "create";
            this.setState({ customMappingStatus: customMappingStatus });
          }
          if (advertiserMapping.length > 0) {
            var customMapping = advertiserMapping.filter(
              (a) => a.format == this.state.format
            );
            //Aman-5295 added condition set the value of customMapping Status to pass in DB for mapping_log
            if (customMapping.length === 0) {
              var customMappingStatus = "create";
              this.setState({ customMappingStatus: customMappingStatus });
            }
            this.setState({ oldcustomMapping: customMapping }); //Aman-5295 - set select customize mapping format to track in the mapping_log table  
              //Rutuja - 5277 -added to Iterate over each object in the customMapping array
              customMapping.forEach((obj) => {
                //Rutuja - 5277 - Iterate over the keys of the current object
                Object.keys(obj).forEach((key) => {
                  //Rutuja - 5277 - Check if the key starts with "extra" and the value is null, then delete the key
                    if (key.startsWith("extra") && obj[key] === null) {
                        delete obj[key];
                    }
                });
              });

              //Rutuja - 5277 - Initialize customMappingOtherField array get extra other Fields
              const customMappingOtherField = [];

              //Rutuja - 5277 - Extract values from "extra21" to "extra30"
              if (customMapping[0]) {
              for (let i = 21; i <= 30; i++) {
                  const key = `extra${i}`;
                  const updatedKey = `Extra ${i}`;
                  const value = customMapping[0][key];
                  if (value !== null && value !== undefined && value !== "") {
                      customMappingOtherField.push({ [updatedKey]: value });
                  }
              }
              }


            if (customMapping.length === 0) {
              var agencyAdvertiserMap = [
                {
                  pID: "Publisher Id",
                  campID: "Campaign ID",
                  campaignName: "campaign Name",
                  leadInteractionDate: "Lead Interaction Date",
                  firstName: "First Name",
                  lastName: "Last Name",
                  companyName: "Company Name",
                  linkedInCompanyName: "LinkedIn Company Name",
                  email: "Email",
                  workPhone: "Work Phone",
                  workPhoneFormat: "91-XXXXXXXXXX",
                  jobTitle: "Job Title",
                  linkedInJobTitle: "LinkedIn Job Title",
                  address: "Address",
                  country: "Country",
                  city: "City",
                  state: "State",
                  zipCode: "Zip Code",
                  companyEmployeeSize: "Company Employee Size",
                  companyRevenue: "company Revenue",
                  industry: "Industry",
                  assetName: "Asset Name",
                  assetNameTouch1: "Asset Name Touch 1",
                  assetTimestampTouch1: "Asset Timestamp Touch 1",
                  assetNameTouch2: "Asset Name Touch 2",
                  assetTimestampTouch2: "Asset Timestamp Touch 2",
                  assetNameTouch3: "Asset Name Touch 3",
                  assetTimestampTouch3: "Asset Timestamp Touch 3",
                  street: "Street",
                  ip: "IP",
                  supportDocID: "Asset ID",
                  jobLevel: "Job Level",
                  jobFunction: "Job Function",
                  channel: "Channel",
                  extra1: "Extra 1",
                  extra2: "Extra 2",
                  extra3: "Extra 3",
                  extra4: "Extra 4",
                  extra5: "Extra 5",
                  extra6: "Extra 6",
                  extra7: "Extra 7",
                  extra8: "Extra 8",
                  extra9: "Extra 9",
                  extra10: "Extra 10",
                  extra11: "Extra 11",
                  extra12: "Extra 12",
                  extra13: "Extra 13",
                  extra14: "Extra 14",
                  extra15: "Extra 15",
                  extra16: "Extra 16",
                  extra17: "Extra 17",
                  extra18: "Extra 18",
                  extra19: "Extra 19",
                  extra20: "Extra 20",
                  domain: "Domain",
                  alternatePhoneNo: "Alternate Phone No",
                  comments: "Comments",
                  linkedIn: "LinkedIn",
                },
              ]; //Sandeep-task-3158-added code for extra 15 fields

              var customMapping = [
                {
                  pID: "",
                  campID: "",
                  campaignName: "",
                  leadInteractionDate: "",
                  firstName: "",
                  lastName: "",
                  companyName: "",
                  linkedInCompanyName: "",
                  email: "",
                  workPhone: "",
                  workPhoneFormat: "",
                  jobTitle: "",
                  linkedInJobTitle: "",
                  address: "",
                  country: "",
                  city: "",
                  state: "",
                  zipCode: "",
                  companyEmployeeSize: "",
                  companyRevenue: "",
                  industry: "",
                  assetName: "",
                  assetNameTouch1: "",
                  assetTimestampTouch1: "",
                  assetNameTouch2: "",
                  assetTimestampTouch2: "",
                  assetNameTouch3: "",
                  assetTimestampTouch3: "",
                  street: "",
                  ip: "",
                  supportDocID: "",
                  jobLevel: "",
                  jobFunction: "",
                  channel: "",
                  extra1: "",
                  extra2: "",
                  extra3: "",
                  extra4: "",
                  extra5: "",
                  extra6: "",
                  extra7: "",
                  extra8: "",
                  extra9: "",
                  extra10: "",
                  extra11: "",
                  extra12: "",
                  extra13: "",
                  extra14: "",
                  extra15: "",
                  extra16: "",
                  extra17: "",
                  extra18: "",
                  extra19: "",
                  extra20: "", //Sandeep-task-3158-added code for extra 15 fields
                  domain: "",
                  alternatePhoneNo: "",
                  comments: "",
                  linkedIn: "",
                },
              ];
              this.state.advertiserMapping.length = 0;
              this.state.agencyAdvertiserMap = [...advertiserMapping];
              this.state.advertiserMapping = [...customMapping];

              //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
              if (customMapping[0].nonEnglishCheck == "Yes") {
                this.state.isCheckednonEnglish = true;
              }

              this.setState({
                advertiserMapping: customMapping,
                agencyAdvertiserMap: agencyAdvertiserMap,
                advertiserID: this.state.advertiserID,
                length: advertiserMapping.length,
                length: this.state.length,
                nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
                customMappingOtherField: customMappingOtherField //Rutuja-5277 - setting the customMappingOtherField value

              });
            } else {
              //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
              if (customMapping[0].nonEnglishCheck == "Yes") {
                this.state.isCheckednonEnglish = true;
              }
              this.state.agencyAdvertiserMap = [...advertiserMapping];
              this.state.advertiserMapping = [...customMapping];
              this.setState({
                advertiserMapping: customMapping,
                agencyAdvertiserMap: this.state.advertiserMapping,
                nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
                customMappingOtherField: customMappingOtherField //Rutuja-5277 - setting the customMappingOtherField value

              });
            }
          }
        });
      this.setState({ userID: userID, advertiserID: this.state.advertiserID });
    });
  }
  handleClientChange(e) {
    document.getElementById("clientName").style.border = "1px solid lightgray";
    this.setState({ clientNameDisplay: "none" , customMappingOtherField: "", customMappingStatus: "edit"}); //Rutuja-5277 - setting the customMappingOtherField value empty //Aman-5295-AC-Customize Mapping-set that satus to edit
    this.setState({ validationDisplay1: "none", duplicateAliasName: "" }); //kiran - 4299-error msg disabled on handled change //Rutuja task 4832 added duplicate alais name array
    this.setState({ validationDisplay2: "none" }); //rutuja task - 4779 added error message for all fields are mandetory
    this.setState({ validationDisplay3: "none", systemFields: "" }); //Rutuja task-4832 error  message and array for similar system fields
    const { user } = this.props.auth;
    var userID = user.id;
    var advertiserID = e.target.value;
    this.setState({ stectedClientID: advertiserID }); //Nilesh-5279- added to set client Id
    var format = this.state.format;
    document.getElementById("checkboxtick").disabled = false; //Chaitanya-4962-checkbox value update
    let data = {
      advertiserID: advertiserID,
      format: this.state.format,
    }; // karan-task-3723-vapt header and query params
    fetch("campaign/getAgencyAdvertiserCustomizedMap", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((advertiserMapping) => {
        //Aman-5295 added condition set the value of customMapping Status to pass in DB for mapping_log
         if (advertiserMapping.length === 0 ) {
          var customMappingStatus = "create";
          this.setState({ customMappingStatus: customMappingStatus });
        }
        if (advertiserMapping.length > 0) {
          this.state.mappingFormat = [...advertiserMapping];
          this.setState({ mappingFormat: this.state.mappingFormat });
          this.state.agencyAdvertiserMap = [...advertiserMapping]; //Sandeep-task-2879 added code advertiserMapping data appended into agencyAdvertiserMap array
          var customMapping = this.state.agencyAdvertiserMap.filter(
            (a) => a.format == this.state.format
          ); //sandeep-task-2879-changing datatype let to var
          //Aman-5295 added condition set the value of customMapping Status to pass in DB for mapping_log
          if (customMapping.length === 0 ) {
            var customMappingStatus = "create";
            this.setState({ customMappingStatus: customMappingStatus });
          }
          this.setState({ oldcustomMapping: customMapping }); //Aman-5295 - set select customize mapping format to track in the mapping_log table
                //Rutuja-5277 - added to Iterate over each object in the customMapping array
                customMapping.forEach((obj) => {
                  //Rutuja-5277 - Iterate over the keys of the current object
                  Object.keys(obj).forEach((key) => {
                      //Rutuja-5277 - Check if the key starts with "extra" and the value is null, then delete the key
                      if (key.startsWith("extra") && obj[key] === null) {
                          delete obj[key];
                      }
                  });
                });
                //Rutuja-5277 - added Initialize customMappingOtherField array to store extra fileds data
                const customMappingOtherField = [];
                //Rutuja-5277 - Extract values from "extra21" to "extra30"
                if (customMapping[0]) {
                for (let i = 21; i <= 30; i++) {
                    const key = `extra${i}`;
                    const updatedKey = `Extra ${i}`;
                    const value = customMapping[0][key];
                    if (value !== null && value !== undefined && value !== "") {
                        customMappingOtherField.push({ [updatedKey]: value });
                    }
                }
                }

          if (customMapping.length === 0) {
            //Sandeep-task-2879 added code to handle format table display issue
            var agencyAdvertiserMap = [
              {
                pID: "Publisher Id",
                campID: "Campaign ID",
                campaignName: "campaign Name",
                leadInteractionDate: "Lead Interaction Date",
                firstName: "First Name",
                lastName: "Last Name",
                companyName: "Company Name",
                linkedInCompanyName: "LinkedIn Company Name",
                email: "Email",
                workPhone: "Work Phone",
                workPhoneFormat: "91-XXXXXXXXXX",
                jobTitle: "Job Title",
                linkedInJobTitle: "LinkedIn Job Title",
                address: "Address",
                country: "Country",
                city: "City",
                state: "State",
                zipCode: "Zip Code",
                companyEmployeeSize: "Company Employee Size",
                companyRevenue: "company Revenue",
                industry: "Industry",
                assetName: "Asset Name",
                assetNameTouch1: "Asset Name Touch 1",
                assetTimestampTouch1: "Asset Timestamp Touch 1",
                assetNameTouch2: "Asset Name Touch 2",
                assetTimestampTouch2: "Asset Timestamp Touch 2",
                assetNameTouch3: "Asset Name Touch 3",
                assetTimestampTouch3: "Asset Timestamp Touch 3",
                street: "Street",
                ip: "IP",
                supportDocID: "Asset ID",
                jobLevel: "Job Level",
                jobFunction: "Job Function",
                channel: "Channel",
                extra1: "Extra 1",
                extra2: "Extra 2",
                extra3: "Extra 3",
                extra4: "Extra 4",
                extra5: "Extra 5",
                extra6: "Extra 6",
                extra7: "Extra 7",
                extra8: "Extra 8",
                extra9: "Extra 9",
                extra10: "Extra 10",
                extra11: "Extra 11",
                extra12: "Extra 12",
                extra13: "Extra 13",
                extra14: "Extra 14",
                extra15: "Extra 15",
                extra16: "Extra 16",
                extra17: "Extra 17",
                extra18: "Extra 18",
                extra19: "Extra 19",
                extra20: "Extra 20", //Sandeep-task-3158-added code for extra 15 fields
                domain: "Domain",
                alternatePhoneNo: "Alternate Phone No",
                comments: "Comments",
                linkedIn: "LinkedIn",
              },
            ];
            //sandeep task-2879 addded array to store agencyAdvertiserMapping data
            var customMapping = [
              {
                pID: "",
                campID: "",
                campaignName: "",
                leadInteractionDate: "",
                firstName: "",
                lastName: "",
                companyName: "",
                linkedInCompanyName: "",
                email: "",
                workPhone: "",
                workPhoneFormat: "",
                jobTitle: "",
                linkedInJobTitle: "",
                address: "",
                country: "",
                city: "",
                state: "",
                zipCode: "",
                companyEmployeeSize: "",
                companyRevenue: "",
                industry: "",
                assetName: "",
                assetNameTouch1: "",
                assetTimestampTouch1: "",
                assetNameTouch2: "",
                assetTimestampTouch2: "",
                assetNameTouch3: "",
                assetTimestampTouch3: "",
                street: "",
                ip: "",
                supportDocID: "",
                jobLevel: "",
                jobFunction: "",
                channel: "",
                extra1: "",
                extra2: "",
                extra3: "",
                extra4: "",
                extra5: "",
                extra6: "",
                extra7: "",
                extra8: "",
                extra9: "",
                extra10: "",
                extra11: "",
                extra12: "",
                extra13: "",
                extra14: "",
                extra15: "",
                extra16: "",
                extra17: "",
                extra18: "",
                extra19: "",
                extra20: "", //Sandeep-task-3158-added code for extra 15 fields
                domain: "",
                alternatePhoneNo: "",
                comments: "",
                linkedIn: "",
              },
            ];
            //sandeep task-2879 addded array to store customMapping  data
            this.state.advertiserMapping.length = 0;
            this.state.agencyAdvertiserMap = [...advertiserMapping];
            this.state.advertiserMapping = [...customMapping];
            //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
            if (customMapping[0].nonEnglishCheck == "Yes") {
              this.state.isCheckednonEnglish = true;
            }

            this.setState({
              advertiserMapping: customMapping,
              agencyAdvertiserMap: agencyAdvertiserMap,
              length: advertiserMapping.length,
              nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
              customMappingOtherField: customMappingOtherField //Rutuja-5277 -set customMappingOtherField value
            }); //sandeep -task-2879 removing advertiserID  from setState as there is no use of that field and length has duplicate value so length also removed from setState
          } else {
            this.state.agencyAdvertiserMap = [...advertiserMapping];
            this.state.advertiserMapping = [...customMapping];
            this.setState({
              advertiserMapping: customMapping,
              agencyAdvertiserMap: this.state.advertiserMapping,
            });
          }
          this.state.advertiserMapping = [...customMapping];
          //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
          if (customMapping[0].nonEnglishCheck == "Yes") {
            this.state.isCheckednonEnglish = true;
          }
          this.setState({
            advertiserMapping: customMapping,
            advertiserID: this.state.advertiserID,
            length: advertiserMapping.length,
            nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
            customMappingOtherField: customMappingOtherField //Rutuja-5277 -set customMappingOtherField value

          });
        } else {
          //Sandeep-task-3158-added code for extra 15 fields
          var agencyAdvertiserMap = [
            {
              pID: "Publisher Id",
              campID: "Campaign ID",
              campaignName: "campaign Name",
              leadInteractionDate: "Lead Interaction Date",
              firstName: "First Name",
              lastName: "Last Name",
              companyName: "Company Name",
              linkedInCompanyName: "LinkedIn Company Name",
              email: "Email",
              workPhone: "Work Phone",
              workPhoneFormat: "91-XXXXXXXXXX",
              jobTitle: "Job Title",
              linkedInJobTitle: "LinkedIn Job Title",
              address: "Address",
              country: "Country",
              city: "City",
              state: "State",
              zipCode: "Zip Code",
              companyEmployeeSize: "Company Employee Size",
              companyRevenue: "company Revenue",
              industry: "Industry",
              assetName: "Asset Name",
              assetNameTouch1: "Asset Name Touch 1",
              assetTimestampTouch1: "Asset Timestamp Touch 1",
              assetNameTouch2: "Asset Name Touch 2",
              assetTimestampTouch2: "Asset Timestamp Touch 2",
              assetNameTouch3: "Asset Name Touch 3",
              assetTimestampTouch3: "Asset Timestamp Touch 3",
              street: "Street",
              ip: "IP",
              supportDocID: "Asset ID",
              jobLevel: "Job Level",
              jobFunction: "Job Function",
              channel: "Channel",
              extra1: "Extra 1",
              extra2: "Extra 2",
              extra3: "Extra 3",
              extra4: "Extra 4",
              extra5: "Extra 5",
              extra6: "Extra 6",
              extra7: "Extra 7",
              extra8: "Extra 8",
              extra9: "Extra 9",
              extra10: "Extra 10",
              extra11: "Extra 11",
              extra12: "Extra 12",
              extra13: "Extra 13",
              extra14: "Extra 14",
              extra15: "Extra 15",
              extra16: "Extra 16",
              extra17: "Extra 17",
              extra18: "Extra 18",
              extra19: "Extra 19",
              extra20: "Extra 20",
              domain: "Domain",
              alternatePhoneNo: "Alternate Phone No",
              comments: "Comments",
              linkedIn: "LinkedIn",
            },
          ];
          this.state.agencyAdvertiserMap = [...advertiserMapping];

          this.setState({
            advertiserMapping: advertiserMapping,
            agencyAdvertiserMap: agencyAdvertiserMap,
            advertiserID: this.state.advertiserID,
            length: advertiserMapping.length,
            nonEnglishChecked: "No", //Chaitanya-4984-Non English check box value will be refresh for Newly created Advertiser
          });
        }
      });
    this.setState({ userID: userID, advertiserID: advertiserID });
  }
  //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(added function for validation)
  validateForm() {
    //Rutuja - 5277 - map method to Remove spaces from the key  names and convert them into lowercase
    var customMappingExtraField = [];
    if (Array.isArray(this.state.customMappingOtherField) && this.state.customMappingOtherField.length > 0) {
        customMappingExtraField = this.state.customMappingOtherField.map(obj => {
            const newObj = {};
            for (let key in obj) {
                const newKey = key.toLowerCase().replace(/\s+/g, ''); 
                newObj[newKey] = obj[key];
            }
            return newObj;
        });
    }  
    //Rutuja - 5277 - Merge the array of objects into a single object
    customMappingExtraField = Object.assign({}, ...customMappingExtraField);
    //Rutuja - 5277 - Extract only values of ExtraFieldvalues
    const ExtraFieldvalues = Object.values(customMappingExtraField);
    
    const {
      pID,
      campID,
      campaignName,
      leadInteractionDate,
      firstName,
      lastName,
      companyName,
      linkedInCompanyName,
      email,
      workPhone,
      workPhoneFormat,
      jobTitle,
      linkedInJobTitle,
      address,
      country,
      city,
      state,
      zipCode,
      companyEmployeeSize,
      companyRevenue,
      industry,
      assetName,
      assetNameTouch1,
      assetTimestampTouch1,
      assetNameTouch2,
      assetTimestampTouch2,
      assetNameTouch3,
      assetTimestampTouch3,
      street,
      ip,
      supportDocID,
      jobLevel,
      jobFunction,
      channel,
      extra1,
      extra2,
      extra3,
      extra4,
      extra5,
      extra6,
      extra7,
      extra8,
      extra9,
      extra10,
      extra11,
      extra12,
      extra13,
      extra14,
      extra15,
      extra16,
      extra17,
      extra18,
      extra19,
      extra20, //Sandeep-task-3158-added code for extra 15 fields
      domain,
      alternatePhoneNo,
      comments,
      linkedIn,
    } = this.state.agencyAdvertiserMap[0];
    //Rutuja-5277 - taking value of extra other Fields from customMappingExtraField
    const { extra21, extra22, extra23, extra24, extra25, extra26, extra27, extra28, extra29, extra30} = customMappingExtraField;

    let formIsvalid = true;
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    let aliasname = false;
    let workPhoneFormatValue = false;
    let sameValue = false;
    let allvalue = false; //4779
    let difieldValue = false; //rutuja 4832 for similar system fields

    const agencyAdvertiserMap = this.state.agencyAdvertiserMap[0] //Rutuja-5277 - added to take all fileds value from agencyAdvertiserMap

    //Rutuja-5277 - Define the keys to be removed from agencyAdvertiserMap (getting issue to handle duplicate  values in setState)
    const keysToRemove = ["created", "lastUpdated","nonEnglishCheck", "extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"]; //Aman-5295-added nonEnglishCheck to resolve the issue of Duplicate alias 
    //Rutuja-5277 - Remove the specified keys from the object and filter null values
      const filteredMap = Object.fromEntries(
        Object.entries(agencyAdvertiserMap)
          .filter(([key, value]) => !keysToRemove.includes(key) && value !== null)
      ); 
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    let arrOne = Object.values(filteredMap).filter(value => value !== null && value !== ""); //Rutuja-5277 - chnaged the this.state.agencyAdvertiserMap[0] with filteredMap to get Filter out null and empty string values
    //arrOne.splice(-2); //kiran- 4299-custom mapping edited  //Rutuja-5277 - commneted it is removing  last two element from filteredMap
   
    //Rutuja-5277 - added to Merge arrOne and ExtraFieldvalues arrays 
    const mergedArray = arrOne.concat(ExtraFieldvalues); 
    //  var pattern = new RegExp(/^(|[0-9]\d*)$/);
    //var pattern = new RegExp(/^(|[_0-9!@#$%^&*(),.":{}\]\[+=~|<>?/;'~`-]\d*)$/); //Kiran-4848 changed pattern for not accepting only these symbols
    var pattern;
    //Chaitanya-4962-Changed pattern for Non English Checked
    if (this.state.nonEnglishChecked == "Yes") {
      pattern = /^([0-9]\d*)$/;
    } else {
      pattern = /^([0-9]\d*|[\W_]+)$/; //kiran-4861-changed pattern not accepting multiple special symbols and also for space
    }
    var duplicateAliasName = []; // Rutuja task 4832-declared the array for stroing duplcate values

    //Rutuja Task -4779 Added validation if duplicate alias name found also hange the below code for showing proper error messages
    for (let i = 0; i < mergedArray.length; i++) { //Rutuja-5277 - replace the arrOne with mergedArray to get both value of arrone and other extra fileds
      if (mergedArray[i] === "") { //Rutuja-5277 - replace the arrOne with mergedArray to get both duplicate value from fileds
        allvalue = false; //Aman-4971-chnaged the flag true to false to accpet blank filed for edit mapping fileds
        this.setState({ validationDisplay2: "none" }); //Aman-4971-chnaged the flag Block to none to do not show error msg for blank fileds
      }
    }
    if (allvalue !== true) {
      if (
        !pattern.test(pID) &&
        !pattern.test(campID) &&
        !pattern.test(leadInteractionDate) &&
        !pattern.test(firstName) &&
        !pattern.test(lastName) &&
        !pattern.test(campaignName) &&
        !pattern.test(companyName) &&
        !pattern.test(linkedInCompanyName) &&
        !pattern.test(email) &&
        !pattern.test(workPhone) &&
        !pattern.test(workPhoneFormat) &&
        !pattern.test(jobTitle) &&
        !pattern.test(linkedInJobTitle) &&
        !pattern.test(address) &&
        !pattern.test(country) &&
        !pattern.test(city) &&
        !pattern.test(state) &&
        !pattern.test(zipCode) &&
        !pattern.test(companyEmployeeSize) &&
        !pattern.test(companyRevenue) &&
        !pattern.test(industry) &&
        !pattern.test(assetName) &&
        !pattern.test(assetNameTouch1) &&
        !pattern.test(assetTimestampTouch1) &&
        !pattern.test(assetNameTouch2) &&
        !pattern.test(assetTimestampTouch2) &&
        !pattern.test(assetNameTouch3) &&
        !pattern.test(assetTimestampTouch3) &&
        !pattern.test(street) &&
        !pattern.test(ip) &&
        !pattern.test(supportDocID) &&
        !pattern.test(jobLevel) &&
        !pattern.test(jobFunction) &&
        !pattern.test(channel) &&
        !pattern.test(extra1) &&
        !pattern.test(extra2) &&
        !pattern.test(extra3) &&
        !pattern.test(extra4) &&
        !pattern.test(extra5) &&
        !pattern.test(extra6) &&
        !pattern.test(extra7) &&
        !pattern.test(extra8) &&
        !pattern.test(extra9) &&
        !pattern.test(extra10) &&
        !pattern.test(extra11) &&
        !pattern.test(extra12) &&
        !pattern.test(extra13) &&
        !pattern.test(extra14) &&
        !pattern.test(extra15) &&
        !pattern.test(extra16) &&
        !pattern.test(extra17) &&
        !pattern.test(extra18) &&
        !pattern.test(extra19) &&
        !pattern.test(extra20) &&
        !pattern.test(domain) &&
        !pattern.test(alternatePhoneNo) &&
        !pattern.test(comments) &&
        !pattern.test(linkedIn) &&
        !pattern.test(extra21) && //Rutuja-5277 - added to extra21 to extra30 to check it value with pattern
        !pattern.test(extra22) &&
        !pattern.test(extra23) &&
        !pattern.test(extra24) &&
        !pattern.test(extra25) &&
        !pattern.test(extra26) &&
        !pattern.test(extra27) &&
        !pattern.test(extra28) &&
        !pattern.test(extra29) &&
        !pattern.test(extra30)
      ) {
        aliasname = false;
        for (let i = 0; i < mergedArray.length; i++) { //Rutuja-5277 - replace the arrOne with mergedArray to get both duplicate value from fileds
          for (let k = i + 1; k < mergedArray.length; k++) { //Rutuja-5277 - replace the arrOne with mergedArray to get both duplicate value from fileds
            //Rutuja TAsk-4779 comparing two obj by converting to lower case
            let obj1 = mergedArray[i].toString(); //Rutuja-5277 - replace the arrOne with mergedArray to get both duplicate value from fileds
            let obj2 = mergedArray[k].toString(); //Rutuja-5277 - replace the arrOne with mergedArray to get both duplicate value from fileds
            //Aman-4981-(added new condtion to handle empty filed)- Customize Mapping - Showing duplicate error for two blank alias name
            if (
              obj1.toLowerCase() !== obj2.toLowerCase() ||
              (obj1.toLowerCase() === "" && obj2.toLowerCase() === "")
            ) {
              //Ignore
            } else {
              //Rutuja task 4832 For showing array if duplicate fields found
              duplicateAliasName.push(mergedArray[i]); //Rutuja-5277 - replace the arrOne with mergedArray to store duplicateAliasName of both
              duplicateAliasName = [...new Set(duplicateAliasName)];
              sameValue = true;
              this.setState({
                validationDisplay1: "block",
                duplicateAliasName: duplicateAliasName,
              }); // Rutuja 4832 set the value of duplicate alais name
            }
          }
        }

        //task 4779 taking for loop inside if statement for printing one error at once
      } else {
        aliasname = true;
        this.setState({ validationDisplay: "block" });
      }
    }
    //Rutuja Task 4832: similar system fields declared in the array
    //Aman-4971-commented the below values from the array to accept those values in other filed as well as metioend in boards list
    var difields = [
      {
        pID: "Publisher Id",
        campID: "Campaign ID",
        // campaignName: "campaign Name",
        leadInteractionDate: "Lead Interaction Date",
        firstName: "First Name",
        lastName: "Last Name",
        companyName: "Company Name",
        linkedInCompanyName: "LinkedIn Company Name",
        email: "Email",
        workPhone: "Work Phone",
        // workPhoneFormat: "91-XXXXXXXXXX",
        jobTitle: "Job Title",
        linkedInJobTitle: "LinkedIn Job Title",
        address: "Address",
        country: "Country",
        city: "City",
        state: "State",
        zipCode: "Zip Code",
        companyEmployeeSize: "Company Employee Size",
        companyRevenue: "company Revenue",
        industry: "Industry",
        assetName: "Asset Name",
        // assetNameTouch1: "Asset Name Touch 1",
        // assetTimestampTouch1: "Asset Timestamp Touch 1",
        // assetNameTouch2: "Asset Name Touch 2",
        // assetTimestampTouch2: "Asset Timestamp Touch 2",
        // assetNameTouch3: "Asset Name Touch 3",
        // assetTimestampTouch3: "Asset Timestamp Touch 3",
        // street: "Street",
        // ip: "IP",
        // supportDocID: "Asset ID",
        jobLevel: "Job Level",
        jobFunction: "Job Function",
        // channel: "Channel",
        // extra1: "Extra 1",
        // extra2: "Extra 2",
        // extra3: "Extra 3",
        // extra4: "Extra 4",
        // extra5: "Extra 5",
        // extra6: "Extra 6",
        // extra7: "Extra 7",
        // extra8: "Extra 8",
        // extra9: "Extra 9",
        // extra10: "Extra 10",
        // extra11: "Extra 11",
        // extra12: "Extra 12",
        // extra13: "Extra 13",
        // extra14: "Extra 14",
        // extra15: "Extra 15",
        // extra16: "Extra 16",
        // extra17: "Extra 17",
        // extra18: "Extra 18",
        // extra19: "Extra 19",
        // extra20: "Extra 20",
        // domain: "Domain",
        // alternatePhoneNo: "Alternate Phone No",
        // comments: "Comments",
        // linkedIn: "LinkedIn",
      },
    ];
    this.state.difields = [...difields];
    this.setState({ difields: this.state.difields });
    let fieldsvalues = Object.values(this.state.difields[0]);
    let fieldskeys = Object.keys(this.state.difields[0]);
    let custommappingkeys = Object.keys(this.state.agencyAdvertiserMap[0]);
    let custommappingvalues = Object.values(this.state.agencyAdvertiserMap[0]);
    var systemFields = [];
    //Rutuja 4832 added for loop for checking if DI fields are using in other fields or not e.g if publisher id is the DI field still it is used under campaign ID fields the it will show error

    let fieldsvaluesNonEnglish = Object.values(this.state.difields[0]);
    let fieldskeysNonEnglish = Object.keys(this.state.difields[0]);
    let custommappingkeysNonEnglish = Object.keys(
      this.state.agencyAdvertiserMap[0]
    );
    let custommappingvaluesNonEnglish = Object.values(
      this.state.agencyAdvertiserMap[0]
    );
    //Chaitanya-4962-added for loop for checking if DI fields are using in other fields or not e.g if publisher id is the DI field For Non-english check in if condition
    //still it is used under campaign ID fields the it will show error for Non-English fields

    if (this.state.nonEnglishChecked == "Yes") {
      //Nilesh-5294- concatinating extra 21-30 fields
      //Nilesh-5294- added if condition only concat if extra fields are added
      if(this.state.customMappingOtherField.length > 0 ){
        custommappingvaluesNonEnglish = custommappingvaluesNonEnglish.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.values(obj));
          }, [])
        );   
        custommappingkeysNonEnglish = custommappingkeysNonEnglish.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.keys(obj));
          }, [])
        );  
        // custommappingkeysNonEnglish = custommappingkeysNonEnglish.concat((Object.keys(this.state.customMappingOtherField))); 
      }

      for (let i = 0; i < custommappingvaluesNonEnglish.length; i++) {
        let obj1 = custommappingvaluesNonEnglish[i];
        let obj2 = custommappingkeysNonEnglish[i];

        for (let j = 0; j < fieldsvaluesNonEnglish.length; j++) {
          let obj3 = fieldsvaluesNonEnglish[j];
          let obj4 = fieldskeysNonEnglish[j];

          if (obj1 === obj3) {
            if (obj2 !== obj4) {
              difieldValue = true;
              systemFields.push(custommappingvaluesNonEnglish[i]);
              systemFields = [...new Set(systemFields)];
              this.setState({
                validationDisplay3: "block",
                systemFields: systemFields,
              });
            }
          }
        }
        //If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
        if (
          obj1 === "status" ||
          obj1 === "leadinfoid" ||
          obj1 === "reason" ||
          obj1 === "agencycampid"
        ) {
          difieldValue = true;
          systemFields.push(custommappingvaluesNonEnglish[i]);
          systemFields = [...new Set(systemFields)];
          this.setState({
            validationDisplay3: "block",
            systemFields: systemFields,
          });
        }
      }
    } else {
    //Nilesh-5294- concatinating extra 21-30 fields 
    //Nilesh-5294-added if condition only concat if extra fields are added
    if(this.state.customMappingOtherField.length > 0 ){
      // custommappingvalues = custommappingvalues.concat(Object.values(this.state.customMappingOtherField));
      // custommappingkeys = custommappingkeys.concat(Object.keys(this.state.customMappingOtherField));
      custommappingvalues = custommappingvalues.concat(
        this.state.customMappingOtherField.reduce((acc, obj) => {
          return acc.concat(Object.values(obj));
        }, [])
      );   
      custommappingkeys = custommappingkeys.concat(
        this.state.customMappingOtherField.reduce((acc, obj) => {
          return acc.concat(Object.keys(obj));
        }, [])
      ); 
    }
      for (let i = 0; i < custommappingvalues.length; i++) {
        let obj1 = custommappingvalues[i]
          .toString()
          .replace(/\s/g, "")
          .toLowerCase();
        let obj2 = custommappingkeys[i]
          .toString()
          .replace(/\s/g, "")
          .toLowerCase();

        for (let j = 0; j < fieldsvalues.length; j++) {
          let obj3 = fieldsvalues[j]
            .toString()
            .replace(/\s/g, "")
            .toLowerCase();
          let obj4 = fieldskeys[j].toString().replace(/\s/g, "").toLowerCase();

          if (obj1 === obj3) {
            if (obj2 !== obj4) {
              difieldValue = true;
              systemFields.push(custommappingvalues[i]);
              systemFields = [...new Set(systemFields)];
              this.setState({
                validationDisplay3: "block",
                systemFields: systemFields,
              });
            }
          }
        }
        //If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
        if (
          obj1 === "status" ||
          obj1 === "leadinfoid" ||
          obj1 === "reason" ||
          obj1 === "agencycampid"
        ) {
          difieldValue = true;
          systemFields.push(custommappingvalues[i]);
          systemFields = [...new Set(systemFields)];
          this.setState({
            validationDisplay3: "block",
            systemFields: systemFields,
          });
        }
      }
    }

    // for (let i = 0; i < custommappingvalues.length; i++)
    // {

    // 	let obj1 = custommappingvalues[i].toString().replace(/\s/g, '').toLowerCase();
    // 	let obj2 = custommappingkeys[i].toString().replace(/\s/g, '').toLowerCase();

    // 	for(let j=0;j < fieldsvalues.length;j++)
    // 	{
    // 		let obj3 = fieldsvalues[j].toString().replace(/\s/g, '').toLowerCase();
    // 		let obj4 = fieldskeys[j].toString().replace(/\s/g, '').toLowerCase();

    // 		if(obj1 === obj3)
    // 		{

    // 				if (obj2 !== obj4)
    // 				{
    // 					difieldValue = true;
    // 					systemFields.push(custommappingvalues[i])
    // 					systemFields = [...new Set(systemFields)];
    // 					this.setState({ validationDisplay3: "block",systemFields:systemFields });
    // 				}
    // 		}

    // 	}
    // 	//If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
    // 	if((obj1 === "status")||(obj1==="leadinfoid")||(obj1==="reason")||(obj1==="agencycampid"))
    // 		 {
    // 			difieldValue = true;
    // 			systemFields.push(custommappingvalues[i])
    // 			systemFields = [...new Set(systemFields)];
    // 			this.setState({ validationDisplay3: "block" ,systemFields:systemFields});
    // 		}
    // }

    //karan-task-3477-work phone format new validations-START
    let workPhoneFormatPattern = new RegExp(
      //  /^(\d)+(-|_|\s){1}(x|X)(x|X|\s|-)*$/
      // /^[(\d)|()(x|X|\s)+][^|](?!.*\+)(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s){1}(.|x|X|-)(.|x|X|\s|-)*$/
      //   /^[(\d)|()(x|X|\s)+]+(x|X|\s)(?!.*[a-wyzA-WYZ])(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s)(?!.*[a-wyzA-WYZ]){1}(.|x|X|-)(.|x|X|\s|-)*$/
      /^(?!.*\(\()(?!.*\-\-)(?!.*\+\+)(?!.*\)\))[(\d)|()(x|X|\s)+-]+(x|X|\s)(?!.*[a-wyzA-WYZ])(?!.*\(\()(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\_)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s)(?!.*[a-wyzA-WYZ]){1}(.|x|X|-)(.|x|X|\s|-)*$/
    ); //karan-task-3477-work phone format regex
    //  kiran-4671-work phone format  pattern changed for new requirement
    //kiran-4688,4689 changed regex pattern for not accepting other special symbols, alphabets,_ , ((,)) other than x|X
    let workPhoneFormatArr = workPhoneFormat
      .split(/\||\n/) //karan-task-3477-to split the array using | or new line
      .filter((obj) => obj.trim().length !== 0); //karan-task-3477-removing empty data
    let validCounter = 0;
    let workPhoneFormatTemp = "";
    let duplicateWorkPhoneFormatErrorTemp = false;
    let workPhoneFormatSet = new Set(workPhoneFormatArr);
    if (workPhoneFormatSet.size !== workPhoneFormatArr.length) {
      duplicateWorkPhoneFormatErrorTemp = true; //karan-task-3477-error if duplicates found
    } else if (
      workPhoneFormatSet.size === workPhoneFormatArr.length &&
      workPhoneFormatArr.length !== 0
    ) {
      //karan-task-3477-ignore case and then compare
      let tempArr = [...workPhoneFormatSet];
      for (let i = 0; i < workPhoneFormatArr.length; i++) {
        let obj1 = workPhoneFormatArr[i];
        for (let j = 0; j < tempArr.length; j++) {
          let obj2 = tempArr[j];
          if (obj1.toLowerCase() === obj2.toLowerCase() && i !== j) {
            duplicateWorkPhoneFormatErrorTemp = true;
            break;
          }
        }
      }
    } else if (workPhoneFormatArr.length === 0) {
      //karan-task-3477-this is for empty data
      // workPhoneFormat = false;
      workPhoneFormatValue = false; //Rutuja task 4844 commented above line as we have to workphoneformat value rather than workPhoneFormat
      //Aman-4971-chnaged the flag Block to none to do not show error msg for blank fileds
      this.setState({ validationDisplay2: "none" }); //Rutuja task 4844 change display message from validationDisplay to validationDisplay2
    }
    for (let i = 0; i < workPhoneFormatArr.length; i++) {
      let obj = workPhoneFormatArr[i].trim();
      if (workPhoneFormatPattern.test(obj) && obj !== "") {
        //karan-task-3477-validating format
        validCounter++;
        if (workPhoneFormatTemp === "") {
          workPhoneFormatTemp += obj;
        } else {
          workPhoneFormatTemp += "|" + obj;
        }
      }
    }
    if (validCounter === workPhoneFormatArr.length) {
      let agencyAdvertiserMapTemp = [...this.state.agencyAdvertiserMap];
      agencyAdvertiserMapTemp[0] = {
        ...agencyAdvertiserMapTemp[0],
        workPhoneFormat: workPhoneFormatTemp,
      };
      if (duplicateWorkPhoneFormatErrorTemp) {
        workPhoneFormatValue = true;
      }
      this.setState({
        workPhoneFormatError: false, //karan-task-3477-resolving error states
        duplicateWorkPhoneFormatError: duplicateWorkPhoneFormatErrorTemp, //karan-task-3477-resolving error states
        agencyAdvertiserMap: agencyAdvertiserMapTemp, //karan-task-3477-generating main array again
      });
    } else {
      workPhoneFormatValue = true;
      this.setState({
        workPhoneFormatError: true, //karan-task-3477-resolving error states
        duplicateWorkPhoneFormatError: duplicateWorkPhoneFormatErrorTemp, //karan-task-3477-resolving error states
      });
    }
    //karan-task-3477-work phone format new validations-END
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    if (
      sameValue == true ||
      aliasname == true ||
      workPhoneFormatValue == true ||
      allvalue == true ||
      difieldValue == true
    ) {
      //4779
      formIsvalid = false;
    }

    document.body.scrollTop = (0, 0);
    document.documentElement.scrollTop = (0, 0);
    return formIsvalid;
  }
  getMappin() {
    //Rutuja 4832
    this.setState({
      validationDisplay: "none",
      validationDisplay1: "none",
      validationDisplay2: "none",
      validationDisplay3: "none",
      systemFields: "",
      duplicateAliasName: "",
    });
    var e = document.getElementById("clientName");
    var strUser = e.options[e.selectedIndex].text;
    let errors = {};
    if (strUser === "Select Advertiser" || strUser === "") {
      this.setState({ clientNameDisplay: "block" });
      document.getElementById("clientName").style.border = "1px solid red";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0; //kiran-4846-added scrollTop
    } else {
      //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(call for validation)
      if (this.validateForm() === false) {
      } else {
        document.getElementById("clientName").style.border =
          "1px solid lightgray";
        this.setState({ clientNameDisplay: "none" });
        const { user } = this.props.auth;
        let data = {
          advertiserID: this.state.advertiserID,
          mappingValues: this.state.agencyAdvertiserMap,
          format: this.state.format,
          nonEnglishChecked: this.state.nonEnglishChecked, //Chaitanya-4962-Set Non English Checked value
          customMappingExtraField: this.state.customMappingOtherField, //Rutuja-5277 - set the customMappingOtherField value
          oldcustomMapping:  this.state.oldcustomMapping,  //Aman-5295 added old customMapping to pass in DB for mapping_log
          customMappingStatus: this.state.customMappingStatus //Aman-5295 added pass the value of customMapping Status to pass in DB for mapping_log
        }; // karan-task-3723-vapt header and query params
        fetch("/campaign/setAgencyDeliveryFormatMapping", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("Return with response");

            Swal.fire({
              text: "Customize Mapping Done",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          });
      }
    }
  } // end of getMapp
  agencyMappinHandleChange(e) {
    const { name, value } = e.target;
    let agencyAdvertiserMap = [...this.state.agencyAdvertiserMap];
    agencyAdvertiserMap[0] = { ...agencyAdvertiserMap[0], [name]: value };
    this.state.agencyAdvertiserMap = [...agencyAdvertiserMap];
    this.setState({
      agencyAdvertiserMap: agencyAdvertiserMap,
      validationDisplay: "none",
      // validationDisplay1:"none", //4832
      validationDisplay2: "none",
      //  validationDisplay3:"none", //Rutuja task-4832
      //  systemFields:"", //4832
      //  duplicateAliasName:"",//4832
    });
  }
  //Chaitanya-4962-Function for non-english checkbox checked
  nonEnglishChange = () => {
    this.setState(
      {
        isCheckednonEnglish: !this.state.isCheckednonEnglish,
      },
      function () {
        if (this.state.isCheckednonEnglish == false) {
          this.setState({
            nonEnglishChecked: "No",
          });
        } else {
          this.setState({
            nonEnglishChecked: "Yes",
          });
        }
      }
    );
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      var userID = user.id;
      fetch("/campaign/getAgencyAdvertiser") // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((clientDetails) => {
          this.setState({ clientDetails: clientDetails });
        });
    }
  }

  
  /**
 * @author Rutuja Jagtap - 5277
 * @param  Description  handling extra FieldChange
 * @return Description handling extra FieldChange and update the current value
 */
  handleFieldChange(index, event) {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedMapping = [...prevState.customMappingOtherField];
      const key = Object.keys(updatedMapping[index])[0];
      updatedMapping[index][key] = value;
      return { customMappingOtherField: updatedMapping };
    });
  }
  
  /**
 * @author Rutuja Jagtap - 5277
 * @param  Description  handling to add extra
 * @return Description handling to add extra in format ad bottom
 */
  addCustomMappingField() {
    //Rutuja - 5277 - Increment the counter to generate unique field names
    this.setState((prevState) => {
      //Rutuja - 5277 - Array containing all the expected Extra values from 21 to 30
      const expectedExtras = Array.from({ length: 10 }, (_, index) => `Extra ${index + 21}`);
  
      //Rutuja - 5277 - Get the existing Extra values from the state
      let existingExtras = []; //Rutuja-5277-(added array to set array)AC-Settings-Customize Mapping-Page crash issue
      if (Array.isArray(prevState.customMappingOtherField)) { //Rutuja-5277-(added to check array is empty or not)AC-Settings-Customize Mapping-Page crash issue
        existingExtras = prevState.customMappingOtherField.map(obj => Object.keys(obj)[0]);
      } else {}  
      //Rutuja - 5277 - Find the next missing Extra value
      const nextMissingExtra = expectedExtras.find(extra => !existingExtras.includes(extra));
  
      //Rutuja - 5277 - If there is a missing Extra value, add it to customMappingOtherField
      if (nextMissingExtra) {
        const newField = { [nextMissingExtra]: nextMissingExtra };
        return {
          otherFieldCounter: prevState.otherFieldCounter + 1,
          customMappingOtherField: [
            ...prevState.customMappingOtherField,
            newField
          ],
        };
      } else {
        //Rutuja - 5277 - If all Extra values are present, return the previous state
        return prevState;
      }
    }, () => {
    });
  }
    /**
 * @author Rutuja Jagtap - 5277
 * @param  Description  handling to remove extra
 * @return Description handling to remove added extra filed from format
 */
  removeCustomMappingField(index, e) {
    e.preventDefault();
      //Rutuja-5277 - Remove the item at the specified index from customMappingOtherField array
      const updatedCustomMappingOtherField = [...this.state.customMappingOtherField];
      updatedCustomMappingOtherField.splice(index, 1);
      //Rutuja-5277 - Update the state to remove the item
      this.setState({ customMappingOtherField: updatedCustomMappingOtherField });
  }

  render() {
    return (
      <div>
        <Navigation />
        <div class="container-fluid">
          {/* //shivani-3283-passed ID for DI label consistency */}
          <div
            style={{
              color: "#056eb8",
              flex: "0 0 auto",
              backgroundColor: "rgb(245 245 245)",
              paddingTop: "10px",
              position: "sticky",
              top: "85px",
              zIndex: "1000",
            }}
          >
            <div
              class="container-fluid fixed-top col-xs-12 col-sm-12 col-md-1 col-lg-1"
              style={{
                color: "#056eb8",
                flex: "0 0 auto",
                backgroundColor: "rgb(245 245 245)",
                position: "sticky",
              }}
            >
              {/* Chaitanya-4984-Changed alignment of Back button */}
              <a
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/newdashboard", {
                    agencyID: this.state.agencyID,
                  });
                }}
                style={{
                  color: "#056eb8",
                  flex: "0 0 auto",
                  backgroundColor: "rgb(245 245 245)",
                  position: "sticky",
                }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ marginLeft: "15%", marginTop: "5%" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>
            <div
              align="center"
              id="labelDI"
              class="container-fluid fixed-top col-xs-12 col-sm-12 col-md-11 col-lg-11"
              style={{
                paddingRight: "10%",
                flex: "0 0 auto",
                backgroundColor: "rgb(245 245 245)",
                position: "sticky",
              }}
            >
              Customized Column Mapping
            </div>
          </div>

          <div
            class="container-fluid fixed-top"
            style={{
              flex: "0 0 auto",
              backgroundColor: "rgb(245 245 245)",
              paddingTop: "15px",
              position: "sticky",
              top: "115px",
              zIndex: "995",
            }}
          >
            {this.state.length === 0 ? (
              <span>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      Advertiser Name
                    </label>
                    &nbsp;&nbsp;
                    <select
                      id="clientName"
                      name="clientName"
                      onChange={this.handleClientChange}
                      class="form-control"
                      required
                    >
                      <option value="" disabled selected>
                        Select Advertiser
                      </option>
                      {this.state.clientDetails.map((clientDetails) => (
                        <option value={clientDetails.clientID}>
                          {clientDetails.clientName}{" "}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      Format
                    </label>
                    &nbsp;&nbsp;
                    <select
                      id="format"
                      name="format"
                      class="form-control"
                      style={{ height: "34px", width: "182px" }}
                      value={this.state.format}
                      onChange={this.handleFormatChange}
                      required
                    >
                      {/* <option value="" disabled selected>Select Format</option> */}
                      <option value="Format 1">Format 1</option>
                    </select>
                  </div>

                  {/* Chaitanya-4962-Added Non-English Checkbox */}
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <input
                      style={{
                        marginTop: "8%",
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      type="checkbox"
                      checked={
                        this.state.nonEnglishChecked == "Yes" ? true : false
                      }
                      onChange={this.nonEnglishChange}
                      id="checkboxtick"
                      disabled
                    />
                    &nbsp;&nbsp;
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      {/* Chaitanya-5010-Changed non english label to Multilingual Campaign */}
                      Multilingual Campaign
                    </label>
                  </div>

                  <div
                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                    style={{ marginTop: "20px" }}
                  >
                    <button
                      class="btn add-button"
                      style={{ width: "130px" }}
                      onClick={this.getMappin}
                    >
                      Save Mapping
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
                  {/* karan-task-3477-added column coz cannot add more than 1 message in earlier design */}
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <span
                      style={{ display: this.state.clientNameDisplay }}
                      className="errorMessage"
                    >
                      Please select advertiser name
                    </span>
                    {/* //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(validation message) */}
                    <span
                      style={{ display: this.state.validationDisplay }}
                      className="errorMessage"
                    >
                      Please enter valid alias names
                    </span>
                    {/* //rutuja task - 4779 added error message for all fields are mandetory */}
                    <span
                      style={{ display: this.state.validationDisplay2 }}
                      className="errorMessage"
                    >
                      All Fields are Mandatory
                    </span>
                    {/*//Sanjana-3897-Customize mapping validation for duplicate alias name*/}
                    <span
                      style={{ display: this.state.validationDisplay1 }}
                      className="errorMessage"
                    >
                      Duplicate alias names found
                    </span>
                    {/* Rutuja Task 4832 shown array elements if duplicate alais name fount */}
                    <p className="errorMessage">
                      {this.state.duplicateAliasName.length == 0 ? (
                        <b>{""}</b>
                      ) : (
                        <b> {this.state.duplicateAliasName.join(", ")} </b>
                      )}
                    </p>
                    {/* 4832 added error msg with array if system fileds are used under other fields */}
                    <span
                      style={{ display: this.state.validationDisplay3 }}
                      className="errorMessage"
                    >
                      Please make sure alias names should be identical to an
                      system fields
                    </span>
                    <p className="errorMessage">
                      {this.state.systemFields.length == 0 ? (
                        <b>{""}</b>
                      ) : (
                        <b> {this.state.systemFields.join(", ")} </b>
                      )}
                    </p>

                    {/* karan-task-3477-work phone format error 1 */}
                    {this.state.workPhoneFormatError && (
                      <span
                        className="errorMessage"
                        style={{ display: "block" }}
                      >
                        Invalid work phone format
                      </span>
                    )}
                    {/* karan-task-3477-work phone format error 2 */}
                    {this.state.duplicateWorkPhoneFormatError && (
                      <span
                        className="errorMessage"
                        style={{ display: "block" }}
                      >
                        Duplicate work phone format found
                      </span>
                    )}
                  </div>
                </div>
              </span>
            ) : (
              <span>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      Advertiser Name{" "}
                    </label>
                    &nbsp;&nbsp;
                    <select
                      id="clientName"
                      name="clientName"
                      onChange={this.handleClientChange}
                      class="form-control"
                      required
                    >
                      <option value="" disabled selected>
                        Select Advertiser
                      </option>
                      {this.state.clientDetails.map((clientDetails) => (
                        <option value={clientDetails.clientID}>
                          {clientDetails.clientName}{" "}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      Format
                    </label>
                    &nbsp;&nbsp;
                    <select
                      id="format"
                      name="format"
                      class="form-control"
                      style={{ height: "34px", width: "182px" }}
                      value={this.state.format}
                      onChange={this.handleFormatChange}
                      required
                    >
                      {this.state.mappingFormat.map((mappingFormat) => {
                        return (
                          <option value={mappingFormat.format}>
                            {mappingFormat.format}
                          </option>
                        );
                      })}
                      <option
                        value={
                          "Format " + (this.state.mappingFormat.length + 1)
                        }
                      >
                        Format {this.state.mappingFormat.length + 1}
                      </option>
                    </select>
                  </div>

                  {/* Chaitanya-4962-Added Non-English Checkbox */}
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <input
                      style={{
                        marginTop: "8%",
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      type="checkbox"
                      checked={
                        this.state.nonEnglishChecked == "Yes" ? true : false
                      }
                      onChange={this.nonEnglishChange}
                      id="checkboxtick"
                      disabled
                    />
                    &nbsp;&nbsp;
                    <label id="label" style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Chaitanya-4984-Made it Bold label */}
                      {/* Chaitanya-5014-Changed non english label to Multilingual Campaign */}
                      Multilingual Campaign
                    </label>
                  </div>

                  <div
                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                    style={{ marginTop: "20px" }}
                  >
                    <button
                      class="btn add-button"
                      style={{ width: "130px" }}
                      onClick={this.getMappin}
                    >
                      Save Mapping
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
                  {/* karan-task-3477-added column coz cannot add more than 1 message in earlier design */}
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <span
                      style={{ display: this.state.clientNameDisplay }}
                      className="errorMessage"
                    >
                      Please select advertiser name
                    </span>
                    {/* //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(validation message) */}
                    <span
                      style={{ display: this.state.validationDisplay }}
                      className="errorMessage"
                    >
                      Please enter valid alias names
                    </span>
                    {/* //rutuja task - 4779 added error message for all fields are mandetory */}
                    <span
                      style={{ display: this.state.validationDisplay2 }}
                      className="errorMessage"
                    >
                      All Fields are Mandatory
                    </span>
                    {/*//Sanjana-3897-Customize mapping validation for duplicate alias name*/}
                    <span
                      style={{ display: this.state.validationDisplay1 }}
                      className="errorMessage"
                    >
                      Duplicate alias names found
                    </span>
                    {/* 4832 display the list of duplicate fields */}
                    <p className="errorMessage">
                      {this.state.duplicateAliasName.length == 0 ? (
                        <b>{""}</b>
                      ) : (
                        <b> {this.state.duplicateAliasName.join(", ")} </b>
                      )}
                    </p>
                    {/* 4832 added error msg with array if system fileds are used under other fields */}

                    <span
                      style={{ display: this.state.validationDisplay3 }}
                      className="errorMessage"
                    >
                      Please make sure alias names should be identical to an
                      system fields
                    </span>
                    <p className="errorMessage">
                      {this.state.systemFields.length == 0 ? (
                        <b>{""}</b>
                      ) : (
                        <b> {this.state.systemFields.join(", ")} </b>
                      )}
                    </p>

                    {/* karan-task-3477-work phone format error 1 */}
                    {this.state.workPhoneFormatError && (
                      <span
                        className="errorMessage"
                        style={{ display: "block" }}
                      >
                        Invalid work phone format
                      </span>
                    )}
                    {/* karan-task-3477-work phone format error 2 */}
                    {this.state.duplicateWorkPhoneFormatError && (
                      <span
                        className="errorMessage"
                        style={{ display: "block" }}
                      >
                        Duplicate work phone format found
                      </span>
                    )}
                  </div>
                </div>
              </span>
            )}
          </div>

          <div
            class="card custom-card"
            style={{
              backgroundColor: "#f8f8f8",
              borderColor: "#707070",
              borderWidth: "0.3px",
              marginTop: "8%",
            }}
          >
            <CardBody>
              {this.state.length === 0 ? (
                <span>
                  <br />
                  <div class="row">
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-2"
                      style={{ marginLeft: "3%" }}
                    ></div>
                    <table
                      class="table table-bordered"
                      id="myTable3-customMapping"
                      style={{ width: "60%" }}
                    >
                      <thead>
                        <tr style={{ height: "35px" }}>
                          <th
                            class="customMapping-data"
                            style={{
                              //fontSize: "17px",
                              //color: "#707070",
                              //color: "#193D8F",
                              //backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;System Field length
                          </th>
                          <th
                            class="customMapping-data"
                            style={{
                              //fontSize: "17px",
                              // color: "#707070",
                              // backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;Mapping Alias Field{" "}
                          </th>
                          <th
                            class="customMapping-data"
                            style={{
                              //fontSize: "17px",
                              // color: "#707070",
                              // backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;Values
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            &nbsp;Publisher ID :{" "}
                            <span className="asterisk">*</span>
                          </td>{" "}
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Publisher ID</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].pID
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="pID"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Campaign ID :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Campaign ID</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].campID
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="campID"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Campaign Name :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Campaign Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].campaignName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="campaignName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Lead Interaction Date :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Lead Interaction Date</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .leadInteractionDate
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="leadInteractionDate"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;First Name :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>First Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].firstName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="firstName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Last Name :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Last Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].lastName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="lastName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Company Name :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Company Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].companyName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="companyName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;LinkedIn Company Name :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>LinkedIn Company Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .linkedInCompanyName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="linkedInCompanyName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Email : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Email</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].email
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="email"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Work Phone :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Work Phone</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].workPhone
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="workPhone"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Work Phone Format :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>91-XXXXXXXXXX</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].workPhoneFormat
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Format{" "}
                              <textarea
                                rows="4"
                                cols="40"
                                name="workPhoneFormat"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Job Title :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Job Title :</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].jobTitle
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="jobTitle"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;LinkedIn Job Title :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>LinkedIn Job Title :</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].linkedInJobTitle
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="linkedInJobTitle"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Address : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Address</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].address
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="address"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Country : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Country</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].country
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="country"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;City : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>City</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].city
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="city"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;State : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>State</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].state
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="state"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Zip Code : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Zip Code</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].zipCode
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="zipCode"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Company Employee Size :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Company Employee Size</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .companyEmployeeSize
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="companyEmployeeSize"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Company Revenue :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Company Revenue</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].companyRevenue
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="companyRevenue"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Industry : <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Industry</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].industry
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="industry"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;Asset Name :{" "}
                            <span className="asterisk">*</span>
                          </td>
                          {/*Aman-4971-Added asterisk class as mentioned in task*/}
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Name</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].assetName
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetName"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Name Touch 1 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Name Touch 1</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].assetNameTouch1
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetNameTouch1"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Timestamp Touch 1 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Timestamp Touch 1</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .assetTimestampTouch1
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetTimestampTouch1"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Name Touch 2 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Name Touch 2</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].assetNameTouch2
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetNameTouch2"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Timestamp Touch 2 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Timestamp Touch 2</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .assetTimestampTouch2
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetTimestampTouch2"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Name Touch 3 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Name Touch 3</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].assetNameTouch3
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetNameTouch3"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset Timestamp Touch 3 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset Timestamp Touch 3</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0]
                                .assetTimestampTouch3
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="assetTimestampTouch3"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>&nbsp;Street :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Street</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].street
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="street"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;IP :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>IP</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].ip
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="ip"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Asset ID :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Asset ID</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].supportDocID
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="supportDocID"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Job Level :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Job Level</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].jobLevel
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="jobLevel"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Job Function :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Job Function</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].jobFunction
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="jobFunction"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Channel :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Channel</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].channel
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="channel"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 1 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 1</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra1
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra1"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 2 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 2</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra2
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra2"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 3 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 3</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra3
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra3"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 4 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 4</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra4
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra4"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 5 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 5</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra5
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra5"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        {/* Sandeep-task-3158-added code for extra 15 fields */}
                        <tr>
                          <td>&nbsp;Extra 6 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 6</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra6
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra6"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 7 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 7</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra7
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra7"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 8 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 8</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra8
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra8"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 9 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 9</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra9
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra9"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 10 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 10</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra10
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra10"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 11 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 11</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra11
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra11"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 12 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 12</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra12
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra12"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 13 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 13</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra13
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra13"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 14 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 14</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra14
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra14"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 15 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 15</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra15
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra15"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 16 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 16</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra16
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra16"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 17 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 17</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra17
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra17"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 18 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 18</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra18
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra18"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 19 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 19</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra19
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra19"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Extra 20 :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Extra 20</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].extra20
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="extra20"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>&nbsp;Domain :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Domain</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].domain
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="domain"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Alternate Phone No :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Alternate Phone No</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].alternatePhoneNo
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="alternatePhoneNo"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;Comments :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>Comments</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].comments
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="comments"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;LinkedIn :</td>
                          <td style={{ paddingBottom: "10px" }}>
                            &nbsp;Current :
                            {this.state.agencyAdvertiserMap.length == 0 ? (
                              <b>LinkedIn</b>
                            ) : (
                              this.state.agencyAdvertiserMap[0].linkedIn
                            )}
                            <br />
                            &nbsp;
                            <span>
                              Alias{" "}
                              <input
                                name="linkedIn"
                                onChange={this.agencyMappinHandleChange.bind(
                                  this
                                )}
                              />{" "}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                         {/* Rutuja 5309 - commented  removeCustomMappingField div*/}
                          {/*ARutuja 5309 - Add new row of other extra filed 21 to 30 */}
                          {this.state.customMappingOtherField === "" ? ("") : ( <>
                        {this.state.customMappingOtherField.map((customMappingOtherField, index) => {
                        const key = Object.keys(customMappingOtherField)[0]; //ARutuja 5309 - Get the dynamic key
                        const value = customMappingOtherField[key]; //ARutuja 5309 - Get the value corresponding to the key
                        return (
                          <tr key={index}>
                            <td>&nbsp;{key}:</td>
                            <td>
                              &nbsp;Current: <b>{value}</b>
                              <br />
                              &nbsp;Alias: <input type="text" value={value} onChange={(e) => this.handleFieldChange(index, e)} />
                            </td>
                            <td>
                              {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <a href="#" onClick={(e) => this.removeCustomMappingField(index, e)} style={{marginTop: "10px"}}>
                                  <span
                                    className="glyphicon glyphicon-minus-sign"
                                    id={key}
                                    style={{
                                      color: "Red",
                                      fontWeight: "bold",
                                      fontSize: 28,
                                    }}
                                  ></span>
                                </a>
                              </div> */}
                            </td>
                          </tr>
                        );
                      })}
                      </>
                      )}
                      </tbody>
                    </table>
                  </div>
                   {/* Rutuja 5309 - added to check stectedClientID is stected or not*/}
                   {this.state.stectedClientID === "" ? ("") : ( 
                     <div className="col-lg-12 col-md-12 col-sm-12" style={{ justifyContent: "center", display: "flex", marginBottom: "10px" }}>  {/*ARutuja 5309 - Button to add new row */}
                    {/* <Tooltip placement="bottom" title={this.state.customMappingOtherField.length === 10 ? "" : "You can only add 10 extra fields"} overlayStyle={{ maxWidth: '500px' }}> */}
                      <div
                        align="center"
                        style={{
                          backgroundColor: "#e0e0e0",
                          padding: "10px",
                          width: "62.5%",
                          maxWidth: "62.5%",
                          marginRight: "10px",
                          fontWeight: "bold",
                          cursor: this.state.customMappingOtherField.length === 10 ? "not-allowed" : "pointer", // - commenting this to disabled the button 
                          opacity: this.state.customMappingOtherField.length === 10 ? 0.5 : 1, // - commenting this to disabled the button
                          //cursor:  "not-allowed",
                          //opacity:  0.5,
                        }}
                        onClick={this.state.customMappingOtherField.length !== 10 ? this.addCustomMappingField : null} // - commenting this to disabled the button
                      >
                        {this.state.customMappingOtherField.length === 10 ? "Max limit to add Extra Fields upto 10. You cannot exceed this limit." : "+ Add one more extra field"}
                      </div>
                    {/* </Tooltip> */}
                  </div>
                   )}
                  <br />
                  <div
                    class="row"
                    style={{
                      float: "right",
                      paddingBottom: "50px",
                      paddingRight: "20px",
                    }}
                  >
                    <button
                      class="btn add-button"
                      style={{ width: "130px" }}
                      onClick={this.getMappin}
                    >
                      Save Mapping
                    </button>
                  </div>
                  <br />

                  <br />
                </span>
              ) : (
                <span>
                  <br />
                  <div class="row">
                    <div
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-2"
                      style={{ marginLeft: "3%" }}
                    ></div>
                    <table
                      class="table table-bordered"
                      id="myTable3"
                      style={{ width: "60%" }}
                    >
                      <thead>
                        <tr style={{ height: "35px" }}>
                          <th
                            style={{
                              // color: "#707070",
                              // backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;System Field length
                          </th>
                          <th
                            style={{
                              // color: "#707070",
                              // backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;Mapping Alias Field{" "}
                          </th>
                          <th
                            style={{
                              // color: "#707070",
                              // backgroundColor: " rgba(126, 127, 129, 0.22)",
                              backgroundColor: "#144c9b", //Chaitanya-4984-Change background color
                              color: "white",
                            }}
                          >
                            &nbsp;Values
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Publisher ID :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.pID}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].pID
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="pID"
                                      defaultValue={advertiserMapping.pID}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Campaign ID :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.campID}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].campID
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="campID"
                                      defaultValue={advertiserMapping.campID}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Campaign Name :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.campaignName}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .campaignName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="campaignName"
                                      defaultValue={
                                        advertiserMapping.campaignName
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Lead Interaction Date :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.leadInteractionDate}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .leadInteractionDate
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="leadInteractionDate"
                                      defaultValue={
                                        advertiserMapping.leadInteractionDate
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;First Name :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.firstName}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .firstName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="firstName"
                                      defaultValue={advertiserMapping.firstName}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Last Name :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.lastName}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].lastName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="lastName"
                                      defaultValue={advertiserMapping.lastName}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Company Name :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.companyName}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .companyName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="companyName"
                                      defaultValue={
                                        advertiserMapping.companyName
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;LinkedIn Company Name :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.linkedInCompanyName}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .linkedInCompanyName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="linkedInCompanyName"
                                      defaultValue={
                                        advertiserMapping.linkedInCompanyName
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Email :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.email}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].email
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="email"
                                      defaultValue={advertiserMapping.email}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Work Phone :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.workPhone}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .workPhone
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="workPhone"
                                      defaultValue={advertiserMapping.workPhone}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Work Phone Format:</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.workPhoneFormat}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .workPhoneFormat
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Format{" "}
                                    <textarea
                                      rows="4"
                                      cols="35"
                                      name="workPhoneFormat"
                                      defaultValue={
                                        advertiserMapping.workPhoneFormat
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Job Title :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.jobTitle} </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].jobTitle
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="jobTitle"
                                      defaultValue={advertiserMapping.jobTitle}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;LinkedIn Job Title :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.linkedInJobTitle}{" "}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .linkedInJobTitle
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="linkedInJobTitle"
                                      defaultValue={
                                        advertiserMapping.linkedInJobTitle
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Address :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.address}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].address
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="address"
                                      defaultValue={advertiserMapping.address}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Country :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.country}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].country
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="country"
                                      defaultValue={advertiserMapping.country}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;City :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.city}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].city
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="city"
                                      defaultValue={advertiserMapping.city}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;State :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.state} </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].state
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="state"
                                      defaultValue={advertiserMapping.state}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Zip Code :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.zipCode} </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].zipCode
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="zipCode"
                                      defaultValue={advertiserMapping.zipCode}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Company Employee Size :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.companyEmployeeSize}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .companyEmployeeSize
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="companyEmployeeSize"
                                      defaultValue={
                                        advertiserMapping.companyEmployeeSize
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Company Revenue :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.companyRevenue}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .companyRevenue
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="companyRevenue"
                                      defaultValue={
                                        advertiserMapping.companyRevenue
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Industry :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.industry}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].industry
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="industry"
                                      defaultValue={advertiserMapping.industry}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>
                                  &nbsp;Asset Name :{" "}
                                  <span className="asterisk">*</span>
                                </td>
                                {/*Aman-4971-Added asterisk class as mentioned in task*/}
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.assetName}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetName
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetName"
                                      defaultValue={advertiserMapping.assetName}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Name Touch 1 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.assetNameTouch1}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetNameTouch1
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetNameTouch1"
                                      defaultValue={
                                        advertiserMapping.assetNameTouch1
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Timestamp Touch 1 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.assetTimestampTouch1}{" "}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetTimestampTouch1
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetTimestampTouch1"
                                      defaultValue={
                                        advertiserMapping.assetTimestampTouch1
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Name Touch 2 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.assetNameTouch2}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetNameTouch2
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetNameTouch2"
                                      defaultValue={
                                        advertiserMapping.assetNameTouch2
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Timestamp Touch 2 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.assetTimestampTouch2}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetTimestampTouch2
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetTimestampTouch2"
                                      defaultValue={
                                        advertiserMapping.assetTimestampTouch2
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Name Touch 3 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.assetNameTouch3}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetNameTouch3
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetNameTouch3"
                                      defaultValue={
                                        advertiserMapping.assetNameTouch3
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset Timestamp Touch 3 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.assetTimestampTouch3}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .assetTimestampTouch3
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="assetTimestampTouch3"
                                      defaultValue={
                                        advertiserMapping.assetTimestampTouch3
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Street :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.street}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].street
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="street"
                                      defaultValue={advertiserMapping.street}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;IP :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.ip}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].ip
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="ip"
                                      defaultValue={advertiserMapping.ip}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Asset ID :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.supportDocID}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .supportDocID
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="supportDocID"
                                      defaultValue={
                                        advertiserMapping.supportDocID
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Job Level :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.jobLevel}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].jobLevel
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="jobLevel"
                                      defaultValue={advertiserMapping.jobLevel}
                                      defaultValue={advertiserMapping.jobLevel}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Job Function :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.jobFunction}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .jobFunction
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="jobFunction"
                                      defaultValue={
                                        advertiserMapping.jobFunction
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Channel :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.channel}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].channel
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="channel"
                                      defaultValue={advertiserMapping.channel}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 1 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra1}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra1
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra1"
                                      defaultValue={advertiserMapping.extra1}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 2 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra2}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra2
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra2"
                                      defaultValue={advertiserMapping.extra2}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 3 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra3}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra3
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra3"
                                      defaultValue={advertiserMapping.extra3}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 4 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra4}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra4
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra4"
                                      defaultValue={advertiserMapping.extra4}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 5 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra5}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra5
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra5"
                                      defaultValue={advertiserMapping.extra5}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {/* Sandeep-task-3158-added code for extra 15 fields */}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 6 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra6}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra6
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra6"
                                      defaultValue={advertiserMapping.extra6}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 7 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra7}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra7
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra7"
                                      defaultValue={advertiserMapping.extra7}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 8 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra8}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra8
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra8"
                                      defaultValue={advertiserMapping.extra8}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 9 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra9}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra9
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra9"
                                      defaultValue={advertiserMapping.extra9}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 10 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra10}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra10
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra10"
                                      defaultValue={advertiserMapping.extra10}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 11 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra11}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra11
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra11"
                                      defaultValue={advertiserMapping.extra11}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 12 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra12}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra12
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra12"
                                      defaultValue={advertiserMapping.extra12}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 13 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra13}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra13
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra13"
                                      defaultValue={advertiserMapping.extra13}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 14 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra14}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra14
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra14"
                                      defaultValue={advertiserMapping.extra14}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 15 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra15}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra15
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra15"
                                      defaultValue={advertiserMapping.extra15}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 16 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra16}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra16
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra16"
                                      defaultValue={advertiserMapping.extra16}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 17 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra17}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra17
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra17"
                                      defaultValue={advertiserMapping.extra17}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 18 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra18}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra18
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra18"
                                      defaultValue={advertiserMapping.extra18}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 19 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra19}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra19
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra19"
                                      defaultValue={advertiserMapping.extra19}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Extra 20 :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.extra20}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].extra20
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="extra20"
                                      defaultValue={advertiserMapping.extra20}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}

                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Domain :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.domain} </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].domain
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="domain"
                                      defaultValue={advertiserMapping.domain}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Alternate Phone No :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>
                                        {advertiserMapping.alternatePhoneNo}{" "}
                                      </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0]
                                        .alternatePhoneNo
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="alternatePhoneNo"
                                      defaultValue={
                                        advertiserMapping.alternatePhoneNo
                                      }
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;Comments :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.comments}</b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].comments
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="comments"
                                      defaultValue={advertiserMapping.comments}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        {this.state.advertiserMapping.map(
                          (advertiserMapping) => {
                            return (
                              <tr>
                                <td>&nbsp;LinkedIn :</td>
                                <td style={{ paddingBottom: "10px" }}>
                                  &nbsp;Current :
                                  <b>
                                    {this.state.agencyAdvertiserMap.length ==
                                    0 ? (
                                      <b>{advertiserMapping.linkedIn} </b>
                                    ) : (
                                      this.state.agencyAdvertiserMap[0].linkedIn
                                    )}
                                  </b>
                                  <br />
                                  &nbsp;
                                  <span>
                                    Alias{" "}
                                    <input
                                      name="linkedIn"
                                      defaultValue={advertiserMapping.linkedIn}
                                      onChange={this.agencyMappinHandleChange.bind(
                                        this
                                      )}
                                    />{" "}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                      
                        {/*Rutuja 5277 - Add new row of other extra filed 21 to 30 */}
                        {this.state.customMappingOtherField === "" ? ("") : ( <>
                        {this.state.customMappingOtherField.map((customMappingOtherField, index) => {
                        const key = Object.keys(customMappingOtherField)[0]; //Rutuja 5277 - Get the dynamic key
                        const value = customMappingOtherField[key]; //Rutuja 5277 - Get the value corresponding to the key
                        return (
                          <tr key={index}>
                            <td>&nbsp;{key}:</td>
                            <td>
                              &nbsp;Current: <b>{value}</b>
                              <br />
                              &nbsp;Alias: <input type="text" value={value} onChange={(e) => this.handleFieldChange(index, e)} />
                            </td>
                            <td>
                              {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <a href="#" onClick={(e) => this.removeCustomMappingField(index, e)} style={{marginTop: "10px"}}>
                                  <span
                                    className="glyphicon glyphicon-minus-sign"
                                    id={key}
                                    style={{
                                      color: "Red",
                                      fontWeight: "bold",
                                      fontSize: 28,
                                    }}
                                  ></span>
                                </a>
                              </div> */}
                            </td>
                          </tr>
                        );
                      })}
                      </>
                      )}
                      </tbody>
                    </table>
                  </div>
                     {/*Rutuja 5277 - Button to add new row */}
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ justifyContent: "center", display: "flex", marginBottom: "10px" }}>
                    {/* <Tooltip placement="bottom" title={this.state.customMappingOtherField.length === 10 ? "" : "You can only add 10 extra fields"} overlayStyle={{ maxWidth: '500px' }}> */}
                      <div
                        align="center"
                        style={{
                          backgroundColor: "#e0e0e0",
                          padding: "10px",
                          width: "62.5%",
                          maxWidth: "62.5%",
                          marginRight: "10px",
                          fontWeight: "bold",
                          cursor: this.state.customMappingOtherField.length === 10 ? "not-allowed" : "pointer", //Rutuja-5277
                          opacity: this.state.customMappingOtherField.length === 10 ? 0.5 : 1, //Rutuja-5277 
                          // cursor:  "not-allowed",
                          // opacity:  0.5,
                        }}
                          onClick={this.state.customMappingOtherField.length !== 10 ? this.addCustomMappingField : null} //Rutuja-5277 
                      >
                        {this.state.customMappingOtherField.length === 10 ? "Max limit to add Extra Fields upto 10. You cannot exceed this limit." : "+ Add one more extra field"}
                      </div>
                    {/* </Tooltip> */}
                  </div>
                  <br />
                  <div
                    class="row"
                    style={{
                      float: "right",
                      paddingBottom: "50px",
                      paddingRight: "20px",
                    }}
                  >
                    <button
                      class="btn add-button"
                      style={{ width: "130px" }}
                      onClick={this.getMappin}
                    >
                      Save Mapping
                    </button>
                  </div>
                  <br />

                  <br />
                </span>
              )}
            </CardBody>
          </div>
        </div>

        <Footer />
      </div>
    );
  } // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CustomMapping.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(CustomMapping)
);
