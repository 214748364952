/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/layouts/header1";
// import NewHeader from './components/layouts/newHeader';
import Footer from "./components/layouts/footer";
import Agency from "./components/login/userLogin";
// import Advertiser from './components/login/advertiserLogin';
// import Publisher from './components/login/publisherLogin';
import Admin from "./components/login/adminDashboard";
import store from "./store";
import IdleTimer from "react-idle-timer";
import jwt_decode from "jwt-decode";
import setAuthToken from "./setAuthToken";
import { setCurrentUser, logoutUser } from "./components/login/authentication";
// import logoutUser from './components/login/authentication';
// import AgencyFrontPage from './components/login/agencyFrontPage';
// import PublisherFrontPage from './components/login/publisherFrontPage';
// import AdvertiserFrontPage from './components/login/AdvertiserFrontPage';
import Navigation from "./components/layouts/navPage";
import PublisherNavigation from "./components/layouts/publisherNavPage";
import CreateCampaign from "./components/campaign/createCampaign";
import EditCampaign from "./components/campaign/editCampaign";
import Edit from "./components/campaign/edit";
// import SupportingDocument from "./components/campaign/supportingDocument";
// import EditSupportingDocument from "./components/campaign/editSupportingDocument";
import DeliveryFormat from "./components/campaign/deliveryFormat";
import EditDeliveryFormat from "./components/campaign/editDeliveryFormat";
import FinishCampaign from "./components/campaign/finishCampaign";
import EditFinishCampaign from "./components/campaign/editFinishCampaign";
// import CampaignSetup from './components/campaign/campaignSetup';
// import Setup from './components/campaign/setup';
// import AllocatingCampaign from "./components/campaign/allocatingCampaign";
// import AssignedCampaign from "./components/publisher/assignedCampaign";
// import AgencyLiveCampaign from "./components/campaign/agencyLiveCampaign";
// import PauseCampaign from "./components/campaign/agencyPauseCampaign";
// import ArchiveCampaign from "./components/campaign/agencyArchiveCampaign";
import IncompleteCampaign from "./components/campaign/incompleteCampaign";
// import PublisherView from "./components/publisher/publisherView";
// import LandingPageFeedBack from "./components/campaign/landingPageFeedBack";
import AgencyReviewLead from "./components/campaign/agencyReviewLead";
import AgencyCreativesReview from "./components/campaign/agencyCreativesReview";
// import DeliverLead from "./components/publisher/deliverLead";
import LeadTable from "./components/publisher/table";
// import Test from "./components/publisher/test";
// import Testing from "./components/publisher/testing";
//  import MainBody from './components/layouts/mainBody';
import ReportByPublisher from "./components/Reports/reportByPublisher";
import ByCampaignEndDate from "./components/Reports/byCampaignEndDate";
import ListGDPRPublisher from "./components/Reports/listGDPRPublisher";
import LeadDownload from "./components/Reports/leadDownload";
import AddPublisher from "./components/publisher/addPublisher";
import Reviewlead from "./components/campaign/reviewLead";
import CampaignTraceReport from "./components/Reports/campaignTraceReport";
import PieChart from "./components/campaign/pieChart";
import PublisherAnalysisDashbord from "./components/campaign/publisherAnalysisDashbord";
import AgencyReport1 from "./components/campaign/weeklyReportDashboard";
import AgencyReport2 from "./components/campaign/deliveryReportDashboard";
// import Table from "./components/publisher/test";
import LeadReviewTable from "./components/publisher/leadReviewTable";
//import Reviewlead from './components/campaign/reviewLead';
import ListAllCampaign from "./components/Reports/listAllCampaign";
import QuickUpload from "./components/advertiser/quickUpload";
import AdvertiserTab from "./components/advertiser/advertiserTabs";
// import Demo from './components/advertiser/advDemo';
import PublisherOnBoard from "./components/publisher/publisherOnBoard";
import AgencyOnBoard from "./components/agency/agencyOnBoard";
import AdvertiserOnBoard from "./components/advertiser/advertiserOnBoard";
import userLogin from "./components/login/userLogin";
import EditQuickUpload from "./components/advertiser/editQuickUpload";
// import AgencyCampaignSetupInDetail from './components/campaign/agencyCampaignSetupInDetail';
// import SetupSupportingDocument from './components/campaign/setupSupportingDocument';
import FinishQuickUpload from "./components/advertiser/finishQuickUpload";
import EditFinishQuickUpload from "./components/advertiser/editFinishQuickUpload";
// import FinishCampaignSetup from './components/campaign/finishCampaignSetup';
import AgencyAllocatedLeads from "./components/campaign/agencyAllocatedLeads";
import ReviewPublisher from "./components/admin/reviewPublisher";
import ReviewAgency from "./components/admin/reviewAgency";
import ApproveOnBoaredPublisher from "./components/admin/approveOnBoaredPublisher";
import ApprovedPublisher from "./components/admin/approvedPublisher";
import ApproveOnBoaredAgency from "./components/admin/approveOnBoaredAgency";
import ApprovedAgency from "./components/admin/approvedAgency";
// import AgencyPOCReview from './components/campaign/agencyPOCReview';
import addAdvertiser from "./components/advertiser/addAdvertiser";
import UpdateInvoice from "./components/Invoice/updateInvoice";
// import CreativeUploads from './components/publisher/creativeUploads';
import PublisherCreativeUploads from "./components/publisher/publisherCreativeUploads";
import CampaignDashboard from "./components/campaign/campaignSpecificationDashboard";
import CampaignSpecificationDashboardDetails from "./components/campaign/campaignSpecificationDashboardDetails";
import PublisherAllocation from "./components/publisher/publisherAllocation";
import Dashboard from "./components/login/dashboard";
import DasboardPublisher from "./components/login/dashboardPublisher";
import NewPublisherDashboard from "./components/login/newPublisherDashboard/newPublisherDashboard"; //karan-task-3635-moved newPublisherDashboard.js file into newPublisherDashboard folder for better management
import Advertiserdashboard from "./components/login/advertiserDashboard";
import IncompleteCampaignTab from "./components/campaign/incompleteCampaignTab";
// import AllocatedCampaignTab from './components/campaign/allocatedCampaignTab';
// import AgencyAllocatedCampaign from './components/campaign/agencyAllocatedCampaign';
import PublisherDeliverLead from "./components/publisher/publisherDeliverLead";
//import MultiSelectDropdown from './components/campaign/multiSelectDropdown';
import PublisherInternalReviewLead from "./components/publisher/publisherInternalReviewLead";
import PublisherInvoice from "./components/publisher/publisherInvoice";
import VoiceLogsUpload from "./components/publisher/voiceLogsUpload";
// import MultiSelectDropdown from './components/campaign/multiSelectDropdown';
import AdvertiserReviewLead from "./components/advertiser/advertiserReviewLead";
import AdvertiserCreativesReview from "./components/advertiser/advertiserCreativesReview";
import CampaignSpecificationPublisherDashboard from "./components/publisher/campaignSpecificationPublisherDashboard";
import CampaignSpecificationPublisherDashboardDetails from "./components/publisher/campaignSpecificationPublisherDashboardDetails";
import CampaignSpecificationAdvertiserDashboard from "./components/advertiser/campaignSpecificationAdvertiserDashboard";
import CampaignSpecificationAdvertiserDashboardDetails from "./components/advertiser/campaignSpecificationAdvertiserDashboardDetails";
import CampaignSetupSpecification from "./components/campaign/campaignsetupSpecification";
import CampaignSetupSpecificationDetails from "./components/campaign/campaignsetupSpecificationDetails";
import AgencyInvoice from "./components/campaign/agencyInvoice";
import LinkPublisher from "./components/admin/linkPublisher";
import ReviewLinkPublisher from "./components/login/reviewLinkPublisher";
import ReviewLinkAgency from "./components/login/reviewLinkAgency";
import ContactPublisher from "./components/campaign/contactPublisher";
import ContactAgency from "./components/campaign/contactAgency";
import ApiLead from "./components/lead/apiLead";
import CreateXLSCampaign from "./components/campaign/createXLSCampaign";
import CreateXLSRfp from "./components/RFP/createXLSRfp"; //* snehal-task-3509Project's(DI) - Create RFP through XLS - FE
import UploadCommonSuppression from "./components/campaign/uploadCommonSuppression";
import UpdateExistingLead from "./components/publisher/updateExistingLead";
import PublisherUpdateDeliverLead from "./components/publisher/publisherUpdateDeliverLead";
import ForgotPwd from "./components/login/forgotPasswordOTP";
import ForgotPasswordEmail from "./components/login/forgotPasswordEmail";
import ResetPwd from "./components/login/resetPassword";
import LPDataTracking from "./components/Reports/lpDataTracking";
import keywordExtractionFromPDF from "./components/notfound/keywordExtractionFromPDF";
import ChangePassword from "./components/login/changePassword";
import ChangePasswordAgency from "./components/login/changePasswordAgency";
import ChangePasswordAdmin from "./components/login/changePasswordAdmin";
import ChangePasswordAdvertiser from "./components/login/changePasswordAdvertiser";
// import RequestForProposal from './components/campaign/requestForProposal';
import RequestForProposalExsisting from "./components/campaign/requestForProposalExsisting";
import RequestForProposalPublisher from "./components/publisher/requestForProposalPublisher";
import RequestForProposalForPublisher from "./components/publisher/requestForProposalPublisherNew";
import RequestForProposalAgency from "./components/campaign/requestProposalAgency";
// import RequestForProposalBidding from './components/publisher/requestForProposalBidding';
import AgencyEmailConfig from "./components/login/agencyEmailConfig";
import PublisherEmailConfig from "./components/login/publisherEmailConfig";
import AdvertiserEmailConfig from "./components/login/advertiserEmailConfig";
// import AdvertiserEmailConfig from './components/login/agencyEmailConfig';
import AddUserAgency from "./components/user/addUserAgency";
import AddUserPublisher from "./components/user/addUserPublisher";
import AddUserAdvertiser from "./components/user/addUserAdvertiser";
import AddCompliance from "./components/admin/addCompliance";
import AddUserAdmin from "./components/user/addUserAdmin";
import PublisherLeadDeliveryReport from "./components/Reports/publisherLeadDeliveryReport";
import ViewUserPassword from "./components/admin/viewUserPassword";
import ByPublisherEndDate from "./components/Reports/byPublisherEndDate";
import CustomMapping from "./components/campaign/customMapping";
import CampaignList from "./components/login/campaignList";
import PublisherCampaignList from "./components/login/publisherCampaignList";
import RfpCampaignAllocation from "./components/publisher/rfpCampaignAllocation.js";
import AgencyInformation from "./components/login/agencyInformation";
import PublisherInformation from "./components/publisher/publisherInformation";
import AdvertiserInformation from "./components/advertiser/advertiserInformation";
import CreateCampaignNew from "./components/campaignUI/createCampaignNew";
import ReviewAdvertiser from "./components/advertiser/reviewAdvertiser";
import ApproveOnBoardAdvertiser from "./components/advertiser/approveOnBoardAdvertiser";
import LinkAdvertiser from "./components/advertiser/linkAdvertiser";
import AgencyInvoicesList from "./components/lead/agencyInvoicesList";
import DisplayPublisherInvoice from "./components/publisher/displayPublisherInvoice";
import ReviewLinkAdvertiser from "./components/advertiser/reviewLinkAdvertiser";
import ReviewAgencyByAdvertiser from "./components/advertiser/reviewAgencyByAdvertiser";
import WelcomePage from "./components/campaignUI/welcomePage";
import WelcomeEdit from "./components/campaignUI/WelcomeEdit";
import EditExistingCampaign from "./components/campaignUI/NewEditCampaign";
import RfpCampaignSpecification from "./components/campaign/rfpCampaignSpecification";
import RfpCampaignSpecificationDetails from "./components/campaign/rfpCampaignSpecificationDetails";
import Step2SupportingDocument from "./components/campaignUI/step2supportingdocument";
import SuccessPage from "./components/campaignUI/successPage";
import PublisherRfpCampaignSpecification from "./components/publisher/publisherRfpCampaignSpecification";
import PublisherRfpCampaignSpecificationDetails from "./components/publisher/publisherRfpCampaignSpecificationDetails";
import newEditSupportingDocuments from "./components/campaignUI/newEditSupportingDocuments";
import ClientSetup from "./components/advertiser/clientSetup";
import MarketoClientSetup from "./components/advertiser/marketoClientSetup";
import HubspotClientSetup from "./components/advertiser/hubspotClientSetup";
import SalesforceLead from "./components/Reports/salesforceLead";
import SalesforceLeadView from "./components/Reports/salesforceLeadView";
import MarketoLead from "./components/Reports/marketoLead";
import MarketoLeadView from "./components/Reports/marketoLeadView";
import HubspotLead from "./components/Reports/hubspotLead";
import HubspotLeadView from "./components/Reports/hubspotLeadView";
import CaptureLandingPage from "./components/Reports/captureLandingPage";
import NewDashboard from "./components/login/newDashboard";
import NewRfp from "./components/RFP/requestForProposal";
import jobLevelMapping from "./components/agency/jobLevelMapping"; //shivani- 3517- imported file for jobLevelMapping
//import agencyNewDashboard from './components/agency/agencyNewDashboard';
import AgencyUnreadAlerts from "./components/agency/agencyUnreadAlerts";
import PublisherUnreadAlerts from "./components/publisher/publisherUnreadAlerts";
import CampaignRespond from "./components/agency/campaignRespond";
import invoiceHistory from "./components/lead/invoiceHistory";
import helpOption from "./components/help/helpOption";
import createCampaignHelp from "./components/help/createCampaignHelp";
import editCampaignHelp from "./components/help/editCampaignHelp";
import rfpAnalysis from "./components/RFP/rfpAnalysis";
import ClientSetupHelp from "./components/help/clientSetupHelp";
import SalesforceLeadHelp from "./components/help/salesforceLeadHelp";
import publisherAllocationHelp from "./components/help/publisherAllocationHelp";
import PublisherInformationEdit from "./components/publisher/publisherInformationEdit";
import agencyInformationEdit from "./components/agency/agencyInformationEdit";
import advertiserInformationEdit from "./components/advertiser/advertiserInformationEdit";
import qalogin from "./components/login/qaLogin";
import publisherRFPAnalysis from "./components/RFP/publisherRFPAnalysis";
import supportingDocumentCreate from "./components/campaignUI/supportingDocumentCreate";
import supportingDocumentEdit from "./components/campaignUI/supportingDocumentEdit"; // User Story - 3269 - Karan Jagtap
import agencyMyReports from "./components/Reports/agencyMyReports"; //shivani-3354-imported this new file in report folder named as "agencyMyReports"
import agencyPublisherLeadtracking from "./components/Reports/agencyPublisherLeadtracking"; //shivani-3415-imported this new file in report folder named as"agencyPublisherLeadtracking"
import agencyPublisherDeliverytracking from "./components/Reports/agencyPublisherDeliverytracking"; //shivani-3354-imported this new file in report folder named as"agencyPublisherDeliverytracking"
import agencyAdvertiserDeliverytracking from "./components/Reports/agencyAdvertiserDeliverytracking"; //sunita-3406-imported this new file in report folder named as"agencyAdvertiserDeliverytracking"
import PublisherMyReport from "./components/Reports/publisherMyReport"; //* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report */
import AgencyDeliveryTrackingReport from "./components/Reports/agencyDeliveryTrackingReport"; //* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report */
import addCountry from "./components/admin/addCountry"; // karan-3453-new file added
import domainName from "./components/Domain/domainName"; //shivani -3245- domain task -import file of ABM Upgrade
import publisherDomainName from "./components/Domain/publisherDomainName"; //shivani -3245- domain task -import file of ABM Upgrade
import createXLSRfp from "./components/RFP/createXLSRfp";
//import physicalAddress from "./components/Domain/physicalAddress";

import advertiserMyReports from "./components/Reports/advertiserMyReports"; //Sandeep-task-3448-added new file for advertiserMyReports
import advertiserDeliveryTrackingReport from "./components/Reports/advertiserDeliveryTrackingReport"; //* Sandeep--task-3448-Report Engine- Advertiser View- My Reports and Advertiser Delivery Tracking Report */
import advertiserLeadTracking from "./components/Reports/advertiserLeadTracking"; //* Sandeep--task-3479-Report Engine- Advertiser View- My Reports and Advertiser Delivery Tracking Report */
import AgencyLeadTrackingReport from "./components/Reports/agencyLeadTrackingReport"; /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */
import AgencyAdvertiserLeadTracking from "./components/Reports/agencyAdvertiserLeadTracking";
import RFPDashboard from "./components/RFP/rfpDashboardFE"; // karan-tasl-3521-rfp dashboard page
import rfpProgress from "./components/RFP/rfpProgress"; // karan-task-3636-rfp progress page
import AddPublisherRequest from "./components/publisher/AddPublisherRequest"; // karan-task-3648-add publisher page
import PublisherRequest from "./components/admin/PublisherRequest"; // karan-task-3648-publisher request page
import RfpReminderSetup from "./components/RFP/rfpReminderSetup"; //karan-task-3515-added rfp reminder setup
import changeUserStatus from "./components/admin/changeUserStatus";//rutuja-task-4357
import campaignSpecificationQADashboard from "./components/qualityAnalyst/campaignSpecificationQADashboard";//rutuja-task-3879
import campaignSpecificationQADashboardDetails from "./components/qualityAnalyst/campaignSpecificationQADashboardDetails";
import AddCustomRegion from "./components/campaignUI/addCustomRegion";//Chaitanya-task3982-add custom region
import PublisherCreativesReview from "./components/publisher/publisherCreativesReview"; //kiran-4173-added publisherCreativesReview
import leadDeliverMarketo from "./components/leadDeliveryCRM/leadDeliverMarketo"; //kiran-4412-added new file
import leadViewMarketo from "./components/leadDeliveryCRM/leadViewMarketo"; //Priyanka-4413-added new file
import leadDeliverHubspot from "./components/leadDeliveryCRM/leadDeliverHubspot"; //kiran-4412-added new file
import leadViewHubspot from "./components/leadDeliveryCRM/leadViewHubspot"; //Priyanka-4413-added new file
import leadDeliverSf from "./components/leadDeliveryCRM/leadDeliverSf"; //nilesh-4444-added new file
import leadViewSf from "./components/leadDeliveryCRM/leadViewSf"; //Priyanka-4422-added new file to display leads details of success and failure leads
import leadReportByCampaign from"./components/Reports/leadReportByCampaign";//vrushabh 4439
import leadReportClient from "./components/Reports/leadReportClient";	//4470-Chaitanya-added file for Lead Report By Client
import addAQARole from "./components/login/addAQARole" //rutuja task-4517 add user as AQA role at AQA login 
import linkCampaign from "./components/login/linkCampaign";//4466-Nilesh Add new page linkCamapign at admin side
import dashboardQA from "./components/login/dashboardQA";//Priyanka-4603-Added new file
import publisherLeadReportByCampaign from"./components/Reports/publisherLeadReportByCampaign";//rutuja 4654 -publisher leadreport by campaign
import SwitchClient from "./components/login/userSwitchClientLogin"; //Aman -4619 -(imported this route for multiple role section) PC/PNC-Login - Client Switch option
import leadReportClientByPC from "./components/Reports/leadReportClientByPC";//4653-Nilesh-Added new file
import LeadFileTrackingReport from "./components/Reports/LeadFileTrackingReport"; //Aman-task 4660 - MBAdmin Report Lead File Tracking Report- DMA's Upload lead file count and file details
import leadRejection from "./components/admin/leadRejection"; //kiran-4623-added new submenu at Admin side 
import addState from "./components/admin/addState"; //rutuja-task -4722 add state
import GenerateReports from "./components/admin/generateReports";//kiran-4721-added new page generateReports
import bypassEmailDomainValidationReport from "./components/Reports/bypassEmailDomainValidationReport"; //Rutuja-task 4823 added new page for bypass email domain validation
import suppressionCompare from "./components/publisher/suppressionComparison.js" //Aman-Task-4894 -Added for Suppression Comparison only for PC
import domainExclusionComparison from "./components/publisher/domainExclusionComparison.js" //Aman-Task-4998 -Added for Suppression Comparison only for PC/PNC
import qaEmailConfig from "./components/login/qaEmailConfig.js"; //RUtuja - 4975 added new page for email configuration at diqa login //Aman-5164 - Chnaged Leads-Nexus-File Names-Replace to QA
import reviewedLeadsFileReport from "./components/Reports/reviewedLeadsFileReport"; //Chaitanya-4972-Added for Reviewed Leads File Report for DIQA login
import changePasswordQA from "./components/login/changePasswordQA"; //Nilesh - 5024 - Added new page for change password at diqa login //Aman-5164 - Chnaged Leads-Nexus-File Names-Replace to QA
import qaUnreadAlerts from "./components/login/qaUnreadAlerts"; //Nilesh-5055-new page for DIQA/DC alerts //Aman-5164 - Chnaged Leads-Nexus-File Names-Replace to QA
import addUserQA from "./components/user/addUserQA.js"; //Chaitanya-5043-Added for Reviewed Leads File Report for DIQA login //Aman-5164 - Chnaged Leads-Nexus-File Names-Replace to QA
import qaReviewedLeadsFileReport from "./components/Reports/qaReviewedLeadsFileReport"; //Chaitanya-5074-Added for Reviewed Leads File Report for DIQA login //Aman-5164 - Chnaged Leads-Nexus-File Names-Replace to QA
import emailStatusChecker from "./components/publisher/emailStatusChecker.js"; //Aman-Task-5060 -Added for email BriteStatus Verifier only for PC/PNC //Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker
import addCountryMbAdmin from "./components/admin/addCountryMbAdmin"; //Aman-5311-new file added for MBAdmin



{
	/* //sunita-3413-routed this new file in report folder named as"AgencyAdvertiserLeadTracking" */
}
if (localStorage.jwtToken) {
	//setAuthToken(localStorage.jwtToken);
	const decoded = jwt_decode(localStorage.jwtToken);
	decoded.token = localStorage.jwtToken; //Somnath Task:2993, Add token in decoded object to pass the setCurrentUser method to access in every page through props which is already get from localstorage
	store.dispatch(setCurrentUser(decoded));

	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		store.dispatch(logoutUser());
		window.location.href = "/";
	}
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: 900000, //Somnath Task-3076, Increase timeout up to 15 min.
			userLoggedIn: false,
			isTimedOut: false,
		};
		// this.myFunction = this.myFunction.bind(this);
		this.idleTimer = null;
		this.onAction = this.onAction.bind(this);
		this.onActive = this.onActive.bind(this);
		this.onIdle = this.onIdle.bind(this);
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description update the timeOut When component is updated
	 * @return Description reset the time
	 */
	componentDidUpdate() {
		this.setState({ isTimedOut: false });
	}
	onAction(e) {
		// console.log('user did something', e)
		// this.setState({isTimedOut: false});
	}

	onActive(e) {
		// console.log('user is active', e)
		// this.setState({isTimedOut: false})
	}

	onIdle(e) {
		// const isTimedOut = this.state.isTimedOut
		// alert(isTimedOut)
		localStorage.removeItem("jwtToken");
		window.location.href = "/userLogin";
	}

	render() {
		return (
			<Provider store={store}>
				<div>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout}
					/>
					<Router>
						<div className="App">
							<Route exact path="/header1" component={Header} />
							<Route exact path="/" component={userLogin} />
							<Route exact path="/footer" component={Footer} />
							<Route exact path="/navPage" component={Navigation} />
							{/* <Route exact path="/bootstrapnav" component={BootstrapNavigation} /> */}
							{/* <Route exact path="/newHeader" component={NewHeader}/> */}
							<Route
								exact
								path="/publisherNavPage"
								component={PublisherNavigation}
							/>
							<Route exact path="/userLogin" component={Agency} />
							{/* <Route exact path="/publisherLogin" component={Publisher} />
              <Route exact path="/advertiserLogin" component={Advertiser} /> */}
			  
			  			  	<Route exact path="/SwitchClient" component={SwitchClient}/> {/* Aman -4619 -(added this route for multiple role section) PC/PNC-Login - Client Switch option */}


							<Route exact path="/adminDashboard" component={Admin} />
							<Route
								exact
								path="/reviewPublisher"
								component={ReviewPublisher}
							/>
							{/* <Route exact path="/approveOnBoaredPublisher" component={ApproveOnBoaredPublisher} /> */}
							<Route
								exact
								path="/publisherInvoice"
								component={PublisherInvoice}
							/>
							<Route
								exact
								path="/voiceLogsUpload"
								component={VoiceLogsUpload}
							/>
							<Route exact path="/createCampaign" component={CreateCampaign} />
							<Route exact path="/editCampaign" component={EditCampaign} />
							<Route exact path="/edit" component={Edit} />
							{/* <Route
								exact
								path="/supportingDocument"
								component={SupportingDocument}
							/>
							<Route
								exact
								path="/editSupportingDocument"
								component={EditSupportingDocument}
							/> */}
							<Route exact path="/deliveryFormat" component={DeliveryFormat} />
							<Route
								exact
								path="/editDeliveryFormat"
								component={EditDeliveryFormat}
							/>
							<Route exact path="/finishCampaign" component={FinishCampaign} />
							<Route
								exact
								path="/editFinishCampaign"
								component={EditFinishCampaign}
							/>
							{/* <Route exact path="/campaignSetup" component={CampaignSetup} />
              <Route exact path="/setup" component={Setup} /> */}
							{/* <Route exact path="/allocatingCampaign" component={AllocatingCampaign} /> */}
							{/* <Route exact path="/assignedCampaign" component={AssignedCampaign} /> */}
							{/* <Route exact path="/agencyLiveCampaign" component={AgencyLiveCampaign} /> */}
							{/* <Route exact path="/agencyPauseCampaign" component={PauseCampaign} /> */}
							{/* <Route exact path="/agencyArchiveCampaign" component={ArchiveCampaign} /> */}
							<Route
								exact
								path="/incompleteCampaign"
								component={IncompleteCampaign}
							/>
							{/* <Route exact path="/publisherView" component={PublisherView} /> */}
							{/* <Route exact path="/landingPageFeedBack" component={LandingPageFeedBack} /> */}
							{/* <Route exact path="/deliverLead" component={DeliverLead} /> */}
							<Route exact path="/table" component={LeadTable} />
							{/* <Route exact path="/agencyFrontPage" component={AgencyFrontPage} />
              <Route exact path="/publisherFrontPage" component={PublisherFrontPage} />
              <Route exact path="/AdvertiserFrontPage" component={AdvertiserFrontPage} /> */}
							<Route
								exact
								path="/reportByPublisher"
								component={ReportByPublisher}
							/>
							<Route
								exact
								path="/listAllCampaign"
								component={ListAllCampaign}
							/>
							<Route
								exact
								path="/byCampaignEndDate"
								component={ByCampaignEndDate}
							/>
							<Route
								exact
								path="/listGDPRPublisher"
								component={ListGDPRPublisher}
							/>
							<Route exact path="/leadDownload" component={LeadDownload} />
							<Route exact path="/reviewLead" component={Reviewlead} />
							<Route exact path="/addPublisher" component={AddPublisher} />
							{/* <Route exact path="/advDemo" component={Demo} /> */}
							<Route
								exact
								path="/campaignTraceReport"
								component={CampaignTraceReport}
							/>
							{/* <Route exact path="/test" component={Table} /> */}
							<Route
								exact
								path="/leadReviewTable"
								component={LeadReviewTable}
							/>
							<Route
								exact
								path="/agencyCreativesReview"
								component={AgencyCreativesReview}
							/>
							<Route
								exact
								path="/agencyReviewLead"
								component={AgencyReviewLead}
							/>
							<Route
								exact
								path="/listGDPRPublisher"
								component={ListGDPRPublisher}
							/>
							<Route exact path="/pieChart" component={PieChart} />
							<Route
								exact
								path="/publisherAnalysisDashbord"
								component={PublisherAnalysisDashbord}
							/>
							<Route exact path="/agencyReport1" component={AgencyReport1} />
							<Route exact path="/agencyReport2" component={AgencyReport2} />
							<Route exact path="/quickUpload" component={QuickUpload} />
							<Route exact path="/advertiserTabs" component={AdvertiserTab} />
							<Route
								exact
								path="/editQuickUpload"
								component={EditQuickUpload}
							/>
							<Route
								exact
								path="/publisherOnBoard"
								component={PublisherOnBoard}
							/>
							<Route exact path="/agencyOnBoard" component={AgencyOnBoard} />
							<Route
								exact
								path="/advertiserOnBoard"
								component={AdvertiserOnBoard}
							/>
							{/* <Route exact path="/agencyCampaignSetupInDetail" component={AgencyCampaignSetupInDetail} /> */}
							{/* <Route exact path="/setupSupportingDocument" component={SetupSupportingDocument} /> */}
							<Route
								exact
								path="/finishQuickUpload"
								component={FinishQuickUpload}
							/>
							<Route
								exact
								path="/editFinishQuickUpload"
								component={EditFinishQuickUpload}
							/>
							{/* <Route exact path="/finishCampaignSetup" component={FinishCampaignSetup} /> */}
							<Route
								exact
								path="/agencyAllocatedLeads"
								component={AgencyAllocatedLeads}
							/>
							<Route
								exact
								path="/reviewPublisher"
								component={ReviewPublisher}
							/>
							<Route exact path="/reviewAgency" component={ReviewAgency} />
							<Route
								exact
								path="/approveOnBoaredPublisher"
								component={ApproveOnBoaredPublisher}
							/>
							<Route
								exact
								path="/approvedPublisher"
								component={ApprovedPublisher}
							/>
							<Route
								exact
								path="/approveOnBoaredAgency"
								component={ApproveOnBoaredAgency}
							/>
							<Route exact path="/approvedAgency" component={ApprovedAgency} />
							{/* <Route exact path="/agencyPOCReview" component={AgencyPOCReview} /> */}
							<Route exact path="/addAdvertiser" component={addAdvertiser} />
							{/* <Route exact path="/creativeUploads" component={CreativeUploads} /> */}
							<Route
								exact
								path="/publisherAllocation"
								component={PublisherAllocation}
							/>
							<Route exact path="/dashboard" component={Dashboard} />
							<Route
								exact
								path="/dashboardPublisher"
								component={DasboardPublisher}
							/>
							<Route
								exact
								path="/newPublisherDashboard"
								component={NewPublisherDashboard}
							/>

							<Route
								exact
								path="/advertiserDashboard"
								component={Advertiserdashboard}
							/>
							<Route
								exact
								path="/campaignSpecificationDashboard"
								component={CampaignDashboard}
							/>
							<Route
								exact
								path="/campaignSpecificationDashboardDetails"
								component={CampaignSpecificationDashboardDetails}
							/>
							<Route
								exact
								path="/publisherCreativeUploads"
								component={PublisherCreativeUploads}
							/>
							<Route
								exact
								path="/publisherInternalReviewLead"
								component={PublisherInternalReviewLead}
							/>
							<Route
								exact
								path="/contactPublisher"
								component={ContactPublisher}
							/>
							<Route exact path="/contactAgency" component={ContactAgency} />

							<Route exact path="/updateInvoice" component={UpdateInvoice} />

							<Route
								exact
								path="/createXLSCampaign"
								component={CreateXLSCampaign}
							/>
							<Route exact path="/createXLSRfp" component={CreateXLSRfp} />
							{/* snehal-task-3509Project's(DI) - Create RFP through XLS - FE */}
							{/* <Route exact path="/multiSelectDropdown" component={MultiSelectDropdown}/> */}
							{/* <Route exact path="/publisherAllocation" component={PublisherAllocation}/> */}
							<Route
								exact
								path="/incompleteCampaignTab"
								component={IncompleteCampaignTab}
							/>
							{/* <Route exact path="/allocatedCampaignTab" component={AllocatedCampaignTab} /> */}
							{/* <Route exact path="/agencyAllocatedCampaign" component={AgencyAllocatedCampaign} /> */}
							<Route
								exact
								path="/publisherDeliverLead"
								component={PublisherDeliverLead}
							/>
							<Route
								exact
								path="/advertiserReviewLead"
								component={AdvertiserReviewLead}
							/>
							<Route
								exact
								path="/advertiserCreativesReview"
								component={AdvertiserCreativesReview}
							/>
							<Route
								exact
								path="/campaignSpecificationPublisherDashboardDetails"
								component={CampaignSpecificationPublisherDashboardDetails}
							/>
							<Route
								exact
								path="/campaignSpecificationPublisherDashboard"
								component={CampaignSpecificationPublisherDashboard}
							/>
							<Route
								exact
								path="/forgotPasswordOTP"
								component={ForgotPwd}></Route>
							<Route
								exact
								path="/addCompliance"
								component={AddCompliance}></Route>
							{/* <Route exact path="/campaignSpecificationPublisherDashboardDetails" component={CampaignSpecificationPublisherDashboardDetails}/> */}
							<Route
								exact
								path="/campaignSpecificationAdvertiserDashboard"
								component={CampaignSpecificationAdvertiserDashboard}
							/>
							<Route
								exact
								path="/campaignsetupSpecification"
								component={CampaignSetupSpecification}
							/>
							<Route
								exact
								path="/campaignsetupSpecificationDetails"
								component={CampaignSetupSpecificationDetails}
							/>
							<Route exact path="/linkPublisher" component={LinkPublisher} />
							<Route exact path="/agencyInvoice" component={AgencyInvoice} />
							<Route
								exact
								path="/reviewLinkPublisher"
								component={ReviewLinkPublisher}
							/>
							<Route
								exact
								path="/reviewLinkAgency"
								component={ReviewLinkAgency}
							/>
							<Route exact path="/apiLead" component={ApiLead} />
							<Route
								exact
								path="/uploadCommonSuppression"
								component={UploadCommonSuppression}
							/>
							<Route
								exact
								path="/updateExistingLead"
								component={UpdateExistingLead}
							/>
							<Route
								exact
								path="/publisherUpdateDeliverLead"
								component={PublisherUpdateDeliverLead}
							/>
							<Route
								exact
								path="/keywordExtractionFromPDF"
								component={keywordExtractionFromPDF}
							/>
							<Route
								exact
								path="/forgotPasswordEmail"
								component={ForgotPasswordEmail}
							/>
							<Route exact path="/forgotPassword" component={ForgotPwd} />
							<Route exact path="/resetPassword" component={ResetPwd} />
							<Route exact path="/lpDataTracking" component={LPDataTracking} />
							<Route exact path="/changePassword" component={ChangePassword} />
							<Route
								exact
								path="/changePasswordAgency"
								component={ChangePasswordAgency}
							/>
							<Route
								exact
								path="/changePasswordAdmin"
								component={ChangePasswordAdmin}
							/>
								{/* rutuja - 4357 */}
							<Route
								exact
								path="/changeUserStatus"
								component={changeUserStatus}
							/>
							<Route
								exact
								path="/changePasswordAdvertiser"
								component={ChangePasswordAdvertiser}
							/>
							{/* <Route exact path="/requestForProposal" component={RequestForProposal} /> */}
							<Route
								exact
								path="/requestForProposalExsisting"
								component={RequestForProposalExsisting}
							/>
							<Route
								exact
								path="/requestForProposalPublisherNew"
								component={RequestForProposalForPublisher}
							/>
							<Route
								exact
								path="/requestForProposalPublisher"
								component={RequestForProposalPublisher}
							/>
							<Route
								exact
								path="/requestProposalAgency"
								component={RequestForProposalAgency}
							/>
							{/* //shivani-3245-route ABM upgrade files */}
							<Route exact path="/domainName" component={domainName} />
							{/* //shivani-3245-route ABM upgrade files */}

							<Route
								exact
								path="/publisherDomainName"
								component={publisherDomainName}
							/>
							{/* <Route exact path="/requestForProposalBidding" component={RequestForProposalBidding}/> */}
							<Route
								exact
								path="/agencyEmailConfig"
								component={AgencyEmailConfig}
							/>
							<Route
								exact
								path="/publisherEmailConfig"
								component={PublisherEmailConfig}
							/>
							<Route
								exact
								path="/advertiserEmailConfig"
								component={AdvertiserEmailConfig}
							/>
							<Route exact path="/addUserAgency" component={AddUserAgency} />
							<Route
								exact
								path="/addUserPublisher"
								component={AddUserPublisher}
							/>
							<Route
								exact
								path="/addUserAdvertiser"
								component={AddUserAdvertiser}
							/>
							<Route exact path="/addUserAdmin" component={AddUserAdmin} />
							<Route
								exact
								path="/publisherLeadDeliveryReport"
								component={PublisherLeadDeliveryReport}
							/>
							<Route
								exact
								path="/viewUserPassword"
								component={ViewUserPassword}
							/>
							<Route
								exact
								path="/byPublisherEndDate"
								component={ByPublisherEndDate}
							/>
							<Route exact path="/customMapping" component={CustomMapping} />
							<Route exact path="/campaignList" component={CampaignList} />
							<Route
								exact
								path="/publisherCampaignList"
								component={PublisherCampaignList}
							/>
							<Route
								exact
								path="/rfpCampaignAllocation"
								component={RfpCampaignAllocation}
							/>
							<Route
								exact
								path="/agencyInformation"
								component={AgencyInformation}
							/>
							<Route
								exact
								path="/createcampaign2"
								component={CreateCampaignNew}
							/>
							<Route
								exact
								path="/reviewAdvertiser"
								component={ReviewAdvertiser}
							/>
							<Route
								exact
								path="/approveOnBoardAdvertiser"
								component={ApproveOnBoardAdvertiser}
							/>
							<Route exact path="/linkAdvertiser" component={LinkAdvertiser} />
							<Route
								exact
								path="/agencyInvoicesList"
								component={AgencyInvoicesList}
							/>
							<Route
								exact
								path="/displayPublisherInvoice"
								component={DisplayPublisherInvoice}
							/>
							<Route
								exact
								path="/reviewLinkAdvertiser"
								component={ReviewLinkAdvertiser}
							/>
							<Route
								exact
								path="/reviewAgencyByAdvertiser"
								component={ReviewAgencyByAdvertiser}
							/>
							<Route exact path="/welcomePage" component={WelcomePage} />
							<Route exact path="/WelcomeEdit" component={WelcomeEdit} />
							<Route
								exact
								path="/NewEditCampaign"
								component={EditExistingCampaign}
							/>
							<Route
								exact
								path="/RfpCampaignSpecification"
								component={RfpCampaignSpecification}
							/>
							<Route
								exact
								path="/rfpCampaignSpecificationDealis"
								component={RfpCampaignSpecificationDetails}
							/>
							{/* User Story : 3125 : Karan Jagtap - Start 
									New addition shift old code to "/step2supportingDocumentOld" new route
									Keep new code to "/step2supportingDocument" old route
							*/}
							{/* Supporting Document : Old Route */}
							<Route
								exact
								path="/step2supportingDocumentOld"
								component={Step2SupportingDocument}
							/>
							{/* Supporting Document : New Route */}
							<Route
								exact
								path="/step2supportingDocument"
								component={supportingDocumentCreate} // User Story : 3125 : Karan Jagtap
							/>
							{/* User Story : 3125 : Karan Jagtap - End */}
							<Route exact path="/successPage" component={SuccessPage} />
							<Route
								exact
								path="/PublisherRfpCampaignSpecification"
								component={PublisherRfpCampaignSpecification}
							/>
							<Route
								exact
								path="/publisherRfpCampaignSpecificationDealis"
								component={PublisherRfpCampaignSpecificationDetails}
							/>
							{/* User Story : 3269 : Karan Jagtap - Start 
									New addition shift old code to "/newEditSupportingDocumentsOld" new route
									Keep new code to "/newEditSupportingDocuments" old route
							*/}
							{/* Supporting Document Edit : Old Route */}
							<Route
								exact
								path="/newEditSupportingDocumentsOld"
								component={newEditSupportingDocuments}
							/>
							{/* Supporting Document Edit : New Route */}
							<Route
								exact
								path="/newEditSupportingDocuments"
								// component={newEditSupportingDocuments}
								component={supportingDocumentEdit} // User Story : 3269 : Karan Jagtap
							/>
							{/* User Story : 3269 : Karan Jagtap - End */}
							<Route
								exact
								path="/publisherInformation"
								component={PublisherInformation}
							/>
							<Route
								exact
								path="/advertiserInformation"
								component={AdvertiserInformation}
							/>
							<Route exact path="/clientSetup" component={ClientSetup} />
							<Route
								exact
								path="/marketoClientSetup"
								component={MarketoClientSetup}
							/>
							<Route
								exact
								path="/hubspotClientSetup"
								component={HubspotClientSetup}
							/>
							<Route exact path="/salesforceLead" component={SalesforceLead} />
							<Route
								exact
								path="/salesforceLeadView"
								component={SalesforceLeadView}
							/>
							<Route exact path="/marketoLead" component={MarketoLead} />
							<Route
								exact
								path="/marketoLeadView"
								component={MarketoLeadView}
							/>
							<Route exact path="/hubspotLead" component={HubspotLead} />
							<Route
								exact
								path="/hubspotLeadView"
								component={HubspotLeadView}
							/>
							<Route
								exact
								path="/captureLandingPage"
								component={CaptureLandingPage}
							/>
							<Route exact path="/newDashboard" component={NewDashboard} />
							<Route exact path="/requestForProposal2" component={NewRfp} />
							{/* <Route exact path="/agencyNewDashboard" component={agencyNewDashboard}/> */}
							<Route
								exact
								path="/agencyUnreadAlerts"
								component={AgencyUnreadAlerts}
							/>
							<Route
								exact
								path="/publisherUnreadAlerts"
								component={PublisherUnreadAlerts}
							/>
							<Route
								exact
								path="/campaignRespond"
								component={CampaignRespond}></Route>
							<Route exact path="/invoiceHistory" component={invoiceHistory} />
							<Route exact path="/helpOption" component={helpOption} />
							<Route
								exact
								path="/createCampaignHelp"
								component={createCampaignHelp}
							/>
							<Route
								exact
								path="/editCampaignHelp"
								component={editCampaignHelp}
							/>
							<Route exact path="/rfpAnalysis" component={rfpAnalysis} />
							<Route
								exact
								path="/clientSetupHelp"
								component={ClientSetupHelp}
							/>
							<Route
								exact
								path="/salesforceLeadHelp"
								component={SalesforceLeadHelp}
							/>
							<Route
								exact
								path="/publisherAllocationHelp"
								component={publisherAllocationHelp}
							/>
							<Route
								exact
								path="/publisherInformationEdit"
								component={PublisherInformationEdit}
							/>
							<Route
								exact
								path="/agencyInformationEdit"
								component={agencyInformationEdit}
							/>
							<Route
								exact
								path="/advertiserInformationEdit"
								component={advertiserInformationEdit}
							/>
							<Route exact path="/qalogin" component={qalogin} />
							<Route
								exact
								path="/publisherRFPAnalysis"
								component={publisherRFPAnalysis}
							/>
							<Route
								exact
								path="/advertiserMyReports"
								component={advertiserMyReports}
							/>
							{/* //sandeep-3448-routed this new file in report folder named as"agencyMyReports" */}
							<Route
								exact
								path="/agencyMyReports"
								component={agencyMyReports}
							/>
							{/* //shivani-3354-routed this new file in report folder named as"agencyPublisherDeliverytracking" */}
							<Route
								exact
								path="/agencyPublisherDeliverytracking"
								component={agencyPublisherDeliverytracking}
							/>
							{/* //shivani-3415-routed this new file in report folder named as"agencyPublisherLeadtracking" */}

							<Route
								exact
								path="/agencyPublisherLeadtracking"
								component={agencyPublisherLeadtracking}
							/>
							{/* //sunita-3406-routed this new file in report folder named as"agencyAdvertiserDeliverytracking" */}
							<Route
								exact
								path="/agencyAdvertiserDeliverytracking"
								component={agencyAdvertiserDeliverytracking}
							/>

							<Route
								exact
								path="/publisherMyReport"
								component={PublisherMyReport}
							/>
							{/* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report */}
							<Route
								exact
								path="/agencyDeliveryTrackingReport"
								component={AgencyDeliveryTrackingReport}
							/>
							{/* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report */}
							{/* karan-3453-route to add country menu */}
							<Route exact path="/addCountry" component={addCountry} />

							<Route
								exact
								path="/advertiserDeliveryTrackingReport"
								component={advertiserDeliveryTrackingReport}
							/>
							{/* Sandeep-task-3479-Report Engine- advertiser View- My Reports and Advertiser Lead Tracking Report */}
							<Route
								exact
								path="/advertiserLeadTracking"
								component={advertiserLeadTracking}
							/>
							{/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */}
							<Route
								exact
								path="/agencyLeadTrackingReport"
								component={AgencyLeadTrackingReport}
							/>

							<Route
								exact
								path="/agencyAdvertiserLeadTracking"
								component={AgencyAdvertiserLeadTracking}
							/>
							{/* //sunita-3413-routed this new file in report folder named as"AgencyAdvertiserLeadTracking" */}

							<Route exact path="/createXLSRfp" component={createXLSRfp}/>

							{/*<Route exact path="/physicalAddress" component={physicalAddress}/>*/}
							<Route
								exact
								path="/jobLevelMapping"
								component={jobLevelMapping}
							/>
							{/* //shivani-3517-routed this new file in agency folder named as"jobLevelMapping" */}
							{/* karan-task-3521-RFP Dashboard menu route */}
							<Route exact path="/rfpDashboard" component={RFPDashboard} />

							{/* karan-task-3636-RFP Dashboard menu route */}
							<Route exact path="/rfpProgress" component={rfpProgress} />

							{/* karan-task-3648-Add Publisher page route */}
							<Route
								exact
								path="/addPublisherRequest"
								component={AddPublisherRequest}
							/>

							{/* karan-task-3648-Publisher request page route for admin */}
							<Route
								exact
								path="/publisherRequest"
								component={PublisherRequest}
							/>

							{/* karan-task-3515-RFP Reminder Setup for agency */}
							<Route
								exact
								path="/rfpReminderSetup"
								component={RfpReminderSetup}
							/>
							{/* Rutuja Jagtap task-3879 Campaign Specification for DIQA role*/}
							<Route
								exact
								path="/campaignSpecificationQADashboard"
								component={campaignSpecificationQADashboard}
							/>
							<Route
								exact
								path="/campaignSpecificationQADashboardDetails"
								component={campaignSpecificationQADashboardDetails}
							/>
							{/* //chaitanya-task-3982-add custom region */}
							<Route
								exact
								path="/addCustomRegion"
								component={AddCustomRegion}
							/>
							{/* kiran-4173-added publisherCreativesReview */}
							<Route
								exact
								path="/publisherCreativesReview"
								component={PublisherCreativesReview}
							/>
							<Route
								exact
								path="/leadDeliverMarketo"
								component={leadDeliverMarketo}
							/>
							<Route
								exact
								path="/leadDeliverHubspot"
								component={leadDeliverHubspot}
							/>
							{/* Priyanka-4413-added leadViewMarketo */}
							<Route
								exact
								path="/leadViewMarketo"
								component={leadViewMarketo}
							/>
							{/* Priyanka-4421-added leadViewHubspot */}
							<Route
								exact
								path="/leadViewHubspot"
								component={leadViewHubspot}
							/>
							{/* nilwsh-4444-added leadViewHubspot */}
							<Route
								exact
								path="/leadDeliverSf"
								component={leadDeliverSf}
							/>
                           {/* Priyanka-4422-added leadViewSalesforce */}
							<Route
								exact
								path="/leadViewSf"
								component={leadViewSf}
							/>

							{/* vrushbh task 4439-leadreport by campaign */}
							<Route
							exact
							path="/leadReportByCampaign"
							component={leadReportByCampaign}
							/>
							{/* rutuja task 4517-ADD AQA User */}
							<Route
							exact
							path="/addAQARole"
							component={addAQARole}
							/>
							{/* Chaitanya-4470-leadReportClient */}
							<Route
								exact
								path="/leadReportClient"
								component={leadReportClient}
							/>
							{/* 4466-Nilesh Add new page linkCamapign at admin side */}
							<Route 
								exact 
								path="/linkCampaign" 
								component={linkCampaign} /> 
							{/* Priyanka-4603-Added new dashboard page for DI QA */}
							<Route
								exact
								path="/dashboardQA"
								component={dashboardQA}
							/>
							{/* rutuja  task 4654- publisher leadreport by campaign */}
							<Route
							exact
							path="/publisherLeadReportByCampaign"
							component={publisherLeadReportByCampaign}
							/>
							{/* Nilesh-4653-leadReportClientByPC */}
							<Route
								exact
								path="/leadReportClientByPC"
								component={leadReportClientByPC}
							/>
							{/* Aman-4660 - MBAdmin Report Lead File Tracking Report- DMA's Upload lead file count and file details */}
							<Route
							exact
							path="/LeadFileTrackingReport"
							component={LeadFileTrackingReport}
							/>
							{/*kiran- 4623-added new submenu at Admin side  */}
							<Route
							exact
							path="/leadRejection"
							component={leadRejection}
							/>
							
							{/*rutuja- 4722-added new submenu add state at Admin side  */}
							<Route exact path="/addState" component={addState} />


							{/* kiran-4721 -added new route for generateReports*/}
							<Route
							exact
							path="/generateReports"
							component={GenerateReports}
							/>
							{/* rutuja - 4823 added new page bypassEmailDomainValidationReport*/}
							<Route
							exact
							path="/bypassEmailDomainValidationReport"
							component={bypassEmailDomainValidationReport}
							/>
							{/*Aman-Task-4894 -Added for Suppression Comparison only for PC */}
							<Route
							exact
							path="/suppressionCompare"
							component={suppressionCompare}
							/>
							{/*Aman-Task-4998 -Added for Suppression Comparison only for PC/PNC */}
							<Route
							exact
							path="/domainExclusionComparison"
							component={domainExclusionComparison}
							/>
							{/* RUtuja - 4975 added new page for email configuration at diqa login */}
							{/* Aman-5164 -Chnaged Leads-Nexus-File Names-Replace to QA */}
							<Route
							  exact
							  path="/qaEmailConfig"
							  component={qaEmailConfig}
							/>
							{/*Chaitanya-Task-4972-Added for Reviewed Leads File Report for DIQA login */}
							<Route
							  exact
							  path="/reviewedLeadsFileReport"
							  component={reviewedLeadsFileReport}
							/>
							{/* Nilesh - 5024 - Added new page for change password at diqa login */}
							{/* Aman-5164 -Chnaged Leads-Nexus-File Names-Replace to QA */}
							<Route
							  exact
							  path="/changePasswordQA"
							  component={changePasswordQA}
							/>
							  {/* Nilesh-5055-new page for DIQA/DC alerts */}
							  {/* Aman-5164 -Chnaged Leads-Nexus-File Names-Replace to QA */}
							  <Route
                              exact
                              path="/qaUnreadAlerts"
                              component={qaUnreadAlerts}
                            />
							 {/* Chaitanya - 5043 - Added new page for add User at diqa tl i.e. dc login */}
							 {/* Aman-5164 -Chnaged Leads-Nexus-File Names-Replace to QA */}
							 <Route 
                              exact 
                              path="/addUserQA" 
                              component={addUserQA} 
                            />
							{/*Chaitanya-Task-5074-Added for Reviewed Leads File Report for DIQA login */}
							{/* Aman-5164 -Chnaged Leads-Nexus-File Names-Replace to QA */}
							<Route
                              exact
                              path="/qaReviewedLeadsFileReport"
                              component={qaReviewedLeadsFileReport}
                            />
							{/*Aman-Task-5060 -Added for email BriteStatus Verifier only for PC/PNC */}
							{/*Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker */}
							<Route
				exact
				path="/emailStatusChecker"
				component={emailStatusChecker}
				/>
				{/* Aman-5311-route to add country menu */}
				<Route exact path="/addCountryMbAdmin" component={addCountryMbAdmin} />
						</div>
					</Router>
				</div>
			</Provider>
		);
	}
}
export default App;
