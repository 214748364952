/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:22/11/2019
@Description:Upload suppression file against multiple Campaign with selected client
*/

import React from "react";
import * as $ from "jquery";
// import { BrowserRouter as Router, Route } from "react-router-dom"; //Nilesh-2833-Removing console warning
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { css } from "emotion";
import { Table, Tooltip, Icon } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import readXlsxFile from "read-excel-file";
import "./uploadCommonSuppression.css"; //mufiz task 4117
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
import { Tabs, Tab } from "react-bootstrap"; //Chaitanya-4865-Added tab for UI design
const Swal = require("sweetalert2");
// const queryString = require("query-string"); //Nilesh-2833-Removing console warning
const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "12px",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    //border: "1px solid black",
    textAlign: "center",
    // borderWidth: '2px',
    // borderColor: 'yellow',
    // borderStyle: 'solid'
    color: "white",
  },
});
var selectedCampaign = [];
var suppValidationArray = [];
var exValidationArray = []; //Nilesh-4933- array use for validation
var suppressionnames = [];
var newsuppressionfilenames = [];
var successSuppressionCount = [];
// var newExeclusionFilenames = [] 
// var rejectSuppressionCount = []; //Nilesh-2833-Removing console warning
//use for fetch url value
const queryString = require("query-string");
let duplicateABMFile = []; // this is used for check duplicate file name
var rfpChange = 0;
var oldABMFileArray = [];
var abmCountArray = [];
var rejectAbmCount = [];
var execlusionnames = []; //Nilesh-4896 store execlusionnames data
var newexclusionfilenames = [];
let chkExclusionExist = [], duplicateExclusionFile = []; //Nilesh-4896 store chkExclusionExist data
var exclusionValidationArray = []; //Nilesh-4896
var successExclusionCount = []; //Nilesh-4896
var selectedCampaignEx = []; //Nilesh-4928
var successABMCount = []; //Rutuja-4895
var abmValidationArray = [];  //Rutuja-4895
var selectedCampaignABM = [];  //Rutuja-4895
let duplicateabmFile = []; //Rutuja-4895
var abmnames = []; //Rutuja-4895
let chkABMExist = []; //Rutuja-4895
var successAbmCount = []; //Rutuja-4895
var newabmfilenames = []; //Rutuja-4895
var otherValidationArray = [];  //Rutuja-4897
var selectedCampaignother = [];  //Rutuja-4897
let duplicateotherFile = []; //Rutuja-4897
var othernames = []; //Rutuja-4897
let chkotherExist = []; //Rutuja-4897
var successotherCount = []; //Rutuja-4897
var newotherfilenames = []; //Rutuja-4897

class UploadCommonSuppression extends React.Component {
  constructor() {
    super();

    this.state = {
      fields: {},
      clientList: [],
      pageSize2: 5,
      clientName: "",
      campaignInfo: [],
      campaignInfoEx: [], //Nilesh-4928 
      campaignInfoABM: [],  //Rutuja-4895
      campaignInfoother: [], //Rutuja - 4897
      tableState: { size: "small" },
      displayTablesuppression: "none",
      suppressionerror: {},
      suppressionData: [], // suppressionfileData
      loader_progress: "", //rutuja -4375 added new loader
      loader_progress1: "",// //Rutuja-4895
      loader_progress2: "",// //Rutuja-4897
      disabled: "",
      agencyID: "",
      x: 0,
      y: 0,
      // this is for maintaining the UI for ABM files
      // all abm files goes in this array
      abmFilesSelectedDocuments: [],
      otherFilesSelectedDocuments: [],//4897
      abmJson: [],
      suppressionFlag: false,
      suppressionFilesSelectedDocuments: [],
      // this is for maintaining the UI for Exclusion files
      exclusionFilesSelectedDocuments: [], //Nilesh-4896 store exclusion files
      backExclusionArrayLengthForUI: 0, //Nilesh-4896
      uploadedFile: false,
      uploadedFileEx: false,
      uploadedFileabm :false,
      uploadedFileother:false, //4897
      tableSearchText: "",
      campaignDetailsSearch: [],
      selectedCampaignsID: [],
      campaignMessage: "",
      exclusionerror: {}, //Nilesh-4896 Used for exclusion errors
      displayTableexclusion: "none" ,//4896
      exclusionData: [] ,//4896
      abmerror: {},  //Rutuja-4895
      displayTableabm: "none" ,  //Rutuja-4895
      abmData: [] , //Rutuja-4895
      submitABMButtonDisabled: false, //Rutuja-4895
      othererror: {},  //Rutuja-4897
      displayTableother: "none" ,  //Rutuja-4897
      otherData: [] , //Rutuja-4897
      submitotherButtonDisabled: false, //Rutuja-4897
      submitExButtonDisabled: false, // Initially, the button is not disabled
      key : "" ,//Nilesh-4928- add keys 
      selectedCampaignsIDEx: [] ,//Nilesh-4928- showing selected campiD 
      selectedCampaignsIDABM: [] , //Rutuja-4895
      selectedCampaignsIDother: [],  //Rutuja-4897
      imageDisabledSupp: false, // Rutuja task 4983 upload image enable
      imageDisabledExl: false, // Rutuja task 4983 upload image enable
      imageDisabledAbm: false, // Rutuja task 4983 upload image enable
      imageDisabledOther: false, // Rutuja task 4983 upload image enable

    };
    this.handleClientName = this.handleClientName.bind(this);
    this.uploadSuppressionFile = this.uploadSuppressionFile.bind(this);
    this.submitFile = this.submitFile.bind(this);
    this.submitSuppressionFile = this.submitSuppressionFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.uploadExclusionFile = this.uploadExclusionFile.bind(this); //Nilesh-4896
    this.deleteExclusionFile = this.deleteExclusionFile.bind(this); //Nilesh-4896
    this.submitExFile = this.submitExFile.bind(this); //Nilesh-4896
    this.uploadABMFile = this.uploadABMFile.bind(this); //RUtuja-4895
    this.deleteABMFile = this.deleteABMFile.bind(this); //Rutuja-4895
    this.submitABMFile = this.submitABMFile.bind(this); //Rutuja-4895
    this.uploadOtherFile = this.uploadOtherFile.bind(this); //RUtuja-4897
    this.deleteotherFile = this.deleteotherFile.bind(this); //Rutuja-4897
    this.submitotherFile = this.submitotherFile.bind(this); //Rutuja-4897
  } /*** End of constructor */

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      this.getFirstTabData();
    } // end of componentwillMount
  }

  handleClientName(e) {
    // selectedCampaign = [];
    var clientID = e.target.value;
      if (clientID === "Select") {
      // Nilesh-4928-If "Select" is chosen, set empty data for both campaignInfo and campaignInfoEx
      if (this.state.key === 1) {
        selectedCampaign = [];
        this.setState({ campaignInfo: "", });
        document.getElementById("search-bar1").disabled = true;
      } else if (this.state.key === 2){
        selectedCampaignEx = []; //4933
        this.setState({ campaignInfoEx: ""});
        document.getElementById("search-bar2").disabled = true;
      }
       //Rutuja-4895 added for abm tab
      else if (this.state.key === 3){
        selectedCampaignABM = [];
        this.setState({ campaignInfoABM: "" });
        document.getElementById("search-bar3").disabled = true;
      }
      //Rutuja-4897 added for other tab
      else if (this.state.key === 4){
        selectedCampaignother = [];
        this.setState({ campaignInfoother: "" });
        document.getElementById("search-bar4").disabled = true;
      }
      
    } else {
      //Nilesh-4928- Fetch and set campaign information based on the selected advertiser and active tab
      const apiUrl = "agencyDashboard/getClientCampaignInfo?clientID=" + clientID;
    
      if (this.state.key === 1) {
        fetch(apiUrl)
          .then((res) => res.json())
          .then((campaignInfo) => {
            this.setState({ campaignInfo: campaignInfo });
    
            if (this.state.campaignInfo && this.state.campaignInfo.length > 0) {
              var name = "key";
              var value;
              // alert("this.state.key-->" + JSON.stringify(this.state.key))
              //Nilesh-4929 Add key wise search bar button shows enabled
              if (this.state.key === 1) {
                document.getElementById("search-bar1").disabled = false;
              } else if(this.state.key === 2) { 
                document.getElementById("search-bar2").disabled = false;
              }
               //Rutuja-4895 Added key wise search bar button shows enabled for abm tab
              else if(this.state.key === 3) { 
                document.getElementById("search-bar3").disabled = false;
              }
                //Rutuja-4897 Added key wise search bar button shows enabled for other tab
                else if(this.state.key === 4) { 
                  document.getElementById("search-bar4").disabled = false;
                }
                
              
              for (var i = 0; i < this.state.campaignInfo.length; i++) {
                // alert("value -->" + JSON.stringify(value))
                value = this.state.campaignInfo[i].campID;
                let campaignInfo = [...this.state.campaignInfo];
                campaignInfo[i] = { ...campaignInfo[i], [name]: value };
                this.setState({ campaignInfo: campaignInfo, clientName: name });
                // alert("clientName--" + JSON.stringify(this.state.clientName))
              }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else if (this.state.key === 2) {
        fetch(apiUrl)
          .then((res) => res.json())
          .then((campaignInfoEx) => {
            this.setState({ campaignInfoEx: campaignInfoEx });
            
            if (this.state.campaignInfoEx && this.state.campaignInfoEx.length > 0) {
              var name = "key";
              var value;
              // alert("this.state.key-->" + JSON.stringify(this.state.key))
              //Nilesh-4929 
              if (this.state.key === 1) {
                document.getElementById("search-bar1").disabled = false;
              } else if(this.state.key === 2) { 
                document.getElementById("search-bar2").disabled = false;
              }
              //Rutuja Task 4895
              else if(this.state.key === 3) { 
                document.getElementById("search-bar3").disabled = false;
              }
              //Rutuja Task 4897
              else if(this.state.key === 4) { 
                document.getElementById("search-bar4").disabled = false;
              }
              
              for (var i = 0; i < this.state.campaignInfoEx.length; i++) {
                value = this.state.campaignInfoEx[i].campID;
                let campaignInfoEx = [...this.state.campaignInfoEx];
                campaignInfoEx[i] = { ...campaignInfoEx[i], [name]: value };
                this.setState({ campaignInfoEx: campaignInfoEx, clientName: name });
              }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
       //Rutuja Task 4895 Added for abm tab
      else if (this.state.key === 3) {
        fetch(apiUrl)
          .then((res) => res.json())
          .then((campaignInfoABM) => {
            this.setState({ campaignInfoABM: campaignInfoABM });
            
            if (this.state.campaignInfoABM && this.state.campaignInfoABM.length > 0) {
              var name = "key";
              var value;
              // alert("this.state.key-->" + JSON.stringify(this.state.key))
              //Nilesh-4929 
              if (this.state.key === 1) {
                document.getElementById("search-bar1").disabled = false;
              } else if(this.state.key === 2) { 
                document.getElementById("search-bar2").disabled = false;
              }
              else if(this.state.key === 3) { 
                document.getElementById("search-bar3").disabled = false;
              }
              
              for (var i = 0; i < this.state.campaignInfoABM.length; i++) {
                value = this.state.campaignInfoABM[i].campID;
                let campaignInfoABM = [...this.state.campaignInfoABM];
                campaignInfoABM[i] = { ...campaignInfoABM[i], [name]: value };
                this.setState({ campaignInfoABM: campaignInfoABM, clientName: name });
              }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
       //Rutuja Task 4897 Added for other tab
       else if (this.state.key === 4) {
        fetch(apiUrl)
          .then((res) => res.json())
          .then((campaignInfoother) => {
            this.setState({ campaignInfoother: campaignInfoother });
            
            if (this.state.campaignInfoother && this.state.campaignInfoother.length > 0) {
              var name = "key";
              var value;
              // alert("this.state.key-->" + JSON.stringify(this.state.key))
              //Nilesh-4929 
              if (this.state.key === 1) {
                document.getElementById("search-bar1").disabled = false;
              } else if(this.state.key === 2) { 
                document.getElementById("search-bar2").disabled = false;
              }
              else if(this.state.key === 3) { 
                document.getElementById("search-bar3").disabled = false;
              }
              else if(this.state.key === 4) { 
                document.getElementById("search-bar4").disabled = false;
              }
              
              for (var i = 0; i < this.state.campaignInfoother.length; i++) {
                value = this.state.campaignInfoother[i].campID;
                let campaignInfoother = [...this.state.campaignInfoother];
                campaignInfoother[i] = { ...campaignInfoother[i], [name]: value };
                this.setState({ campaignInfoother: campaignInfoother, clientName: name });
              }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    }
    
  } /*** End of handleClientName */
  
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      // var parsed = queryString.parse(this.props.location.search); //Nilesh-2833-Removing console warning
      //var agencyID=parsed.agencyID;
      const { user } = this.props.auth;
      var agencyID = user.id;
      newsuppressionfilenames = []; //Chaitanya-4869-Setting array blank to avoid previous uploaded file names issue
      newexclusionfilenames = []; //Nilesh-4933-Setting array blank to avoid previous uploaded file names issue
      newabmfilenames = [];  //Rutuja Task 4895
      newotherfilenames = [];  //Rutuja Task 4897
      let suppressionFilesSelectedDocuments = [];
      // let exclusionFilesArrayUI = []; //Nilesh-4896 showing exclusion files on UI
      let exclusionFilesSelectedDocuments = [];
      let abmFilesSelectedDocuments = [];
      let otherFilesSelectedDocuments = []; //Rutuja 4897
      //alert("suppressionFilesSelectedDocuments--->"+JSON.stringify(suppressionFilesSelectedDocuments))
      this.setState({
        agencyID: agencyID,
        suppressionFilesSelectedDocuments: suppressionFilesSelectedDocuments,
        exclusionFilesSelectedDocuments: exclusionFilesSelectedDocuments, //Nilesh-4896
        abmFilesSelectedDocuments : abmFilesSelectedDocuments , //Rutuja Task 4895
        otherFilesSelectedDocuments : otherFilesSelectedDocuments //Rutuja Task 4897 
      });
      //Chaitanya-4867-Added setState for suppressionFilesSelectedDocuments
      fetch("agencyDashboard/getClientList") // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((clientList) => this.setState({ clientList: clientList }))
        .catch(function (err) {
          console.log(err);
        });
    } /**End of Else Block */
  } /*** End of ComponentDidMount */

  submitFile(e) {
    e.preventDefault(); //Nilesh-2833-Removing console warning
    let clientName = this.state.clientName;
    var alertmessage = "";

    //Chaitanya-4847-Changed Error message for submitting without selecting Advertiser.
    if (clientName === "") {
      alertmessage = "Please Select All Mandatory Fields.";

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      var campInfoStatus = false;
      let uploadedFile = this.state.uploadedFile;
      // alert("uploadedFile--->" + JSON.stringify(uploadedFile))
      if (selectedCampaign.length > 0) {
        campInfoStatus = true;
      } else {
        alertmessage = "Please Select Campaign from list.";
      }
      
      if (uploadedFile === false && selectedCampaign.length === 0) {
        alertmessage = "Please Select Campaign from list And Upload File.";
      } else if (uploadedFile === false) {
        alertmessage = "Please Upload File.";
      }

      if (uploadedFile === true && campInfoStatus === true) {
        //Chaitanya-4847-Disabled submit Button after files submitted.
        document.getElementById("submitButton").disabled = true;
        this.setState({ loader_progress: "loader_campaign_list" ,imageDisabledSupp:true });// Rutuja task 4983 upload image disabled
        if (this.state.suppressionFlag === true) {
          this.submitSuppressionFile();
        }
      } else {
        Swal.fire({
          text: alertmessage,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  } /*** End of submitFile */

  /**
   * @author : Chaitanya Shinde
   * @description : This is used submit suppression file function-4813
   */
  submitSuppressionFile(e) {
    let suppressionData = [];
    var data = new FormData();
    suppressionData = this.state.suppressionData;
    let length = this.state.suppressionData.length;

    //rutuja -4375 added new loader
    // var link;  //Nilesh-2833-Removing console warning
    //var data = new FormData();
    data.append("campaignInfo", JSON.stringify(selectedCampaign));
    for (let i = 0; i < length; i++) {
      let fileName = suppressionData[i].fileName; //Chaitanya-4851-Changed name with fileName
      if (newsuppressionfilenames.includes(fileName))
        data.append("suppressionData", suppressionData[i].fileData); //Chaitanya-4851-passing fileData instead of whole record
    }
    fetch("/agencyDashboard/uploadCommonSuppression", {
      method: "POST",
      body: data,
    }) // karan-task-3723-vapt header and query params
      .then((res) => res.json())
      .then((suppList) => {
        this.setState({ loader_progress: "" ,imageDisabledSupp : false}); // Rutuja task 4983 upload image enabled

        let msg; //Nilesh-2833-Removing console warning
        let message = document.getElementById("suppresionMessage");
        let selectedCampaignsID = [];
        let allselectedCampaignsID = [];
        let campaignMessage = "";

        if (suppList[0].success === true) {
          var suppDocName = [];
          for (var i = 0; i < suppList.length; i++) {
            if (suppList[i].success === true) {
              suppDocName.push(suppList[i].fileName);
              successSuppressionCount.push(suppList[i].fileName);
            }
          }
          suppDocName = [...new Set(suppDocName)];

          let RejectedCount = 0;
          var HTML2 = "<table style='width:100%'>";

          let suppressionFilesArrayUI =
            this.state.suppressionFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning
          for (let j = 0; j < newsuppressionfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isExist = suppDocName.includes(newsuppressionfilenames[j]);

            let toBeState;
            if (isExist === true) {
              //alert("cross")
              toBeState = {
                success: true,
                suppDocName: newsuppressionfilenames[j],
                typeOfSuppDoc: "Suppression",
                //loading: false,
              };
              /**** If uploaded file is wrong then it displays as in Red color */
              // var HTML; //Nilesh-2833-Removing console warning

              // HTML += this.suppressionFileSectionTemplate(
              // 	false,
              // 	newsuppressionfilenames[j]
              // );
            } else {
              RejectedCount = RejectedCount + 1;

              toBeState = {
                success: false,
                suppDocName: newsuppressionfilenames[j],
                typeOfSuppDoc: "Suppression",
                //loading: false,
              };
            }

            suppressionFilesArrayUI = [
              ...suppressionFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }

          this.setState({
            suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
          });

          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaign.length; i++) {
            allselectedCampaignsID.push(selectedCampaign[i].campID);
          }
          //alert("selected Campaign => " + allselectedCampaignsID);
          selectedCampaignsID["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsID: selectedCampaignsID,
            campaignMessage: campaignMessage,
          });

          HTML2 += "</table>";
          document.getElementById("suppressionList").innerHTML = HTML2;
          if (RejectedCount > 0) {

            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg =
              "<font color = 'Green'>Suppression File Submitted Successfully</font>";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }

          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        } else {
          var isError = suppList[0].Error;
          for (let i = 0; i < suppList.length; i++) {
            //Nilesh-2833-Removing console warning
            if (suppList[i].success === false) {
              suppValidationArray.push(suppList[i].fileName);
            }
          }

          let HTML = "<table style='width:100%'>"; //Nilesh-2833-Removing console warning
          let suppressionFilesArrayUI =
            this.state.suppressionFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning

          let RejectedCount = 0;

          for (let j = 0; j < newsuppressionfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isValid = suppValidationArray.includes(
              newsuppressionfilenames[j]
            );
            let toBeState;
            if (isValid === true) {
              RejectedCount++;
              toBeState = {
                success: false,
                //supportDocID,
                suppDocName: newsuppressionfilenames[j],
                typeOfSuppDoc: "Suppression",
                //loading: false,
              };
            } else {
              toBeState = {
                success: true,
                //supportDocID,
                suppDocName: newsuppressionfilenames[j],
                typeOfSuppDoc: "Suppression",
                //loading: false,
              };

              let isTrue = successSuppressionCount.includes(
                newsuppressionfilenames[j]
              );
              if (isTrue === false) {
                successSuppressionCount.push(newsuppressionfilenames[j]);
              }
            }
            suppressionFilesArrayUI = [
              ...suppressionFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
          });

          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaign.length; i++) {
            allselectedCampaignsID.push(selectedCampaign[i].campID);
          }
          //alert("selected Campaign => " + allselectedCampaignsID);
          selectedCampaignsID["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsID: selectedCampaignsID,
            campaignMessage: campaignMessage,
          });

          HTML += "</table>";
          // let rejectedCount =
          // 	newsuppressionfilenames.length - successSuppressionCount.length; //Nilesh-2833-Removing console warning
          document.getElementById("suppressionList").innerHTML = HTML;
          // alert("RejectedCount==>" + RejectedCount)
          if (RejectedCount > 0) {
            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg = "";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }
          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author : Chaitanya Shinde
   * @description : This is used delete file function-4813
   */
  deleteFile(e, item) {
    e.preventDefault();
    let clickedFile = item.suppDocName;
    if (item.typeOfSuppDoc === "Suppression") {
      let suppressionFilesSelectedDocuments =
        this.state.suppressionFilesSelectedDocuments;

      const revisedSuppressionFilesSelectedDocuments =
        suppressionFilesSelectedDocuments.filter(
          (item) => item.suppDocName !== clickedFile
        );

      for (var k = 0; k < newsuppressionfilenames.length; k++) {
        if (newsuppressionfilenames[k] === clickedFile) {
          newsuppressionfilenames.splice(k, 1);
        }
      }
      //alert("newsuppressionfilenames.length  ==> "+newsuppressionfilenames.length)
      //Chaitanya-4902-If deleted all files, Submit button will be disabled.
      if (newsuppressionfilenames.length === 0) {
        document.getElementById("submitButton").disabled = true;
      } else {
        document.getElementById("submitButton").disabled = false;
      }

      this.setState({
        suppressionFilesSelectedDocuments:
          revisedSuppressionFilesSelectedDocuments,
        selectedCampaignsID: "",
        suppressionerror: "",
      });

      let message = document.getElementById("suppresionMessage");
      let msg = "";
      message.innerHTML = msg;
    }
  }

  /**
   * @author : Karan Jagtap
   * @description : This is used as a template to show files in suppression file section
   */
  suppressionFileSectionTemplate = (success, fileName) => {
    let imageSrc = "success.png";
    let bgColor = "#daecda";
    let txtColor = "green";
    if (!success) {
      imageSrc = "failed.png";
      bgColor = "#fbd5db";
      txtColor = "red";
    }
    return `<tr>
    <td style="background-color:${bgColor};color:${txtColor};border: 1px solid #ffffff;">
      <div class="row align-items-center mb-2 mx-0">
        <img src="${imageSrc}" alt="sucess" class="col-xs-2 col-md-auto p-0 m-0" style="height:18px;width:18px"/>
        <div class="col overflow-hidden">
          <label class="mb-0 ml-2 overflow-hidden align-middle" style="font-weight:normal;font-size:13px;">${fileName}</label>
        </div>
        <a class="col-xs-2 col-md-auto justify-content-center removeSuppressionFile" data-fileid="${fileName}">
          <img class="mb-0 mx-2" src="file_upload_2_blue.png" alt="sucess" height="18" width="18"/>
        </a>
      </div>
    </td>
  </tr>`;
  };

  uploadSuppressionFile(e) {
    e.preventDefault();
    //Rutuja 4983 checking image is enabled or disabled if diabled then will not allow to upload file
    if(this.state.imageDisabledSupp === false){
    suppressionnames = [];
    let suppressionerror = {};
    let self = this;
    e = document.createElement("input"); //sunita-task-3101-creating a input element but not binding it to body
    e.setAttribute("type", "file"); //sunita-task-3101-setting a attribute for type file
    e.setAttribute("multiple", true); //sunita-task-3101-setting a attribute for multiple
    e.click(); //sunita-task-3101-to open file dialouge
    e.addEventListener(
      "change",
      function () {
        //sunita-task-3101-calling an eventListener at change event
        self.setState({ displayTablesuppression: "block" });
        let message = document.getElementById("suppresionMessage");
        let msg = ""; //chaitanya-4867-changed suppresionMessage
        message.innerHTML = msg;
        let suppressionfilesData = e.files; //sunita-task-3101-getting filelist object.
        // alert("suppressionfilesData --->"+ JSON.stringify(suppressionfilesData))
        //Chaitanya-4851-Added below code to add newly uploaded file data into already uploaded file data
        let suppressionData = this.state.suppressionData;
        //if(suppressionObject.length>0){
        for (let p = 0; p < suppressionfilesData.length; p++) {
          let file = suppressionfilesData[p];

          let found = suppressionData.findIndex(
            (item) => item.fileName === file.name
          );
 
          if (found === -1) {
            let obj = {};
            obj.fileName = file.name;
            obj.typeOfSuppDoc = "Suppression";
            obj.fileData = file;
            suppressionData.push(obj);
          } else {
            let obj = suppressionData[found];
            obj.fileData = file;
            suppressionData[found] = obj;
          }
        }
        //Chaitanya-4851-End code here
        for (var i = 0, l = suppressionfilesData.length; i < l; i++) {
          suppressionnames[i] = suppressionfilesData[i].name;
        }

        var suppressionarrayLength = suppressionnames.length;
        var nosuppressionoutput = document.getElementById("nosuppressionList");
        var valuenosuppressionoutput =
          document.getElementById("nosuppressionList").innerHTML;
        // var suppressionres = new Array();
        var suppressionres = []; //Nilesh-2833-Removing console warning
        suppressionres = valuenosuppressionoutput.split(" ");
        var backsuppressionarraylength;
        for (i = 0; i < suppressionres.length; i++) {
          backsuppressionarraylength = suppressionres[0];
        }
        suppressionarrayLength = parseInt(suppressionarrayLength);

        var suppressionarrayLength1;
        suppressionarrayLength1 = suppressionarrayLength;

        if (suppressionarrayLength1 >= 1) {
          var allowedFiles = [".xls", ".xlsx", ".csv"];
          var suppression = suppressionfilesData;
          var regex = new RegExp(
            "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
          );
          if (!regex.test(suppression[0].name.toLowerCase())) {
            //sunita-task-3101-get the uploaded filename
            suppressionerror["suppressionerr"] =
              "Please upload files having extensions: " +
              allowedFiles.join(", ") +
              " only";
            this.setState({ suppressionerror: suppressionerror });
          } else {
            for (var j = 0; j < suppressionarrayLength; j++) {
              newsuppressionfilenames.push(suppressionnames[j]);
            }
            //alert("newsuppressionfilenames 1  =>"+newsuppressionfilenames)
            var FileExt;
            var HTML = "<table style='width:100%'>";
            // var imgsuccess; //Nilesh-2833-Removing console warning
            var countSuppressison = 0;
            let suppressionFilesArrayUI =
              self.state.suppressionFilesSelectedDocuments; //4823
            for (let j = 0; j < newsuppressionfilenames.length; j++) {
              //Nilesh-2833-Removing console warning
              if (document.getElementById("suppressionError") !== null) {
                document.getElementById("suppressionError").innerHTML = "";
              }
              FileExt = newsuppressionfilenames[j].split(".").pop();
              // var allowedFiles = [".xls", ".xlsx", ".csv"];  //Nilesh-2833-Removing console warning
              if (
                FileExt.toString() === "csv" ||
                FileExt.toString() === "xlsx" ||
                FileExt.toString() === "xls"
              ) {
                //Nilesh-2833-Removing console warning
              } else {
                countSuppressison = countSuppressison + 1;
              }
              var isExist = suppValidationArray.includes(
                newsuppressionfilenames[j]
              );

              let loading = true;
              let index = suppressionFilesArrayUI.findIndex(
                (item) =>
                  item.suppDocName === newsuppressionfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                /**** If uploaded file is wrong then it displays as in Red color */
                // User Story - 3427 - Karan Jagtap - commented old code
                // User Story - 3427 - Karan Jagtap - renders the file template
                // HTML += this.suppressionFileSectionTemplate(
                // 	false,
                // 	newsuppressionfilenames[j]
                // );

                toBeState = {
                  success: false,
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  //loading,
                };
              } else {
                toBeState = {
                  success: true,
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  //loading,
                };
              }

              suppressionFilesArrayUI = [
                ...suppressionFilesArrayUI.filter(
                  (item) => item.suppDocName !== toBeState.suppDocName
                ),
                toBeState,
              ];
            }

            self.setState({
              suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
            });

            HTML += "</table>";

            document.getElementById("suppressionList").innerHTML = HTML;
            if (countSuppressison > 0) {
              var msg3 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var suppressionErrorFile =
                document.getElementById("suppressionError");
              suppressionErrorFile.innerHTML += msg3;
            }

            $(document).on("click", ".removeSuppressionFile", function (e) {
              e.preventDefault();

              var clickedFile = $(this).parent().children("a").data("fileid");
              var j = 0;
              for (j; j < newsuppressionfilenames.length; j++) {
                var temp = j;
                if (newsuppressionfilenames[temp] === clickedFile) {
                  var isExist = suppValidationArray.includes(
                    newsuppressionfilenames[j]
                  );
                  if (isExist === true) {
                    var index = suppValidationArray.indexOf(
                      newsuppressionfilenames[j]
                    );
                    suppValidationArray.splice(index, 1);
                  }

                  // filename = newsuppressionfilenames[temp]; //Nilesh-2833-Removing console warning
                  newsuppressionfilenames.splice(j, 1);
                  // User Story - 3427 - Karan Jagtap - removing the <td> itself
                  $(this).closest("td").remove();
                  suppressionarrayLength = newsuppressionfilenames.length;
                  if (newsuppressionfilenames.length === 0) {
                    suppValidationArray = [];
                    //   rejectSuppressionCount=[];
                    // successSuppressionCount=[];
                  }

                  var msg1 = "";
                  // "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                  var message1 = document.getElementById("suppresionMessage");
                  if ($("#suppresionMessage").text() === "") {
                    message1.innerHTML += msg1;
                  } else {
                    message1.innerHTML = msg1;
                  }

                  if (newsuppressionfilenames.length === 0) {
                    //Nilesh-2833-Removing console warning
                    self.setState({
                      displayTablesuppression: "none",
                      disabled: "",
                    });
                  }

                  // var typeOfSuppDoc = "Suppression"; //Nilesh-2833-Removing console warning
                  /*** Remove File Code */
                  var suppressionfilesData = self.state.suppressionData;
                  let newSuppressionFileData = [];

                  for (let i = 0; i < suppressionfilesData.length; i++) {
                    if (
                      suppressionfilesData[i].name.toString() !==
                      clickedFile.toString()
                    ) {
                      newSuppressionFileData.push(suppressionfilesData[i]);
                    }
                  }

                  self.setState({ suppressionData: newSuppressionFileData });
                  suppressionarrayLength = parseInt(suppressionarrayLength);
                  backsuppressionarraylength = parseInt(
                    backsuppressionarraylength
                  );
                  suppressionarrayLength1 =
                    suppressionarrayLength + backsuppressionarraylength;

                  if (suppressionarrayLength1 > 0) {
                    nosuppressionoutput.innerHTML =
                      suppressionarrayLength1 + " Suppression  Files Selected";
                  } else {
                    nosuppressionoutput.innerHTML = "";
                    $("#suppression").val("");
                    var message = document.getElementById("suppresionMessage");
                    message.innerHTML = "";
                  }
                } // end of  if(names[temp] === clickedFile)
              } // end of for
            }); //$(document).on

            /** Add File Code*/
            suppressionerror["suppressionerr"] = "";
            document.getElementById("submitButton").disabled = false;
            this.setState({
              uploadedFile: true,
              suppressionFlag: true,
              suppressionData: suppressionData,
              disabled: "drpdisable",
              suppressionerror: suppressionerror,
            });
          }
        } else {
          nosuppressionoutput.innerHTML = "";
        }

        const input = suppressionfilesData;
        //Nilesh-2833- Used try catch block
        try {
          readXlsxFile(input[0].files[0])
            .then((rows) => {
              console.log(rows[0]);
              if (rows.length > 0) {
                if (rows[0].includes("Email") || rows[0].includes("email")) {
                  // Your logic here
                } else {
                  suppressionerror["headerError"] =
                    "Please upload file with valid header only";
                  this.setState({ suppressionerror: suppressionerror });
                  var HTML;
                  HTML = this.suppressionFileSectionTemplate(
                    false,
                    newsuppressionfilenames[0]
                  );
                  document.getElementById("suppressionList").innerHTML = HTML;
                }
              } else {
                if (rows.length === 0) {
                  //Nilesh-2833-Removing console warning
                  // var HTML; //Nilesh-2833-Removing console warning
                  HTML = this.suppressionFileSectionTemplate(
                    false,
                    newsuppressionfilenames[0]
                  );
                  document.getElementById("suppressionList").innerHTML = HTML;
                  suppressionerror["headerError"] =
                    "Please upload file with valid header only";
                  this.setState({ suppressionerror: suppressionerror });
                }
              }
            })
            .catch((error) => {
              console.error("Error reading XLSX file:", error);
            });
        } catch (error) {
          console.error("Error processing file input:", error);
        }
      }.bind(this)
    );
  }
  }

 

  /**
   * @author Chaitanya Shinde
   * @param  Description 4891 - Searching Campaigns by Id
   */
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // alert("filterData--->" + JSON.stringify(filterData))
    // If the search bar isn't empty
    if (e.target.value === "") {
      //Nilesh-4928- Key wise search bar set empty
      if (this.state.key === 1) {
        newList = this.state.campaignInfo;
      } else if(this.state.key === 2){
        newList = this.state.campaignInfoEx;
      }
       //Rutuja Task 4895 Key wise search bar set empty for abm tab
      else if(this.state.key === 3){
        newList = this.state.campaignInfoABM;
      }
       //Rutuja Task 4897 Key wise search bar set empty for other tab
       else if(this.state.key === 4){
        newList = this.state.campaignInfoother;
      }
    } else {
      //Nilesh-4928- Key wise search bar set data
    
      if (this.state.key === 1) {
        currentList = this.state.campaignInfo;
      } else if(this.state.key === 2){
        currentList = this.state.campaignInfoEx;
      }
       //Rutuja Task 4895
      else if(this.state.key === 3){
        currentList = this.state.campaignInfoABM;
      }
      //Rutuja Task 4897
      else if(this.state.key === 4){
        currentList = this.state.campaignInfoother;
      }
      newList = currentList.filter((item) => {
        let obj = {}; //Somnath Task-3972, Add specific Keys in search criteria
        obj[`campID`] = item.campID;
        obj[`campaignName`] = item.campaignName;
        obj[`startDate`] = item.startDate;
        obj[`endDate`] = item.endDate;
        obj[`leadAllocation`] = item.leadAllocation;
        obj[`allocatedLead`] = item.allocatedLead;
        if (this.state.tabName === "CampaignSetup") {
          obj[`advCampID`] = item.advCampID;
          obj[`advCampaignName`] = item.advCampaignName;
          obj[`startDate`] = item.startDate;
          obj[`endDate`] = item.endDate;
          obj[`advLeadAllocation`] = item.advLeadAllocation;
        }
        // change current item to lowercase
        const lc = obj;
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      campaignDetailsSearch: [...newList],
      tableSearchText: filterData,
    });
  }

  handleSelect(key) {
    this.setState({ key });
    if (key === 1) {
      this.getFirstTabData();
    } else if (key === 2) {
      this.getSecondTabData();
    } else if (key === 3) {
      this.getThirdTabData();
    } else if (key === 4) {
      this.getFourthTabData();
    }
  }

  getFirstTabData() {
    let key = 1;
    this.setState({
      loader2: "loader_review",
      key: key,
      campListSearch: "",
      tableSearchText: "",
      // campaignInfo: "",  
      // clientID: "" , 
    }); //Sandeep-task-3441-added code for loader
    //this.getCampaignList(key);
  }

  getSecondTabData() {
    let key = 2;
    this.setState({
      loader2: "loader_review",
      key: key,
      campListSearch: "",
      tableSearchText: "",
      // campaignInfo: "",
      // clientID: "", 
    }); //Sandeep-task-3441-added code for loader
    //this.getCampaignList(key);
  }

  getThirdTabData() {
    let key = 3;
    this.setState({
      loader2: "loader_review",
      key: key,
      campListSearch: "",
      tableSearchText: "",
    }); //Sandeep-task-3441-added code for loader
    //this.getCampaignList(key);
  }

  getFourthTabData() {
    let key = 4;
    this.setState({
      loader2: "loader_review",
      key: key,
      campListSearch: "",
      tableSearchText: "",
    }); //Sandeep-task-3441-added code for loader
    //this.getCampaignList(key);
  }

  //Nilesh-4896 below function used for delete exclusion file 
  deleteExclusionFile(e, item) {
    e.preventDefault();
    let clickedFile = item.suppDocName;
  
    if (item.typeOfSuppDoc === "Exclusion") {
      let exclusionFilesSelectedDocuments = this.state.exclusionFilesSelectedDocuments;

      const revisedExclusionFilesSelectedDocuments = exclusionFilesSelectedDocuments.filter(
        (item) => item.suppDocName !== clickedFile
      );
  
      //Nilesh-4927- Remove the clicked file from the exclusion array
      newexclusionfilenames = newexclusionfilenames.filter((name) => name !== clickedFile);
      chkExclusionExist = chkExclusionExist.filter((name) => name !== clickedFile);
      
      for (var k = 0; k < newexclusionfilenames.length; k++) {
        if (newexclusionfilenames[k] === clickedFile) {
          newexclusionfilenames.splice(k, 1);
        }
      }

      //Nilesh-4927- if newexclusionfilenames is empty to disable the submit button
      if (newexclusionfilenames.length === 0) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({
          submitExButtonDisabled: true
        })
      } else {
        this.setState({
          submitExButtonDisabled: false
        })
      }

      this.setState({
        exclusionFilesSelectedDocuments: revisedExclusionFilesSelectedDocuments,
        selectedCampaignsIDEx: "", //4928
        exclusionerr: "",
      });
  
      let message = document.getElementById("exclusionMessage");
      let msg = "";
      message.innerHTML = msg;
      
    }
  }
  

  //Nilesh-4896 Use function for upload exclusion file
  uploadExclusionFile(e) {
     //Rutuja 4983 checking image is enabled or disabled if diabled then will not allow to upload file
    if(this.state.imageDisabledExl === false){
    execlusionnames = [];
    let exclusionerror = {};
    let self = this;

    e = document.createElement("input"); //creating a input element but not binding it to body added
    e.setAttribute("type", "file"); //setting a attribute for type file added
    e.setAttribute("multiple", true); //setting a attribute for multiple added
    e.click(); //to open file dialougeadded
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added

       // var parsed = this.props.location.state;
        // alert("parsed--->" + JSON.stringify(parsed))
       // if (parsed !== undefined) {
          var campID;
          
          // if (parsed.hasOwnProperty("campID")) {
          //   campID = parsed.campID;
          // }
          // let msg = ""; 
          let message = document.getElementById("exclusionMessage");
          let msg = ""; //Nilesh-4933-changed exclusionMessage
          message.innerHTML = msg; 
          self.setState({ displayTableexclusion: "block" });
          let execlusionfilesData = e.files;
          let exclusionData = this.state.exclusionData
          for (let p = 0; p < execlusionfilesData.length; p++) {
            let file = execlusionfilesData[p];
  
            let found = exclusionData.findIndex(
              (item) => item.fileName === file.name
            );

            if (found === -1) {
              let obj = {};
              obj.fileName = file.name;
              obj.typeOfSuppDoc = "Exclusion";
              obj.fileData = file;
              exclusionData.push(obj);
            } else {
              let obj = exclusionData[found];
              obj.fileData = file;
              exclusionData[found] = obj;
            }
          }
          // Rutuja task 4952 -commented below code  as it is adding file count
          // for (let i = 0, l = execlusionfilesData.length; i < l; i++) {
          //   let fileName = execlusionfilesData[i].name;
          //   if (chkExclusionExist.includes(fileName)) {
          //     duplicateExclusionFile.push(fileName);
          //   } else {
          //     execlusionnames.push(fileName);
          //     exclusionData.push({
          //       fileName: fileName,
          //       fileData: execlusionfilesData[i],
          //       fileType: execlusionfilesData[i].type,
          //   });
          //   }
          // }
          // if (chkExclusionExist != execlusionnames) {
          //   rfpChange++;
          // }
          // Rutuja task 4952 -added below code  
          for (var i = 0, l = execlusionfilesData.length; i < l; i++) {
            execlusionnames[i] = execlusionfilesData[i].name;
           }

          /**
           * @author Sanjana Godbole
           * @param  A FileList object that lists every selected file
           * @return List of uploded file along with remove link
           */
          // var link;
          // var filename = "";
            var execlusionarrayLength = execlusionnames.length;
            // alert("execlusionarrayLength-->" + execlusionarrayLength)
            var nooutputexeclusion = document.getElementById("noexclusionList");
            var valuenooutputexeclusion =
              document.getElementById("noexclusionList").innerHTML;

            var resexeclusion = [];
            resexeclusion = valuenooutputexeclusion.split(" ");

            var backecelusionarraylength;
            for (let i = 0; i < resexeclusion.length; i++) {
              backecelusionarraylength = resexeclusion[0];
            }
            // alert("backecelusionarraylength-->" + backecelusionarraylength)
            // self.setState({
            //   backExclusionArrayLengthForUI: backecelusionarraylength,
            // });

            execlusionarrayLength = parseInt(execlusionarrayLength);
            backecelusionarraylength = parseInt(backecelusionarraylength);
            //Nilesh-4928 assign execlusionarrayLength 
            var arrayexeclusionLength1;
            arrayexeclusionLength1 = execlusionarrayLength;
          if (arrayexeclusionLength1 > 0) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            for (let i = 0; i < execlusionnames.length; i++) {
              if (!regex.test(execlusionnames[i].toLowerCase())) {
                exclusionerror["exclusionerr"] =
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";
                this.setState({
                  exclusionerror: exclusionerror,
                });
                return;
              }
            }

            for (var j = 0; j < execlusionarrayLength; j++) {
              newexclusionfilenames.push(execlusionnames[j]);
              chkExclusionExist.push(execlusionnames[j]);
            }

            var HTML = "<table>";
            var FileExt;
            var countExclusion = 0;
            var imgsuccess;
            let exclusionFilesArrayUI =
              self.state.exclusionFilesSelectedDocuments;
            // alert("exclusionFilesArrayUI ====>>" + JSON.stringify(exclusionFilesArrayUI))
            for (var j = 0; j < newexclusionfilenames.length; j++) {
              if (document.getElementById("exclusionError") !== null) {
                document.getElementById("exclusionError").innerHTML = "";
              }

              FileExt = newexclusionfilenames[j].split(".").pop();

              var allowedFiles = [".xls", ".xlsx", ".csv"];
              if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              } else {
                countExclusion = countExclusion + 1;
              }

              var isExist = exclusionValidationArray.includes(
                newexclusionfilenames[j]
              );

              let loading = true;
              let index = exclusionFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newexclusionfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newexclusionfilenames[j],
                  suppDocName: newexclusionfilenames[j],
                  typeOfSuppDoc: "Exclusion",
                   loading,
                };
              } else {
                toBeState = {
                  success: true,
                   dataFileID: newexclusionfilenames[j],
                  suppDocName: newexclusionfilenames[j],
                  typeOfSuppDoc: "Exclusion",
                   loading,
                };
              }
              exclusionFilesArrayUI = [
                ...exclusionFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            // self.setState({
            //   exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
            //   submitExButtonDisabled: false 
            // });

            if(exclusionFilesArrayUI.length > 1){
              // alert("hiiii 22")
              var uniqueSuppDocNames = {};
               //Nilesh-4928 Use filter to create a new array with unique suppDocNames
                  var newArray = exclusionFilesArrayUI.filter(item => {
                  if (!uniqueSuppDocNames[item.suppDocName]) {
              //Nilesh-4928 If the suppDocName is not in the temporary object, add it and return true to keep the item
                  uniqueSuppDocNames[item.suppDocName] = true;
                return true;
            }
                return false;
             });
             self.setState({
               exclusionFilesSelectedDocuments: newArray,
               submitExButtonDisabled: false //4927
             });
  
               }else{
                self.setState({
                  exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
                  submitExButtonDisabled: false //4927
                });              
            }

            HTML += "</table>";

            // commented by karan
            // document.getElementById("exclusionList").innerHTML = HTML;
            if (countExclusion > 0) {
              var msg2 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var exclusionErrorFile =
                document.getElementById("exclusionError");
               

              exclusionErrorFile.innerHTML += msg2;
            }

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);

            for (var i = 0, l = execlusionfilesData.length; i < l; i++) {
              let fileName = execlusionfilesData[i].name;
              if (duplicateExclusionFile.includes(fileName)) {
              } else {
                data.append("file", execlusionfilesData[i]);
              }
            }

          //  document.getElementById("submitButton").disabled = false;
           this.state.submitExButtonDisabled = false
            this.setState({
              uploadedFileEx: true,
              suppressionFlag: true,
              exclusionData: exclusionData,
              disabled: "drpdisable",
              exclusionerror: exclusionerror,
            });
          
            this.setState({
              files: data,
              exclusionerror: "",
            });
            //  alert("files Ex--->"+ JSON.stringify(this.state.files))
            nooutputexeclusion.innerHTML = arrayexeclusionLength1;
          } else {
            nooutputexeclusion.innerHTML = " No Exclusion File Selected";
          }
       // }
      }.bind(this)
    );
  }
  }

 


  

  //Nilesh-4896 below function used for submit exclusion file 
  submitExclusionFile(e) {
    let exclusionData = [];
    var data = new FormData();
    exclusionData = this.state.exclusionData;
    let length = this.state.exclusionData.length;
  
    data.append("campaignInfoEx", JSON.stringify(selectedCampaignEx)); //4928
    for (let i = 0; i < length; i++) {
      let fileName = exclusionData[i].fileName;
      if (newexclusionfilenames.includes(fileName))
        data.append("exclusionData", exclusionData[i].fileData);
    }
  
    fetch("/uploadCommonSupportingDocuments/uploadCommonExclusion", { //Rutjja task 4895 change file name from uploadCommenSupportingDocuments touploadCommonSupportingDocuments 
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((exclusionList) => {
        // alert("exclusionList--->" + JSON.stringify(exclusionList))
        this.setState({ loader_progress: "" ,imageDisabledExl:false }); // Rutuja task 4983 upload image enabled

        let msg = ""; //Nilesh-2833-Removing console warning
        let message = document.getElementById("exclusionMessage");
        let selectedCampaignsIDEx = []; //4928
        let allselectedCampaignsID = [];
        let campaignMessage = "";
        let supportDocID

        if (exclusionList[0].success === true) {
          var suppDocName = [];
          for (var i = 0; i < exclusionList.length; i++) {
            if (exclusionList[i].success === true) {
              suppDocName.push(exclusionList[i].fileName);
              successExclusionCount.push(exclusionList[i].fileName);
            }
          }
          suppDocName = [...new Set(suppDocName)];

          let RejectedCountEx = 0; //Nilesh-4933- Add variable for rejected files
          var HTML2 = "<table style='width:100%'>";
          let exclusionFilesArrayUI = this.state.exclusionFilesSelectedDocuments.slice(); // Create a copy of the array
          // let exclusionFilesArrayUI = 
          //   this.state.exclusionFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning
          for (let j = 0; j < newexclusionfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isExist = suppDocName.includes(newexclusionfilenames[j]);

            let toBeState;
            if (isExist === true) {
              toBeState = {
                success: true,
                suppDocName: newexclusionfilenames[j],
                typeOfSuppDoc: "Exclusion",
                //loading: false,
              };
              /**** If uploaded file is wrong then it displays as in Red color */
              // var HTML; //Nilesh-2833-Removing console warning

              // HTML += this.suppressionFileSectionTemplate(
              // 	false,
              // 	newexclusionfilenames[j]
              // );
            } else {
              RejectedCountEx = RejectedCountEx + 1;

              toBeState = {
                success: false,
                suppDocName: newexclusionfilenames[j],
                typeOfSuppDoc: "Exclusion",
                //loading: false,
              };
            }

            exclusionFilesArrayUI = [
              ...exclusionFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
          });

          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignEx.length; i++) {
            allselectedCampaignsID.push(selectedCampaignEx[i].campID);
          }
          //alert("selected Campaign => " + allselectedCampaignsID);

          selectedCampaignsIDEx["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDEx: selectedCampaignsIDEx,
            campaignMessage: campaignMessage,
          });

          HTML2 += "</table>";
          document.getElementById("noexclusionList").innerHTML = HTML2;
          if (RejectedCountEx > 0) {
            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg =
              "<font color = 'Green'>Exclusion File Submitted Successfully</font>";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }

          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        } else {
          var isError = exclusionList[0].Error;
          for (let i = 0; i < exclusionList.length; i++) {
            //Nilesh-2833-Removing console warning
            if (exclusionList[i].success === false) {
              exValidationArray.push(exclusionList[i].fileName);
            }
          }

          let HTML = "<table style='width:100%'>"; //Nilesh-2833-Removing console warning
          let exclusionFilesArrayUI =
            this.state.exclusionFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning

          let RejectedCountEx = 0;

          for (let j = 0; j < newexclusionfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isValid = exValidationArray.includes(
              newexclusionfilenames[j]
            );
            let toBeState;
            if (isValid === true) {
              RejectedCountEx++;
              toBeState = {
                success: false,
                supportDocID,
                suppDocName: newexclusionfilenames[j],
                typeOfSuppDoc: "Exclusion",
                loading: false,
              };
            } else {
              toBeState = {
                success: true,
                supportDocID,
                suppDocName: newexclusionfilenames[j],
                typeOfSuppDoc: "Exclusion",
                loading: false,
              };

              let isTrue = successExclusionCount.includes(
                newexclusionfilenames[j]
              );
              if (isTrue === false) {
                successExclusionCount.push(newexclusionfilenames[j]);
              }
            }
            exclusionFilesArrayUI = [
              ...exclusionFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            exclusionFilesSelectedDocuments: exclusionFilesArrayUI,
          });
          //  alert("exclusionFilesSelectedDocuments === " + JSON.stringify(this.state.exclusionFilesSelectedDocuments))
          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignEx.length; i++) {
            allselectedCampaignsID.push(selectedCampaignEx[i].campID);
          }
          //alert("selected Campaign => " + allselectedCampaignsID);
          selectedCampaignsIDEx["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDEx: selectedCampaignsIDEx,
            campaignMessage: campaignMessage,
          });

          HTML += "</table>";
          // let rejectedCount =
          // 	newexclusionfilenames.length - successSuppressionCount.length; //Nilesh-2833-Removing console warning
          document.getElementById("noexclusionList").innerHTML = HTML;
          // alert("RejectedCountEx ---->" + RejectedCountEx)
          if (RejectedCountEx > 0) {
            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg = "";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }
          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  //Nilesh-4896 below function used for submit exclusion file 
  submitExFile(e) {
    e.preventDefault(); //Nilesh-2833-Removing console warning
    let clientName = this.state.clientName;
    var alertmessage = "";

    //Chaitanya-4847-Changed Error message for submitting without selecting Advertiser.
    if (clientName === "") {
      alertmessage = "Please Select All Mandatory Fields.";

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      var campInfoStatus = false;
      let uploadedFileEx = this.state.uploadedFileEx;
      //  alert("uploadedFileEx--->" + JSON.stringify(uploadedFileEx))
      if (selectedCampaignEx.length > 0) { //Nilesh-4928
        campInfoStatus = true;
      } else {
        alertmessage = "Please Select Campaign from list.";
      }

      if (uploadedFileEx === false && selectedCampaignEx.length === 0) {
        alertmessage = "Please Select Campaign from list And Upload File.";
      } else if (uploadedFileEx === false) {
        alertmessage = "Please Upload File.";
      }

      if (uploadedFileEx === true && campInfoStatus === true) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({ 
          loader_progress: "loader_campaign_list" ,
          submitExButtonDisabled: true, //Nilesh-4896 Used for button disabled after file submit
          imageDisabledExl:true, // Rutuja task 4983 upload image disabled
      });
        if (this.state.suppressionFlag === true) {
          this.submitExclusionFile();
        }
      } else {
        Swal.fire({
          text: alertmessage,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  } 
 /**
   * @author : Rutuja Jagtap
   * @description : This is used submit file function-4895
   */


  uploadABMFile(e) {
     //Rutuja 4983 checking image is enabled or disabled if diabled then will not allow to upload file
    if(this.state.imageDisabledAbm === false){
    e.preventDefault();
    abmnames = [];
    let abmerror = {};
    let self = this;
    e = document.createElement("input"); //creating a input element but not binding it to body added
    e.setAttribute("type", "file"); //setting a attribute for type file added
    e.setAttribute("multiple", true); //setting a attribute for multiple added
    e.click(); //to open file dialougeadded
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added

       // var parsed = this.props.location.state;
        // alert("parsed--->" + JSON.stringify(parsed))
       // if (parsed !== undefined) {
        var campID;
          
        // if (parsed.hasOwnProperty("campID")) {
        //   campID = parsed.campID;
        // }
        let message = document.getElementById("abmMessage");
        let msg = ""; 
        message.innerHTML = msg; 
        self.setState({ displayTableabm: "block" });
        let abmfilesData = e.files;
        let abmData = this.state.abmData
        for (let p = 0; p < abmfilesData.length; p++) {
          let file = abmfilesData[p];

          let found = abmData.findIndex(
            (item) => item.fileName === file.name
          );
        
          if (found === -1) {
            let obj = {};
            obj.fileName = file.name;
            obj.typeOfSuppDoc = "ABM";
            obj.fileData = file;
            abmData.push(obj);
          } else {
            let obj = abmData[found];
            obj.fileData = file;
            abmData[found] = obj;
          }
        }
          // Rutuja task 4952 -commented below code  as it is adding file count
        // for (let i = 0, l = abmfilesData.length; i < l; i++) {
        //   let fileName = abmfilesData[i].name;
        //   if (chkABMExist.includes(fileName)) {
        //     duplicateabmFile.push(fileName);
        //   } else {
        //     abmnames.push(fileName);
        //     abmData.push({
        //       fileName: fileName,
        //       fileData: abmfilesData[i],
        //       fileType: abmfilesData[i].type,
        //   });
        //   }
        // }

        //   if (chkABMExist != abmnames) {
        //     rfpChange++;
        //   }
      // Rutuja task 4952 -added below code
        for (var i = 0, l = abmfilesData.length; i < l; i++) {
         abmnames[i] = abmfilesData[i].name;
        }
     
          
          var abmarrayLength = abmnames.length;
          var nooutputabm = document.getElementById("noabmList");
          var valuenooutputabm =
            document.getElementById("noabmList").innerHTML;

          var reabm = [];
          reabm = valuenooutputabm.split(" ");

          var backabmarraylength;
          for (let i = 0; i < reabm.length; i++) {
            backabmarraylength = reabm[0];
          }
          // self.setState({
          //   backabmArrayLengthForUI: backabmarraylength,
          // });

          abmarrayLength = parseInt(abmarrayLength);
          backabmarraylength = parseInt(backabmarraylength);

          var arrayabmLength1 ;
          arrayabmLength1 = abmarrayLength;
          if (arrayabmLength1 > 0) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            for (let i = 0; i < abmnames.length; i++) {
              if (!regex.test(abmnames[i].toLowerCase())) {
                abmerror["abmerr"] = //Rutuja Task 4937 changed abmnerr to abmerr for displaying below error
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";
                this.setState({
                 abmerror: abmerror,
                });
                return;
              }
            }

            for (var j = 0; j < abmarrayLength; j++) {
              newabmfilenames.push(abmnames[j]);
              chkABMExist.push(abmnames[j]);
            }

            var HTML = "<table>";
            var FileExt;
            var countABM = 0;
            var imgsuccess;
            let abmFilesArrayUI =
              self.state.abmFilesSelectedDocuments;
            for (var j = 0; j < newabmfilenames.length; j++) {
              if (document.getElementById("abmError") !== null) {
                document.getElementById("abmError").innerHTML = "";
              }

              FileExt = newabmfilenames[j].split(".").pop();

              var allowedFiles = [".xls", ".xlsx", ".csv"];
              if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              } else {
                countABM = countABM + 1;
              }

              var isExist = abmValidationArray.includes(
                newabmfilenames[j]
              );

              let loading = true;
              let index = abmFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newabmfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newabmfilenames[j],
                  suppDocName: newabmfilenames[j],
                  typeOfSuppDoc: "ABM",
                  loading,
                };
              } else {
                toBeState = {
                  success: true,
                  dataFileID: newabmfilenames[j],
                  suppDocName: newabmfilenames[j],
                  typeOfSuppDoc: "ABM",
                  loading,
                };
              }
              abmFilesArrayUI = [
                ...abmFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            // self.setState({
            //   abmFilesSelectedDocuments: abmFilesArrayUI,
            // });
            if(abmFilesArrayUI.length > 1){
              var uniqueSuppDocNames = {};
                  var newArray = abmFilesArrayUI.filter(item => {
                  if (!uniqueSuppDocNames[item.suppDocName]) {
                  uniqueSuppDocNames[item.suppDocName] = true;
                return true;
            }
                return false;
             });
             
             self.setState({
               abmFilesSelectedDocuments: newArray,
               submitABMButtonDisabled: false 
             });
  
               }else{
                self.setState({
                  abmFilesSelectedDocuments: abmFilesArrayUI,
                  submitABMButtonDisabled: false 
                });              
            }
            
            HTML += "</table>";

            // commented by karan
            // document.getElementById("exclusionList").innerHTML = HTML;
            if (countABM > 0) {
              var msg2 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var abmErrorFile =
                document.getElementById("abmError");
               

             abmErrorFile.innerHTML += msg2;
            }

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);

            for (var i = 0, l = abmfilesData.length; i < l; i++) {
              let fileName = abmfilesData[i].name;
              if (duplicateABMFile.includes(fileName)) {
              } else {
                data.append("file", abmfilesData[i]);
              }
            }

         //  document.getElementById("submitButton").disabled = false;
         this.state.submitABMButtonDisabled = false
            this.setState({
              uploadedFileabm: true,
              abmFlag: true,
              abmData: abmData,
              disabled: "drpdisable",
              abmerror: abmerror,
            });
          //  alert("exclusionData ===============>" + JSON.stringify(this.state.exclusionData))
          //   alert("exclusionFilesSelectedDocuments--->" + JSON.stringify(this.state.exclusionFilesSelectedDocuments))
            this.setState({
              files: data,
              abmerror: "",
            });
            // alert("files Ex--->"+ JSON.stringify(this.state.files))
            nooutputabm.innerHTML = arrayabmLength1;
          } else {
            nooutputabm.innerHTML = " No ABM File Selected";
          }
       // }
      }.bind(this)
    );
  }}
   /**
   * @author : Rutuja Jagtap
   * @description : This is used submit file function-4895
   */
  submitABMFile(e) {
    e.preventDefault(); 
    let clientName = this.state.clientName;
    var alertmessage = "";
    //-Changed Error message for submitting without selecting Advertiser.
    if (clientName === "") {
      alertmessage = "Please Select All Mandatory Fields.";

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      var campInfoStatus = false;
      let uploadedFileabm = this.state.uploadedFileabm;
      if (selectedCampaignABM.length > 0) {
        campInfoStatus = true;
      } else {
        alertmessage = "Please Select Campaign from list.";
      }

      if (uploadedFileabm === false && selectedCampaignABM.length === 0) {
        alertmessage = "Please Select Campaign from list And Upload File.";
      } else if (uploadedFileabm === false) {
        alertmessage = "Please Upload File.";
      }

      if (uploadedFileabm === true && campInfoStatus === true) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({ 
          loader_progress1: "loader_campaign_list" ,
          submitABMButtonDisabled: true, 
          imageDisabledAbm: true // Rutuja task 4983 upload image disabled
      });
        if (this.state.abmFlag === true) {
          this.submitABM1File();
        }
      } else {
        Swal.fire({
          text: alertmessage,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  } 
 /**
   * @author : Rutuja Jagtap
   * @description : This is used submit file function-4895
   */
  submitABM1File(e) {
    let abmData = [];
    var data = new FormData();
    abmData = this.state.abmData;
    let length = this.state.abmData.length;
    data.append("campaignInfoABM", JSON.stringify(selectedCampaignABM));
    for (let i = 0; i < length; i++) {
      let fileName = abmData[i].fileName;
      if (newabmfilenames.includes(fileName))
        data.append("abmData", abmData[i].fileData);
    }
    fetch("/uploadCommonSupportingDocuments/uploadCommonABM", { //Rutjja task 4895 change file name from uploadCommenSupportingDocuments touploadCommonSupportingDocuments 
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((abmList) => {
        this.setState({ loader_progress1: "" ,imageDisabledAbm:false}); // Rutuja task 4983 upload image enabled

        let msg=""; 
        let message = document.getElementById("abmMessage");
        let selectedCampaignsIDABM = [];
        let allselectedCampaignsID = [];
        let campaignMessage = "";
        let supportDocID
        if (abmList[0].success === true) {
          var suppDocName = [];
          for (var i = 0; i < abmList.length; i++) {
            if (abmList[i].success === true) {
              suppDocName.push(abmList[i].fileName);
              successABMCount.push(abmList[i].fileName);
            }
          }
          suppDocName = [...new Set(suppDocName)];

          let RejectedCountabm = 0;
          var HTML2 = "<table style='width:100%'>";

          let abmFilesArrayUI = this.state.abmFilesSelectedDocuments.slice();
        
          for (let j = 0; j < newabmfilenames.length; j++) {
            var isExist = suppDocName.includes(newabmfilenames[j]);

            let toBeState;
            if (isExist === true) {
              //alert("cross")
              toBeState = {
                success: true,
                suppDocName: newabmfilenames[j],
                typeOfSuppDoc: "ABM",
                //loading: false,
              };
              /**** If uploaded file is wrong then it displays as in Red color */
              // var HTML; //Nilesh-2833-Removing console warning

              // HTML += this.suppressionFileSectionTemplate(
              // 	false,
              // 	newexclusionfilenames[j]
              // );
            } else {
              RejectedCountabm = RejectedCountabm + 1;

              toBeState = {
                success: false,
                suppDocName: newabmfilenames[j],
                typeOfSuppDoc: "ABM",
                //loading: false,
              };
            }

            abmFilesArrayUI = [
              ...abmFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            abmFilesSelectedDocuments: abmFilesArrayUI,
          });

          //-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignABM.length; i++) {
            allselectedCampaignsID.push(selectedCampaignABM[i].campID);
          }
          selectedCampaignsIDABM["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDABM: selectedCampaignsIDABM,
            campaignMessage: campaignMessage,
          });

          HTML2 += "</table>";
          document.getElementById("noabmList").innerHTML = HTML2;
          if (RejectedCountabm > 0) {
            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg =
              "<font color = 'Green'>ABM File Submitted Successfully</font>";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }

          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        } else {
          var isError = abmList[0].Error;
          for (let i = 0; i < abmList.length; i++) {
            if (abmList[i].success === false) {
              abmValidationArray.push(abmList[i].fileName);
            }
          }

          let HTML = "<table style='width:100%'>"; //Nilesh-2833-Removing console warning
          let abmFilesArrayUI = this.state.abmFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning

          let RejectedCountabm = 0;

          for (let j = 0; j < newabmfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isValid = abmValidationArray.includes(
              newabmfilenames[j]
            );
            let toBeState;
            if (isValid === true) {
              RejectedCountabm++;
              toBeState = {
                success: false,
                supportDocID,
                suppDocName: newabmfilenames[j],
                typeOfSuppDoc: "ABM",
                loading: false,
              };
            } else {
              toBeState = {
                success: true,
                supportDocID,
                suppDocName: newabmfilenames[j],
                typeOfSuppDoc: "ABM",
                loading: false,
              };

              let isTrue = successAbmCount.includes(
                newabmfilenames[j]
              );
              if (isTrue === false) {
                successAbmCount.push(newabmfilenames[j]);
              }
            }
            abmFilesArrayUI = [
              ...abmFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            abmFilesSelectedDocuments: abmFilesArrayUI,
          });

          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignABM.length; i++) {
            allselectedCampaignsID.push(selectedCampaignABM[i].campID);
          }
          //alert("selected Campaign => " + selectedCampaignsIDABM);
          selectedCampaignsIDABM["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDABM: selectedCampaignsIDABM,
            campaignMessage: campaignMessage,
          });

          HTML += "</table>";
          // let rejectedCount =
          // 	newexclusionfilenames.length - successSuppressionCount.length; //Nilesh-2833-Removing console warning
          document.getElementById("noabmList").innerHTML = HTML;
          if (RejectedCountabm > 0) {
            msg = "<font color='Red'>Please Upload Valid Header File</font>"; //Nilesh-2833-Removing console warning
          } else {
            msg = "";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }
          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
 /**
   * @author : Rutuja Jagtap
   * @description : This is used to delete ABM file -4895
   */
  deleteABMFile(e, item) {
    e.preventDefault();
    let clickedFile = item.suppDocName;
  
    if (item.typeOfSuppDoc === "ABM") {
      let abmFilesSelectedDocuments = this.state.abmFilesSelectedDocuments;

      const revisedabmFilesSelectedDocuments = abmFilesSelectedDocuments.filter(
        (item) => item.suppDocName !== clickedFile
      );
  
      // Remove the clicked file from the exclusion array
      newabmfilenames = newabmfilenames.filter((name) => name !== clickedFile);
      chkABMExist = chkABMExist.filter((name) => name !== clickedFile);
      
      for (var k = 0; k < newabmfilenames.length; k++) {
        if (newabmfilenames[k] === clickedFile) {
          newabmfilenames.splice(k, 1);
        }
      }

      // if newexclusionfilenames is empty to disable the submit button
      if (newabmfilenames.length === 0) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({
          submitABMButtonDisabled: true //4940
        })
      } else {
        this.setState({
          submitABMButtonDisabled: false //4940
        })
      }
      this.setState({
        abmFilesSelectedDocuments: revisedabmFilesSelectedDocuments,
        selectedCampaignsIDABM: "", 
        abmerr: "",
      });
  
      let message = document.getElementById("abmMessage");
      let msg = "";
      message.innerHTML = msg;
      
    }
  }
  /**
   * @author : Rutuja Jagtap
   * @description : This is used submit other file -4897
   */
  uploadOtherFile(e) {
     //Rutuja 4983 checking image is enabled or disabled if diabled then will not allow to upload file
    if(this.state.imageDisabledOther === false){
    e.preventDefault();
    othernames = [];
    let othererror = {};
    let self = this;
    e = document.createElement("input"); //creating a input element but not binding it to body added
    e.setAttribute("type", "file"); //setting a attribute for type file added
    e.setAttribute("multiple", true); //setting a attribute for multiple added
    e.click(); //to open file dialougeadded
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added

       // var parsed = this.props.location.state;
        // alert("parsed--->" + JSON.stringify(parsed))
       // if (parsed !== undefined) {
        var campID;
          
        // if (parsed.hasOwnProperty("campID")) {
        //   campID = parsed.campID;
        // }
        let message = document.getElementById("otherMessage");
        let msg = ""; 
        message.innerHTML = msg; 
        self.setState({ displayTableother: "block" });
        let otherfilesData = e.files;
        let otherData = [new Set(...(this.state.otherData))]
      
        for (let p = 0; p < otherfilesData.length; p++) {
          let file = otherfilesData[p];

          let found = otherData.findIndex(
            (item) => item.fileName === file.name
          );
          if (found === -1) {
            let obj = {};
            obj.fileName = file.name;
            obj.typeOfSuppDoc = "Other";
            obj.fileData = file;
            otherData.push(obj);
          } else {
            let obj = otherData[found];
            obj.fileData = file;
            otherData[found] = obj;
          }
        }
          // Rutuja task 4952 -commented below code  as it is adding file count
        // for (let i = 0, l = otherfilesData.length; i < l; i++) {
        //   let fileName = otherfilesData[i].name;
        //   if (chkotherExist.includes(fileName)) {
        //     duplicateotherFile.push(fileName);
        //   } else {
        //     othernames.push(fileName);
        //     otherData.push({
        //       fileName: fileName,
        //       fileData: otherfilesData[i],
        //       fileType: otherfilesData[i].type,
        //   });
        //   }
        // }

        //   if (chkotherExist != othernames) {
        //     rfpChange++;
        //   }
       // Rutuja task 4952 -Added below code 
        for (var i = 0, l = otherfilesData.length; i < l; i++) {
          othernames[i] = otherfilesData[i].name;
         }
          
          var otherarrayLength = othernames.length;
          var nooutputother = document.getElementById("nootherList");
          var valuenooutputother =
            document.getElementById("nootherList").innerHTML;
            
          var reother = [];
          reother = valuenooutputother.split(" ");

          var backotherarraylength;
          for (let i = 0; i < reother.length; i++) {
            backotherarraylength = reother[0];
          }
          // self.setState({
          //   backotherArrayLengthForUI: backotherarraylength,
          // });

          otherarrayLength = parseInt(otherarrayLength);
          backotherarraylength = parseInt(backotherarraylength);
        

          var arrayotherLength1 ;
          arrayotherLength1 = otherarrayLength;
          if (arrayotherLength1 > 0) {
            // var allowedFiles = [".xls", ".xlsx", ".csv"];
            // var regex = new RegExp(
            //   "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            // );
            // for (let i = 0; i < othernames.length; i++) {
            //   if (!regex.test(othernames[i].toLowerCase())) {
               
            //     othererror["othererr"] =
            //       "Please upload files having extensions: " +
            //       allowedFiles.join(", ") +
            //       " only";
            //     this.setState({
            //      othererror: othererror,
            //     });
            //     return;
            //   }
            // }

            for (var j = 0; j < otherarrayLength; j++) {
              newotherfilenames.push(othernames[j]);
              chkotherExist.push(othernames[j]);
            }

            var HTML = "<table>";
            var FileExt;
            var countother = 0;
            var imgsuccess;
            let otherFilesArrayUI =
              self.state.otherFilesSelectedDocuments;
            for (var j = 0; j < newotherfilenames.length; j++) {
              if (document.getElementById("otherError") !== null) {
                document.getElementById("otherError").innerHTML = "";
              }

              //FileExt = newotherfilenames[j].split(".").pop();

              // var allowedFiles = [".xls", ".xlsx", ".csv"];
              // if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
              // } else {
                countother = countother + 1;
              //}

              var isExist = otherValidationArray.includes(
                newotherfilenames[j]
              );

              let loading = true;
              let index = otherFilesArrayUI.findIndex(
                (item) =>
                  item.dataFileID === newotherfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  dataFileID: newotherfilenames[j],
                  suppDocName: newotherfilenames[j],
                  typeOfSuppDoc: "Other",
                  loading,
                };
              } else {
                toBeState = {
                  success: true,
                  dataFileID: newotherfilenames[j],
                  suppDocName: newotherfilenames[j],
                  typeOfSuppDoc: "Other",
                  loading,
                };
              }
              otherFilesArrayUI = [
                ...otherFilesArrayUI.filter(
                  (item) => item.dataFileID !== toBeState.dataFileID
                ),
                toBeState,
              ];
            }
            // self.setState({
            //   otherFilesSelectedDocuments: otherFilesArrayUI,
            // });
            if(otherFilesArrayUI.length > 1){
              var uniqueSuppDocNames = {};
                  var newArray = otherFilesArrayUI.filter(item => {
                  if (!uniqueSuppDocNames[item.suppDocName]) {
                  uniqueSuppDocNames[item.suppDocName] = true;
                return true;
            }
                return false;
             });
             
             self.setState({
               otherFilesSelectedDocuments: newArray,
               submitotherButtonDisabled: false 
             });
  
               }else{
                self.setState({
                  otherFilesSelectedDocuments: otherFilesArrayUI,
                  submitotherButtonDisabled: false 
                });              
            }
            
            HTML += "</table>";

            // commented by karan
            // document.getElementById("exclusionList").innerHTML = HTML;
            // if (countother > 0) {
            //   var msg2 =
            //     "Please upload files having extensions: " +
            //     allowedFiles.join(", ") +
            //     " only";
            //   var otherErrorFile =
            //     document.getElementById("otherError");
               

            //  otherErrorFile.innerHTML += msg2;
            // }

            var data = new FormData();
            data.append("campID", campID);
            //data.append("names",names);

            for (var i = 0, l = otherfilesData.length; i < l; i++) {
              let fileName = otherfilesData[i].name;
              if (duplicateotherFile.includes(fileName)) {
              } else {
                data.append("file", otherfilesData[i]);
              }
            }

         //  document.getElementById("submitButton").disabled = false;
         this.state.submitotherButtonDisabled = false
            this.setState({
              uploadedFileother: true,
              otherFlag: true,
              otherData: otherData,
              disabled: "drpdisable",
              othererror: othererror,
            });
          
            this.setState({
              files: data,
              othererror: "",
            });
            // alert("files Ex--->"+ JSON.stringify(this.state.files))
            nooutputother.innerHTML = arrayotherLength1;
          } else {
            nooutputother.innerHTML = " No Other File Selected";
          }
       // }
      }.bind(this)
    );
  }}
 
   /**
   * @author : Rutuja Jagtap
   * @description : This is used submit other file function-4897
   */
  submitotherFile(e) {
    e.preventDefault(); 
    let clientName = this.state.clientName;
    var alertmessage = "";
    //-Changed Error message for submitting without selecting Advertiser.
    if (clientName === "") {
      alertmessage = "Please Select All Mandatory Fields.";

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      var campInfoStatus = false;
      let uploadedFileother = this.state.uploadedFileother;
      if (selectedCampaignother.length > 0) {
        campInfoStatus = true;
      } else {
        alertmessage = "Please Select Campaign from list.";
      }

      if (uploadedFileother === false && selectedCampaignother.length === 0) {
        alertmessage = "Please Select Campaign from list And Upload File.";
      } else if (uploadedFileother === false) {
        alertmessage = "Please Upload File.";
      }

      if (uploadedFileother === true && campInfoStatus === true) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({ 
          loader_progress2: "loader_campaign_list" ,
          submitotherButtonDisabled: true, 
          imageDisabledOther:true, // Rutuja task 4983 upload image disabled
      });
        if (this.state.otherFlag === true) {
          this.submitother1File();
        }
      } else {
        Swal.fire({
          text: alertmessage,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    }
  } 
 /**
   * @author : Rutuja Jagtap
   * @description : This is used submit other file function-4897
   */
  submitother1File(e) {
    let otherData = [];
    var data = new FormData();
    otherData = this.state.otherData;
    otherData = [...new Set(otherData)];
    let length = this.state.otherData.length;
    data.append("campaignInfoother", JSON.stringify(selectedCampaignother));
    for (let i = 0; i < length; i++) {
      let fileName = otherData[i].fileName;
     
      if (newotherfilenames.includes(fileName))
        data.append("otherData", otherData[i].fileData);
        
    }

    fetch("/uploadCommonSupportingDocuments/uploadCommonother", { 
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((otherList) => {
        this.setState({ loader_progress2: "",imageDisabledOther:false });// Rutuja task 4983 upload image enabled 

        let msg=""; 
        let message = document.getElementById("otherMessage");
        let selectedCampaignsIDother = [];
        let allselectedCampaignsID = [];
        let campaignMessage = "";
        let supportDocID
        if (otherList[0].success === true) {
          var suppDocName = [];
          for (var i = 0; i < otherList.length; i++) {
            if (otherList[i].success === true) {
              suppDocName.push(otherList[i].fileName);
              successotherCount.push(otherList[i].fileName);
            }
          }
          suppDocName = [...new Set(suppDocName)];

          let RejectedCountother = 0;
          var HTML2 = "<table style='width:100%'>";

          let otherFilesArrayUI = this.state.otherFilesSelectedDocuments.slice();
        
          for (let j = 0; j < newotherfilenames.length; j++) {
            var isExist = suppDocName.includes(newotherfilenames[j]);

            let toBeState;
            if (isExist === true) {
              //alert("cross")
              toBeState = {
                success: true,
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "other",
                //loading: false,
              };
              /**** If uploaded file is wrong then it displays as in Red color */
              // var HTML; //Nilesh-2833-Removing console warning

              // HTML += this.suppressionFileSectionTemplate(
              // 	false,
              // 	newexclusionfilenames[j]
              // );
            } else {
              RejectedCountother = RejectedCountother + 1;

              toBeState = {
                success: false,
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "other",
                //loading: false,
              };
            }

            otherFilesArrayUI = [
              ...otherFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            otherFilesSelectedDocuments: otherFilesArrayUI,
          });

          //-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignother.length; i++) {
            allselectedCampaignsID.push(selectedCampaignother[i].campID);
          }
          selectedCampaignsIDother["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDother: selectedCampaignsIDother,
            campaignMessage: campaignMessage,
          });

          HTML2 += "</table>";
          document.getElementById("nootherList").innerHTML = HTML2;
          if (RejectedCountother > 0) {
            msg = ""; //Nilesh-2833-Removing console warning
          } else {
            msg =
              "<font color = 'Green'> Document Submitted Successfully</font>";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }

          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        } else {
          var isError = otherList[0].Error;
          for (let i = 0; i < otherList.length; i++) {
            if (otherList[i].success === false) {
              otherValidationArray.push(otherList[i].fileName);
            }
          }

          let HTML = "<table style='width:100%'>"; //Nilesh-2833-Removing console warning
          let otherFilesArrayUI = this.state.otherFilesSelectedDocuments;
          // var imgsuccess; //Nilesh-2833-Removing console warning
          let RejectedCountother = 0;

          for (let j = 0; j < newotherfilenames.length; j++) {
            //Nilesh-2833-Removing console warning
            var isValid = otherValidationArray.includes(
              newotherfilenames[j]
            );
            let toBeState;
            if (isValid === true) {
              RejectedCountother++;
              toBeState = {
                success: false,
                supportDocID,
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "other",
                loading: false,
              };
            } else {
              toBeState = {
                success: true,
                supportDocID,
                suppDocName: newotherfilenames[j],
                typeOfSuppDoc: "other",
                loading: false,
              };

              let isTrue = successotherCount.includes(
                newotherfilenames[j]
              );
              if (isTrue === false) {
                successotherCount.push(newotherfilenames[j]);
              }
            }
            otherFilesArrayUI = [
              ...otherFilesArrayUI.filter(
                (item) => item.suppDocName !== toBeState.suppDocName
              ),
              toBeState,
            ];
          }
          this.setState({
            otherFilesSelectedDocuments: otherFilesArrayUI,
          });
          //Chaitanya-4865-Showing msg for selected campaigns id
          for (let i = 0; i < selectedCampaignother.length; i++) {
            allselectedCampaignsID.push(selectedCampaignother[i].campID);
          }
          //alert("selected Campaign => " + selectedCampaignsIDother);
          selectedCampaignsIDother["campaignMessage"] =
            "Selected Campaign Id : " + allselectedCampaignsID;
          this.setState({
            selectedCampaignsIDother: selectedCampaignsIDother,
            campaignMessage: campaignMessage,
          });

          HTML += "</table>";
          // let rejectedCount =
          // 	newexclusionfilenames.length - successSuppressionCount.length; //Nilesh-2833-Removing console warning
          document.getElementById("nootherList").innerHTML = HTML;
          if (RejectedCountother > 0) {
            msg = ""; //Nilesh-2833-Removing console warning
          } else {
            msg = "";
            // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
          }
          if (message === "") {
            message.innerHTML += msg;
          } else {
            message.innerHTML = msg;
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
 /**
   * @author : Rutuja Jagtap
   * @description : This is used to delete other file -4897
   */
  deleteotherFile(e, item) {
    e.preventDefault();
    let clickedFile = item.suppDocName;
  
    if (item.typeOfSuppDoc === "Other" ||item.typeOfSuppDoc === "other" ) {
      let otherFilesSelectedDocuments = this.state.otherFilesSelectedDocuments;

      const revisedotherFilesSelectedDocuments = otherFilesSelectedDocuments.filter(
        (item) => item.suppDocName !== clickedFile
      );

  
      // Remove the clicked file from the exclusion array
      newotherfilenames = newotherfilenames.filter((name) => name !== clickedFile);
      chkotherExist = chkotherExist.filter((name) => name !== clickedFile);
      
      for (var k = 0; k < newotherfilenames.length; k++) {
        if (newotherfilenames[k] === clickedFile) {
          newotherfilenames.splice(k, 1);
        }
      }

      // if newexclusionfilenames is empty to disable the submit button
      if (newotherfilenames.length === 0) {
        // document.getElementById("submitButton").disabled = true;
        this.setState({
          submitotherButtonDisabled: true
        })
      } else {
        this.setState({
          submitotherButtonDisabled: false
        })
      }

      this.setState({
        otherFilesSelectedDocuments: revisedotherFilesSelectedDocuments,
        selectedCampaignsIDother: "", 
        othererr: "",
      });
  
      let message = document.getElementById("otherMessage");
      let msg = "";
      message.innerHTML = msg;
      
    }
  }

  render() {
    const asterisk = {
      color: "red",
    };
    const campaignData = [
      {
        title: "Campaign ID",
        dataIndex: "campID",
        key: "campID",
        width: "11%",
        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span>{record.parentCampID}</span>
          </div>
        ),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaignName",
        key: "campaignName",
        align: "center",
        width: "40%",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          //Chaitanya-4847-Added tooltip to display full campaign name
          <div
            class="dotUploadSuppression"
            style={{ width: "500px" }}
            title={record.campaignName}
          >
            <span style={{ fontSize: "12px", cursor: "pointer" }}>
              {record.campaignName}
            </span>
          </div>
        ),
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        align: "center",
        width: "15%",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.startDate.toUpperCase() < b.startDate.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.startDate.toUpperCase() > b.startDate.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>{record.startDate}</span>
          </div>
        ),
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        align: "center",
        width: "15%",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.endDate.toUpperCase() < b.endDate.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.endDate.toUpperCase() > b.endDate.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>{record.endDate}</span>
          </div>
        ),
      },
      {
        title: "Total Leads",
        dataIndex: "leadAllocation",
        key: "leadAllocation",
        width: "11%",
        align: "center",
        sorter: (a, b) => a.leadAllocation - b.leadAllocation,
        render: (text, record) => (
          <div>
            <span>{record.leadAllocation}</span>
          </div>
        ),
      },
    ];
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (key, selectedRows) => {
        //Nilesh-4928- selectedCampaign key wise
        if (this.state.key === 1) {
          //Rutuja task 4952 
          if (selectedRows.length === 0)
          {
            selectedCampaign = [];
          }
          else{
          //alert("selectedRows ==> "+ JSON.stringify(selectedRows))
          //Chaitanya-5246-removed ...selectedCampaign from below array of selectedCampaign
          //selectedCampaign =[...selectedCampaign, ...selectedRows];
          
          selectedCampaign =[...selectedRows];
          selectedCampaign = [...new Set(selectedCampaign)]
          }
         
        } else if (this.state.key === 2){
          if (selectedRows.length === 0)
          {
            selectedCampaignEx = [];
          }
          else{
          //alert("selectedRows ==> "+ JSON.stringify(selectedRows))
          //Chaitanya-5246-removed ...selectedCampaign from below array of selectedCampaign
          //selectedCampaign =[...selectedCampaign, ...selectedRows];
          
          selectedCampaignEx =[...selectedRows];
          selectedCampaignEx = [...new Set(selectedCampaignEx)]
          }
        } 
        //Rutuja Task -4895
        else if (this.state.key === 3){
          if (selectedRows.length === 0)
          {
            selectedCampaignABM = [];
          }
          else{
          //alert("selectedRows ==> "+ JSON.stringify(selectedRows))
          //Chaitanya-5246-removed ...selectedCampaign from below array of selectedCampaign
          //selectedCampaign =[...selectedCampaign, ...selectedRows];
          
          selectedCampaignABM =[...selectedRows];
          selectedCampaignABM = [...new Set(selectedCampaignABM)]
          }
        } 
        //Rutuja Task -4897
        else if (this.state.key === 4){
          if (selectedRows.length === 0)
          {
            selectedCampaignother = [];
          }
          else{
          //alert("selectedRows ==> "+ JSON.stringify(selectedRows))
          //Chaitanya-5246-removed ...selectedCampaign from below array of selectedCampaign
          //selectedCampaign =[...selectedCampaign, ...selectedRows];
          
          selectedCampaignother =[...selectedRows];
          selectedCampaignother = [...new Set(selectedCampaignother)]
        }
      }
      },
      onSelection: this.onSelection,
      hideDefaultSelections: true,

      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }
    return (
      <div>
        <Navigation />
        {/* rutuja 4375 respensive */}
        <div
          class="container-fluid"
          id="containerUCS"
          style={{ paddingTop: "112px", paddingBottom: "70px" }}
        >
          {/*Mufiz-Task-3910 responsiveness issue*/}
          <div class="row">
            <div
              class="col-sm-12"
              align="center"
              style={{ marginBottom: "30px" }}
            >
              {/* Mufiz Task-3910 respensive Display */}
              <a
                href="#/" //Nilesh-2833-Removing console warning
                onClick={(e) => {
                  //Chaitanya-4867-console warning
                  e.preventDefault();
                  this.props.history.push("/newdashboard", {
                    agencyID: this.state.agencyID,
                  });
                  window.location.reload() //Nilesh-4933
                }} //karan-task-3684-replace query params
                
                style={{ color: "#056eb8" }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left" }}
                  title="Back to Dashboard"
                />
              </a>
              <label
                align="center"
                id="labelheading"
                style={{ color: "#056eb8", fontSize: "20px" }}
              >
                Upload Common Supporting Documents
                {/* 4813-Changed title */}
              </label>
            </div>
          </div>
          {/* Chaitanya-4865-Added Tabs for ABM/Exclusion/Other with same design as suppression */}
          <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
            <Tab eventKey={1} title="Suppression">
              <br />
              <div class="row">
                {/* rutuja 4375 - Responsive  */}
                <div class="col-sm-4 col-md-3 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Select Advertiser &nbsp;:&nbsp;
                    <span style={asterisk}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      height: "52px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    name="clientName"
                    defaultValue={this.state.clientID}
                    value={this.state.clientID}
                    onChange={this.handleClientName}
                    class="form-control"
                  >
                    <option value="Select">Select Advertiser</option>
                    {this.state.clientList.map((clientList) => (
                      
                      <option value={clientList.Id}>
                         {/* Priyanka - 4976 - Passing ID instead of Client ID */}
                        {/* Chaitanya - 4891 - Showing client id with client name */}
                        {clientList.clientID} - {clientList.clientName}{" "}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                <br />

                {/* rutuja 4375 - Responsive  */}
                <div class="col-sm-4 col-md-4 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Upload Suppression File&nbsp;:&nbsp;
                  </label>
                  <br />
                  <div class="card">
                    <div className="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <label
                            style={{
                              fontWeight: 500,
                              color: "black",
                              marginBottom: 0,
                            }}
                          >
                            Suppression :&nbsp;
                          </label>
                          <Tooltip
                            placement="right"
                            title="Suppression file can contain 1 column 'Email'"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </div>

                        <div class="col">
                          <div
                            class="upload-btn-wrapper"
                            // className={
                            // 	// this.state.disabled + " " + "upload-btn-wrapper"
                            // 	this.state.disabled + " upload-btn-wrapper" //Nilesh-2833-Removing console warning
                            // }
                            style={{ float: "right" }}                      
                          >
                            <img
                              src="upload.png"
                              alt="upload"
                              onClick={this.uploadSuppressionFile}
                              style={{
                                height: "25px",
                                width: "25px",
                                // Rutuja task 4983 image will be clickable after getting response
                                cursor: this.state.imageDisabledSupp ? "not-allowed" : "pointer",
                                opacity: this.state.imageDisabledSupp ? 0.5 : 1,
                              }}
                            />
                            {/* sunita-task-3101-removed input type file*/}
                          </div>
                        </div>
                      </div>
                      <div id="nosuppressionList" style={{ display: "none" }}>
                        0 Suppression Files Selected
                      </div>
                      <div
                        class="table-responsive-lg"
                        style={{
                          width: "100%",
                          display: this.state.displayTablesuppression,
                        }}
                      >
                        {/* <br /> */}
                        <div
                          id="suppresionMessage"
                          style={{ color: "green" }}
                        ></div>
                        <div style={{ color: "red" }}>
                          {this.state.suppressionerror.suppressionerr}
                        </div>
                        <div
                          id={"suppressionError"}
                          style={{ color: "red" }}
                        ></div>
                        <span class="errorMessage">
                          {this.state.suppressionerror.headerError}
                        </span>

                        <div
                          className="mt-2"
                          style={
                            this.state.suppressionFilesSelectedDocuments
                              .length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.suppressionFilesSelectedDocuments &&
                            this.state.suppressionFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {
                                        <img
                                          alt=""
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      }
                                    </div>

                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.suppDocName}
                                      </label>
                                    </div>
                                    <a
                                      href="#/"
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      onClick={(e) => this.deleteFile(e, item)}
                                    >
                                      <img
                                        alt=""
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <table
                          id="myTable"
                          className=" table table-bordered table-striped "
                          style={{
                            width: "100%",
                            border: "0px solid white",
                            margin: 0,
                          }}
                        >
                          <tbody class="tbody" style={{ height: "auto" }}>
                            <span
                              id="suppressionList"
                              className="word-wrap"
                            ></span>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Chaitanya - 4891 - changed alignment of submit button */}
                <div class="col-sm-4 col-md-5 col-lg-6">
                  <button
                    class="btn add-button"
                    id="submitButton"
                    style={{
                      float: "right",
                      marginTop: "5%",
                      marginRight: "10%",
                    }}
                    onClick={this.submitFile}
                  >
                    Submit
                  </button>
                </div>
              </div>

              {/*  Chaitanya - 4891 - Added search bar for campaign Id */}
              <div class="row">
                <div class="col-sm-12 col-lg-8 col-md-8">
                  <div
                    style={{
                      color: "Black",
                      paddingTop: "1%",
                      marginLeft: "1%",
                      fontWeight: 600,
                    }}
                  >
                    {/* Chaitanya-4865-Showing msg of selected campaigns id */}
                    {this.state.selectedCampaignsID.campaignMessage}
                  </div>
                </div>

                <div class="col-sm-12 col-lg-4 col-md-4">
                  <input
                     id="search-bar1"
                    type="text"
                    onkeyup="myFunction()"
                    disabled="search-bar1"
                    style={{
                      backgroundImage: "url(Group_294.png)",
                      backgroundPosition: "15px 15px",
                      backgroundSize: "15px 15px",
                      backgroundRepeat: "no-repeat",
                      float: "right",
                      marginRight: "1%",
                      fontSize: "100%",
                      border: "1px solid #ddd",
                      padding: "10px 12px 12px 40px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(221, 221, 221)",
                    }}
                    onChange={this.handleChangeSearch}
                    onPressEnter={this.handleChangeSearch}
                    placeholder="Search..."
                    title="Type in a name"
                  />
                </div>
              </div>

              {/* rutuja -4375 added new loader */}
              <div
                className={this.state.loader_progress}
                style={{
                  left: "650px",
                  top: "80px",
                  bottom: "300px",
                  zIndex: "2",
                }}
              ></div>
              <div id={this.state.loader_progress}></div>
              <br />
              <div class="row">
                <div class="col-sm-12">
                  {/* Chaitanya - 4891 - Added Condition of table search for Search Campaigns data*/}
                  {this.state.tableSearchText === "" ? (
                    <Table
                      {...this.state.tableState}
                      rowSelection={rowSelection}
                      id="myTable"
                      bordered
                      dataSource={this.state.campaignInfo}
                      loading={this.state.loading2}
                      onChange={onChange}
                      columns={campaignData}
                      // className="ant-table-thead"
                      // className={tableCSS}
                      className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                      scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                      pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  ) : (
                    //rutuja task- 4374 - remove comment part of pagination as pagination is not showing
                    <Table
                      {...this.state.tableState}
                      rowSelection={rowSelection}
                      id="myTable"
                      bordered
                      dataSource={this.state.campaignDetailsSearch}
                      loading={this.state.loading2}
                      onChange={onChange}
                      columns={campaignData}
                      className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                      scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                      pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey={2} title="Exclusion">
              <br />
              <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Select Advertiser &nbsp;:&nbsp;
                    <span style={asterisk}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      height: "52px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    name="clientName"
                    defaultValue={this.state.clientID}
                    value={this.state.clientID}
                    onChange={this.handleClientName}
                    class="form-control"
                  >
                    <option value="Select">Select Advertiser</option>
                    {this.state.clientList.map((clientList) => (
                      <option value={clientList.Id}>
                      {/* Priyanka - 4976 - Passing ID instead of Client ID */}
                        {clientList.clientID} - {clientList.clientName}{" "}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                <br />

                <div class="col-sm-4 col-md-4 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Upload Exclusion File&nbsp;:&nbsp;
                  </label>
                  <br />
                  <div class="card">
                    <div className="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <label
                            style={{
                              fontWeight: 500,
                              color: "black",
                              marginBottom: 0,
                            }}
                          >
                            Exclusion :&nbsp;
                          </label>
                          <Tooltip
                            placement="right"
                            title="Exclusion file must have one column with header name 'Domain' or 'Company Name'"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </div>

                        <div class="col">
                          <div
                            class="upload-btn-wrapper"
                            style={{ float: "right" }}
                          >
                            <img
                              src="upload.png"
                              alt="upload"
                              onClick={this.uploadExclusionFile} //Nilesh-4896
                              style={{
                                height: "25px",
                                width: "25px",
                                // Rutuja task 4983 image will be clickable after getting response
                                cursor: this.state.imageDisabledExl ? "not-allowed" : "pointer",
                                opacity: this.state.imageDisabledExl ? 0.5 : 1,
                              }}
                            />
                            {/* sunita-task-3101-removed input type file*/}
                          </div>
                        </div>
                      </div>
                      <div id="noexclusionList" style={{ display: "none" }}>
                      {/* <div id="nosuppressionList" style={{ display: "none" }}> */}
                        0 Exclusion Files Selected
                      </div>
                      <div
                        class="table-responsive-lg"
                        style={{
                          width: "100%",
                         display: this.state.displayTableexclusion,
                        }}
                      >
                        {/* <br /> */}
                        <div
                          id="exclusionMessage"
                          style={{ color: "green" }}
                        ></div>
                        <div style={{ color: "red" }}>
                          {this.state.exclusionerror.exclusionerr}
                        </div>
                        <div
                          id={"exclusionError"}
                          style={{ color: "red" }}
                        ></div>
                        <span class="errorMessage">
                          {this.state.exclusionerror.headerError}
                        </span>

                        <div
                          className="mt-2"
                          style={
                            this.state.exclusionFilesSelectedDocuments
                              .length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.exclusionFilesSelectedDocuments &&
                            this.state.exclusionFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div  key={item.dataFileID}
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {
                                        <img
                                          alt=""
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      }
                                    </div>

                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.suppDocName}
                                      </label>
                                    </div>
                                    <a
                                      href="#/"
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      onClick={(e) => this.deleteExclusionFile(e, item)}
                                    >
                                      <img
                                        alt=""
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <table
                          id="myTable"
                          className=" table table-bordered table-striped "
                          style={{
                            width: "100%",
                            border: "0px solid white",
                            margin: 0,
                          }}
                        >
                          <tbody class="tbody" style={{ height: "auto" }}>
                            <span
                              id="noexclusionList"
                              className="word-wrap"
                            ></span>
                          </tbody>
                        </table>
                      </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              
              <div class="col-sm-4 col-md-5 col-lg-6">
                  <button
                    class="btn add-button"
                    id="submitButton"
                    style={{
                      float: "right",
                      marginTop: "5%",
                      marginRight: "10%",
                    }}
                    onClick={this.submitExFile}
                    disabled={this.state.submitExButtonDisabled}
                  >
                    Submit
                  </button>
                </div>
            </div>

              <div class="row">
              <div class="col-sm-12 col-lg-8 col-md-8">
                  <div
                    style={{
                      color: "Black",
                      paddingTop: "1%",
                      marginLeft: "1%",
                      fontWeight: 600,
                    }}
                  >
                    {/* Chaitanya-4865-Showing msg of selected campaigns id */}
                    {this.state.selectedCampaignsIDEx.campaignMessage}
                  </div>
                </div>

                <div class="col-sm-12 col-lg-4 col-md-4">
                  <input
                    id="search-bar2" // Nilesh-4929 
                    type="text"
                    onkeyup="myFunction()"
                    disabled="search-bar2" // Nilesh-4929 
                    style={{
                      backgroundImage: "url(Group_294.png)",
                      backgroundPosition: "15px 15px",
                      backgroundSize: "15px 15px",
                      backgroundRepeat: "no-repeat",
                      float: "right",
                      marginRight: "1%",
                      fontSize: "100%",
                      border: "1px solid #ddd",
                      padding: "10px 12px 12px 40px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(221, 221, 221)",
                    }}
                    onChange={this.handleChangeSearch}
                    onPressEnter={this.handleChangeSearch}
                    placeholder="Search..."
                    title="Type in a name"
                  />
                </div>
              </div>

              <div
                className={this.state.loader_progress}
                style={{
                  left: "650px",
                  top: "80px",
                  bottom: "300px",
                  zIndex: "2",
                }}
              ></div>
              <div id={this.state.loader_progress}></div>
              <br />
              <div class="row">
                <div class="col-sm-12">
                  {/* Chaitanya - 4891 - Added Condition of table search for Search Campaigns data*/}
                  {this.state.tableSearchText === "" ? (
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignInfoEx}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  ) : (
                    //rutuja task- 4374 - remove comment part of pagination as pagination is not showing
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignDetailsSearch}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  )}
                </div>
              </div>
              {/* </div> */}
            </Tab>

            <Tab eventKey={3} title="ABM">
              <br />
              <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Select Advertiser &nbsp;:&nbsp;
                    <span style={asterisk}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      height: "52px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    name="clientName"
                    defaultValue={this.state.clientID}
                    value={this.state.clientID}
                    onChange={this.handleClientName} //RUutja task 4895
                    class="form-control"
                  >
                    <option value="Select">Select Advertiser</option>
                    {/* //RUutja task 4895 */}
                    {this.state.clientList.map((clientList) => (
                       <option value={clientList.Id}>
                       {/* Priyanka - 4976 - Passing ID instead of Client ID */}
                        {clientList.clientID} - {clientList.clientName}{" "}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                <br />
                <div class="col-sm-4 col-md-4 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Upload ABM File&nbsp;:&nbsp;
                  </label>
                  <br />
                  <div class="card">
                    <div className="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <label
                            style={{
                              fontWeight: 500,
                              color: "black",
                              marginBottom: 0,
                            }}
                          >
                            ABM :&nbsp;
                          </label>
                          <Tooltip
                            placement="right"
                            title="The ABM file must have one column with header name as 'Account Name' OR 'Domain Name'"
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </div>

                        <div class="col">
                          <div
                            class="upload-btn-wrapper"
                            style={{ float: "right" }}
                          >
                            <img
                              src="upload.png"
                              alt="upload"
                              onClick={this.uploadABMFile} //RUutja task 4895
                              style={{
                                height: "25px",
                                width: "25px",
                                // Rutuja task 4983 image will be clickable after getting response
                                cursor: this.state.imageDisabledAbm ? "not-allowed" : "pointer",
                                opacity: this.state.imageDisabledAbm ? 0.5 : 1,
                              }}
                            />
                            {/* sunita-task-3101-removed input type file*/}
                          </div>
                        </div>
                      </div>
                      {/* //RUutja task 4895 */}
                      <div id="noabmList" style={{ display: "none" }}>
                        0 ABM Files Selected
                      </div>
                      <div
                        class="table-responsive-lg"
                        style={{
                          width: "100%",
                          display: this.state.displayTableabm,
                        }}
                      >
                        <br />
                        <div
                          id="abmMessage"
                          style={{ color: "green" }}
                        ></div>
                        <div style={{ color: "red" }}>
                          {this.state.abmerror.abmerr}
                        </div>
                        <div
                          id={"abmerror"}
                          style={{ color: "red" }}
                        ></div>
                        <span class="errorMessage">
                          {this.state.abmerror.headerError}
                        </span>

                        <div
                          className="mt-2"
                          style={
                            this.state.abmFilesSelectedDocuments
                              .length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.abmFilesSelectedDocuments &&
                            this.state.abmFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div key={item.dataFileID}
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {
                                        <img
                                          alt=""
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      }
                                    </div>

                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.suppDocName}
                                      </label>
                                    </div>
                                    <a
                                      href="#/"
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      onClick={(e) => this.deleteABMFile(e, item)}
                                    >
                                      <img
                                        alt=""
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <table
                          id="myTable"
                          className=" table table-bordered table-striped "
                          style={{
                            width: "100%",
                            border: "0px solid white",
                            margin: 0,
                          }}
                        >
                          <tbody class="tbody" style={{ height: "auto" }}>
                            <span
                              id="abmList"
                              className="word-wrap"
                            ></span>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-5 col-lg-6">
                  <button
                    class="btn add-button"
                    id="submitButton"
                    style={{
                      float: "right",
                      marginTop: "5%",
                      marginRight: "10%",
                    }}
                    onClick={this.submitABMFile}
                    disabled={this.state.submitABMButtonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div class="row">
            
              <div class="col-sm-12 col-lg-8 col-md-8">
                  <div
                    style={{
                      color: "Black",
                      paddingTop: "1%",
                      marginLeft: "1%",
                      fontWeight: 600,
                    }}
                  >
                    {this.state.selectedCampaignsIDABM.campaignMessage}
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                  <input
                    id="search-bar3"
                    type="text"
                    onkeyup="myFunction()"
                    disabled="search-bar3"
                    style={{
                      backgroundImage: "url(Group_294.png)",
                      backgroundPosition: "15px 15px",
                      backgroundSize: "15px 15px",
                      backgroundRepeat: "no-repeat",
                      float: "right",
                      marginRight: "1%",
                      fontSize: "100%",
                      border: "1px solid #ddd",
                      padding: "10px 12px 12px 40px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(221, 221, 221)",
                    }}
                    onChange={this.handleChangeSearch}
                    onPressEnter={this.handleChangeSearch}
                    placeholder="Search..."
                    title="Type in a name"
                  />
                </div>
              </div>

              <div
                className={this.state.loader_progress1} //RUutja task 4895
                style={{
                  left: "650px",
                  top: "80px",
                  bottom: "300px",
                  zIndex: "2",
                }}
              ></div>
              {/* //RUutja task 4895 */}
              <div id={this.state.loader_progress1}></div> 
              <br />
              <div class="row">
                <div class="col-sm-12">
                  {/* Chaitanya - 4891 - Added Condition of table search for Search Campaigns data*/}
                  {this.state.tableSearchText === "" ? (
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignInfoABM}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  ) : (
                    //rutuja task- 4374 - remove comment part of pagination as pagination is not showing
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignDetailsSearch}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey={4} title="Other Documents">
              <br />
              <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Select Advertiser &nbsp;:&nbsp;
                    <span style={asterisk}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      height: "52px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    name="clientName"
                    defaultValue={this.state.clientID}
                    value={this.state.clientID}
                    onChange={this.handleClientName}  //4897
                    class="form-control"
                  >
                    <option value="Select">Select Advertiser</option>
                    {/* 4897 */}
                    {this.state.clientList.map((clientList) => (
                       <option value={clientList.Id}>
                       {/* Priyanka - 4976 - Passing ID instead of Client ID */}
                        {clientList.clientID} - {clientList.clientName}{" "}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                <br />
                <div class="col-sm-4 col-md-4 col-lg-3">
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#14254A",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Upload Other File&nbsp;:&nbsp;
                  </label>
                  <br />
                  <div class="card">
                    <div className="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <label
                            style={{
                              fontWeight: 500,
                              color: "black",
                              marginBottom: 0,
                            }}
                          >
                            Other :&nbsp;
                          </label>
                          {/* <Tooltip
                            placement="right"
                            title="Suppression file can contain 1 column 'Email'"
                          >
                            <Icon type="question-circle" />
                          </Tooltip> */}
                        </div>

                        <div class="col">
                          <div
                            class="upload-btn-wrapper"
                            style={{ float: "right" }}
                          >
                            <img
                              src="upload.png"
                              alt="upload"
                              onClick={this.uploadOtherFile} //4897
                              style={{
                                height: "25px",
                                width: "25px",
                                // Rutuja task 4983 image will be clickable after getting response
                                cursor: this.state.imageDisabledOther ? "not-allowed" : "pointer",
                                opacity: this.state.imageDisabledOther ? 0.5 : 1,
                              }}
                            />
                            {/* sunita-task-3101-removed input type file*/}
                          </div>
                        </div>
                      </div>
                      <div id="nootherList" style={{ display: "none" }}> //4897
                        0 Files Selected
                      </div>
                      <div
                        class="table-responsive-lg"
                        style={{
                          width: "100%",
                          display: this.state.displayTableother,//4897
                        }}
                      >
                        <br />
                        <div
                          id="otherMessage" //4897
                          style={{ color: "green" }}
                        ></div>
                        <div style={{ color: "red" }}>
                          {this.state.othererror.othererr} 
                        </div>
                        <div
                          id={"othererror"}
                          style={{ color: "red" }}
                        ></div>
                        <span class="errorMessage">
                          {this.state.othererror.headerError}
                        </span>

                        <div
                          className="mt-2"
                          style={
                            this.state.otherFilesSelectedDocuments
                              .length > 5
                              ? {
                                  maxHeight: 280,
                                  overflowY: "auto",
                                }
                              : {}
                          }
                        >
                          {this.state.otherFilesSelectedDocuments &&
                            this.state.otherFilesSelectedDocuments.map(
                              (item) => {
                                return (
                                  <div
                                    className="row align-items-center mb-2"
                                    style={{
                                      backgroundColor: item.success
                                        ? "#DAECDA"
                                        : "#FFEEF0",
                                      paddingLeft: 12,
                                      paddingTop: 12,
                                      paddingBottom: 12,
                                      paddingRight: 12,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      borderRadius: 2,
                                    }}
                                  >
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col-xs-2 col-md-auto"
                                    >
                                      {
                                        <img
                                          alt=""
                                          style={{
                                            height: 26,
                                            width: 26,
                                          }}
                                          src={
                                            item.success
                                              ? "success.png"
                                              : "failed.png"
                                          }
                                        />
                                      }
                                    </div>

                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="col overflow-hidden"
                                    >
                                      <label
                                        className="mb-0 ml-2 overflow-hidden align-middle"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {item.suppDocName}
                                      </label>
                                    </div>
                                    <a
                                      href="#/"
                                      className="col-xs-2 col-md-auto justify-content-center"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        width: "auto",
                                      }}
                                      onClick={(e) => this.deleteotherFile(e, item)}
                                    >
                                      <img
                                        alt=""
                                        className="mb-0 mx-2"
                                        style={{
                                          height: 18,
                                          width: 18,
                                        }}
                                        src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
                                      />
                                    </a>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <table
                          id="myTable"
                          className=" table table-bordered table-striped "
                          style={{
                            width: "100%",
                            border: "0px solid white",
                            margin: 0,
                          }}
                        >
                          <tbody class="tbody" style={{ height: "auto" }}>
                            <span
                              id="otherList"
                              className="word-wrap"
                            ></span>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-5 col-lg-6">
                  <button
                    class="btn add-button"
                    id="submitButton"
                    style={{
                      float: "right",
                      marginTop: "5%",
                      marginRight: "10%",
                    }}
                    onClick={this.submitotherFile}
                    disabled={this.state.submitotherButtonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="row">
                    
              <div class="col-sm-12 col-lg-8 col-md-8">
                  <div
                    style={{
                      color: "Black",
                      paddingTop: "1%",
                      marginLeft: "1%",
                      fontWeight: 600,
                    }}
                  >
                    {this.state.selectedCampaignsIDother.campaignMessage}
                  </div>
                </div>
              
                <div class="col-sm-12 col-lg-12 col-md-12">
                  <input
                    id="search-bar4"
                    type="text"
                    onkeyup="myFunction()"
                    disabled="search-bar4"
                    style={{
                      backgroundImage: "url(Group_294.png)",
                      backgroundPosition: "15px 15px",
                      backgroundSize: "15px 15px",
                      backgroundRepeat: "no-repeat",
                      float: "right",
                      marginRight: "1%",
                      fontSize: "100%",
                      border: "1px solid #ddd",
                      padding: "10px 12px 12px 40px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(221, 221, 221)",
                    }}
                    onChange={this.handleChangeSearch}
                    onPressEnter={this.handleChangeSearch}
                    placeholder="Search..."
                    title="Type in a name"
                  />
                </div>
              </div>

              <div
                className={this.state.loader_progress2}
                style={{
                  left: "650px",
                  top: "80px",
                  bottom: "300px",
                  zIndex: "2",
                }}
              ></div>
              <div id={this.state.loader_progress2}></div>
              <br />
              <div class="row">
                <div class="col-sm-12">
                  {/* Chaitanya - 4891 - Added Condition of table search for Search Campaigns data*/}
                  {this.state.tableSearchText === "" ? (
                    // 4897
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignInfoother}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  ) : (
                    //rutuja task- 4374 - remove comment part of pagination as pagination is not showing
                    <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignDetailsSearch}
                    loading={this.state.loading2}
                    onChange={onChange}
                    columns={campaignData}
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
                    scroll={{ x: 1280, y: 4000 }} //Mufiz-Task-3910 responsiveness issue
                    pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <Footer />
      </div>
    ); /*** End of return */
  } /** End of render */
} /*** End of Class */

UploadCommonSuppression.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(UploadCommonSuppression)
);
