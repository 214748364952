/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Karan Jagtap
 * @description : Step 2 : Delivery Format : Modal which displays mapping table
 */
import React from "react";

import "./supportingDocumentCreate.css";

export default function AgencyCustomizeMappingModal({
	agencyCustomizeMapping,
	customizeMappingCancleHandleChange,
}) {
	return (
		<div
			class="modal fade"
			id="customizeMappingAdd"
			role="dialog"
			data-backdrop="static"
			data-keyboard="false"
			style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header custom-classPopup">
						<h4 style={{ color: "#ffffff" }}>
							Customize mapping
							<button
								type="button"
								class="cancel-button"
								data-dismiss="modal"
								style={{
									fontSize: 15,
									color: "#ffffff",
									float: "right",
									position: " relative",
								}}
								onClick={customizeMappingCancleHandleChange}>
								&times;
							</button>
						</h4>
					</div>
					<br />
					<br />
					<div
						align="center"
						style={{
							overflowY: "scroll",
							height: "350px",
							position: " relative",
						}}>
						<table
							class="table table-bordered"
							id="myTable31"
							style={{
								width: "85%",
								position: " relative",
							}}>
							<thead>
								<tr>
									<th
										style={{
											position: "sticky",
											color: "#707070",
											height: "35px",
											backgroundColor: " rgba(126, 127, 129, 0.22)",
										}}>
										&nbsp;System Field
									</th>
									<th
										style={{
											position: "sticky",
											color: "#707070",
											height: "35px",
											backgroundColor: " rgba(126, 127, 129, 0.22)",
										}}>
										&nbsp;Mapping Alias Field{" "}
									</th>
								</tr>
							</thead>
							{agencyCustomizeMapping.map((agencyCustomizeMapping) => (
								<tbody>
									<tr>
										<td>&nbsp;Publisher ID :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.pID}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Campaign ID :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.campID}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Campaign Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.campaignName}
										</td>
									</tr>

									<tr>
										<td>&nbsp;Lead Interaction Date :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.leadInteractionDate}
										</td>
									</tr>
									<tr>
										<td>&nbsp;First Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.firstName}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Last Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias :{agencyCustomizeMapping.lastName}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Company Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.companyName}
										</td>
									</tr>
									<tr>
										<td>&nbsp;LinkedIn Company Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.linkedInCompanyName}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Email :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.email}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Work Phone :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.workPhone}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Work Phone Format :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.workPhoneFormat}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Job Title :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.jobTitle}
										</td>
									</tr>
									<tr>
										<td>&nbsp;LinkedIn Job Title :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.linkedInJobTitle}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Address :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.address}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Country :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.country}
										</td>
									</tr>
									<tr>
										<td>&nbsp;City :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.city}
										</td>
									</tr>
									<tr>
										<td>&nbsp;State :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.state}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Zip Code :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.zipCode}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Company Employee Size :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.companyEmployeeSize}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Company Revenue :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.companyRevenue}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Industry :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.industry}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Name :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.assetName}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Name Touch 1 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch1}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Timestamp Touch 1 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias :{agencyCustomizeMapping.assetTimestampTouch1}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Name Touch 2 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch2}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Timestamp Touch 2 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias :{" "}
											{agencyCustomizeMapping.assetTimestampTouch2}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Name Touch 3 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch3}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset Timestamp Touch 3 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias :{" "}
											{agencyCustomizeMapping.assetTimestampTouch3}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Street :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.street}
										</td>
									</tr>
									<tr>
										<td>&nbsp;IP :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.ip}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Asset ID :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.supportDocID}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Job Level :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.jobLevel}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Job Function :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.jobFunction}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Channel :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.channel}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 1 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra1}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 2 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra2}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 3 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra3}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 4 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra4}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 5 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra5}
										</td>
									</tr>
									{/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
									<tr>
										<td>&nbsp;Extra 6 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra6}
										</td>
									</tr>

									<tr>
										<td>&nbsp;Extra 7 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra7}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 8 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra8}
										</td>
									</tr>

									<tr>
										<td>&nbsp;Extra 9 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra9}
										</td>
									</tr>

									<tr>
										<td>&nbsp;Extra 10 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra10}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 11 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra11}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 12 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra12}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 13 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra13}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 14 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra14}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 15 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra15}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 16 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra16}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 17 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra17}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 18 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra18}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 19 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra19}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Extra 20 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra20}
										</td>
									</tr>

									<tr>
										<td>&nbsp;Domain :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.domain}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Alternate Phone No :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.alternatePhoneNo}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Comments :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.comments}
										</td>
									</tr>
									<tr>
										<td>&nbsp;LinkedIn :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.linkedIn}
										</td>
									</tr>
									<tr>
										<td>&nbsp;Channel :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.channel}
										</td>
									</tr>
									{/* Rutuja 5278 -added the other extra filed 21 to 30 to dispaly in ui*/}
									{agencyCustomizeMapping.extra21 && agencyCustomizeMapping.extra21 !== "Yes" && agencyCustomizeMapping.extra21 !== "No" ? (
										<tr>
											<td>&nbsp;Extra 21 :</td>
											<td
												style={{
													paddingBottom: "10px",
												}}>
												&nbsp;Alias : {agencyCustomizeMapping.extra21}
											</td>
										</tr>
									) : null }
									
									{agencyCustomizeMapping.extra22 && agencyCustomizeMapping.extra22 !== "Yes" && agencyCustomizeMapping.extra22 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 22 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra22}
										</td>
									</tr>
									) : null }

									{agencyCustomizeMapping.extra23 && agencyCustomizeMapping.extra23 !== "Yes" && agencyCustomizeMapping.extra23 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 23 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra23}
										</td>
									</tr>
									) : null }

									{agencyCustomizeMapping.extra24 && agencyCustomizeMapping.extra24 !== "Yes" && agencyCustomizeMapping.extra24 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 24 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra24}
										</td>
									</tr>
									) : null }
									
									{agencyCustomizeMapping.extra25 && agencyCustomizeMapping.extra25 !== "Yes" && agencyCustomizeMapping.extra25 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 25 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra25}
										</td>
									</tr>
									) : null }

									{agencyCustomizeMapping.extra26 && agencyCustomizeMapping.extra26 !== "Yes" && agencyCustomizeMapping.extra26 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 26 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra26}
										</td>
									</tr>
									) : null}

									{agencyCustomizeMapping.extra27 && agencyCustomizeMapping.extra27 !== "Yes" && agencyCustomizeMapping.extra27 !== "No" ? (	
									<tr>
										<td>&nbsp;Extra 27 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra27}
										</td>
									</tr>
									) : null}

									{agencyCustomizeMapping.extra28 && agencyCustomizeMapping.extra28 !== "Yes" && agencyCustomizeMapping.extra28 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 28 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra28}
										</td>
									</tr>
									) : null}

									{agencyCustomizeMapping.extra29 && agencyCustomizeMapping.extra29 !== "Yes" && agencyCustomizeMapping.extra29 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 29 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra29}
										</td>
									</tr>
									) : null}

									{agencyCustomizeMapping.extra30 && agencyCustomizeMapping.extra30 !== "Yes" && agencyCustomizeMapping.extra30 !== "No" ? (
									<tr>
										<td>&nbsp;Extra 30 :</td>
										<td
											style={{
												paddingBottom: "10px",
											}}>
											&nbsp;Alias : {agencyCustomizeMapping.extra30}
										</td>
									</tr>
									) : null}
								</tbody>
							))}
						</table>
					</div>
					<br />
					<br />
				</div>
			</div>
		</div>
	);
}
