/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Karan Jagtap
 * @description : task-3453-Add Country/State/City screen
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import AdminNavpage from "../layouts/adminNavpage";
import Footer from "../layouts/footer";
import "./addCountry.css";
import Loader from "../campaignUI/Loader";
import { Empty } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
import { saveAs } from "file-saver"; //Rutuja 5243 added filesaver
import styled from "styled-components"; //Rutuja 5243
import { Icon, Tooltip } from "antd"; //Rutuja 5243
const Swal = require("sweetalert2");
const xlsx = require("xlsx");//Rutuja 5243 
//Rutuja 5243
const HtmlTooltip = styled(Tooltip)`
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: '30px',
		border: '1px solid #dadde9',
		marginLeft: '200px',
		`;

var leadLength;
var leadFileExt;
class AddCountry extends React.Component {
	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - constructor function
	 * @return Description – called when component initialized
	 */
	constructor() {
		super();
		this.state = {
			cities: [], // karan-task-3453-contains all cities
			searchedCities: [], // karan-task-3453-contains only searched cities
			country: "", // karan-task-3453-country
			state: "", // karan-task-3453-state
			city: "", // karan-task-3453-city
			isSearchDisabled: true, // karan-task-3453-to control search button enable/disable functionality
			isAddDisabled: true, // karan-task-3453-to control add button enable/disable functionality
			invalidInput: false, // karan-task-3453-validation error for invalid input
			invalidInput1: false, //4769
			cityExists: false, // karan-task-3453-if city already exists
			searchedCity: "", // karan-task-3453-search box input text
			countryShortName: "", // karan-task-3453-country short name
			stateShortName: "", // karan-task-3453-state short name
			countryPhoneCode: "", // karan-task-3453-country phone code
			cityInputString: "", // karan-task-3453-city input text
			cityInputArray: [], // karan-task-3453-cityInputString converted into valid array
			asyncInProgress: false, // karan-task-3453-hack to fast response loader issue
			agencyArray:[], //rutuja - task - 4622 
			countryCode:"",  //rutuja - task - 4622 
			DownloadErrorFile:[], //Rutuja 5243

		};
		this.handleChangeFileupload = this.handleChangeFileupload.bind(this); //Rutuja 5243 file upload
		this.validateForm = this.validateForm.bind(this); //Rutuja 5243
		this.downloadErrorFile = this.downloadErrorFile.bind(this); //Rutuja 5243 download file
		this.loadingRef = new React.createRef(); // karan-task-3453-ref for openeing loader
		this.closeLoadingRef = new React.createRef(); // karan-task-3453-ref for closing loader
	} // end of constructor function

	/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-5243 - upload file function
	 * @return Description – used for uploading file
	 */
	handleChangeFileupload(e) {
		e.preventDefault();
		var fileName;
		var file = [];
		
		let headerErrors = {};
		 e = document.createElement("input"); //creating a input element but not binding it to body added by sandeep
		e.setAttribute("type", "file");
		e.click(); //to open file dialougeadded by sandeep
		e.addEventListener(
		  "change",
		  function () {
			let filesData = e.files[0];
			let filesData1 = e.files;
			var output = document.getElementById("LeadList");
			leadLength = filesData1.length;
			var extFileName = filesData.name;
			this.setState({extFileName: extFileName})
			leadFileExt = extFileName.split(".").pop();
			
			var allowedFiles = [".xls", ".xlsx", ".csv"];
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
			if (!regex.test(extFileName.toLowerCase())) {
			  Swal.fire({
				text: "Please Upload only Excel(.xls, .xlsx) file",
				type: "warning",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			  });
			  //kiran-4573-aaded swal fire for msg
			  //Sandeep-task-3079-passsing filesData parameter in validateForm function
			  return;
			}
			else{
				this.loadingRef.current.click();
			  var data = new FormData();
			  data.append("file", filesData);
			   fetch("admin/uploadFileForCityState", {
				method: "POST",
				body: data,
			  })
			  .then((res) => res.json())
				  .then((CSCData) => {
					setTimeout(() => {
						this.closeLoadingRef.current.click(); // karan-task-3453-closes the loader
					}, 500);
					if (CSCData.message === "Invalid header in the uploaded file") {
						Swal.fire({
						  text: "Please Upload valid header file",
						  type: "warning",
						  confirmButtonText: "Ok",
						  allowOutsideClick: false,
						  preConfirm: () => {
							window.location.href = "/addCountry";
						  },
						});
						//kiran-4573-aaded swal fire for msg
						//Sandeep-task-3079-passsing filesData parameter in validateForm function
						return;
					  }
					  else if (CSCData.message === "All Data Already Exits") {
						Swal.fire({
						  text: "All cities and states are already present",
						  type: "success",
						icon: "success",
						  confirmButtonText: "Ok",
						  allowOutsideClick: false,
						  preConfirm: () => {
							window.location.href = "/addCountry";
						  },
						});
						return;
					}
					else if (CSCData.message === "File Uploaded successfully") {
						const filterData = CSCData.responsearray.filter(a => a.status === "Data Inserted Success");
						if( CSCData.responsearray.length === filterData.length){
							Swal.fire({
								text:  " Cities and States are upload sucessfully",
								type: "success",
							  icon: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
								  window.location.href = "/addCountry";
								},
							  });
							  return;
						}
						else{
							Swal.fire({
								text:  "Some records not uploaded. Please download error file for missing value records.",
								type: "warning",
							  icon: "warning",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								
							  });
							this.setState({
								DownloadErrorFile: CSCData.responsearray,
							});
							
							
						}
					}
					
				  })
				  
			}
		  }.bind(this)
		);
	  }
	  /**
	 * @author Rutuja Jagtap
	 * @param  Description Task-5243 - downloading error file function
	 * @return Description – used for downloading file
	 */
	  downloadErrorFile(e) {
		e.preventDefault(); 
	  if (this.state.DownloadErrorFile.length > 0) {
		
		
		var ws = xlsx.utils.json_to_sheet(this.state.DownloadErrorFile);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // /* write workbook */
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          // var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Nilesh-4242-Removing console warnings
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (
              var i = 0;
              i !== s.length;
              ++i //Nilesh-4242-Removing console warnings
            )
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          var fileName = this.state.extFileName;
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
	}
}
	/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-5243 - validate file function
	 * @return Description – used for validating  file
	 */
	  validateForm(filesData1 = "") {
		//Sandeep-task-3079-accessing filesData parameter in validateForm function
	
		var pclick = document.getElementById("pleaseClick");
		pclick.innerHTML = "";
		let formIsValid = true;
		let errors = {};
		let fileerrors = {};
	
		var campFileExt;
		if (this.state.getExt === "Excel") { //Nilesh-4240-Removing console warnings
		  campFileExt = "xlsx";
		} 
	
		
		if (leadLength === undefined) {  //Nilesh-4240-Removing console warnings
		  errors["file"] = "Please upload minimum one excel file";
		  formIsValid = "false";
		}
	
		if (leadLength !== undefined) {
		  // var allowedFiles = [".xls", ".xlsx", ".csv"];
		  var allowedFiles = [".xls", ".xlsx"];//kiran-4573-removed .csv file
	
		  var deliveryLeadfiles = filesData1; //sandeep-task-3079-filesData1 stored into deliveryLeadfiles
		  var regex = new RegExp(
			"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
		  );
		  if (!regex.test(deliveryLeadfiles.toLowerCase())) {
			//Sandeep-task-3079-accessing deliveryLeadfiles with .name to access Leadfile name
			fileerrors["fileextensions"] =
			  "Please upload files having extensions: " +
			  allowedFiles.join(", ") +
			  " only";
			formIsValid = "false";
		  }
		}
		this.setState({
		  errors: errors,
		  fileerrors: fileerrors,
		});
	
		return formIsValid;
	  }

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - react lifecycle function
	 * @return Description – used to check if user is logged in or not
	 */
	componentDidMount() {
		// karan-task-3453-checks and redirects to login page if user is not logged in
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
	} // end of componentDidMount function

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - handle <input> field changes
	 * @return Description – sets the state when user changes the input field data
	 */
	handleChangeText = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value }, () => {
			// karan-task-3453-this is to enable/disable search button
			if (this.state.country.length > 0 && this.state.state.length > 0) {
				this.setState({ isSearchDisabled: false });
			} else {
				this.setState({ isSearchDisabled: true });
			}
			// karan-task-3453-this is to enable/disable add button
			if (
				//this.state.countryShortName.length > 0 && // rutuja task- 4622 commented this as fetching country short name dynamically according to change of country and states
				this.state.stateShortName.length > 0 &&
				//this.state.countryPhoneCode.length > 0 &&// rutuja task- 4622 commented this as fetching country phone code dynamically according to change of country and states
				this.state.cityInputString.length > 0 &&
				this.state.country.length > 0 &&
				this.state.state.length > 0
			) {
				this.setState({ isAddDisabled: false });
			} else {
				this.setState({ isAddDisabled: true });
			}
			// karan-task-3453-this is for handling searched city name on single alphabet change by user
			if (name === "searchedCity") {
				this.setState({
					searchedCities: this.state.cities.filter((obj) =>
						obj.toLowerCase().includes(this.state.searchedCity.toLowerCase())
					),
				});
			}
		});
	}; // end of handleChangeText function

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - fired on Search button clicked
	 * @return Description – searhes the cities from database
	 */
	onSearchClick = (e) => {
		e.preventDefault();
		this.setState({ cityExists: false, invalidInput: false }); //karan-task-3453-refresh error state
		let { country, state, city } = this.state;
		country = country.trim();
		state = state.trim();
		city = city.trim();
		// karan-task-3453-regex to check only alphabets and whitespace is present
		const regx = new RegExp("^[0-9('\"]*[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([0-9(),_\\/&'\"]+[a-zA-Z0-9'\")\\s]+(0-9-[a-zA-Z'\")\\s]+)*)*[A-Za-z0-9'\"]"); //Rutuja Task-4769 changed the regex for acceting alphabets , numbers, and special characters '"()/&"
		if (country !== "" && state !== "") {
			if (regx.test(country) && regx.test(state)) {
				if (city === "") {
					this.fetchCitiesFromDB(country, state);
				} else if (regx.test(city)) {
					this.fetchCitiesFromDB(country, state, city);
				} else {
					this.setState({ invalidInput: true });
				}
			} else {
				// karan-task-3453-showing error for invalid input
				this.setState({ invalidInput: true });
			}
		}
	}; // end of onSearchClick function

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - handles Add button click
	 * @return Description – adds new cities data into database
	 */
	onAddClick = (e) => {
		e.preventDefault();
		this.setState({ cityExists: false, invalidInput1: false }); //karan-task-3453-refresh error state
	//	const regx = new RegExp("^([A-Za-z\\s]+)$"); // karan-task-3453-regex for checking alphabets and white space only
	const regx = new RegExp("^[0-9('\"]*[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([0-9(),_\\/&'\"]+[a-zA-Z0-9'\")\\s]+(0-9-[a-zA-Z'\")\\s]+)*)*[A-Za-z0-9'\"]"); //Rutuja Task-4769 changed the regex for acceting alphabets , numbers, and special characters '"()/&"

		let {
			country,
			state,
			//countryShortName, //Rutuja task 4796 commented as never used
			stateShortName,
			countryPhoneCode,
			cityInputString,
			countryCode, //rutuja task - 4622 
			agencyArray, //rutuja task - 4622 
		} = this.state; // karan-task-3453-destructuring frequently used state variables
		country = country.trim();
		state = state.trim();
		//rutuja task -4622
		//countryShortName = countryShortName.trim();
		countryCode = agencyArray[0].countryCode; 
		countryPhoneCode = agencyArray[0].countryPhoneCode;
		// let countryShortName = countryCode.trim();//Rutuja task 4796 commented as never used
		 stateShortName = stateShortName.trim();
		//countryPhoneCode = countryPhoneCode.trim();
		cityInputString = cityInputString.trim();
		let flag = false;
		if (!regx.test(countryCode)) {
			flag = true;
		}
		if (!regx.test(stateShortName)) {
			flag = true;
		}
		if (!regx.test(country)) {
			flag = true;
		}
		if (!regx.test(state)) {
			flag = true;
		}
		const regexPhone = new RegExp("^(\\+{0,1}[0-9]+)"); // karan-task-3453-regex for checking digits and + symbol only
		if (!regexPhone.test(countryPhoneCode)) {
			flag = true;
		}
		let cityInputArray = cityInputString.split(/[,\|\n]+/); // karan-task-3453-regex for spliting cityInputString into array based on ,(comma) and |(pipe) separator
		cityInputArray = cityInputArray
			.map((obj) => obj.trim()) // karan-task-3453-trims off whitespace(if any)
			.filter((obj) => obj !== "");
		cityInputArray.map((obj) => {
			if (!regx.test(obj)) {
				flag = true;
			}
		});
		if (flag) {
			this.setState({ invalidInput1: true }); // karan-task-3453-invalid input present
		} else {
			this.setState({ invalidInput1: false, cityInputArray }, () => {
				this.addCitiesToDB(); // karan-task-3453-all valid inputs found hence calling the add api here
			});
		}
	}; // end of onAddClick function

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - route to fetch cities data from database
	 * @return Description – communicates with backend and retrieves cities list for given country and state
	 */
	fetchCitiesFromDB = (country, state, city = "") => {
		//const start = performance.now();//Rutuja 4796 commented as never used
		this.loadingRef.current.click(); // karan-task-3453-opens the loader
		const data = {
			// karan-task-3453-creating data required by BE
			country: country.toLowerCase(),
			state: state.toLowerCase(),
			city: city !== "" && city.toLowerCase(),
		};
		const { user } = this.props.auth;
		// karan-task-3453-calling the api to search for cities
		fetch("/admin/searchCountryCityStateDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				setTimeout(() => {
					this.closeLoadingRef.current.click(); // karan-task-3453-closes the loader
				}, 500);
				if (response.success) {
					// karan-task-3453-cities found
					if (response.message === "") {
						// this means city array found
						this.setState({
							cities: response.cityArray,
							searchedCities: response.cityArray,
							
						});
					}
					this.getCountry(data); // rutuja task - 4622 
				} else {
					// rutuja task -4650 Display swal fire when city not found		
					if (
						response.message === "City Not Found" 
						
					) {
						
						// karan-task-3453-opens an alert with message in it
						Swal.fire({
							text: "City does not exist. Please add city",
						type: "error",
						allowOutsideClick: false,
					
						}).then((result) => {
							
							this.setState({
								cities: [],
								searchedCities: [""],
								
							});
							
						});
						this.getCountry(data); // rutuja task - 4622 
					} 
					// rutuja task -4650 Display swal fire when country is invalid	
					if (
						response.message === "Country Not Found"
					) {
						
						// karan-task-3453-opens an alert with message in it
						Swal.fire({
							text: "Invalid Country",
						type: "error",
						allowOutsideClick: false,
					
						}).then((result) => {
							
							this.setState({
								cities: [],
								searchedCities: [""],
								
							});
							
						});
					}
					// rutuja task -4650 Display swal fire when state is invalid
					if (
						
						response.message === "State Not Found" 
					) {
						
						// karan-task-3453-opens an alert with message in it
						Swal.fire({
							text: "Invalid state",
						type: "error",
						allowOutsideClick: false,
					
						}).then((result) => {
							
							this.setState({
								cities: [],
								searchedCities: [""],
								
							});
							
						});
						this.getCountry(data); // rutuja task - 4622 
					} else {
						// karan-task-3453-show no data icon and message
						this.setState({
							cities: [],
							searchedCities: [],
							invalidInput: false,
							invalidInput1: false, //4769
						});
					}
					this.setState({
						cities: [],
						invalidInput: false,
						invalidInput1: false, //4769
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setTimeout(() => {
					this.closeLoadingRef.current.click(); // karan-task-3453-closes the loader
				}, 500);
			});
	};
/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4622 - route for fetching data from database
	 * @return Description – communicates with backend and retrieves data from country_code_info 
	 */
	getCountry(data) {
		fetch("/admin/getCountry", {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(data),
		})
		  .then(res => res.json())
		  .then(agencyArray =>{
			this.setState({agencyArray:agencyArray})
				}).catch(function (err) {
			console.log(err)
			
		  });
	
	  }

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - rote to add new data to database
	 * @return Description – adds new cities to database
	 */
	addCitiesToDB = () => {
		const data = {
			// karan-task-3453-creating data required by BE
			country: this.state.country,
			state: this.state.state,
			countryShortName: this.state.countryShortName,
			stateShortName: this.state.stateShortName,
			countryPhoneCode: this.state.countryPhoneCode,
			city: this.state.cityInputArray,
		};
		const { user } = this.props.auth;
		// karan-task-3453-calling the api to add new data
		fetch("/admin/addCountryCityStateDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.success) {
					// karan-task-3453-success
					Swal.fire({
						text: "Data added successfully.",
						type: "success",
						icon: "success",
						allowOutsideClick: false,
					}).then((result) => {
						// karan-task-3453-setting all state variables to its original state
						// this.setState({
						// 	cities: [],
						// 	searchedCities: [],
						// 	country: "",
						// 	state: "",
						// 	city: "",
						// 	isSearchDisabled: true,
						// 	isAddDisabled: true,
						// 	invalidInput: false,
						// 	cityExists: false,
						// 	searchedCity: "",
						// 	countryShortName: "",
						// 	stateShortName: "",
						// 	countryPhoneCode: "",
						// 	cityInputString: "",
						// 	cityInputArray: [],
						// });
						window.location.reload(); //Rutuja - task -4622 refresh page after adding city sucessfully
					});
				} else {
					if (response.mesg === "This country, State and City already Exists") {
						this.setState({ cityExists: true });
					} else {
						Swal.fire({
							text: "Data not added",
							icon: "error",
							type: "error",
							allowOutsideClick: false,
						});
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}; // end of addCitiesToDB function

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - react lifecycle function
	 * @return Description – renders the jsx for current component
	 */
	render() {
		return (
			<div>
				{/* karan-task-3453-load the navigation bar for admin user */}
				<AdminNavpage />
				{/* karan-task-3453-loader to display when async calls are being made*/}
				<Loader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Wait a moment while we are loading your data."
				/>
				<div
					style={{
						marginTop: 90,
						marginLeft: 40,
						marginRight: 40,
					}}>
					{/* karan-task-3453- Start - Page Title */}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginBottom: 20,
						}}>
						{/* <span className="custom-page-header">Add Country/State/City</span> */}
						{/* rutuja task - 4622 change page title from add country/state/city to Add city */}
						<span className="custom-page-header">Add City</span> 
					</div>
					<div>
						{/* <span className="custom-page-header">Add Country/State/City</span> */}
						{/* rutuja task-4622 added below note for user  */}
						<p><span style={{ color: "red" }}>*</span> Please enter Country and State to Search City </p>
					</div>
					{/* karan-task-3453- End - Page Title */}

					{/* karan-task-3453- Start - Error Block */}
					{/* karan-task-3453- Start - validation errors */}
					{(this.state.invalidInput || this.state.cityExists) && (
						<div style={{ border: "1px solid red", marginBottom: 20 }}>
							<div
								className="errorMessage"
								style={{
									padding: "8px 15px",
								}}>
									{/* Rutuja Task 4769 Changed the error messgae */}
								{this.state.invalidInput && (
									<li className="error-message">
										<b>Please Add valid country, State or City Name.</b>
									</li>
								)}
								{this.state.cityExists && (
									<li className="error-message">
										<b>Entered cities already exists in DI.</b>
									</li>
								)}
							</div>
						</div>
					)}
					{/* karan-task-3453- End - validation errors */}
					{/* karan-task-3453- End - Error Block */}

					{/* karan-task-3453- Start - Search Container */}
					<form>
						<div className="row align-items-end">
							{/* karan-task-3453- Start - Country field */}
							<div className="col-lg-2.5 mb-3 mb-lg-0"> 
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
									}}>
									<label className="form-field-label">
										Country :&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										className="form-control form-field"
										type="text"
										name="country"
										value={this.state.country}
										onChange={this.handleChangeText}
										placeholder="Enter country"
									/>
								</div>
							</div>
							{/* karan-task-3453- End - Country field */}
							&nbsp;&nbsp;&nbsp;
							{/* karan-task-3453- Start - State field */}
							<div className="col-lg-2.5 mb-3 mb-lg-0">
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
									}}>
									<label className="form-field-label">
										State :&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										className="form-control form-field align-bottom"
										type="text"
										name="state"
										value={this.state.state}
										onChange={this.handleChangeText}
										placeholder="Enter state"
									/>
								</div>
							</div>
							{/* karan-task-3453- End - State field */}
							&nbsp;&nbsp;&nbsp;
							{/* karan-task-3453- Start - City field */}
							<div className="col-lg-2.5 mb-3 mb-lg-0">
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
									}}>
									<label className="form-field-label">City :</label>
									<input
										className="form-control form-field"
										type="text"
										name="city"
										value={this.state.city}
										onChange={this.handleChangeText}
										placeholder="Enter city"
									/>
								</div>
								
							</div>
							{/* karan-task-3453- End - City field */}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							{/* karan-task-3453- Start - Search Button */}
							<div className="col-lg-2 mb-3 mb-lg-0">
								<button
									className="primary-button"
									style={{ marginLeft: "5%" }}
									onClick={this.onSearchClick}
									disabled={this.state.isSearchDisabled}>
									Search
								</button>
							</div>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							{/* Rutuja 5243 added upload file button */}
							<div className="col-lg-2.5 mb-3 mb-lg-0">
								<button
									 id="file"
									className="primary-button"
									onClick={this.handleChangeFileupload}
									>
									Upload File
								</button>
								&nbsp;&nbsp;&nbsp;
								<HtmlTooltip 
									title={
									<React.Fragment>
										<li style={{ wordWrap: 'break-word' }}>
										{
											"File Must contain four columns country, city, state & stateshortname"
										}
										</li>
									</React.Fragment>
									}
								>
									<Icon type="question-circle" />
								</HtmlTooltip>
								</div>
								{/* Rutuja 5243 added download error file button it will show when thier is an error in file*/}
							{this.state.DownloadErrorFile.length > 0  ? (
								<div className="col-lg-1 mb-3 mb-lg-0"
								// style={{ marginTop: 10, marginBottom: 10 , float: "right" }}
								>
										<a href="#/">
										<i class="fa fa-download"
										 style={{fontSize:"200%"}}
											title="Download Error File" 
											onClick={this.downloadErrorFile}></i>
									</a>
									
								
									
											
								</div>
							):(
								""
							)}
							
							{/* karan-task-3453- End - Search Button */}
						</div>
					</form>

					{/* karan-task-3453- End - Search Container */}

					<hr className="divider" />

					<div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
						{/* karan-task-3453- Start - Left Box */}
						<div
							className="col-lg-9"
							style={{ borderRight: "0.5px solid #ccc" }}>
							{/* karan-task-3453- Start - Box Heading and Search */}
							<div
								className="row justify-content-between"
								style={{ marginBottom: 10 }}>
								{/* karan-task-3453- Start - Cities heading */}
								<div className="col-lg-6">
									<span className="page-label-addCountry">
										Cities that already added :
									</span>
								</div>
								{/* karan-task-3453- End - Cities heading */}
								{/* karan-task-3453- Start - Search Box */}
								<div className="col-lg-6 d-flex justify-content-end">
									<input
										style={{
											backgroundImage: "url(searchIcon.png)",
											backgroundPosition: "8px 6px",
											backgroundSize: "12px 12px",
											backgroundRepeat: "no-repeat",
											height: "20px",
											fontSize: "10px",
											border: "1px solid #ddd",
											padding: "12px 20px 12px 34px",
											backgroundColor: "#ffffff",
											borderRadius: 4,
										}}
										type="text"
										placeholder="Search city"
										name="searchedCity"
										value={this.state.searchedCity}
										onChange={this.handleChangeText}
									/>
								</div>
								{/* karan-task-3453- Start - Search Box */}
							</div>
							{/* karan-task-3453- End - Box Heading and Search */}

							{/* karan-task-3453- Start - Cities */}
							<div
								className="card custom-border-addCountry"
								style={{
									padding: "15px 0px",
									margin: 0,
								}}>
								{this.state.searchedCities.length > 0  ? (
									<div
										style={{
											marginLeft: 15,
											display: "grid",
											gridTemplateColumns: "repeat(5, 1fr)",
											columnGap: 10,
											rowGap: 6,
											overflowY: "auto",
											maxHeight: 155,
										}}>
										{this.state.searchedCities.map((city, i) => (
											<label
												key={i}
												style={{
													margin: 0,
													fontWeight: 400,
													textTransform: "capitalize",
												}}>
												{city}
											</label>
										))}
									</div>
								) : (
									<div className="d-flex justify-content-center">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</div>
								)}
							</div>
							{/* karan-task-3453- End - Cities */}
						</div>
						{/* karan-task-3453- End - Left Box */}
						{/* karan-task-3453- Start - Right Box */}
						<div
							className="col-lg-3"
							style={{
								display: "flex",
								flexDirection: "column",
							}}>
							{/* karan-task-3453- Start - Right Box Heading */}
							<span
								className="page-label-addCountry"
								style={{ marginBottom: 10 }}>
								Enter cities you want to add :
							</span>
							{/* Rutuja Task-4769 Added the error message if enter wrong state short name and cities */}
							{(this.state.invalidInput1 ) && (
						<div style={{ border: "1px solid red", marginBottom: 20 }}>
							<div
								className="errorMessage"
								style={{
									padding: "8px 15px",
								}}>
								{this.state.invalidInput1 && (
									<li className="error-message">
										<b>Please add valid stateShortName or cities name.</b>
									</li>
								)}
								
							</div>
						</div>
					)}
							<table>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											Country short name :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										{/* rutuja task - 4622 */}
										{/* <input
											className="form-control form-field"
											type="text"
											name="countryShortName"
											value={this.state.countryShortName}
											onChange={this.handleChangeText}
										/> */}
									{/* Rutuja Task-4769 If Agency array is null then country code will be disabled */}

									{this.state.agencyArray.length === 0 ? (
									<input
									className="form-control form-field align-bottom"
									type="text"
									name="countryCode"
									disabled
									></input>
											):(
												this.state.agencyArray.map((agencyArray) =>  (	
													<input
											className="form-control form-field align-bottom"
											type="text"
											name="countryCode"
											value={agencyArray.countryCode}
											defaultValue="IN"
											disabled
											></input>
											))
											)}

									</td>
								</tr>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											State short name :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										<input
											className="form-control form-field"
											type="text"
											name="stateShortName"
											value={this.state.stateShortName}
											onChange={this.handleChangeText}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											Country phone code :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										{/* rutuja task - 4622 */}
									{/* {this.state.agencyArray.map((agencyArray) => (	<input
											className="form-control form-field align-bottom"
											type="number"
											name="countryPhoneCode"
											value={agencyArray.countryPhoneCode}
											onChange={this.handleChangeText}
											defaultValue="91"
											disabled>
												</input>
										))} */}
									{/* Rutuja Task-4769 If Agency array is null then country phone code will be disabled */}
									{this.state.agencyArray.length === 0 ? (
									<input
									className="form-control form-field align-bottom"
									type="number"
									name="countryPhoneCode"
									disabled>
										</input>
									):(
										this.state.agencyArray.map((agencyArray) =>  (	
											<input
											className="form-control form-field align-bottom"
											type="number"
											name="countryPhoneCode"
											value={agencyArray.countryPhoneCode}
											onChange={this.handleChangeText}
											defaultValue="91"
											disabled>
												</input>
											))
											)}

									</td>
								</tr>
							</table>

							<span
								className="form-field-label"
								style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
								Cities :&nbsp;<span style={{ color: "red" }}>*</span>
							</span>
							<div
								className="card custom-border-addCountry"
								style={{
									padding: "15px 0px",
									margin: 0,
									display: "flex",
									flexGrow: 1,
								}}>
								<textarea
									className="input-field-addition"
									style={{ maxHeight: 155, flexGrow: 1 }}
									placeholder="Enter cities"
									name="cityInputString"
									onChange={this.handleChangeText}
									value={this.state.cityInputString}
								/>
							</div>
							{/* karan-task-3453- End - Right Box Heading */}
						</div>
						{/* karan-task-3453- End - Right Box */}
					</div>

					{/* karan-task-3453- Add button */}
					<button
						className="primary-button"
						style={{ float: "right", marginBottom: 60 }}
						onClick={this.onAddClick}
						disabled={this.state.isAddDisabled}>
						Add
					</button>
				</div>
				<Footer />
			</div>
		);
	}
}

AddCountry.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(AddCountry));
