/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Raunak Thakkar
 *@fileName :addAdvertiser.js
 *Desc: Add the Advertiser
 *Date: 14-08-2019
 */

import React, { Component } from "react";
import { Input, Form } from "antd";
import Header from "../layouts/header1";
import Footer from "../layouts/footer";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navigation from "../layouts/navPage";
import "antd/dist/antd.css";
import "./addAdvertiser.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Priyanka-task-3720-added default header

//import Swal from 'sweetalert2'
const queryString = require("query-string");
const Swal = require("sweetalert2");
class addAdvertiser extends Component {
	constructor() {
		super();
		this.state = {
			Id: "",
			Name: "",
			clientList: [],
			newsuccess: "",
			newfailure: "",
			parseBackValue: "",
			campID: "",
			parentCampID: "",
			reallocationID: "",
			rfpCampaignID: "",
			tableState: { size: "small" },
		};
		this.idHandleChange = this.idHandleChange.bind(this);
		this.nameHandleChange = this.nameHandleChange.bind(this);
		this.Submithandle = this.Submithandle.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	idHandleChange(e) {
		this.setState({ Id: e.target.value });
	}

	nameHandleChange(e) {
		this.setState({ Name: e.target.value });
	}
	handleBack(e) {
		e.preventDefault();
		var parsed;//Somnath Task-2828, Get URL dynamic
		if(this.props.location.state!==undefined){
			parsed = this.props.location.state;
		}else{
			parsed = queryString.parse(this.props.location.search);
		}
		if (e.target.id === "edit") {
			this.props.history.push("/newEditCampaign", {
				campID: this.state.campID,
				parentCampID: this.state.parentCampID,
				reallocationID: this.state.reallocationID,
			}); // karan-task-3767-replace query params
		}
		if (e.target.id === "incomplete") {
			let status = "ClientBasicDetails";
			this.props.history.push("/createcampaign2", {
				campID: this.state.campID,
				status,
			}); // karan-task-3767-replace query params
		}
		if (e.target.id === "setup") {
			this.props.history.push("/createcampaign2", {
				agencyAllocationID: parsed.agencyAllocationID,
				advCampID: parsed.advcampID,
				from:"Client",
				BasicDetailArray:parsed.BasicDetailArray
			}); // karan-task-3767-replace query params
		}
		if (e.target.id === "rfp") {
			this.props.history.push("/createcampaign2", {
				rfpCampaignID: parsed.rfpCampaignID,
				from:"Client",
			}); // karan-task-3767-replace query params
		}
	}
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
		 else { 
			//const { user } = this.props.auth;  //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
			// var agencyID = user.id; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
			var parseBack;
			if (this.props.location.state !== undefined) {
				parseBack = this.props.location.state; //Somnath Task-3936-replace query params
			}else{
				parseBack = queryString.parse(this.props.location.search);
			}
			this.setState({
				campID: parseBack.campID,
				parentCampID: parseBack.parentCampID,
				reallocationID: parseBack.reallocationID,
				rfpCampaignID: parseBack.rfpCampaignID,
				parseBackValue: parseBack.add,
			});

			
			fetch("/campaign/getClientList")
				.then((res) => res.json())
				.then((clientList) => {
					this.setState({ clientList: clientList
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	/**
	 * @author Sonali Kalke
	 * @param  Add client
	 * @return Description
	 */
	Submithandle(e) {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err) => {
			if (!err) {
				var clientID = this.state.Id;
				var clientName = this.state.Name;

				//const { user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
				//var agencyID = user.id; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
				let data = {
					clientID: clientID,
					clientName: clientName,	 	//Priyanka--3720--removed param
				};

				fetch("/campaign/addClient", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success === true) {
							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
							//alert(this.state.newsuccess)
						}

						if (res.failed === true) {
							Swal.fire({
								text: res.message,
								type: "error",
								confirmButtonText: "Ok",
								preConfirm: () => {
									window.location.reload();
								},
							});

							//alert(a)
						}
						// setTimeout(() => {
						//   window .location.reload();
						// }, 2000);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				//alert("nothing happends")
			}
		});
		//alert("values====>"+this.state.Id+" "+this.state.Name);
	}
	/**
  * @author Somnath Keswad
  * @param  Description Task-2525, Create GetBack Function
  * @return Description Redirect to createcampaign Page
  */
	getBack(e) {
		e.preventDefault()
		var BasicDetailArray = [];
		if (this.props.location.state !== undefined) {
			var parseBack = this.props.location.state; //Somnath Task-3936-replace query params
			BasicDetailArray = parseBack.BasicDetailArray;
		}
		this.props.history.push("/createcampaign2", {
			from: "Client", BasicDetailArray
		});
	}
	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		};

		// const columns = [
		//   {
		//     title: 'Sr-no',
		//     dataIndex: 'srNO',
		//      width: 160,

		//   },

		//   {
		//       title: 'Client/Advertiser-id',
		//       dataIndex: 'clientID',
		//        width: 160,
		//     },

		//     {
		//     title: 'Name',
		//     dataIndex: 'clientName',
		//   //   width: 150,
		//   },

		// ];

		const { getFieldDecorator } = this.props.form;

		return (
			<div>
				<Header />
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "130px" }}>
				                  {/* kiran - task 3873- alignement of back button */}
					<div class="row first-img" style={{paddingLeft:"20px"}}> 
						{/* { this.state.parseBackValue===" step1rfp"?<div  class="col-xs-6 col-lg-4"id="rfp" ><button class="btn add-button" id="rfp"><a id="rfp" onClick={this.handleBack} style={{color:'#fff'}}> Back </a></button></div>:this.state.parseBackValue===" step1newsetup"?<div  class="col-xs-6 col-lg-4"id="setup" ><button class="btn add-button" id="setup"><a id="setup" onClick={this.handleBack} style={{color:'#fff'}}> Back </a></button></div>:this.state.parseBackValue===" step1newincomplete"?<div  class="col-xs-6 col-lg-4" id="incomplete"><button class="btn add-button" id="incomplete"><a onClick={this.handleBack}id="incomplete" style={{color:'#fff'}}> Back </a></button></div>:this.state.parseBackValue===" step1new"?<div  class="col-xs-6 col-lg-4"id="edit" ><button class="btn add-button" id="edit"><a id="edit" onClick={this.handleBack} style={{color:'#fff'}}> Back</a></button></div>:this.state.parseBackValue===' step1newCreate'?
       <div  class="col-xs-6 col-lg-4" ><button class="btn add-button"><a href={'/createCampaign2'} style={{color:'#fff'}}> Back</a></button></div>:
       this.state.parseBackValue===' step1newEdit'?
       <div  class="col-xs-6 col-lg-4" ><button class="btn add-button"><a href={'/newEditCampaign?campID='+this.state.campID} style={{color:'#fff'}}> Back</a></button></div>:
       this.state.parseBackValue===' step1'? this.state.campID!=null?
       <div  class="col-xs-6 col-lg-4" ><button class="btn add-button"><a href={'/createCampaign?campID='+this.state.campID+'&step1'} style={{color:'#fff'}}> Back</a></button></div>

       : <div  class="col-xs-6 col-lg-4" ><button class="btn add-button"  ><a href="/createCampaign" style={{color:'#fff'}}> Back</a> </button></div>
       : <div  class="col-xs-6 col-lg-4" ></div>
      } */}
						{/* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE (condition added for RFP)*/}
						{this.state.parseBackValue === "step1rfp" ? (
							
							<a id="rfp" href={"#/"}> {/*Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu */}
								{" "}
								<img
									src="back_button.png"
									id="rfp"
									style={{ height: "40px", width: "40px" }}
									onClick={this.handleBack} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>

						) : this.state.parseBackValue === "step1newsetup" ? (
							<a id="setup" href={"#/"}> {/*Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu */}
								<img
									src="back_button.png"
									id="setup"
									style={{ height: "40px", width: "40px" }}
									onClick={this.handleBack} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === "step1newincomplete" ? (
							<a id="incomplete" href={"#/"}> {/*Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu */}
								<img
									src="back_button.png"
									id="incomplete"
									style={{ height: "40px", width: "40px" }}
									onClick={this.handleBack} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === "step1new" ? (
							<a id="edit" href={"#/"}> {/*Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu */}
								<img
									src="back_button.png"
									id="edit"
									style={{ height: "40px", width: "40px" }}
									onClick={this.handleBack} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === "step1newCreate" ? (
							<a
								onClick={this.getBack.bind(this)}//Task-2525, Create GetBack Function
								// karan-task-3767-replace query params
								// onClick={() => {
								// 	this.props.history.push("/createcampaign2", {
								// 		from: "Client",
								// 	});
								// }}
								//Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
								href={"#/"} 
								>  
								{" "}
								<img
									src="back_button.png"
									style={{ height: "40px", width: "40px" }} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === "newCreateRfp" ? (
							<a //Somnath Task-3936, Add URL in history Object
							href={"#/"} // Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu 
							onClick={(e) => {
								e.preventDefault();
								this.props.history.push("/requestForProposal2", {
									from: "Client",
								});
							}}>
								{" "}
								<img
									src="back_button.png"
									style={{ height: "40px", width: "40px" }} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === "editRfp" ? (
							<a //Somnath Task-3936, Add URL in history Object
							href={"#/"} // Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu 
							onClick={(e) => {
								e.preventDefault();
								this.props.history.push("/requestForProposal2", {
									rfpCampaignID: this.state.rfpCampaignID
								});
							}}>
								{" "}
								<img
									src="back_button.png"
									style={{ height: "40px", width: "40px" }} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === " step1newEdit" ? (
							<a
							href={"#/"} // Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu 
								onClick={(e) => {
									e.preventDefault();	
									this.props.history.push("/newEditCampaign", {
										campID: this.state.campID,
									}); // karan-task-3767-replace query params
								}}>
								<img
									src="back_button.png"
									style={{ height: "40px", width: "40px" }} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
							</a>
						) : this.state.parseBackValue === " step1" ? (
							this.state.campID != null ? (
								<a
									href={
										"/createCampaign?campID=" + this.state.campID + "&step1"
									}>
									<img
										src="back_button.png"
										style={{ height: "40px", width: "40px" }} alt=""></img> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
								</a>
							) : (
								<a href="/createCampaign">
									{" "}
									<img
										src="back_button.png"
										style={{ height: "40px", width: "40px" }} alt=""> {/*Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu */}
										{" "}
									</img>
								</a>
							)
						) : (
							<a //Somnath Task-3936, Add URL in history Object
							href={"#/"} // Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu 
							onClick={(e) => {
								e.preventDefault();
								this.props.history.push("/newdashboard");
							}}>
								{" "}
								{/* <img
									src="back_button.png"
									style={{ height: "40px", width: "40px" }}></img> */}
									<FaArrowAltCircleLeft size={32} color="#337ab7" />  
							</a>
						)}

						{/* kiran- task 3873- aligned title in center */}
					   <div   class=" col-xs-6 col-md-8 col-lg-4 offset-lg-4 "
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}>

						
						<span
							style={{
								fontSize: 28,
								fontWeight: "500",
								color: "#193D8F",
								fontFamily: "Roboto",
							}}>
							Add : Advertiser
						</span>
					</div>
						<div class=" col-lg-4">
							{" "}
							<label></label>
						</div>
					</div>

					<Form {...formItemLayout} onSubmit={this.Submithandle}>
						<div style={{ paddingTop: "32px" }}>
							<div class="row">
								<div class="col-xs-12  col-sm-12 col-md-4 col-lg-2">
									<Form.Item label="Id ">
										{getFieldDecorator("Advid", {
											rules: [
												{
													// pattern: /^[0-9][0-9]{0,3}$/,
													pattern: /^(?!0+$)[0-9][0-9]{0,3}$/,   //kiran-4879-changed pattern for not accepting only '0' and multiple combinations of 0
													required: true,
													message: "Please enter the Id upto 4 digits",
												},
											],
										})(
											<Input
												onChange={this.idHandleChange}
												id="Advid"
												name="Advid"
											/>
										)}
									</Form.Item>
								</div>
								<div class="col-xs-12  col-sm-12 col-md-4 col-lg-6">
									<Form.Item label="Name">
										{getFieldDecorator("Advname", {
											rules: [
												//Nilesh-5237-added below validator for client name
												{
													required: true,
													message: "Please enter a valid advertiser name",
												},
												{
													validator: (rule, value, callback) => {
														if (!value) {
															callback(); //Nilesh-5237-added If value is empty, required rule will handle it
														} 
														//Rutuja task 5316-commented to AC-Add Advertiser-Allow apostrophe in client name
														// else if (value.includes("'")) {
														// 	callback("Advertiser name should not contain apostrophes"); //Nilesh-5237-added For not accept apostrophe(').
														// }
														 else if (value.includes("/") || value.includes("\\")) {
															callback("Advertiser name should not contain slashe(/) or backslashe(\\)"); //Nilesh-5255-added validation for not accept Slash(/) and Backslash(\).
														} else if (!/^[a-zA-Z]+[a-zA-Z0-9]*|[0-9a-zA-Z]*[a-zA-Z]+$/.test(value)) {
															callback("Please enter a valid advertiser name"); //Nilesh-5237-added For accepting Combination letters and Numbers 
														} else {
															callback(); //Nilesh-5237-added for No errors
														}
													},
												},
												// {
												// 	pattern: /^[a-zA-Z]+[a-zA-Z0-9]|[0-9a-zA-Z]+[a-zA-Z]+$/, //Rutuja Jagtap Bug-4022 For accepting Combination letters and Numbers  
												// 	required: true,
												// 	message: "Please enter valid advertiser name",
												// },
											],
										})(
											<Input
												onChange={this.nameHandleChange}
												id="Advname"
												name="Advname"
											/>
										)}
									</Form.Item>
								</div>
								<div class="col-xs-12  col-sm-12 col-md-4 col-lg-3">
									<div class="float-right">
										<Form.Item>
											<button
												class="btn add-button"
												type="primary"
												htmlType="submit"
												style={{
													backgroundColor: "#056eb8",
													color: "#fff",
													width: "min-content",
													height: "31px",
													fontSize: "14px",
													borderColor: "#056eb8",
													marginRight: "15px",
												}}>
												Add
											</button>
										</Form.Item>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{ fontSize: "24px", color: "green", textAlign: "center" }}>
							{this.state.newsuccess}
						</div>
						<div
							style={{ fontSize: "24px", color: "red", textAlign: "center" }}>
							{this.state.newfailure}
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-4 col-lg-4">
								<label
									style={{
										color: "#056eb8",
										fontSize: "20px",
										marginLeft: "15px",
									}}>
									{" "}
									Advertiser List
								</label>
							</div>
						</div>
                       
						<div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div  style={{ overflowY: "scroll", height: "300px" }}>
							
								<table
									class="table table-bordered"
									style={{
										paddingTop: "80px",
										borderCollapse: "collapse",
										border: "1px solid #D3D3D3",
										overflowX: "hidden",
									}}>
									<thead >
										<tr  style={{ textAlign: "center", height: "50px" }}>
											<th 
												style={{
													width: "100px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
													position: "sticky", //kiran - task 3873 -header position fixed
													top:"0"  //kiran - task 3873 -header position fixed
													
												}}>
												Sr.no
											</th>
											<th
												style={{
													width: "250px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
													position: "sticky", //kiran - task 3873 -header position fixed
													top:"0"  //kiran - task 3873 -header position fixed
												}}>
												Advertiser id
											</th>
											<th
												style={{
													width: "75%",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
													position: "sticky", //kiran - task 3873 -header position fixed
													top:"0"  //kiran - task 3873 -header position fixed
												}}>
												Name
											</th>
										</tr>
									</thead>
									
									{this.state.clientList.map((clientList) => {
										return (
											<tbody >
												<tr  style={{ textAlign: "center", height: "50px" }}>
													<td>{clientList.srNO}</td>
													<td>{clientList.clientID}</td>
													<td>{clientList.clientName}</td>
												</tr>
											</tbody>
										);
									})}
								</table>
								
							</div>
						</div>
						
                        

					</Form>
				</div>
				<Footer />
			</div>
		);
	}
}
addAdvertiser = Form.create({ name: "addAdvertiser" })(addAdvertiser);

addAdvertiser.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(addAdvertiser)
);
