/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React,{ Component } from 'react'
import {BrowserRouter as Router,Route,Link} from 'react-router-dom' 
import Footer from './footer';


export default class Header extends Component{
    render()
    {
        return(
            <div>
            <nav className="navbar navbar-expand-md navbar-dark navbar-fixed-top shadow">
            {/* <a className="logo">DEMAND INTEGRATE</a> */}
            {/* Nilesh-5131- Add MbleadHub logo image*/}
             {/* rutuja-5131- Add leads -nexus logo image*/}
            <img src="Leads-Nexus.png" alt="Leads-Nexus" height="50px" width="200px" style={{ pointerEvents: "none"}}></img> 
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
            <span class="navbar-toggler-icon"></span>
            </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              
          
         
         
         
            </div>
           
          </nav>
          {/* <br></br>  <br></br><br></br><br></br><br></br>
         <Footer/> */}
         </div>

              )
    }
}


