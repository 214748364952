/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Sandeep Dhawale
 * @description : added loader for all lead Review page
 */
 import React from "react";

 function LeadReviewLoader({ loadingRef, closeLoadingRef, message }) {
     return (
         <div>
             <button
                 ref={loadingRef}
                 style={{ display: "none" }}
                 type="button"
                 class="btn btn-primary"
                 data-toggle="modal"
                 data-target="#loadingModal"></button>
             <div
                 id="loadingModal"
                 class="modal fade"
                 style={{ backgroundColor: "rgba(0,0,0,.4)" }}
                 role="dialog"
                 data-backdrop="static"
                 data-keyboard="false"
                 aria-hidden="true">
                 <div
                     class="modal-dialog modal-sm modal-dialog-centered"
                     style={{ marginTop: 0, marginBottom: 0 }}>
                     <div class="modal-content">
                         <button
                             type="button"
                             style={{ display: "none" }}
                             ref={closeLoadingRef}
                             data-dismiss="modal"
                             aria-label="Close">
                             <span aria-hidden="true">&times;</span>
                         </button>
                         <div
                             style={{
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: "center",
                                 paddingTop: 5,
                                 paddingBottom: 10,
                                 paddingLeft: 10,
                                 paddingRight: 10,
                             }}>
                             {/* Nilesh-5128- Add new Mb loader */}
                             <img src="LN.gif" alt="LN" style={{ height: 80, width: 80 }} />
                             <span
                                 style={{
                                     color: "#14254A",
                                     fontSize: 16,
                                     textAlign: "center",
                                 }}>
                                 {message}
                             </span>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     );
 }
 
 export { LeadReviewLoader };
 