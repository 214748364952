/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
import React,{ Component } from 'react'
import {BrowserRouter as Router,Route,Link} from 'react-router-dom' 

 
export default class Footer extends Component{
    constructor()
    {
        super()
        this.handleWebsite=this.handleWebsite.bind(this)
    }//end of constructor
    handleWebsite(e)
    {
        e.preventDefault(); //Nilesh-5127- remove # from link
        // window.open("https://apssmedia.com");
        window.open("https://machbizz.com/"); //Nilesh-5127- Add Machbizz link
    }
    
    render()
    {

        const date = new Date();
        const year = date.getFullYear() //4655-Nilesh- Add dynamic year in footer section 
        return(

            <div class="container-fluid">
            <div class="row">
            <div class="Footer" style={{height: "27px"}}> 
            {/* bug-4467-nilesh-minimum footer height */}
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    {/* sandeep-task-3127-added change in year for footer */}
                <p style={{color:'#EEEEEE', marginTop: "-5px"}}>Copyright &copy;{year} Leads Nexus Powered By&nbsp;
                    <a href={'#/'} onClick={this.handleWebsite} style={{color:'#2196f3'}}>Machbizz</a></p>
               
                </div>
           </ div>
            </div>
            </div>




            // <div class="Footer">
            //    <p class="text-center text-muted">Copyright &copy;2018 Vector Powered By&nbsp;
            //      <Link to="/apssmedia">APSS Media.com</Link></p>
            // </div>
               
           
        )
    }
}
