/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Shivani Pathak
*@fileName : agencyPublisherLeadtracking.js
@Creation Date:08-04-2021
 *Desc: 
*/
import React from "react";
//import { Tabs, Tab } from 'react-bootstrap'; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Navigation from "../layouts/navPage";
//import * as $ from "jquery"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./agencyMyReports.css";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
//import styled from "styled-components"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE

// import Loader from "../Reports/report_Loader";

import { css } from "emotion";
import { Table, Checkbox } from "antd"; //Aman-4797-(remove Tooltip, Spin not in use)-Console Warnings-Agency-Reports Part 2

//const CheckboxGroup = Checkbox.Group; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
const Swal = require("sweetalert2");

const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "13px",
    textAlign: "center !important",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center !important",
    color: "white",
    fontSize: "12px",
  },
});
//const queryString = require('query-string'); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
// const PickyStyle = styled(Picky) //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
// //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API - FE
// `

// background-color: #bd141480 !important;
// border-radius: '4px !important'

// #-list.picky__dropdown{
//   overflow:'hidden !important';
// }
// .picky__placeholder {
//     color: #000000 !important;
// }

// .picky {
//     width:148px !important;
//     height:32px !important;
//   }
// }
//  `
//  ;

//  .picky__dropdown,
//  .drpdisable_report{
//    font-size: 2em;
//  }
// shivani-3557 - change width for table ,
let z = 8000;
let a = 60;
if (a === 60) {
  //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
  z = 120 * a;
}
//shivani - 3415 - added column array  for table .
//  shivani-3538-changed table content color .
const columnsReport = [
  {
    //  shivani-3538-replace pID to PID
    title: (
      <span>
        PID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "pID",
    key: "pID",
    width: "10%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.pID < b.pID) {
        return asc ? -1 : 1;
      } else if (a.pID > b.pID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.pID}</span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        CampID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "campID",
    key: "campID",
    width: "15%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.campID < b.campID) {
        return asc ? -1 : 1;
      } else if (a.campID > b.campID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.campID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Lead Interaction Date&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "leadInteractionDate",
    key: "leadInteractionDate",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.leadInteractionDate < b.leadInteractionDate) {
        return asc ? -1 : 1;
      } else if (a.leadInteractionDate > b.leadInteractionDate) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.leadInteractionDate}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Email&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "email",
    key: "email",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.email.toUpperCase() > b.email.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.email}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        First Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "firstName",
    key: "firstName",
    width: "28%",
    align: "left",
    sorter: (a, b) => a.firstName - b.firstName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.firstName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Last Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "lastName",
    key: "lastName",
    width: "28%",
    align: "left",
    sorter: (a, b) => a.lastName - b.lastName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.lastName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Campaign Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "campaignName",
    key: "campaignName",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.campaignName < b.campaignName) {
        return asc ? -1 : 1;
      } else if (a.campaignName > b.campaignName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.campaignName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInCompanyName",
    key: "linkedInCompanyName",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInCompanyName < b.linkedInCompanyName) {
        return asc ? -1 : 1;
      } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInCompanyName === "" ||
      record.linkedInCompanyName === undefined ||
      record.linkedInCompanyName === null ||
      record.linkedInCompanyName === "undefined" ||
      record.linkedInCompanyName === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInCompanyName}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobTitle",
    key: "jobTitle",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobTitle < b.jobTitle) {
        return asc ? -1 : 1;
      } else if (a.jobTitle > b.jobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span id="to_truncate2" style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobTitle}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInJobTitle",
    key: "linkedInJobTitle",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInJobTitle < b.linkedInJobTitle) {
        return asc ? -1 : 1;
      } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInJobTitle === "" ||
      record.linkedInJobTitle === undefined ||
      record.linkedInJobTitle === null ||
      record.linkedInJobTitle === "undefined" ||
      record.linkedInJobTitle === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInJobTitle}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Job Level&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobLevel",
    key: "jobLevel",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobLevel < b.jobLevel) {
        return asc ? -1 : 1;
      } else if (a.jobLevel > b.jobLevel) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobLevel}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Job Function&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobFunction",
    key: "jobFunction",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobFunction < b.jobFunction) {
        return asc ? -1 : 1;
      } else if (a.jobFunction > b.jobFunction) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobFunction}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Address&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "address",
    key: "address",
    width: "30%", //shivani-3538-increase width for address..
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.address < b.address) {
        return asc ? -1 : 1;
      } else if (a.address > b.address) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.address}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        City&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "city",
    key: "city",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.city < b.city) {
        return asc ? -1 : 1;
      } else if (a.city > b.city) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.city}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        State&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "state",
    key: "state",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.state < b.state) {
        return asc ? -1 : 1;
      } else if (a.state > b.state) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.state}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Country&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "country",
    key: "country",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.country < b.country) {
        return asc ? -1 : 1;
      } else if (a.country > b.country) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.country}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Street&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "street",
    key: "street",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.street < b.street) {
        return asc ? -1 : 1;
      } else if (a.street > b.street) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.street === "" ||
      record.street === undefined ||
      record.street === null ||
      record.street === "undefined" ||
      record.street === "null" ? (
        ""
      ) : (
        <div style={{ width: "75px" }}>
          <span
            id="to_truncate2"
            style={{ fontSize: "13px", color: "#4F4F4F" }}
          >
            {record.street}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Zip Code&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "zipCode",
    key: "zipCode",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.zipCode < b.zipCode) {
        return asc ? -1 : 1;
      } else if (a.zipCode > b.zipCode) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.zipCode}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Phone No.&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "workPhone",
    key: "workPhone",
    width: "28%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.workPhone < b.workPhone) {
        return asc ? -1 : 1;
      } else if (a.workPhone > b.workPhone) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.workPhone}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Company Revenue&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyRevenue",
    key: "companyRevenue",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyRevenue < b.companyRevenue) {
        return asc ? -1 : 1;
      } else if (a.companyRevenue > b.companyRevenue) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyRevenue}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Company Employee Size&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyEmployeeSize",
    key: "companyEmployeeSize",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyEmployeeSize < b.companyEmployeeSize) {
        return asc ? -1 : 1;
      } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyEmployeeSize}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Industry&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "industry",
    key: "industry",
    width: "20%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.industry.toUpperCase() > b.industry.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.industry}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        IP&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "ip",
    key: "ip",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.ip < b.ip) {
        return asc ? -1 : 1;
      } else if (a.ip > b.ip) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.ip}</span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset ID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "supportDocID",
    key: "supportDocID",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";
      if (a.supportDocID < b.supportDocID) {
        return asc ? -1 : 1;
      } else if (a.supportDocID > b.supportDocID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.supportDocID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Custom Questions&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "customQuestionAliasName",
    key: "customQuestionAliasName",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.customQuestionAliasName < b.customQuestionAliasName) {
        return asc ? -1 : 1;
      } else if (a.customQuestionAliasName > b.customQuestionAliasName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.customQuestionAliasName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Extra 1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra1",
    key: "extra1",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra1 < b.extra1) {
        return asc ? -1 : 1;
      } else if (a.extra1 > b.extra1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra1 === "" ||
      record.extra1 === undefined ||
      record.extra1 === null ||
      record.extra1 === "undefined" ||
      record.extra1 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra1}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra2",
    key: "extra2",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra2 < b.extra2) {
        return asc ? -1 : 1;
      } else if (a.extra2 > b.extra2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra2 === "" ||
      record.extra2 === undefined ||
      record.extra2 === null ||
      record.extra2 === "undefined" ||
      record.extra2 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra2}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra3",
    key: "extra3",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra3 < b.extra3) {
        return asc ? -1 : 1;
      } else if (a.extra3 > b.extra3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra3 === "" ||
      record.extra3 === undefined ||
      record.extra3 === null ||
      record.extra3 === "undefined" ||
      record.extra3 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra3}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 4&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra4",
    key: "extra4",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra4 < b.extra4) {
        return asc ? -1 : 1;
      } else if (a.extra4 > b.extra4) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra4 === "" ||
      record.extra4 === undefined ||
      record.extra4 === null ||
      record.extra4 === "undefined" ||
      record.extra4 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra4}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 5&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra5",
    key: "extra5",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra5 < b.extra5) {
        return asc ? -1 : 1;
      } else if (a.extra5 > b.extra5) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra5 === "" ||
      record.extra5 === undefined ||
      record.extra5 === null ||
      record.extra5 === "undefined" ||
      record.extra5 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra5}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 6&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra6",
    key: "extra6",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra6 < b.extra6) {
        return asc ? -1 : 1;
      } else if (a.extra6 > b.extra6) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra6 === "" ||
      record.extra6 === undefined ||
      record.extra6 === null ||
      record.extra6 === "undefined" ||
      record.extra6 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra6}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 7&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra7",
    key: "extra7",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra7 < b.extra7) {
        return asc ? -1 : 1;
      } else if (a.extra7 > b.extra7) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra7 === "" ||
      record.extra7 === undefined ||
      record.extra7 === null ||
      record.extra7 === "undefined" ||
      record.extra7 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra7}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 8&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra8",
    key: "extra8",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra8 < b.extra8) {
        return asc ? -1 : 1;
      } else if (a.extra8 > b.extra8) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra8 === "" ||
      record.extra8 === undefined ||
      record.extra8 === null ||
      record.extra8 === "undefined" ||
      record.extra8 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra8}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 9&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra9",
    key: "extra9",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra9 < b.extra9) {
        return asc ? -1 : 1;
      } else if (a.extra9 > b.extra9) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra9 === "" ||
      record.extra9 === undefined ||
      record.extra9 === null ||
      record.extra9 === "undefined" ||
      record.extra9 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra9}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 10&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra10",
    key: "extra10",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra10 < b.extra10) {
        return asc ? -1 : 1;
      } else if (a.extra10 > b.extra10) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra10 === "" ||
      record.extra10 === undefined ||
      record.extra10 === null ||
      record.extra10 === "undefined" ||
      record.extra10 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra10}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 11&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra11",
    key: "extra11",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra11 < b.extra11) {
        return asc ? -1 : 1;
      } else if (a.extra11 > b.extra11) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra11 === "" ||
      record.extra11 === undefined ||
      record.extra11 === null ||
      record.extra11 === "undefined" ||
      record.extra11 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra11}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 12&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra12",
    key: "extra12",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra12 < b.extra12) {
        return asc ? -1 : 1;
      } else if (a.extra12 > b.extra12) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra12 === "" ||
      record.extra12 === undefined ||
      record.extra12 === null ||
      record.extra12 === "undefined" ||
      record.extra12 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra12}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 13&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra13",
    key: "extra13",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra13 < b.extra13) {
        return asc ? -1 : 1;
      } else if (a.extra13 > b.extra13) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra13 === "" ||
      record.extra13 === undefined ||
      record.extra13 === null ||
      record.extra13 === "undefined" ||
      record.extra13 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra13}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 14&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra14",
    key: "extra14",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra14 < b.extra14) {
        return asc ? -1 : 1;
      } else if (a.extra14 > b.extra14) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra14 === "" ||
      record.extra14 === undefined ||
      record.extra14 == null ||
      record.extra14 === "undefined" ||
      record.extra14 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra14}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 15&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra15",
    key: "extra15",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra15 < b.extra15) {
        return asc ? -1 : 1;
      } else if (a.extra15 > b.extra15) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra15 === "" ||
      record.extra15 === undefined ||
      record.extra15 === null ||
      record.extra15 === "undefined" ||
      record.extra15 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra15}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 16&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra16",
    key: "extra16",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra16 < b.extra16) {
        return asc ? -1 : 1;
      } else if (a.extra16 > b.extra16) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra16 === "" ||
      record.extra16 === undefined ||
      record.extra16 === null ||
      record.extra16 === "undefined" ||
      record.extra16 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra16}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 17&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra17",
    key: "extra17",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra17 < b.extra17) {
        return asc ? -1 : 1;
      } else if (a.extra17 > b.extra17) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra17 === "" ||
      record.extra17 === undefined ||
      record.extra17 === null ||
      record.extra17 === "undefined" ||
      record.extra17 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra17}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 18&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra18",
    key: "extra18",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra18 < b.extra18) {
        return asc ? -1 : 1;
      } else if (a.extra18 > b.extra18) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra18 === "" ||
      record.extra18 === undefined ||
      record.extra18 === null ||
      record.extra18 === "undefined" ||
      record.extra18 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra18}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 19&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra19",
    key: "extra19",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra19 < b.extra19) {
        return asc ? -1 : 1;
      } else if (a.extra19 > b.extra19) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra19 === "" ||
      record.extra19 === undefined ||
      record.extra19 === null ||
      record.extra19 === "undefined" ||
      record.extra19 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra19}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 20&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra20",
    key: "extra20",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra20 < b.extra20) {
        return asc ? -1 : 1;
      } else if (a.extra20 > b.extra20) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra20 === "" ||
      record.extra20 === undefined ||
      record.extra20 === null ||
      record.extra20 === "undefined" ||
      record.extra20 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra20}
          </span>
          <br />
        </div>
      ),
  },
   //Rutuja - 5300-Added 21-30 extra fields
   {
    title: (
      <span>
        Extra 21&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra21",
    key: "extra21",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra21 < b.extra21) {
        return asc ? -1 : 1;
      } else if (a.extra21 > b.extra21) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) =>
      record.extra21 === "" ||
      record.extra21 === undefined ||
      record.extra21 === null ||
      record.extra21 === "undefined" ||
      record.extra21 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra21}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 22&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra22",
    key: "extra22",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra22 < b.extra22) {
        return asc ? -1 : 1;
      } else if (a.extra22 > b.extra22) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra22 === "" ||
      record.extra22 === undefined ||
      record.extra22 === null ||
      record.extra22 === "undefined" ||
      record.extra22 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra22}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 23&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra23",
    key: "extra23",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra23 < b.extra23) {
        return asc ? -1 : 1;
      } else if (a.extra23 > b.extra23) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra23 === "" ||
      record.extra23 === undefined ||
      record.extra23 === null ||
      record.extra23 === "undefined" ||
      record.extra23 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra23}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 24&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra24",
    key: "extra24",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra24 < b.extra24) {
        return asc ? -1 : 1;
      } else if (a.extra24 > b.extra24) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra24 === "" ||
      record.extra24 === undefined ||
      record.extra24 == null ||
      record.extra24 === "undefined" ||
      record.extra24 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra24}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 25&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra25",
    key: "extra25",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra25 < b.extra25) {
        return asc ? -1 : 1;
      } else if (a.extra25 > b.extra25) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra25 === "" ||
      record.extra25 === undefined ||
      record.extra25 === null ||
      record.extra25 === "undefined" ||
      record.extra25 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra25}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 26&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra26",
    key: "extra26",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra26 < b.extra26) {
        return asc ? -1 : 1;
      } else if (a.extra26 > b.extra26) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra26 === "" ||
      record.extra26 === undefined ||
      record.extra26 === null ||
      record.extra26 === "undefined" ||
      record.extra26 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra26}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 27&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra27",
    key: "extra27",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra27 < b.extra27) {
        return asc ? -1 : 1;
      } else if (a.extra27 > b.extra27) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra27 === "" ||
      record.extra27 === undefined ||
      record.extra27 === null ||
      record.extra27 === "undefined" ||
      record.extra27 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra27}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 28&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra28",
    key: "extra28",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra28 < b.extra28) {
        return asc ? -1 : 1;
      } else if (a.extra28 > b.extra28) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra28 === "" ||
      record.extra28 === undefined ||
      record.extra28 === null ||
      record.extra28 === "undefined" ||
      record.extra28 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra28}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 29&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra29",
    key: "extra29",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra29 < b.extra29) {
        return asc ? -1 : 1;
      } else if (a.extra29 > b.extra29) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra29 === "" ||
      record.extra29 === undefined ||
      record.extra29 === null ||
      record.extra29 === "undefined" ||
      record.extra29 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra29}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 30&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra30",
    key: "extra30",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra30 < b.extra30) {
        return asc ? -1 : 1;
      } else if (a.extra30 > b.extra30) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra30 === "" ||
      record.extra30 === undefined ||
      record.extra30 === null ||
      record.extra30 === "undefined" ||
      record.extra30 === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra30}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Reallocation ID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "reAllocationID",
    key: "reAllocationID",
    width: "16%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.reAllocationID < b.reAllocationID) {
        return asc ? -1 : 1;
      } else if (a.reAllocationID > b.reAllocationID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.reAllocationID}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Domain&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "domain",
    key: "domain",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.domain < b.domain) {
        return asc ? -1 : 1;
      } else if (a.domain > b.domain) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.domain}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Alternate Phone No&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "alternatePhoneNo",
    key: "alternatePhoneNo",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.alternatePhoneNo < b.alternatePhoneNo) {
        return asc ? -1 : 1;
      } else if (a.alternatePhoneNo > b.alternatePhoneNo) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.alternatePhoneNo}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedIn",
    key: "linkedIn",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedIn < b.linkedIn) {
        return asc ? -1 : 1;
      } else if (a.linkedIn > b.linkedIn) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.linkedIn}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Comments&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "comments",
    key: "comments",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.comments < b.comments) {
        return asc ? -1 : 1;
      } else if (a.comments > b.comments) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.comments}
        </span>
        <br />
      </div>
    ),
  },
  // {
  //     title: <span>Parent CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "parentCampID",
  //     key: "parentCampID",
  //     width: "13%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.parentCampID < b.parentCampID) {
  //             return asc ? -1 : 1;
  //         } else if (a.parentCampID > b.parentCampID) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.parentCampID}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },
  //   {
  //     title: <span>Lead Info ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "leadInfoID",
  //     key: "leadInfoID",
  //     width: "13%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.leadInfoID < b.leadInfoID) {
  //             return asc ? -1 : 1;
  //         } else if (a.leadInfoID > b.leadInfoID) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.leadInfoID}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  //   {
  //     title: <span>Last Updated&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "lastUpdated",
  //     key: "lastUpdated",
  //     width: "22%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.lastUpdated < b.lastUpdated) {
  //             return asc ? -1 : 1;
  //         } else if (a.lastUpdated > b.lastUpdated) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.lastUpdated}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },
  {
    title: (
      <span>
        Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyName",
    key: "companyName",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyName < b.companyName) {
        return asc ? -1 : 1;
      } else if (a.companyName > b.companyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyName}
        </span>
        <br />
      </div>
    ),
  },
  // {
  //     title: <span>Status&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "status",
  //     key: "status",
  //     width: "25%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.status < b.status) {
  //             return asc ? -1 : 1;
  //         } else if (a.status > b.status) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1"style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.status}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },
  {
    title: (
      <span>
        Asset Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetName",
    key: "assetName",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetName < b.assetName) {
        return asc ? -1 : 1;
      } else if (a.assetName > b.assetName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetName}
        </span>
        <br />
      </div>
    ),
  },

  // {
  //     title: <span>Voice Log Link&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "voiceLogLink",
  //     key: "voiceLogLink",
  //     width: "25%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.voiceLogLink < b.voiceLogLink) {
  //             return asc ? -1 : 1;
  //         } else if (a.voiceLogLink > b.voiceLogLink) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1"style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.voiceLogLink}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },
  // {
  //     title: <span>Channel&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "channel",
  //     key: "channel",
  //     width: "25%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.channel < b.channel) {
  //             return asc ? -1 : 1;
  //         } else if (a.channel > b.channel) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1"style={{ width: "75px" }}>
  //             <span style={{fontSize:"13px",color:"#4F4F4F"}}>
  //                 {record.channel}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  {
    title: (
      <span>
        Asset Name Touch1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch1",
    key: "assetNameTouch1",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch1 < b.assetNameTouch1) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch1 > b.assetNameTouch1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch1}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Timestamp Touch1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch1",
    key: "assetTimestampTouch1",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch1 < b.assetTimestampTouch1) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch1 > b.assetTimestampTouch1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch1}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Touch2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch2",
    key: "assetNameTouch2",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch2 < b.assetNameTouch2) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch2 > b.assetNameTouch2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch2}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Timestamp Touch2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch2",
    key: "assetTimestampTouch2",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch2 < b.assetTimestampTouch2) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch2 > b.assetTimestampTouch2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch2}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Asset Name Touch3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch3",
    key: "assetNameTouch3",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch3 < b.assetNameTouch3) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch3 > b.assetNameTouch3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch3}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Asset Name Timestamp Touch3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch3",
    key: "assetTimestampTouch3",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch3 < b.assetTimestampTouch3) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch3 > b.assetTimestampTouch3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch3}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Status&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "status",
    key: "status",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.status < b.status) {
        return asc ? -1 : 1;
      } else if (a.status > b.status) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.status}
        </span>
        <br />
      </div>
    ),
  },
];
//shivani - 3415 - added column array  for blank table .
const columnsReportForblank = [
  {
    title: (
      <span>
        PID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "pID",
    key: "pID",
    width: "10%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.pID < b.pID) {
        return asc ? -1 : 1;
      } else if (a.pID > b.pID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.pID}</span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Lead Interaction Date&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "leadInteractionDate",
    key: "leadInteractionDate",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.leadInteractionDate < b.leadInteractionDate) {
        return asc ? -1 : 1;
      } else if (a.leadInteractionDate > b.leadInteractionDate) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.leadInteractionDate}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Email&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "email",
    key: "email",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.email.toUpperCase() > b.email.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.email}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        First Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "firstName",
    key: "firstName",
    width: "25%",
    align: "left",
    sorter: (a, b) => a.firstName - b.firstName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.firstName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Last Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "lastName",
    key: "lastName",
    width: "25%",
    align: "left",
    sorter: (a, b) => a.lastName - b.lastName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.lastName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInCompanyName",
    key: "linkedInCompanyName",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInCompanyName < b.linkedInCompanyName) {
        return asc ? -1 : 1;
      } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInCompanyName === "" ||
      record.linkedInCompanyName === undefined ||
      record.linkedInCompanyName === null ||
      record.linkedInCompanyName === "undefined" ||
      record.linkedInCompanyName === "null" ? (
        ""
      ) : (
        <div id="to_truncate1" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInCompanyName}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobTitle",
    key: "jobTitle",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobTitle < b.jobTitle) {
        return asc ? -1 : 1;
      } else if (a.jobTitle > b.jobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span id="to_truncate2" style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobTitle}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInJobTitle",
    key: "linkedInJobTitle",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInJobTitle < b.linkedInJobTitle) {
        return asc ? -1 : 1;
      } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInJobTitle === "" ||
      record.linkedInJobTitle === undefined ||
      record.linkedInJobTitle === null ||
      record.linkedInJobTitle === "undefined" ||
      record.linkedInJobTitle === "null" ? (
        ""
      ) : (
        <div id="to_truncate2" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInJobTitle}
          </span>
          <br />
        </div>
      ),
  },

  {
    title: (
      <span>
        Address&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "address",
    key: "address",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.address < b.address) {
        return asc ? -1 : 1;
      } else if (a.address > b.address) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.address}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        City&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "city",
    key: "city",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.city < b.city) {
        return asc ? -1 : 1;
      } else if (a.city > b.city) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.city}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        State&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "state",
    key: "state",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.state < b.state) {
        return asc ? -1 : 1;
      } else if (a.state > b.state) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.state}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Country&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "country",
    key: "country",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.country < b.country) {
        return asc ? -1 : 1;
      } else if (a.country > b.country) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.country}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Zip Code&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "zipCode",
    key: "zipCode",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.zipCode < b.zipCode) {
        return asc ? -1 : 1;
      } else if (a.zipCode > b.zipCode) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.zipCode}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Phone No.&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "workPhone",
    key: "workPhone",
    width: "25%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.workPhone < b.workPhone) {
        return asc ? -1 : 1;
      } else if (a.workPhone > b.workPhone) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.workPhone}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Employee Size&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyEmployeeSize",
    key: "companyEmployeeSize",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyEmployeeSize < b.companyEmployeeSize) {
        return asc ? -1 : 1;
      } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyEmployeeSize}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Industry&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "industry",
    key: "industry",
    width: "25%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.industry.toUpperCase() > b.industry.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate2">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.industry}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyName",
    key: "companyName",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyName < b.companyName) {
        return asc ? -1 : 1;
      } else if (a.companyName > b.companyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetName",
    key: "assetName",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetName < b.assetName) {
        return asc ? -1 : 1;
      } else if (a.assetName > b.assetName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetName}
        </span>
        <br />
      </div>
    ),
  },
];

//shivani - 3415 - added array  for lead status .
const leadStatusOptions = [
  { id: "Accepted", name: "Accepted" },
  { id: "Rejected", name: "Rejected" },
  { id: "In QA", name: "In QA" },
];
//shivani - 3415 - added array  for campaign status .
const CampaignStatusOptions = [
  { id: "Active", name: "Active" },
  { id: "Paused", name: "Paused" },
  { id: "Completed", name: "Completed" },
];
//shivani - 3415 - added checkbox for manage column .
const checkBoxArray = [
  //  shivani-3538-removed extra campaignID checkbox
  //shivani-3547-changed checkbox sequence and color of each label
  { id: "Publisher ID", name: "pID", value: "No" },
  { id: "Campaign ID", name: "campID", value: "No" },
  { id: "Campaign Name", name: "campaignName", value: "No" },
  { id: "Allocation ID", name: "reAllocationID", value: "No" },
  { id: "Lead Interaction Day", name: "leadInteractionDate", value: "No" },
  { id: "First Name", name: "firstName", value: "No" },
  { id: "Email", name: "email", value: "No" },
  { id: "Last Name", name: "lastName", value: "No" },
  { id: "Work Phone", name: "workPhone", value: "No" },
  { id: "Alternate Phone No", name: "alternatePhoneNo", value: "No" },
  { id: "Company Name", name: "companyName", value: "No" },
  { id: "LinkedIn Company Name", name: "linkedInCompanyName", value: "No" },
  { id: "Industry", name: "industry", value: "No" },
  { id: "Company Employee Size", name: "companyEmployeeSize", value: "No" },
  { id: "Company revenue", name: "companyRevenue", value: "No" },
  { id: "Job Title", name: "jobTitle", value: "No" },
  { id: "LinkedIn Job Title", name: "linkedInJobTitle", value: "No" },
  { id: "LinkedIn", name: "linkedIn", value: "No" },
  { id: "Job Level", name: "jobLevel", value: "No" },
  { id: "Job Function", name: "jobFunction", value: "No" },
  { id: "Country", name: "country", value: "No" },
  { id: "State", name: "state", value: "No" },
  { id: "City", name: "city", value: "No" },
  { id: "Zip Code", name: "zipCode", value: "No" },
  { id: "Address", name: "address", value: "No" },
  { id: "Street", name: "street", value: "No" },
  { id: "Asset Name", name: "assetName", value: "No" },
  { id: "Asset ID", name: "supportDocID", value: "No" },
  { id: "IP", name: "ip", value: "No" },
  { id: "Domain", name: "domain", value: "No" },
  { id: "Comments", name: "comments", value: "No" },
  { id: "Custom Questions", name: "Custom Question", value: "No" },
  { id: "Extra1", name: "extra1", value: "No" },
  { id: "Extra2", name: "extra2", value: "No" },
  { id: "Extra3", name: "extra3", value: "No" },
  { id: "Extra4", name: "extra4", value: "No" },
  { id: "Extra5", name: "extra5", value: "No" },
  { id: "Extra6", name: "extra6", value: "No" },
  { id: "Extra7", name: "extra7", value: "No" },
  { id: "Extra8", name: "extra8", value: "No" },
  { id: "Extra9", name: "extra9", value: "No" },
  { id: "Extra10", name: "extra10", value: "No" },
  { id: "Extra11", name: "extra11", value: "No" },
  { id: "Extra12", name: "extra12", value: "No" },
  { id: "Extra13", name: "extra13", value: "No" },
  { id: "Extra14", name: "extra14", value: "No" },
  { id: "Extra15", name: "extra15", value: "No" },
  { id: "Extra16", name: "extra16", value: "No" },
  { id: "Extra17", name: "extra17", value: "No" },
  { id: "Extra18", name: "extra18", value: "No" },
  { id: "Extra19", name: "extra19", value: "No" },
  { id: "Extra20", name: "extra20", value: "No" },
   //Rutuja - 5300-Added 21-30 extra fields
   { id: "Extra21", name: "extra21", value: "No" },
   { id: "Extra22", name: "extra22", value: "No" },
   { id: "Extra23", name: "extra23", value: "No" },
   { id: "Extra24", name: "extra24", value: "No" },
   { id: "Extra25", name: "extra25", value: "No" },
   { id: "Extra26", name: "extra26", value: "No" },
   { id: "Extra27", name: "extra27", value: "No" },
   { id: "Extra28", name: "extra28", value: "No" },
   { id: "Extra29", name: "extra29", value: "No" },
   { id: "Extra30", name: "extra30", value: "No" },
  // { id: 'Voice Log Link', name: 'voiceLogLink', value: 'No' },
  { id: "Touch1", name: "assetNameTouch1", value: "No" },
  { id: "Touch2", name: "assetNameTouch2", value: "No" },
  { id: "Touch3", name: "assetNameTouch3", value: "No" },
];

//const leadPlain = checkBoxArray.map(function (a) { return a.name }); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

/**
 * @author : Shivani Pathak-task 3415
 * @description : created class component named as agencyPublisherLeadtracking.
 */
class agencyPublisherLeadtracking extends React.Component {
  constructor() {
    super();
    this.state = {
      tableState: { size: "small" }, //shivani-3415-used for table design
      agencyMyReportsDataNew1: [], //shivani-3415-declare to set table data in this new array while filtering the data
      agencyMyReportsData: [], //shivani-3415-array declared for table data
      reportSummary: [], //shivani-3415-array declared for report summary data
      publisherList: [], //shivani-3415-array declared for publisher name
      publisherList1: [], //shivani-3415-array declared to filter publisher name
      agencyID: "", // shivani-3415-declare to used from userLogin
      pID: "Select Publisher", //shivani-3415-used to display bydefault non-selected .
      campaignStatus: [], //shivani-3415-used to display bydefault non-selected .
      timeline: "Select Timeline", //shivani-3415-used to display bydefault non-selected .
      label: "", //shivani-3415-used to filter deadline as per label .
      reportKey: "", //shivani-3415-used key to put conditions when user came by clicking on report name .
      // reportKey: "",//shivani-3415-used key to put conditions when user came by clicking on report name .
      publisherName: "", //shivani-3415-to target publisher name .
      type: "Publisher Campaign Delivery Tracking", //shivani-3415-used bydefault this type .{/* //shivani-3432-passed value for same report type for create and edit report */}
      pageSize: 100,
      reportName: "", //shivani-3415-used to pass report name to api .
      generateBtnFlag: false, //shivani-3415- used to enable - disable generate button.
      token: "", // shivani-3415-used token for authentication
      loader: "", //shivani-3415-used for loader.
      loader1: "", //shivani-3415-used for generate loader.

      campaignLoader: "", //shivani-3415-used for campaign loader.
      leadIndeterminate: true, //shivani-3415-used flag to setState checkbox value.
      leadCheckAll: false, //shivani-3415-used flag to check checkbox.
      userType: "Publisher", //shivani-3415-declare usertype.
      CampaignDetail: [], //shivani-3415-declare array to update values .
      CampaignLeadDetail: [], //shivani-3415-declare array to iterate table data .
      campID: "Select Campaign", //shivani-3415-declare campID for campaign dropdown .
      leadStatus: [], //shivani-3415-declare array for lead status .
      campaignName: "", //shivani-3415-declare variable for campaign.
      campaignStatusArray: [], //shivani-3415-declare array for cmapaign status array .
      campaignLeadStatusArray: [], //shivani-3415-declare array for  lead status array
      deliveryFormat: [], //shivani-3415-declare array for cmapaign status array
      deadline: "", //shivani-3540-declare for timeline issue...
      dataTableColumns: columnsReport, //shivani-3415-declare array to set table column array .
      checkedColumnKeys: [], //shivani-3415-declare array for checkbox value
      drpdisabled_report: "", //shivani-3415-declare for disable checkbox.
      customQuestionCount: 0, //shivani-3415-declare variable for  customQuestionCount.
      checkBoxObjArray: checkBoxArray, //shivani-3415-declare array for checkbox array.
      multiTouch: "No", //shivani-3415-declare variable for multitouch.
    };
    /**
     * @author : Shivani Pathak-task 3415
     * @description : binded all handlechange here .
     */
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.onPublisherNameHandleChange =
      this.onPublisherNameHandleChange.bind(this);
    this.onCampaignStatusHandleChange =
      this.onCampaignStatusHandleChange.bind(this);
    this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
    this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
    this.generateHandleChange = this.generateHandleChange.bind(this);
    this.onCampaignDetailsHandleChange =
      this.onCampaignDetailsHandleChange.bind(this);
    this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
    this.onCampaignHandleChange = this.onCampaignHandleChange.bind(this);
    this.onCampaignLeadDetailsHandleChange =
      this.onCampaignLeadDetailsHandleChange.bind(this);

    // this.loadingRef = React.createRef(); // 3415- shivani pathak - removed default loader and implemented custom loader
    // this.closeLoadingRef = React.createRef(); // 3415- shivani pathak - removed default loader and implemented custom loader
  }

  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange for checkbox in manage column .
   */
  leadOnChange = (checkedColumnKeys) => {
    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")) {
      checkedColumnKeys.push("assetTimestampTouch1");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }

    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")) {
      checkedColumnKeys.push("assetTimestampTouch2");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }

    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")) {
      checkedColumnKeys.push("assetTimestampTouch3");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }
    this.setState({
      checkedColumnKeys,
      leadIndeterminate:
        !!checkedColumnKeys.length &&
        checkedColumnKeys.length < this.state.checkBoxObjArray.length,
      leadCheckAll:
        checkedColumnKeys.length === this.state.checkBoxObjArray.length,
    });
  };

  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange for pageSize .
   */
  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  }

  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange for backbutton to navigate on same page from where user came .
   */
  backButtonHandleChange() {
    if (this.state.reportKey !== "report") {
      Swal.fire({
        title: "Are you sure you want to discard this report?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.value) {
          //window.location.href = "/agencyMyReports";
          this.props.history.push("/agencyMyReports"); //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        } else if (result.dismiss === "close") {
          //window.location.href = "#";
        }
      });
    } else {
      window.location.href = "/agencyMyReports";
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange to load data after selecting all three filters publisher, campaign Status and timeline .
   */

  onLeadStatusHandleChange(leadStatus) {
    // let CampaignLeadDetail=[...this.state.CampaignLeadDetail]

    let campaignLeadStatusArray = [];
    let checkAccepted = leadStatus.some((o) => o.name === "Accepted");
    if (checkAccepted) {
      campaignLeadStatusArray.push("Accepted");
    }

    let checkRejected = leadStatus.some((o) => o.name === "Rejected");
    if (checkRejected) {
      campaignLeadStatusArray.push("Rejected");
    }

    let checkInQA = leadStatus.some((o) => o.name === "In QA");
    if (checkInQA) {
      campaignLeadStatusArray.push("In QA");
    }

    let pID = this.state.pID;
    var campID = this.state.campID;
    let timeline = this.state.timeline;
    // let campaignStatusArray = this.state.campaignStatusArray;
    this.setState({ leadStatus, campaignLeadStatusArray });

    if (
      pID &&
      pID !== "Select Publisher" &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        pID,
        campaignLeadStatusArray,
        campID,
        timeline
      );
    } else {
      this.setState({ generateBtnFlag: false, CampaignLeadDetail: [] });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handleChange for table data/columns & (multiTouch / custome question) .
   */
  setDataTableColumn(checkedColumnKeys, customQuestionCount, multiTouch) {
    checkedColumnKeys.push("status");
    let dataTableColumns = [...columnsReport];
    let checkBoxObjArray = [...this.state.checkBoxObjArray];
    if (
      customQuestionCount > 0 &&
      checkedColumnKeys &&
      checkedColumnKeys.includes("Custom Question")
    ) {
      let cqArray = [];

      for (let i = 1; i <= customQuestionCount; i++) {
        let CQkey = `CQ-${i}`,
          ansKey = `CQ-${i}-ANS`;
        let Q_obj = {
          title: (
            <span>
              {CQkey}&nbsp;&nbsp;&nbsp;
              <span>
                <i class="fas fa-sort"></i>
              </span>
            </span>
          ),
          dataIndex: CQkey,
          key: CQkey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[CQkey] < b[CQkey]) {
              return asc ? -1 : 1;
            } else if (a[CQkey] > b[CQkey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          },
        };
        let ans_obj = {
          title: (
            <span>
              {ansKey}&nbsp;&nbsp;&nbsp;
              <span>
                <i class="fas fa-sort"></i>
              </span>
            </span>
          ),
          dataIndex: ansKey,
          key: ansKey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[ansKey] < b[ansKey]) {
              return asc ? -1 : 1;
            } else if (a[ansKey] > b[ansKey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          },
        };
        cqArray.push(Q_obj);
        cqArray.push(ans_obj);
        // if(this.state.reportKey!=="report"){
        checkedColumnKeys.push(CQkey);
        checkedColumnKeys.push(ansKey);
      } //End of CQ for loop
      checkedColumnKeys = [...new Set(checkedColumnKeys)];
      dataTableColumns = dataTableColumns.concat(cqArray);
    } else {
      if (customQuestionCount === 0) {
        checkBoxObjArray = checkBoxObjArray.filter(
          (a) => a.id !== "Custom Question"
        );
      }

      let keys = [];
      for (let i = 1; i <= customQuestionCount; i++) {
        let CQkey = `CQ-${i}`,
          ansKey = `CQ-${i}-ANS`;
        keys.push(CQkey);
        keys.push(ansKey);
      }
      dataTableColumns = dataTableColumns.filter((obj) => {
        return keys && !keys.includes(obj.key);
      });
    }
    if (multiTouch === "Yes") {
      checkBoxObjArray = checkBoxObjArray.filter((a) => a.name !== "assetName");
    }
    dataTableColumns = dataTableColumns.filter((obj) => {
      return checkedColumnKeys && checkedColumnKeys.includes(obj.key);
    });

    checkedColumnKeys = [...new Set(checkedColumnKeys)];
    this.setState({ dataTableColumns, checkBoxObjArray, checkedColumnKeys });
  }

  /**
   * @author : Shivani Pathak-task 3415
   * @description : handleChange for table data after selecting values from all filters .
   */

  onCampaignLeadDetailsHandleChange(
    pID,
    campaignLeadStatusArray,
    campID,
    timeline
  ) {
    let reportSummary = [
      {
        pID: pID,
        publisherName: "",
        deliveryLabel: "",
        totalAllocation: 0,
        totalAccepted: 0,
        deliveredLead: 0,
        rejectedLead: 0,
        qaReviewLead: 0,
        balanceLead: 0,
      },
    ];
    this.setState({
      reportSummary,
      CampaignLeadDetail: [],
      loader: "lead_loader",
      generateBtnFlag: false,
    });
    const { user } = this.props.auth;
    const agencyID = user.id;
    let campaignStatusArray = this.state.campaignStatusArray;
    this.setState({ agencyID });
    if (this.state.reportKey === "report") {
      if (
        pID &&
        pID !== "Select Publisher" &&
        campaignLeadStatusArray &&
        campaignLeadStatusArray.length > 0 &&
        campID &&
        campID !== "Select Campaign" &&
        timeline &&
        timeline !== "Select Timeline"
      ) {
        let data = {
          // agencyID: agencyID,                  //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
          pID: pID,
          campID: campID,
          timeline: timeline,
          leadStatus: campaignLeadStatusArray,
        };

        fetch("/reportEngine/leadTrackingReport", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) => {
            let deliveryFormat = result.deliveryFormat;
            let checkedColumnKeys = [],
              multiTouch = "No";
            if (deliveryFormat.length > 0) {
              multiTouch = deliveryFormat[0].multiTouch;
            }
            for (const [key, value] of Object.entries(deliveryFormat[0])) {
              if (value === "Yes" || value === "yes") {
                checkedColumnKeys.push(key);
              }
            }
            let customQuestionCount = result.customQuestionCount;
            if (customQuestionCount > 0) {
              checkedColumnKeys.push("Custom Question");
            }
            this.setDataTableColumn(
              checkedColumnKeys,
              customQuestionCount,
              multiTouch
            );

            this.setState({
              CampaignLeadDetail: result.reportDetail,
              reportSummary: result.reportSummary,
              deliveryFormat: result.deliveryFormat,
              loader: "",
              checkedColumnKeys,
              customQuestionCount,
              multiTouch,
            });
            if (this.state.CampaignLeadDetail.length > 0) {
              this.setState({ generateBtnFlag: true });
            } else {
              this.setState({ generateBtnFlag: false });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    } else if (
      pID &&
      pID !== "Select Publisher" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      let data = {
        //agencyID: agencyID,               //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        pID: pID,
        campID: campID,

        timeline: timeline,
        leadStatus: campaignLeadStatusArray,
      };

      fetch("/reportEngine/leadTrackingReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          let deliveryFormat = result.deliveryFormat;
          let checkedColumnKeys = [],
            multiTouch = "No";
          if (deliveryFormat.length > 0) {
            multiTouch = deliveryFormat[0].multiTouch;
          }
          for (const [key, value] of Object.entries(deliveryFormat[0])) {
            if (value === "Yes" || value === "yes") {
              checkedColumnKeys.push(key);
            }
          }
          let customQuestionCount = result.customQuestionCount;
          if (customQuestionCount > 0) {
            checkedColumnKeys.push("Custom Question");
          }
          this.setDataTableColumn(
            checkedColumnKeys,
            customQuestionCount,
            multiTouch
          );

          this.setState({
            CampaignLeadDetail: result.reportDetail,
            reportSummary: result.reportSummary,
            deliveryFormat: result.deliveryFormat,
            loader: "",
            checkedColumnKeys,
            customQuestionCount,
            multiTouch,
          });

          if (this.state.CampaignLeadDetail.length > 0) {
            this.setState({ generateBtnFlag: true });
          } else {
            this.setState({ generateBtnFlag: false });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handleChange for campaign dropdown .
   */
  onCampaignHandleChange(e) {
    var campID = e.target.value;
    this.setState({ campID });
    let pID = this.state.pID;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;

    let checkName = this.state.CampaignDetail.filter(
      (obj) => obj.campID.toString() === campID
    ); //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    let campaignName = "";
    if (checkName && checkName.length > 0) {
      campaignName = checkName[0].campaignName;
    }
    this.setState({ campaignName });

    let timeline = this.state.timeline;

    if (
      pID &&
      pID !== "Select Publisher" &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        pID,
        campaignLeadStatusArray,
        campID,
        timeline
      );
    } else {
      this.setState({ generateBtnFlag: false, CampaignLeadDetail: [] });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handleChange to load the campaign after selecting publisher and campaign status .
   */
  onCampaignDetailsHandleChange(pID, campaignStatusArray) {
    const { user } = this.props.auth;
    const agencyID = user.id;
    let timeline = this.state.timeline;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let campID = this.state.campID;
    //shivani - 3560 - added below condition for close loader on time while all filters having values .
    if (
      pID &&
      pID !== "Select Publisher" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline"
    ) {
      this.setState({ campaignLoader: "" });
    }
    let reportSummary = [
      {
        pID: pID,
        publisherName: "",
        deliveryLabel: "",
        totalAllocation: 0,
        totalAccepted: 0,
        deliveredLead: 0,
        rejectedLead: 0,
        qaReviewLead: 0,
        balanceLead: 0,
      },
    ];
    this.setState({
      agencyID,
      reportSummary,
      CampaignLeadDetail: [],
      campaignLoader: "campaignLoader",
    });
    if (
      pID &&
      pID !== "Select Publisher" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      let data = {
        // agencyID: agencyID,          //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        pID: pID,
        campaignStatus: campaignStatusArray,
      };

      fetch("/reportEngine/leadUploadedCampaign", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((CampaignDetail) => {
          this.setState({ CampaignDetail, campaignLoader: "" }); //
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange to target selected publisher .
   */
  onPublisherNameHandleChange(e) {
    var pID = e.target.value;
    this.setState({ pID });
    let campaignStatusArray = this.state.campaignStatusArray;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let timeline = this.state.timeline;
    let campID = this.state.campID;

    if (
      pID &&
      pID !== "Select Publisher" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignDetailsHandleChange(pID, campaignStatusArray);
    }
    //shivani - 3560- added below condition for - if same campaign having by number of publisher So data should load in table.
    if (
      pID &&
      pID !== "Select Publisher" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        pID,
        campaignLeadStatusArray,
        campID,
        timeline
      );
    } else {
      this.setState({ generateBtnFlag: false, CampaignLeadDetail: [] });
    }
  }

  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange to target selected status .
   */
  onCampaignStatusHandleChange(campaignStatus) {
    let campaignStatusArray = [];
    let checkActive = campaignStatus.some((o) => o.name === "Active");
    if (checkActive) {
      campaignStatusArray.push("Active");
    }

    let checkPaused = campaignStatus.some((o) => o.name === "Paused");
    if (checkPaused) {
      campaignStatusArray.push("Paused");
    }

    let checkCompleted = campaignStatus.some((o) => o.name === "Completed");
    if (checkCompleted) {
      campaignStatusArray.push("Completed");
    }

    let pID = this.state.pID;
    // let campID =this.state.campID;
    // let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    // let timeline =  this.state.timeline;
    this.setState({ campaignStatus, campaignStatusArray });
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    if (campaignStatusArray.length === 0 || campaignStatusArray === []) {
      this.setState({
        campID: "Select Campaign",
        generateBtnFlag: false,
        CampaignLeadDetail: [],
      });
    } else if (
      pID &&
      pID !== "Select Publisher" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignDetailsHandleChange(pID, campaignStatusArray);
    }
    // else  if ((pID && pID !== "Select Publisher") && (campaignLeadStatusArray && campaignLeadStatusArray.length>0)&&(campID && campID !== "Select Campaign")&&(timeline && timeline !== "Select Timeline")&&(campaignStatusArray && campaignStatusArray.length>0)) {
    //     alert("for table data if same campaign allocate to 2 pub")
    //     this.onCampaignLeadDetailsHandleChange(pID,campaignLeadStatusArray,campID,timeline);

    // }
    else {
      this.setState({ generateBtnFlag: false, CampaignLeadDetail: [] });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange to target selected deadline .
   */
  ondeadlineHandleChange(e) {
    var timeline = e.target.value;
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    //Aman-4797-Commenting below code because it is not in use - taking this value from label directly
    //shivani-3431-used below conditions to display bydeafult timeline value in front of delivery summary when user came by clicking on report name.
    // if (timeline === "All") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    //     this.setState({label : "All"}); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "Weekly") {
    //     this.setState({label : "Ending This Week"}); //this.state.label = "Ending This Week" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "Monthly") {
    //     this.setState({label : "Ending This Month"}); //this.state.label = "Ending This Month" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "Quarterly") {
    //     this.setState({label : "Ending This Quarter"}); //this.state.label = "Ending This Quarter" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "Yearly") {
    //     this.setState({label : "Ending This Year"}); //this.state.label = "Ending This Year" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "PreMonth") {
    //     this.setState({label : "Ending Previous Month"}); //this.state.label = "Ending Previous Month" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "PreQuarter") {
    //     this.setState({label : "Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }
    // else if (timeline === "PreYear") {
    //     this.setState({label : "Ending Previous Year"}); //this.state.label = "Ending Previous Year" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
    // }

    this.setState({ timeline, label: label }); //Aman-4797-(remove this label: this.state.label and e.nativeEvent.target[index].text dupliacte)-Console Warnings-Agency-Reports Part 2

    let pID = this.state.pID;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    // let campaignStatusArray=this.state.campaignStatusArray;

    let campID = this.state.campID;

    if (
      pID &&
      pID !== "Select Publisher" &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        pID,
        campaignLeadStatusArray,
        campID,
        timeline
      );
    } else {
      this.setState({ generateBtnFlag: false, CampaignLeadDetail: [] });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange to generate report successfully .
   */
  generateHandleChange(e) {
    //const { user } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    //const agencyID = user.id; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    // var pID =e.target.value;
    let pID = this.state.pID;
    let timeline = this.state.timeline;
    let campID = this.state.campID;
    let campaignStatus = [...this.state.campaignStatusArray];
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let reportDetail = [...this.state.CampaignLeadDetail];
    let reportSummary = [...this.state.reportSummary];
    let reportName = this.state.reportName;
    let type = this.state.type;
    let userType = this.state.userType;
    let leadStatus = this.state.campaignLeadStatusArray;

    if (leadStatus) {
      leadStatus = leadStatus.toString();
    }
    this.setState({ campaignStatus, reportDetail, loader1: "gen_loader" });

    if (
      pID &&
      pID !== "Select Publisher" &&
      campaignStatus &&
      campaignStatus.length > 0 &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeline &&
      timeline !== "Select Timeline"
    ) {
      if (reportSummary && reportSummary.length > 0) {
        reportSummary[0].customQuestionCount = this.state.customQuestionCount;
        reportSummary[0].leadStatus = leadStatus;
        reportSummary[0].campID = this.state.campID;
        reportSummary[0].campaignName = this.state.campaignName;
        reportSummary[0].multiTouch = this.state.multiTouch;
      }

      let data = {
        //user: user,                                               //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        //agencyID: agencyID,
        pID: pID,
        timeline: timeline,
        campaignStatus: campaignStatus, //JSON array
        type: type,
        reportName: reportName,
        reportSummary: reportSummary,
        reportDetail: reportDetail,
        userType: userType,
        checkedColumnKeys: this.state.checkedColumnKeys,
      };

      fetch("/reportEngine/generateLeadReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ loader1: "" });
          if (res.success === true) {
            Swal.fire({
              title:
                '<span id="success_popUp">Report generated successfully.</span>',
              html: "<span id='for_popUp_generate'>Please <a href='/agencyMyReports'><span id='for_popUp_generate1'>click here</span></a> to check report.</span>",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.href = "/agencyMyReports";
              },
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : WillMount (called api in it which is for disable screen if report key is present in url) .
   */
  componentWillMount(e) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
      let reportKey, reportID, pID, reportName, type;
      if (this.props.location.state !== undefined) {
        pID = this.props.location.state.pID;
        reportKey = this.props.location.state.reportKey;
        reportID = this.props.location.state.reportID;
        //this.state.reportID=reportID; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
        reportName = this.props.location.state.reportName;
        this.setState({ reportName: reportName, reportID: reportID }); //this.state.reportName=reportName; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
        type = this.props.location.state.type;
      }

      //const { isAuthenticated } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
      const { user } = this.props.auth;
      const agencyID = user.id;

      this.setState({
        agencyID: agencyID,
        pID: pID,
        reportKey: reportKey,
        generateBtnFlag: false,
        reportName: reportName,
        type: type,
      });

      if (reportKey === "report") {
        //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        this.setState({ drpdisabled_report: "drpdisable_report" });
      } else {
        this.setState({ drpdisabled_report: "" });
      }

      let data = {
        //agencyID: this.state.agencyID,                    //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        pID: pID,
        reportID: reportID,
      };
      if (reportID) {
        this.setState({ loader: "lead_loader" });
        fetch("/reportEngine/reportDetail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) => {
            //shivani-3415-used below condition to display bydeafult values when user came by clicking on report name.
            let timeline, deadline, pID, campaignStatus, checkedColumnKeys;
            if (result) {
              pID = result.pID;
              timeline = result.deadline;
              campaignStatus = result.campaignStatus;
              checkedColumnKeys = result.checkedColumnKeys;
              checkedColumnKeys = checkedColumnKeys.split(",");
            }

            var str = campaignStatus;
            var statusTempArray = [];
            var str_array = str.split(",");
            for (var i = 0; i < str_array.length; i++) {
              if (str_array[i] !== "") {
                //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                statusTempArray.push({ id: str_array[i], name: str_array[i] });
              }
            }

            let reportSummary = result.reportSummary;
            let multiTouch = reportSummary[0].multiTouch;
            let campaignName = reportSummary[0].campaignName;
            let customQuestionCount = reportSummary[0].customQuestionCount;

            let leadStatus = reportSummary[0].leadStatus;

            var str1 = leadStatus;
            var leadTempArray = [];
            var str_array1 = str1.split(",");
            for (let i = 0; i < str_array1.length; i++) {
              //Aman-4797-(change var to let in for loop)-Console Warnings-Agency-Reports Part 2
              if (str_array1[i] !== "") {
                //Aman-4797-(added '!==' and instead saw '!=')-Console Warnings-Agency-Reports Part 2
                leadTempArray.push({ id: str_array1[i], name: str_array1[i] });
              }
            }
            //shivani-3415-used below conditions to display bydeafult deadline value in front of delivery summary when user came by clicking on report name.
            if (result.deadline !== "") {
              if (result.deadline === "All") {
                this.setState({ label: "All" }); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "Weekly") {
                this.setState({ label: "Ending This Week" }); //this.state.label = "Ending This Week"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "Monthly") {
                this.setState({ label: "Ending This Month" }); //this.state.label = "Ending This Month"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "Quarterly") {
                this.setState({ label: "Ending This Quarter" }); //this.state.label = "Ending This Quarter"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "Yearly") {
                this.setState({ label: "Ending This Year" }); //this.state.label = "Ending This Year"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "PreMonth") {
                this.setState({ label: "Ending Previous Month" }); //this.state.label = "Ending Previous Month"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "PreQuarter") {
                this.setState({ label: "Ending Previous Quarter" }); //this.state.label = "Ending Previous Quarter"//Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              } else if (result.deadline === "PreYear") {
                this.setState({ label: "Ending Previous Year" }); //this.state.label = "Ending Previous Year" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
              }
            }

            this.setState(
              {
                CampaignLeadDetail: result.reportDetail,
                reportSummary: result.reportSummary,
                pID,
                deadline,
                timeline,
                campaignStatus: statusTempArray,
                leadStatus: leadTempArray,
                campID: reportSummary[0].campID,
                campaignName,
                multiTouch,
                customQuestionCount,
                checkedColumnKeys,
                campaignLeadStatusArray: str_array1,
                label: this.state.label,
                loader: "",
              },
              function () {
                this.setDataTableColumn(
                  checkedColumnKeys,
                  customQuestionCount,
                  multiTouch
                );
              }
            );
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      fetch("/reportEngine/getPublisher", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((publisherList) => {
          this.setState({ publisherList, publisherList1: publisherList });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  /**
   * @author : Shivani Pathak-task 3415
   * @description : handlechange for save and close button after check/uncheck checkbox .
   */
  handleSubmit = (e) => {
    this.setDataTableColumn(
      this.state.checkedColumnKeys,
      this.state.customQuestionCount,
      this.state.multiTouch
    );
  };
  /**
   * @author : Shivani Pathak-task 3415
   * @description :Render part to display complete screen with functionality as per given screen .
   */

  render() {
    const asterisk = {
      color: "red",
    };

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
      <div>
        <Navigation />
        <div
          style={{ paddingBottom: "60px", paddingTop: "100px" }}
          class="container-fluid "
        >
          <div class="row">
            <div class="col-sm-12 col-md-1 col-lg-2">
              {/*3721-Rutuja Kale-VAPT-removing # -Agency side--Reports menu--URL Links & API-FE*/}
              {/*Aman-4797-(added void(0) to "#/" and removed the duplicate style attribute)-Console Warnings-Agency-Reports Part 2 */}
              <a
                style={{ color: "#056eb8" }}
                href={"#/"}
                onClick={this.backButtonHandleChange}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  title="Back to agencyMyReport"
                />
              </a>
            </div>
            <div
              class=" col-xs-12 col-sm-12 col-md-8 offset-lg-2 col-lg-4"
              align="center"
            >
              <label id="labelDI">Publisher Lead Tracking Report</label>
            </div>
            <div
              class="col-sm-12 col-md-3 offset-lg-1 col-lg-3"
              id="gnrt-btn"
              style={{ paddingLeft: "193px" }}
            >
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                ""
              ) : this.state.generateBtnFlag === true ? (
                <button
                  class="btn"
                  type="button"
                  data-toggle="modal"
                  data-target="#generateReport"
                  onClick={this.generateHandleChange}
                  style={{
                    backgroundImage:
                      "linear-gradient(to left, #007CC3,#193D8F)",
                    color: " white",
                  }}
                >
                  Generate Report
                </button>
              ) : (
                <button
                  disabled
                  class="btn"
                  type="button"
                  data-toggle="modal"
                  data-target="#generateReport"
                  style={{
                    backgroundImage: "linear-gradient(to left,#007CC3,#193D8F)",
                    color: " white",
                  }}
                >
                  Generate Report
                </button>
              )}
            </div>
          </div>
          <div
            class="row"
            style={{
              paddingTop: "15px",
              paddingLeft: "25px",
              paddingBottom: "5px",
            }}
          >
            <div class="col-sm-12 col-md-3 col-lg-2" id="PB_1">
              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "#14254A",
                }}
              >
                Publisher&nbsp;:&nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  disabled
                  value={this.state.pID}
                  class="form-control inputStyle"
                  name="PublisherName"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                    opacity: "0.5",
                  }}
                  onChange={this.onPublisherNameHandleChange}
                >
                  <option
                    value={"Select Publisher"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#EEEEEE",
                    }}
                  >
                    Select Publisher
                  </option>{" "}
                  {/*Aman-4797-(removed value={"Select Publisher"} attributes issue)-Console Warnings-Agency-Reports Part 2 */}
                  {/*Aman-4797-(added placeholder insted instead of value)-Console Warnings-Agency-Reports Part 2 */}
                  {this.state.publisherList.map((publisherList, i) => (
                    <option
                      placeholder={"Select Publisher"}
                      style={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        color: "#EEEEEE",
                      }}
                      value={publisherList.pID}
                      key={i}
                    >
                      {publisherList.publisherName}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  value={this.state.pID}
                  class="form-control inputStyle"
                  name="PublisherName"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                  onChange={this.onPublisherNameHandleChange}
                >
                  <option
                    value={"Select Publisher"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Publisher
                  </option>
                  {this.state.publisherList.map((publisherList, i) => (
                    <option
                      style={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        color: "#555555",
                      }}
                      value={publisherList.pID}
                      key={i}
                    >
                      {publisherList.publisherName}
                    </option>
                  ))}
                </select>
              )}
            </div>
            &nbsp;
            {/* //shivani-3540-changed some css for disable dropsowns... */}
            {/* //Rutuja Kale-3721-changed some css for disable dropsowns... */}
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "#14254A",
                }}
              >
                Campaign Status&nbsp;:&nbsp;<span style={asterisk}>*</span>
                &nbsp;
              </label>{" "}
              {/* Chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue */}
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <div>
                  <Picky
                    value={this.state.campaignStatus}
                    options={CampaignStatusOptions}
                    onChange={this.onCampaignStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    // multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    numberDisplayed={1}
                    id=""
                    readOnly
                    isDisabled="true"
                    name="CampaignStatus"
                    className={"PickyStyle"}
                    //{/*3721-Rutuja Kale-VAPT-read only and disabled checkbox-chAgency side--Reports menu--URL Links & API-FE*/}
                    render={({ item, isSelected, labelKey }) => {
                      return (
                        <li>
                          {/* required to select item */}
                          <input
                            type="checkbox"
                            disabled
                            checked={isSelected}
                            readOnly
                          />
                          <span>{item[labelKey]}</span>
                        </li>
                      );
                    }}
                  />
                </div>
              ) : (
                //{/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
                <div
                  style={{
                    width: "148px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                >
                  <Picky
                    value={this.state.campaignStatus}
                    options={CampaignStatusOptions}
                    onChange={this.onCampaignStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    placeholder="Select Status"
                    dropdownHeight={200}
                    numberDisplayed={0}
                    id="CampaignStatus"
                    name="CampaignStatus"
                    className={"PickyStyle"}
                  />{" "}
                </div>
              )}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="col-sm-12 col-md-3 col-lg-2" id="CP_1">
              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "#14254A",
                }}
              >
                Campaign&nbsp;:&nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  disabled
                  class="form-control inputStyle"
                  value={this.state.campaignName}
                  id="for_res"
                  name="campID"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                    opacity: "0.5",
                  }}
                  onChange={this.onCampaignHandleChange}
                >
                  {/*Aman-4797-(added placeholder insted instead of value)-Console Warnings-Agency-Reports Part 2 */}
                  <option
                    placeholder={"Select Publisher"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                    value={this.state.campaignName}
                    selected
                  >
                    {this.state.campID}-{this.state.campaignName}
                  </option>
                </select>
              ) : (
                <select
                  class="form-control inputStyle"
                  value={this.state.campID}
                  id="for_res"
                  name="campID"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                  onChange={this.onCampaignHandleChange}
                >
                  <option
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Campaign
                  </option>
                  {this.state.CampaignDetail &&
                    this.state.CampaignDetail.map((CampaignDetail, i) => (
                      <option
                        style={{
                          fontSize: "14px",
                          fontFamily: "sans-serif",
                          color: "#555555",
                        }}
                        value={CampaignDetail.campID}
                        key={i}
                      >
                        {CampaignDetail.campID}-{CampaignDetail.campaignName}
                      </option>
                    ))}
                </select>
              )}
            </div>
            &nbsp;
            {/* //shivani-3540-passed proper values to timelines... */}
            <div class="col-sm-12 col-md-3 col-lg-2" id="TM_1">
              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "#14254A",
                }}
              >
                Timeline&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;
              </label>
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  class="form-control inputStyle"
                  disabled
                  name="Timeline"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                    opacity: "0.5",
                    marginBottom: "10px",
                  }}
                  value={this.state.timeline}
                  required
                  onChange={this.ondeadlineHandleChange}
                >
                  <option
                    value={"Select Timeline"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Timeline
                  </option>
                  <option
                    value="All"
                    lable="All"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    All
                  </option>
                  <option
                    value="Weekly"
                    label="Ending This Week"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Week
                  </option>
                  <option
                    value="Monthly"
                    label="Ending This Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Month
                  </option>
                  <option
                    value="Quarterly"
                    label="Ending This Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Quarter
                  </option>
                  <option
                    value="Yearly"
                    label="Ending This Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Year
                  </option>
                  <option
                    value="PreMonth"
                    label="Ending Previous Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Month
                  </option>
                  <option
                    value="PreQuarter"
                    label="Ending Previous Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Quarter
                  </option>
                  <option
                    value="PreYear"
                    label="Ending Previous Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Year
                  </option>
                </select>
              ) : (
                <select
                  class="form-control inputStyle"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                    marginBottom: "10px",
                  }}
                  name="Timeline"
                  id="for_res"
                  value={this.state.timeline}
                  required
                  onChange={this.ondeadlineHandleChange}
                >
                  <option
                    value={"Select Timeline"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Timeline
                  </option>

                  <option
                    value="All"
                    lable="All"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    All
                  </option>
                  <option
                    value="Weekly"
                    label="Ending This Week"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Week
                  </option>
                  <option
                    value="Monthly"
                    label="Ending This Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Month
                  </option>
                  <option
                    value="Quarterly"
                    label="Ending This Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Quarter
                  </option>
                  <option
                    value="Yearly"
                    label="Ending This Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Year
                  </option>
                  <option
                    value="PreMonth"
                    label="Ending Previous Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Month
                  </option>
                  <option
                    value="PreQuarter"
                    label="Ending Previous Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Quarter
                  </option>
                  <option
                    value="PreYear"
                    label="Ending Previous Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Year
                  </option>
                </select>
              )}
            </div>
            {/* //shivani-3540-changed some css for disable dropsowns... */}
            {/* //Rutuja Kale-3721-changed some css for disable dropsowns... */}
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "Sans-Serif",
                  color: "#14254A",
                  marginLeft: "5px",
                }}
              >
                Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <div>
                  <Picky
                    value={this.state.leadStatus}
                    options={leadStatusOptions}
                    onChange={this.onLeadStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    // multiple={true}
                    //  disabled={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    //  dropdownHeight={200}
                    numberDisplayed={1}
                    id=""
                    readOnly
                    isDisabled="true"
                    //  name="leadStatus"
                    className={"PickyStyle"}
                    render={({ item, isSelected, labelKey }) => {
                      return (
                        <li>
                          {/* required to select item */}
                          <input
                            type="checkbox"
                            disabled
                            checked={isSelected}
                            readOnly
                          />
                          <span>{item[labelKey]}</span>
                        </li>
                      );
                    }}
                  />
                </div>
              ) : (
                <div
                  // {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
                  style={{
                    width: "148px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                >
                  <Picky
                    value={this.state.leadStatus}
                    options={leadStatusOptions}
                    onChange={this.onLeadStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    placeholder={"Select Status"}
                    includeFilter={true}
                    dropdownHeight={200}
                    numberDisplayed={0}
                    id="leadStatus"
                    name="leadStatus"
                    className={"PickyStyle"}
                  />
                </div>
              )}
            </div>
            &nbsp;
          </div>
          {/* <br/> */}
          <hr />
          {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
          {this.state.reportKey === "report" ||
          this.state.generateBtnFlag === true ? (
            <div style={{ backgroundColor: "white", marginBottom: "30px" }}>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  border: "1px solid #CCCCCC",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "3px",
                }}
              >
                <div>
                  <div>
                    <label
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "600px",
                        fontSize: "18px",
                        color: "#F28C0F",
                      }}
                    >
                      Delivery Summary :
                      {this.state.timeline !== "Select Timeline" ||
                      this.state.reportKey === "report" ? (
                        <span style={{ color: "black", fontSize: "14px" }}>
                          &nbsp;&nbsp;&nbsp;{this.state.label}
                          {/* //shivani-task 3431-display  "" label for All and No Lead Delivered. */}
                          {this.state.label === "All"
                            ? ""
                            : this.state.reportSummary &&
                              this.state.reportSummary.map((reportSummary) => (
                                <span
                                  style={{ color: "black", fontSize: "14px" }}
                                >
                                  ({reportSummary.deliveryLabel})
                                </span>
                              ))}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>

                  <hr></hr>
                  {/* {this.state.reportKey == "report" || this.state.generateBtnFlag == true ? */}
                  <div>
                    {this.state.reportSummary &&
                      this.state.reportSummary.map((reportSummary) => (
                        <div class="row">
                          {/* //  shivani-3538-changed value color  and 3555 for pID alignment issue*/}

                          <div class="col-sm-2 col-md-3 col-lg-2">
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Publisher ID&nbsp;:&nbsp;
                            </label>
                            <span
                              style={{
                                color: " #14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              {reportSummary.pID}&nbsp;
                            </span>
                          </div>
                          {/* //  shivani-3538-changed value color   and 3555 for pID alignment issue*/}

                          <div
                            class="col-sm-3 col-md-4 col-lg-4"
                            style={{ marginBottom: "10px" }}
                          >
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Publisher Name&nbsp;:&nbsp;
                            </label>
                            <span
                              style={{
                                color: " #14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              {reportSummary.publisherName}
                            </span>
                          </div>
                          {/* */}
                          <div class="col-sm-2">
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Report ID&nbsp;:&nbsp;
                            </label>
                            <span style={{ color: "#14254A" }}>
                              {this.state.reportID}&nbsp;
                            </span>
                          </div>
                          <div
                            class="col-sm-3 col-lg-4"
                            style={{ marginBottom: "10px" }}
                          >
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Report Name&nbsp;:&nbsp;
                            </label>
                            <span style={{ color: "#14254A" }}>
                              {this.state.reportName}
                            </span>
                          </div>
                          {/* Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link  */}

                          <div class="col-sm-7 col-lg-6"></div>

                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <hr></hr>
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              <b>Total count :</b>
                            </label>
                          </div>

                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Allocated Lead&nbsp;:&nbsp;
                            </span>

                            <span style={{ color: "#414141" }}>
                              {reportSummary.totalAllocation}
                            </span>
                          </div>

                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Delivered&nbsp;:&nbsp;
                            </span>
                            <span style={{ color: " #193D8F" }}>
                              {reportSummary.deliveredLead}
                            </span>
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Review Pending&nbsp;:&nbsp;
                            </span>
                            {this.state.campaignLeadStatusArray.includes(
                              "In QA"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#F28C0F",
                                }}
                              >
                                {reportSummary.qaReviewLead}
                              </b>
                            ) : (
                              <span style={{ color: "#F28C0F" }}>
                                {reportSummary.qaReviewLead}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Accepted&nbsp;:&nbsp;
                            </span>
                            {this.state.campaignLeadStatusArray.includes(
                              "Accepted"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#008000",
                                }}
                              >
                                {reportSummary.totalAccepted}
                              </b>
                            ) : (
                              <span style={{ color: "#008000" }}>
                                {reportSummary.totalAccepted}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Rejected&nbsp;:&nbsp;
                            </span>
                            {this.state.campaignLeadStatusArray.includes(
                              "Rejected"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#FF0000",
                                }}
                              >
                                {reportSummary.rejectedLead}
                              </b>
                            ) : (
                              <span style={{ color: "#FF0000" }}>
                                {reportSummary.rejectedLead}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Balance&nbsp;:&nbsp;
                            </span>
                            <span style={{ color: "#4F4F4F" }}>
                              {reportSummary.balanceLead}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* :""} */}

                {/* ///manage column */}

                <div
                  class="modal fade"
                  id="manageColumn"
                  role="dialog"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                >
                  <div
                    class="modal-dialog modal-md float-right"
                    style={{ paddingTop: "120px", paddingRight: "36%" }}
                  >
                    <div
                      class="modal-content"
                      id="manage_popup_cont"
                      style={{ width: "410px", float: "right" }}
                    >
                      <div class="modal-header" style={{ height: "65px" }}>
                        <div>
                          <span
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "20px",
                              fontWeight: "500",
                              color: "#414141",
                              cursor: "pointer",
                              // Chaitanya-4812-Added cursor: 'pointer'
                            }}
                          >
                            Manage Columns
                            <span
                              style={{ float: "right", fontSize: "30px" }}
                              align="right"
                              data-dismiss="modal"
                            >
                              &times;
                            </span>
                          </span>
                        </div>
                      </div>
                      {/* {this.state.reportKey == "report"?
"": */}
                      <div>
                        <div
                          class="modal-body"
                          style={{
                            overflowY: "scroll",
                            height: "330px",
                            overflowX: "hidden",
                          }}
                        >
                          <Checkbox.Group
                            className="checkbox_clr"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              width: "391px",
                              fontSize: "12px",
                              margin: "5px auto",
                            }}
                            options={this.state.checkBoxObjArray.map((obj) => ({
                              label: obj.id,
                              value: obj.name,
                            }))}
                            value={this.state.checkedColumnKeys}
                            onChange={this.leadOnChange}
                          />
                        </div>

                        <hr></hr>
                        <div style={{ marginLeft: "40px", height: "45px" }}>
                          <button
                            type="button"
                            class="btn11 info11"
                            data-dismiss="modal"
                            style={{ width: "150px", height: "35px" }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn add-button"
                            style={{
                              width: "150px",
                              marginLeft: "15px",
                              height: "35px",
                              marginTop: "-3px",
                            }}
                            data-dismiss="modal"
                            onClick={this.handleSubmit}
                          >
                            Save&nbsp;&&nbsp;Close{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* manage column */}
                <div>
                  <div
                    class="col-sm-12 col-md-4 col-lg-3"
                    style={{ marginTop: "16px", marginLeft: "-21px" }}
                  >
                    <span
                      style={{
                        color: "#14254A",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                      }}
                    >
                      <b>Lead Details :</b>
                    </span>
                  </div>
                  {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                  {this.state.reportKey === "report" ? (
                    ""
                  ) : (
                    <div
                      class="col-sm-12 offset-md-5 col-md-3 offset-lg-7 col-lg-2"
                      align="right"
                      style={{ marginTop: "16px" }}
                    >
                      <span
                        data-toggle="modal"
                        data-target="#manageColumn"
                        style={{
                          color: "#1890FF",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                          // Chaitanya-4812-Added cursor: 'pointer'
                        }}
                      >
                        Manage Columns
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div align="center" id={this.state.loader1}></div>
          <div align="center" id={this.state.loader}></div>
          <div align="center" id={this.state.campaignLoader}></div>

          {this.state.CampaignLeadDetail.length > 0 ? (
            <Table
              {...this.state.tableState}
              id="myTableReport tr"
              bordered
              dataSource={this.state.CampaignLeadDetail}
              columns={this.state.dataTableColumns}
              onChange={onChange}
              className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
              //className="ant-table-thead"
              //className={tableCSS}
              scroll={
                this.state.multiTouch !== "No"
                  ? { x: z, y: 1333 }
                  : this.state.customQuestionCount !== 0
                  ? { x: 4000, y: 1333 }
                  : { x: 5500, y: 1333 }
              }
              //shivani-3557  changed width for table
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
              }}
            ></Table>
          ) : (
            <Table
              {...this.state.tableState}
              id="myTable tr"
              bordered
              dataSource={""}
              columns={columnsReportForblank}
              onChange={onChange}
              className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
              //className="ant-table-thead"
              //className={tableCSS}
              scroll={{ x: 2500, y: 1333 }}
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
              }}
            ></Table>
          )}
          <div
            class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
            style={{
              marginTop: "-53px",
              marginLeft: "5px",
              fontSize: "13px",
              color: "#4F4F4F",
              fontFamily: "sans-serif",
            }}
          >
            Records per page:&nbsp;
            <select
              defaultValue={this.state.pageSize}
              onChange={this.handleChangePageSize}
              id="pacing11"
              class="input-small"
              className="form-control"
              name="pacing"
              style={{
                width: "71px",
                height: "30px",
                display: "initial",
                fontSize: "13px",
                fontFamily: "sans-serif",
                color: "#4F4F4F",
              }}
            >
              <option value="100" selected>
                100
              </option>
              <option value="200">200</option>
              <option value="300">300</option>
            </select>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
/**
 * @author Shivani Pathak-task 3415
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
agencyPublisherLeadtracking.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(agencyPublisherLeadtracking)
);
