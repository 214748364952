/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanobar Golandaj
@Description:UI for agency login
*/

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { userLogin } from "./authentication";
import { Form, Icon, Input, Checkbox, Card } from "antd";
import "./userLogin.css";
import { encryption } from "../../configration/fetch_default_headers";
const Cryptr = require("cryptr");
const cryptr = new Cryptr(encryption.secretKey);

class Agency extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      systemIP: "",
      apss_media: "",
      disabled : false //Aman-Task-4619-(added this handle the login button)-PC/PNC-Login - Client Switch option 
    };

    this.handleChange = this.handleChange.bind(this);
    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this); //Nilesh-5140- bind the function
    this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);  //Nilesh-5140- bind the function
    // this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }
  //Nilesh-5140- Fetching new downloadPrivacyPolicy route
  openPrivacyPolicy(e) {
    e.preventDefault();
    fetch("/campaign/downloadPrivacyPolicy")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download file");
        }
        return response.blob();
      })
      .then((blob) => {
        this.setState({ downloadFiles: blob });
        this.handlePDFFileShowDownload(blob);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }
  
  //Nilesh-5140
  handlePDFFileShowDownload(blob) {
    let newBlob = new Blob([blob], { type: "application/pdf" });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
    } else {
      const url = window.URL.createObjectURL(newBlob);
      window.open(url);
      this.setState({ apss_media: url });
    }
  }

  handleChange(e) {
    let fields = this.state;
    let errors = {};
    errors["wrongusername"] = "";
    errors["wrongpassword"] = "";
    fields[e.target.name] = e.target.value;
    this.setState({
      errors: errors,
    });
    // let errors={}
    // var email=e.target.value;
    // errors['wrongUsername']=''
    // this.setState({errors:errors})
    // this.setState({username:username});
  }

  // handleConfirmBlur = e => {
  //   const { value } = e.target;
  //   this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  // };
  /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
  //      */
  // submituserRegistrationForm(e) {
  //   e.preventDefault();
  //   const user = {
  //     userName: this.state.emailid,
  //     password: this.state.password
  //    };

  //   //  alert("UserName=="+user.userName);
  //   //  alert("UserName=="+user.password);
  //   if(this.validateForm()===true){

  //     this.props.userLogin(user);
  //    }
  //    else{
  //      return
  //    }

  // }
  handleSubmit = (e) => {
    e.preventDefault();
      if (this.state.disabled) { //Aman-Task-4619-(added this to prevent multiple click event)-PC/PNC-Login - Client Switch option 
        return;
      }
    this.setState({disabled: true}); //Aman-Task-4619-(added disable login button)-PC/PNC-Login - Client Switch option 
    //Rutuja -4214 Login Route Issue - Payload showing details (encrypted Password)
    var encryptedPassword = cryptr.encrypt(this.state.password);
    var encryptedUserName = cryptr.encrypt(this.state.userName);
    const user = {
      userName: encryptedUserName,
      password: encryptedPassword,
      systemIP: this.state.systemIP,
      loginFrom: "DI_web", //Sonali-3464-flag to identify login from web or mobile
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { errors } = this.state;
        errors.agency = "";
        this.props.userLogin(user);
      } else { 
        return;
      }
    });
  };

  componentDidMount() {
    this.props.auth.isAuthenticated = false;
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
    //Get client IP and country
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((json) => {
        this.setState({ systemIP: json.ip });
      })
      .catch((e) => console.log(e)); //Somnath Task-4085, Add catch block to handle error
  }

  // openPrivacyPolicy() {
  // 	//window.open(Pdf,'_blank');
  // 	fetch("/campaign/getPrivacyPolicyDoc")
  // 		.then((r) => r.blob())

  // 		.then((downloadFiles) => {
  // 			this.setState({ downloadFiles: downloadFiles });
  // 		})
  // 		.then(this.handlePDFFileShowDownload);
  // 	//})
  // }

  // handlePDFFileShowDownload(blob) {
  // 	/***** If POC File is PDF */

  // 	let pdfFileDownloadName = "Privacy_Policy";

  // 	let newBlob = new Blob([this.state.downloadFiles], {
  // 		type: "application/pdf",
  // 	});
  // 	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  // 		window.navigator.msSaveOrOpenBlob(newBlob);
  // 		return;
  // 	}
  // 	const data = window.URL.createObjectURL(newBlob);
  // 	// var a = document.createElement("a");
  // 	// a.href = data;
  // 	// a.download = "APSS_Media_Privacy_Policy";
  // 	window.open(data);
  // 	this.setState({ apss_media: data });

  // 	//a.click();
  // 	// window.URL.revokeObjectURL(data);

  // 	//     setTimeout(function(){
  // 	//   // For Firefox it is necessary to delay revoking the ObjectURL
  // 	//   window.URL.revokeObjectURL(data);
  // 	// }, 100);
  // } /**** End of downloadPOCFie */

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      const userID = nextProps.auth.user.id;
      const userRole = nextProps.auth.user.role;
      const userDetailsInfo = nextProps.auth.user.userDetails; //Aman Task - 4619 -(to get user Details from route) PC/PNC-Login - Client Switch option
      if (userDetailsInfo.length > 1) { //Aman Task - 4619 -(added if/else condtion to handle the user login page) PC/PNC-Login - Client Switch option
        this.props.history.push("/SwitchClient"); //Aman Task - 4619 -(added to Switch if user is Multiple for PC/PNC role it will take you to Client Switch page) PC/PNC-Login - Client Switch option
      } else { //Aman Task - 4619 -(else condtion to handle the other role after login) PC/PNC-Login - Client Switch option
        if (userRole !== undefined) {
          if (userRole === "AC" || userRole === "ANC" || userRole === "AQA") {
            //this.props.history.push('/agencyFrontPage?agencyID='+userID);
            this.props.history.push("/newdashboard", {
              agencyID: userID,
            }); //karan-task-3684-replace query params
          }
          // Nilesh-5162- Replace DI to LC and replace DIQA to LNQA
          if (userRole === "LNQA" || userRole === "LC") { //Nilesh-5007- Added DIQA Commercial role.
            this.props.history.push("/dashboardQA");//Priyanka-4603-Added new dashboard page for DI QA
          }
          if (userRole === "PC" || userRole === "PNC" || userRole === "PQA") {
            this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
          }
          if (
            userRole === "ADV" ||
            userRole === "ADVNC" ||
            userRole === "ADV_QA"
          ) {
            this.props.history.push("/advertiserDashboard", {
              advertiserID: userID,
            }); //snehal-task-3768-vAPT URL changes
          }
          if (userRole === "Admin" || userRole === "Admin") {
            this.props.history.push("/adminDashboard"); //Somnath Task-3930, remove admin ID
          }
          if (userRole === "PC-ACCOUNT") {
            // this.props.history.push("/displayPublisherInvoice?pID=" + userID);
            this.props.history.push("/displayPublisherInvoice"); //kiran - bug 4087- removed pid from url for PAC  login
          }
          if (userRole === "AC-ACCOUNT") {
            //Somnath Task-4085, Add AC Account
            this.props.history.push("/agencyInvoicesList");
          }
          // Aman-4660 -(added new Route) MBAdmin Report Lead File Tracking Report- DMA's Upload lead file count and file details
          if (userRole === "MBAdmin") {
            this.props.history.push("/LeadFileTrackingReport");
          }
        } else { 
          this.props.history.push("/userLogin");
        }
      }
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
      //alert("Errors"+JSON.stringify(this.state.errors));
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { errors } = this.state;
    return (
      <div style={{ backgroundColor: "rgb(216, 221, 230)" }}>
        {/** Somnath Task-3253, Change class to className due to error */}
        <div
          className="container-fluid"
          style={{ position: "absolute", width: "100%", height: "100%" }}
        >
          <div
            className="row"
            style={{ position: "absolute", width: "100%", height: "100%" }}
          >
            <div
              className="col-sm-12 col-md-12 col-lg-12 col-xs-12 col-xl-12  img-responsive "
              id="img1"
              style={{
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 col-xl-12" > 
    <img className="img-responsive img1" src="Login_skeleton_3.png" width="100%" height="auto"></img> */}
              <Card
                className="card11"
                style={{
                  width: 350,
                  height: 400,
                  margin: "auto",
                  display: "block",
                  border: "2px solid #ff000005",
                  borderRadius: "10px",
                  backgroundColor: "rgba(20,72,152,0.0)",
                  right: "381px",
                  paddingTop: "50px",
                }}
              >
                <Form className="Login-form1" onSubmit={this.handleSubmit}>
                  <br />
                  <div
                    className="row"
                    style={{
                      marginLeft: "28px",
                      marginTop: "10px",
                      width: "202px",
                    }}
                  >
                    <span
                      id="invalidError"
                      style={{ color: "red", marginLeft: "15px" }}
                    >
                      {" "}
                      {errors.agency}
                    </span>
                  </div>
                  <br />
                  <Form.Item className="item1">
                    {getFieldDecorator("username", {
                      //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
                      // rules: [{ pattern:  /^\s*$|^[a-zA-Z0-9_%+-]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                      //Sandeep-task-3893-Production issue-Email format issue--All roles
                      rules: [
                        {
                          pattern:
                            /^\s*$|^[.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,

                          required: true,
                          message: "Enter Valid Username!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Username"
                        onChange={this.handleChange}
                        id="userName"
                        name="userName"
                      />
                    )}
                  </Form.Item>
                  {/* snehal-task-3637-User Login---password should not paste--FE(passwoed should not pasted) */}
                  <Form.Item className="item1">
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          pattern:
                            "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&*~!^<>?]){6}",
                          required: true,
                          onpaste: "return false",
                          message: "Enter Valid Password!",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        placeholder="Password"
                        onChange={this.handleChange}
                        id="password"
                        name="password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="item1">
                    {getFieldDecorator("remember", {
                      valuePropName: "checked",
                      initialValue: true,
                    })(
                      <Checkbox
                        style={{
                          color: "white",
                          fontFamily: "-apple-system,BlinkMacSystemFont,",
                          fontWeight: "500",
                        }}
                      >
                        Remember me
                      </Checkbox>
                    )}
                    <a
                      href="forgotPasswordEmail"
                      className="login-form-forgot"
                      style={{ color: "white", fontWeight: "500" }}
                    >
                      Forgot Password ?
                    </a>
                    <br />
                    {/* onClick={this.openPrivacyPolicy} */}
                    <center>
                      <u style={{ color: "white", fontWeight: "500" }}>
                        {" "}
                        Read Our{" "}
                        <a
                          href="#/"
                          style={{ color: "white", fontWeight: "500" }}
                          onClick={this.openPrivacyPolicy}
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </u>
                    </center>
                    {/* <a href={Pdf} >
      Read Privacy Policy
   </a> */}
                    {/* onClick={this.openPrivacyPolicy} */}

                    {/* Aman-Task-4619-(added this condtion to call login route once)-PC/PNC-Login - Client Switch option */}
                    { errors.agency === "" ? (
                      <button
                      type="primary"
                      htmltype="submit"
                      className="login-form-button btn"
                      style={{
                        borderRadius: "40px",
                        fontSize: "18px",
                        backgroundImage:
                          "linear-gradient(#1f143a, #101f65, #093394)",
                        outline: "none",
                        color: "white",
                      }}
                    disabled={this.state.disabled} //Aman-Task-4619-(added this to call login route once)-PC/PNC-Login - Client Switch option 
                    >
                    {this.state.disabled ? 'Log in...' : 'Log in'}
                    </button>
                    ) :( 
                    <div>
                    {errors.agency != "" ? (
                      <button
                      type="primary"
                      htmltype="submit"
                      className="login-form-button btn"
                      style={{
                        borderRadius: "40px",
                        fontSize: "18px",
                        backgroundImage:
                          "linear-gradient(#1f143a, #101f65, #093394)",
                        outline: "none",
                        color: "white",
                      }}
                      disabled={this.state.disabled = false} //Aman-Task-4619-(added this to call login route once)-PC/PNC-Login - Client Switch option 
                    >
                    Log in
                    </button>
                      ) : (
                        <button
                        type="primary"
                        htmltype="submit"
                        className="login-form-button btn"
                        style={{
                          borderRadius: "40px",
                          fontSize: "18px",
                          backgroundImage:
                            "linear-gradient(#1f143a, #101f65, #093394)",
                          outline: "none",
                          color: "white",
                        }}
                        disabled={this.state.disabled = false} //Aman-Task-4619-(added this to call login route once)-PC/PNC-Login - Client Switch option 
                      >
                      Log in
                      </button>
                      )}
                      </div>
                     )}
                    {/* Or <a href="">Register Now!</a>*/}
                  </Form.Item>
                </Form>
              </Card>
            </div>

            {/* <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6 " > 
   
   
     
  
   
    </div> */}
          </div>
        </div>
      </div>
    );
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
  propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(Agency);
export default connect(mapStateToProps, { userLogin })(WrappedNormalLoginForm);
